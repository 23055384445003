import React from "react";

function Content({ children, className }) {
  const [key, setKey] = React.useState(0);
  const containerRef = React.useRef(null);

  React.useEffect(() => {
    containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
  }, [key]);

  const childrenWithAdjustedProps = React.Children.map(children, (child) => {
    return React.cloneElement(child, { setKey });
  });

  return (
    <div ref={containerRef} className={`p-3  ${className}`}>
      {childrenWithAdjustedProps}
    </div>
  );
}

export default Content;
