import React from "react";
import { GoogleMap, Marker, LoadScript, Circle } from "@react-google-maps/api";
import { constant } from "../_config/constant";

const containerStyle = {
  width: "100%",
  height: "100%",
};

function AddressMapBox({ location }) {
  const center = {
    lat: location[1],
    lng: location[0],
  };

  return (
    <div className="h-96">
      <LoadScript googleMapsApiKey={constant.googleAPI_KEY}>
        <GoogleMap
          zoomControl={false}
          scaleControl={false}
          mapContainerStyle={containerStyle}
          center={center}
          zoom={13}
        >
          {/* <Marker position={center}></Marker> */}
          <Circle
            radius={804}
            center={center}
            strokeColor="transparent"
            strokeOpacity={0}
            strokeWeight={5}
            fillOpacity={0.5}
            options={{strokeColor:'transparent',fillColor:'#1362fc'}}
          />
        </GoogleMap>
      </LoadScript>
    </div>
  );
}

export default React.memo(AddressMapBox);
