import { toast } from 'react-toastify'
export var monthFull = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
export var month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export function getMonthFromIndex(ind) {
    return monthFull[ind]
}

export function getNumberOfDays(chkIn, chkOut) {
    let today = new Date(chkIn)
    const lastDayofMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)
    if (!chkOut) {
        let noOfDay = (lastDayofMonth - new Date(chkIn)) / (1000 * 3600 * 24)
        noOfDay++ //current day
        return Math.ceil(noOfDay)
    }

    let noOfDays = (chkOut - chkIn) / (1000 * 3600 * 24)
    noOfDays++ //current day
    return Math.ceil(noOfDays || 0)
}

export const getCoords = async () => {
    const pos = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            (pos) => resolve(pos),
            (err) => reject(err)
        )
    })

    return pos
}

export const getFormattedDate = (dd) => {
    let date = new Date(dd).getDate()
    let month = new Date(dd).getMonth() + 1
    let year = new Date(dd).getFullYear()

    if (date < 10) date = `0${date}`
    if (month < 10) month = `0${month}`
    return `${year}-${month}-${date}`
}

export const getTommorow = () => {
    const tommorow = new Date()
    tommorow.setDate(new Date().getDate() + 1)

    return getFormattedDate(tommorow)
}

export const getNextMonth = (dd) => {
    let nxt = new Date(dd)
    nxt.setMonth(nxt.getMonth() + 1)
    return nxt
}

export function isWithinSevenDays(checkInDate) {
    const currentDate = new Date()
    const differenceInMilliseconds = checkInDate - currentDate
    const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24)
    return differenceInDays <= 7
}
