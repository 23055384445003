import { useState, useRef, useEffect } from "react";
import useQuery from "@hooks/useQuery";
import Modal from "@components/Modal";
import Button from "@ui/Button";
import { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import { getAmenitiesList } from "@services/propertyFilter";
import useOutsideClickHandler from "@hooks/useClickOutside";
import { toast } from "react-toastify";

const Typography = ({ type, children, className, ...props }) => {
  switch (type) {
    case "filter-heading": {
      return (
        <h4
          {...props}
          className={`text-sm  font-medium flex justify-between items-center  ${className}`}
        >
          {children}
        </h4>
      );
    }
    case "filter-description": {
      return (
        <p {...props} className={`text-xs  ${className}`}>
          {children}
        </p>
      );
    }
    default: {
      return (
        <p {...props} className={`text-xs ${className}`}>
          {children}
        </p>
      );
    }
  }
};

const FormCard = ({
  title,
  gridcols = 1,
  properties,
  id,
  value,
  setValue,
  selectAll,
  onSelectAll,
  ...props
}) => {
  return (
    <div
      {...props}
      className="space-y-2 py-5 border-b border-gray-200 text-gray-600"
    >
      <Typography type="filter-heading">
        {title}{" "}
        {selectAll && (
          <input
            onChange={onSelectAll}
            type="checkbox"
            name="allAmenities"
            id=""
            className="h-5 w-5"
          />
        )}
      </Typography>
      <div className="grid grid-cols-2 gap-2">
        {properties.map((property, index) => {
          return (
            <div
              key={Date.now() + index}
              className="flex items-center space-x-2 mr-2"
            >
              <input
                value={property.value}
                name={id}
                defaultChecked={value.includes(property.value)}
                onChange={(e) => {
                  if (!e.target.checked) {
                    setValue((e) => {
                      const obj = { ...e };
                      obj[id] = e[id].filter((curr) => curr !== property.value);
                      return obj;
                    });
                  } else {
                    setValue((e) => {
                      const obj = { ...e };
                      obj[id] = e[id].filter((curr) => curr !== property.value);
                      obj[id].push(property.value);
                      return obj;
                    });
                  }
                }}
                type="checkbox"
                className="h-5 w-5"
              />
              <Typography type="filter-description" className="text-gray-500">
                {property.key}
              </Typography>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const RangeSelect = ({
  title,
  description,
  min,
  max,
  step = 1,
  dots,
  value,
  setValue,
}) => {
  const [minValue, setMinValue] = useState(value[0]);
  const [maxValue, setMaxValue] = useState(value[1]);
  return (
    <div className="space-y-4 py-8 border-b border-gray-200 text-gray-700">
      <Typography type="filter-heading">{title}</Typography>
      <Typography type="filter-description">{description}</Typography>
      <div className="grid grid-cols-2 gap-x-5">
        <div className=" rounded border-2 border-gray-300 py-1.5 px-3">
          <Typography type="filter-description">Min</Typography>
          <input
            type="number"
            className="text-sm outline-none w-full ring-0 border-0 focus:ring-0"
            value={minValue}
            onChange={(e) => {
              setValue((c) => {
                return [parseInt(e.target.value), c[1]];
              });
              setMinValue(parseInt(e.target.value));
            }}
            min={min}
            max={maxValue - step}
          />
        </div>
        <div className=" rounded border-2 border-gray-300 py-1.5 px-3">
          <Typography type="filter-description">Max</Typography>
          <input
            type={maxValue >= max ? "text" : "number"}
            value={maxValue >= max ? `${max - step}+` : maxValue}
            onChange={(e) => {
              setValue((c) => {
                return [c[0], parseInt(e.target.value)];
              });
              setMaxValue(parseInt(e.target.value));
            }}
            className="text-sm outline-none w-full ring-0 border-0 focus:ring-0"
            min={minValue}
            max={max}
          />
        </div>
      </div>

      <Range
        value={[minValue, maxValue]}
        min={min}
        max={max}
        dots={dots}
        step={step}
        onChange={(e) => {
          setValue(e);
          setMaxValue(e[1]);
          setMinValue(e[0]);
        }}
        className="col-span-full text-primary-blue"
      />
    </div>
  );
};

export default function FilterModal({ title, onClose, onSubmit, formData }) {
  const query = useQuery();
  const [filterData, setFilterData] = useState({ ...formData });
  const [price, setPrice] = useState(formData.price);
  const [allAmenitiesSelected, setAllAmenitiesSelected] = useState(false);
  const [stayLimit, setStayLimit] = useState(formData.stayLimit);
  const [filterFields, setFilterFields] = useState([
    {
      title: "Listing Type",
      key: "listing_type",
      properties: [
        { key: "Crashpad", value: "Crashpad" },
        { key: "Entire Place", value: "Entire Place" },
      ],
    },
    {
      title: "Property Type",
      key: "property_type",
      properties: [
        { key: "House", value: "House" },
        { key: "Hotel", value: "Hotel" },
        { key: "Apartment", value: "Apartment" },
      ],
    },
    {
      title: "Profession",
      key: "profession",
      properties: [
        { key: "Flight Attendant Only", value: "Flight Attendant Only" },
        {
          key: "Flight Attendant and Pilot",
          value: "Flight Attendant and Pilot",
        },
        { key: "Pilot Only", value: "Pilot Only" },
        {
          key: "All Airline Professionals",
          value: "All Airline Professionals",
        },
      ],
    },

    {
      title: "Gender",
      key: "gender",
      properties: [
        { key: "Male", value: "Male" },
        { key: "Female", value: "Female" },
        { key: "Coed", value: "Coed" },
      ],
    },
  ]);

  const [radiusToAirport, setRadiusToAirport] = useState(
    formData.radiusToAirport
  );
  const filterRef = useRef();
  useOutsideClickHandler(filterRef, onClose);

  const handleFilterForm = (e) => {
    e.preventDefault();

    onSubmit({ ...filterData, price, stayLimit, radiusToAirport });
  };

  useEffect(() => {
    const fetchAmenities = async () => {
      try {
        let { data } = await getAmenitiesList();

        let newFilterFields = [...filterFields];
        const filtered = newFilterFields.filter(
          (curr) => curr.key !== "amenities"
        );
        filtered.push({
          title: "Amenities",
          key: "amenities",
          properties: data.data.map((curr) => {
            return { key: curr.name, value: curr.id };
          }),
        });

        if (
          JSON.stringify(data.data.map((curr) => curr.id)) ===
          JSON.stringify(formData["amenities"])
        ) {
          setAllAmenitiesSelected(true);
        } else setAllAmenitiesSelected(false);
        setFilterFields(filtered);
      } catch (error) {
        toast.error("Something went wrong",{toastId:91});
      }
    };

    fetchAmenities();
  }, []);
  return (
    <Modal onClose={onClose} title={title} className="text-gray-700 h-4/6 ">
      <form ref={filterRef} onSubmit={handleFilterForm}>
        {filterFields.map((subCard, index) => {
          return (
            <FormCard
              // selectAll={subCard.key === "amenities"}
              onSelectAll={() => {
                const arr = [];
                subCard.properties.map((el) => {
                  arr.push(el.value);
                });
                setFilterData((e) => ({ ...e, [subCard.key]: arr }));
              }}
              key={index}
              title={subCard.title}
              id={subCard.key}
              gridcols={subCard.gridcols}
              properties={subCard.properties}
              value={filterData[subCard.key]}
              setValue={setFilterData}
            />
          );
        })}

        <RangeSelect
          title="Price"
          description="Filter By"
          min={0}
          max={1001}
          step={1}
          value={price}
          setValue={setPrice}
        />
        {(query.get("bookingType") === "Cold bed" ||
          query.get("bookingType") === "Flexible") && (
          <RangeSelect
            title="Stay Limits"
            description="Nights"
            min={1}
            max={30}
            value={stayLimit}
            setValue={setStayLimit}
          />
        )}

        <RangeSelect
          title="Radius to Airport"
          description="Filter By"
          min={0}
          max={101}
          step={1}
          value={radiusToAirport}
          setValue={setRadiusToAirport}
        />
        <div className="sticky  bottom-0 bg-white text-gray-700 flex justify-between items-center px-3 py-2">
          <Typography type="filter-description">300+ Places</Typography>
          <Button
            type="submit"
            btnType="filled"
            className="text-white px-6 py-2 rounded-lg"
          >
            Done
          </Button>
        </div>
      </form>
    </Modal>
  );
}
