import React, { useEffect, useState, useCallback } from 'react'
import { GoSearch } from 'react-icons/go'
import { useAuth } from '@context/authContext'
import ChatCard from './ChatCard'

const ChatList = ({ onSelectChannel, activeChannel, client, viewList, setViewList, refreshChatlist, setRefreshChatlist, onChannelsLoaded }) => {
    const [channelUserStatuses, setChannelUserStatuses] = useState({})
    const [pagination, setPagination] = useState({
        nextPageFunc: null,
        prevPageFunc: null,
        hasNextPage: false,
        hasPrevPage: false,
    })
    const [channels, setChannels] = useState({
        list: [],
        filtered: [],
        search: '',
    })
    const [loadingState, setLoadingState] = useState({
        initial: true,
        updating: false,
    })
    const [receivingMessages, setReceivingMessages] = useState(new Set()) // Track channels receiving messages
    const [allChannelsLoaded, setAllChannelsLoaded] = useState(false)
    const { user } = useAuth()

    const loadPage = async (pageFunc) => {
        if (pageFunc) await pageFunc()
    }

    const fetchUserChannels = useCallback(
        async (isInitial = false) => {
            try {
                if (isInitial) {
                    setLoadingState({ initial: true, updating: false })
                } else {
                    setLoadingState((prev) => ({ ...prev, updating: true }))
                }

                const list = await client.current.getUserChannelDescriptors()

                setPagination({
                    hasNextPage: list.hasNextPage,
                    hasPrevPage: list.hasPrevPage,
                    nextPageFunc: list.hasNextPage ? list.state.nextToken : null,
                    prevPageFunc: list.hasPrevPage ? list.state.prevToken : null,
                })

                const channelsData = await Promise.all(
                    list.state.items.map(async (curr) => {
                        const channel = await client.current.getChannelBySid(curr.sid)

                        // Fetch the last message
                        const messages = await channel.getMessages(1)
                        const lastMessage = messages.items[0] || null

                        // Fetch all members and their statuses
                        const members = await channel.getMembers()
                        const users = {}

                        for (const member of members) {
                            const userId = member.identity
                            const userStatus = await client.current.getUser(userId)
                            users[userId] = userStatus.state

                            if (userId !== user.id.toString()) {
                                users['displayState'] = userStatus.state.online
                            }
                        }

                        // Listen for messages added
                        channel.on('messageAdded', (message) => {
                            if (message.author.toString() !== user.id.toString()) {
                                setReceivingMessages((prev) => new Set(prev).add(curr.uniqueName))
                                setRefreshChatlist((prev) => !prev)
                            }
                        })

                        // Listen for channel updates
                        channel.on('updated', (details) => {
                            setRefreshChatlist((prev) => !prev)
                        })

                        return {
                            channelId: curr.uniqueName,
                            name: curr.friendlyName,
                            property: curr.attributes.property,
                            unreadMessages: curr.descriptor.unread_messages_count,
                            lastMessageText: lastMessage?.body || '',
                            lastMessageDate: lastMessage?.dateCreated || null,
                            users,
                        }
                    })
                )

                const userChannels = channelsData
                    .filter((channel) => channel.channelId.split('-')[1] === user.id.toString())
                    .sort((a, b) => new Date(b.lastMessageDate) - new Date(a.lastMessageDate))

                client.current.on('userUpdated', (updatedUser) => {})

                setChannels({ list: userChannels, filtered: userChannels, search: '' })
                setLoadingState({ initial: false, updating: false })
                setAllChannelsLoaded(true) // Mark all channels as loaded

                client.current.on('userUpdated', (updatedUser) => {})

                // Notify parent component that channels are loaded
                if (onChannelsLoaded) {
                    onChannelsLoaded(userChannels)
                }

                // Set initial user statuses
                setChannelUserStatuses(
                    userChannels.reduce((acc, channel) => {
                        acc[channel.channelId] = channel.users
                        return acc
                    }, {})
                )
            } catch (error) {
                console.error('Error fetching channels:', error)
                setLoadingState({ initial: false, updating: false })
            }
        },
        [client, user.id, setRefreshChatlist, onChannelsLoaded]
    )

    const updateUnreadCount = useCallback((channelId, count) => {
        setChannels((prev) => {
            const updatedList = prev.list.map((channel) => (channel.channelId === channelId ? { ...channel, unreadMessages: count } : channel))
            const filteredList = updatedList.filter(
                (channel) =>
                    channel.property.name.toUpperCase().includes(prev.search.toUpperCase()) ||
                    channel.property.host.toUpperCase().includes(prev.search.toUpperCase()) ||
                    channel.property.reservationId.toUpperCase().includes(prev.search.toUpperCase())
            )
            return { ...prev, list: updatedList, filtered: filteredList }
        })
    }, [])

    useEffect(() => {
        fetchUserChannels(true) // Initial load
    }, [fetchUserChannels])

    useEffect(() => {
        if (!loadingState.initial) {
            fetchUserChannels() // Subsequent updates
        }
    }, [fetchUserChannels, refreshChatlist, loadingState.initial])

    useEffect(() => {
        if (receivingMessages.size > 0) {
            setLoadingState((prev) => ({ ...prev, updating: true }))
        } else {
            setLoadingState((prev) => ({ ...prev, updating: false }))
        }
    }, [receivingMessages])

    const handleSearchFilter = (text) => {
        setChannels((prev) => ({
            ...prev,
            search: text,
            filtered: prev.list.filter((channel) =>
                `${channel.property.name}-${channel.property.user}${channel.property.reservationId}`.toUpperCase().includes(text.toUpperCase())
            ),
        }))
    }

    return (
        <div
            className={`flex flex-col px-2 md:col-span-2 absolute md:static top-0 left-0 right-0 bottom-0 z-[49] transform md:transform-none bg-[#f5f7fb] text-sm max-h-full overflow-hidden transition-all ${
                viewList ? 'translate-x-[-100%]' : ''
            }`}
        >
            <div className="p-4">
                <h2 className="text-lg flex items-center font-medium justify-start">Chats</h2>
            </div>
            <div className="my-2 mx-2 rounded p-1 bg-white flex items-center">
                <GoSearch className="m-2 flex-shrink-0" />
                <input
                    type="text"
                    value={channels.search}
                    onChange={(e) => handleSearchFilter(e.target.value)}
                    className="bg-transparent flex-grow text-sm p-0 outline-none ring-0 border-0 focus:ring-0 focus:border-0"
                    placeholder="Search"
                />
            </div>
            {loadingState.initial && !allChannelsLoaded ? (
                <div className="flex-center h-screen">
                    <div className="loader"></div>
                </div>
            ) : (
                <div className="overflow-y-scroll scrollbar-hide flex-grow">
                    {loadingState.updating && !allChannelsLoaded && (
                        <div className="dotloader m-2">
                            <div className="dotloading"></div>
                            <div className="dotloading"></div>
                            <div className="dotloading"></div>
                        </div>
                    )}
                    {!channels.list.length && <p className="text-center my-10">No Conversation Found!</p>}
                    {pagination.hasPrevPage && (
                        <p onClick={() => loadPage(pagination.prevPageFunc)} className="my-2 text-center text-sm cursor-pointer">
                            Prev
                        </p>
                    )}
                    {(channels.search ? channels.filtered : channels.list).map((curr, ind) => (
                        <ChatCard
                            key={ind}
                            data={curr}
                            activeChannel={activeChannel}
                            onSelectChannel={onSelectChannel}
                            setViewList={setViewList}
                            updateUnreadCount={updateUnreadCount}
                            userStatus={channelUserStatuses}
                        />
                    ))}
                    {pagination.hasNextPage && (
                        <p onClick={() => loadPage(pagination.nextPageFunc)} className="my-2 text-center text-sm cursor-pointer">
                            Next
                        </p>
                    )}
                </div>
            )}
        </div>
    )
}

export default ChatList
