import { useEffect, useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import useTextZoomDisable from '@hooks/useTextZoomDisable'
import Home from '@pages/Home'
import BecomeHost from '@pages/Become-host'
import PropertyListing from '@pages/Property-listing'
import About from '@pages/About'
import Contact from '@pages/Contact'
import Test from '@pages/test'
import Register from '@pages/Register'
import Login from '@pages/Login'
import ForgetPassword from '@pages/ForgetPassword'
import ResetPasswordEmail from '@pages/ResetPasswordEmail'
import ResetPasswordPhone from '@pages/ResetPasswordPhone'
import Dashboard from '@pages/dashboard'
import PropertyDetails from './pages/Property-details'
import Booking from '@pages/Booking'
import Faqs from '@pages/Faqs'
import PaymentConfirmation from '@pages/payment-confirmation'
import BookingDetails from '@pages/banking-details'
import Verification from '@pages/Verification'
import PrivateRoute, { VerificationRoute } from './utils/PrivateRoute'
import TermsCondition from '@pages/TermsCondition'
import PrivacyPolicy from '@pages/PrivacyPolicy'
import ScrollToTop from '@utils/ScrollToTop'
import LandingPage from '@pages/LandingPage'
import { ToastContainer } from 'react-toastify'
import 'animate.css'
import 'react-tippy/dist/tippy.css'
import 'react-datepicker/dist/react-datepicker.css'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { AppConfig } from './Config'
import MaintenanceMode from './components/MaintenanceMode'
import { siteSettings } from './_services/general'

const stripePromise = loadStripe(AppConfig.stripePublisherKey)
function App() {
   const [settings, setSettings] = useState(null);
   const [isLoading, setIsLoading] = useState(true);


   useEffect(() => {
        siteSettings().then(data=>{
            console.log(data.data.data)
            setSettings(data.data.data)
            setIsLoading(false)
        }).catch(e=>{
            console.log(e)
            setIsLoading(false)
        })
   }, [])
   
    useTextZoomDisable()

    useEffect(() => {
        window.onunhandledrejection = (err) => {
            // handle error
        }
    }, [])


    if(settings &&  settings.maintenanceMode){
        return <MaintenanceMode/>
    }

    


    return (
        <Elements stripe={stripePromise}>
            <div className="App">
                <ScrollToTop />
                <ToastContainer />
                <Switch>
                    {/* comment section start */}
                    {/* <Route exact path="/" component={Home} /> */}
                    <Route exact path="/" component={LandingPage} />
                    <Route exact path="/login" component={Login} />
                    {/* <Route exact path="/home" component={Home} /> */}
                    <Route exact path="/home" component={LandingPage} />
                    <Route exact path="/property-listing" component={PropertyListing} />
                    <Route exact path="/property-details/:id" component={PropertyDetails} />
                    <Route exact path="/about" component={About} />
                    <Route exact path="/booking-details" component={BookingDetails} />
                    <Route exact path="/payment-confirmation" component={PaymentConfirmation} />
                    <Route exact path="/booking-preview" component={Booking} />
                    <PrivateRoute path="/dashboard" component={Dashboard} />
                    {/* <PrivateRoute path="/update-payment-information" component={UpdatePaymentInformation} /> */}

                    {/* comment section end */}

                    <Route exact path="/test" component={Test} />
                    <Route exact path="/faq" component={Faqs} />
                    <Route exact path="/become-host" component={BecomeHost} />
                    <Route exact path="/register" component={Register} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/forget-password" component={ForgetPassword} />
                    <Route exact path="/reset-password-email" component={ResetPasswordEmail} />
                    <Route exact path="/reset-password-phone" component={ResetPasswordPhone} />
                    <Route exact path="/contact" component={Contact} />
                    <Route exact path="/terms-and-condition" component={TermsCondition} />
                    <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                    <VerificationRoute exact path="/verification" component={Verification} />
                    {/* <Redirect from="/" to="/register" /> */}
                </Switch>
            </div>
        </Elements>
    )
}

export default App
