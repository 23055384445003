import React, { useState } from "react";
import { changePassword } from "@services/user";
import TextInput from "@dashboard/components/TextInput";
import { toast } from "react-toastify";
function ChangePassword() {
  const [requesting, setRequesting] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const handleFormSubmit = async (e) => {
    try {
      e.preventDefault();
      setRequesting(true);
      const data = { oldPassword, newPassword, confirmPassword };
      const res = await changePassword(data);

      if (!res.data.status) {
        toast.error(res.data.message);
      } else toast.success(res.data.message);
      setRequesting(false);
    } catch (error) {
      toast.error("Something went wrong!");
      setRequesting(false);
    }
  };
  return (
    <>
      <form onSubmit={handleFormSubmit} className="md:w-6/12 space-y-3 ">
        <TextInput
          type="password"
          value={oldPassword}
          setValue={setOldPassword}
          label="Old Password"
          required
          minLength={8}
        />
        <TextInput
          type="password"
          value={newPassword}
          setValue={setNewPassword}
          label="New Password"
          required
          minLength={8}
        />
        <TextInput
          type="password"
          value={confirmPassword}
          setValue={setConfirmPassword}
          label="Confirm New Password"
          required
          minLength={8}
        />
        <button
          type="submit"
          className="rounded-md p-3 px-8 bg-primary-blue text-white text-sm "
        >
          {requesting ? "Please wait..." : "Submit"}
        </button>
      </form>
    </>
  );
}

export default ChangePassword;
