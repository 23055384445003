import { autocomplete, multi, single } from "air-port-codes-node";

export const getAirportList = (term, onSuccess, onError) => {
  const apca = multi({
    key: "8a97eb671c",
    secret: "16b8a8f50f1988b", // Your API Secret Key: use this if you are not connecting from a web server
    limit: 5,
  });
  apca.request(term);
  apca.onSuccess = onSuccess;
  apca.onError = onError;
};

export const getIataInfo = async (iata, onSuccess, onError) => {
  const apca = single({
    key: "8a97eb671c",
    secret: "16b8a8f50f1988b", // Your API Secret Key: use this if you are not connecting from a web server
    limit: 5,
  });
  apca.request(iata);
  apca.onSuccess = onSuccess;
  apca.onError = onError;
};
