import React from "react";
import Header from "@front/Header";
import Footer from "@front/Footer";
import Section from "@layout/Section";
import { FiCheckCircle } from "react-icons/fi";

import BreadCrunb from "@components/BreadCrunb";

const TeamCard = ({ profile, name }) => {
  return (
    <div className="w-full transform transition-all cursor-pointer  hover:-translate-y-5 rounded-2xl shadow-xl ">
      <img
        className="w-full h-64     rounded-2xl object-cover"
        src={profile}
        alt={name}
      />
      <div className="py-2 pb-4">
        <p className="text-base  lg:text-lg text-center text-gray-800 font-semibold">
          {name}
        </p>
        <p className=" text-xs lg:text-sm text-center font- text-primary-blue ">
          (DEVELOPER)
        </p>
      </div>
    </div>
  );
};

const ValueCard = ({ vector, title, description }) => {
  return (
    <div className="p-8 transform transition-all hover:-translate-y-5 rounded-xl hover:bg-parrot-light hover:bg-opacity-10   shadow-xl cursor-pointer ">
      <img className="mb-8 h-16 mx-auto" src={vector} alt={title} />
      <h4 className="text-base lg:text-xl text-gray-600 font-medium  my-2 mb-5">
        {title}
      </h4>
      <p className="text-gray-500 my-2 text-sm">{description}</p>
    </div>
  );
};

function About() {
  return (
    <div>
      <Header />
      <Section className="px-4 mt-24  ">
        <BreadCrunb
          className="mb-10 mt-5"
          title="About Us"
          bread={[
            { name: "Home", path: "/" },
            { name: "About Us", path: "/about" },
          ]}
        />

        <div className="mb-32 flex flex-col md:flex-row ">
          <div className="text-gray-500 md:h-auto md:w-6/12 sm:p-2 lg:p-5">
            <h3 className=" primary__heading__caption">WHY CHOOSE US</h3>
            <h2 className=" primary__heading mb-4">Why Crew mates?</h2>
            <span className="text-xl my-4 block font-medium text-gray-900">
              Easy & Fast
            </span>
            <p className="text-sm ">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged.{" "}
            </p>
          </div>
          <div className=" my-4 md:my-0   md:h-auto h-64  w-full md:w-6/12 overflow-hidden relative">
            <img
              src="/assets/resources/pilot-travel.jpg"
              alt="pilot travelling"
              className="h-full w-full md:w-10/12 mx-auto object-cover rounded-xl absolute top-0 right-0 left-0"
            />
          </div>
        </div>
        <div className="mb-32">
          <h3 className="text-center primary__heading__caption">OUR STORY</h3>
          <h2 className="text-center primary__heading">
            Story Behind crewmates
          </h2>
          <div className="lg:w-10/12 mx-auto flex flex-col md:flex-row  my-10 md:my-20">
            <div className=" w-full h-64 my-4 md:h-auto md:w-6/12 overflow-hidden relative ">
              <img
                src="/assets/resources/happy-client.jpg"
                alt="pilot travelling"
                className="h-full w-full md:w-10/12 mx-auto object-cover rounded-xl absolute top-0 right-0 left-0"
              />
            </div>
            <div className=" w-full md:w-6/12 text-gray-600 md:p-4">
              <p className=" text-sm lg:text-base mb-4">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,
              </p>
              <p className=" text-sm lg:text-base mb-4">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,
              </p>
              <div className="my-4">
                <p className="flex items-start justify-start mb-4 text-sm lg:text-base md:w-10/12">
                  <FiCheckCircle
                    className="text-parrot-dark  mr-2 flex-shrink-0"
                    size={24}
                  />
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting
                </p>
                <p className="flex items-start justify-start mb-4 text-sm lg:text-base md:w-10/12">
                  <FiCheckCircle
                    className="text-parrot-dark  mr-2 flex-shrink-0"
                    size={24}
                  />
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting
                </p>
                <p className="flex items-start justify-start mb-4 text-sm lg:text-base md:w-10/12">
                  <FiCheckCircle
                    className="text-parrot-dark  mr-2 flex-shrink-0"
                    size={24}
                  />
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-32">
          <h3 className="text-center primary__heading__caption">
            WHAT WE SERVE
          </h3>
          <h2 className="text-center primary__heading">Top Values For You</h2>
          <div className="grid py-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 my-10 w-10/12 lg:w-8/12 mx-auto">
            <ValueCard
              vector="/assets/resources/family.png"
              title="We have a place for everyone"
              description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, "
            />
            <ValueCard
              vector="/assets/resources/home-vector.png"
              title="We want families to travel better together"
              description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, "
            />
            <ValueCard
              vector="/assets/resources/globe-flight.png"
              title="We believe in family connection"
              description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, "
            />
          </div>
        </div>
        <div className="mb-4">
          <h3 className="text-center primary__heading__caption">
            MEET THE TEAM
          </h3>
          <h2 className="text-center primary__heading">
            Faces Behind Crewmates
          </h2>
          <div className="grid py-10 grid-cols-1 md:grid-cols-2  lg:grid-cols-4 gap-6 my-10 w-11/12 lg:w-8/12 mx-auto">
            <TeamCard profile="/assets/resources/team1.jpg" name="John" />
            <TeamCard profile="/assets/resources/team2.jpg" name="Ajay" />
            <TeamCard profile="/assets/resources/team3.jpg" name="Akriti" />
            <TeamCard profile="/assets/resources/team4.jpg" name="Ashutosh" />
          </div>
        </div>
      </Section>
      <Footer />
    </div>
  );
}

export default About;
