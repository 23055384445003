import React from 'react'
import { Route, Switch, NavLink } from 'react-router-dom'

import Navbar from '@dashboard/Navbar'
import ChangePassword from './ChangePassword'
import SavedCards from './SavedCards'
import BillingAddress from './BillingAddress'
import Notification from './Notification'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

function Setting() {
    return (
        <div>
            <Navbar title={'Settings'} />
            <div className="lg:mr-44">
                <div className="my-10 flex flex-wrap gap-y-2 items-center sm:gap-4 ga-2 overflow-scroll scrollbar-hide py-2 px-2  md:gap-5">
                    <NavLink
                        exact
                        className="p-4 px-6 rounded-md flex-shrink-0 text-sm ring-1 ring-primary-blue-dim text-center mr-3"
                        activeClassName="bg-primary-blue text-white "
                        to="/dashboard/setting"
                    >
                        Saved Card
                    </NavLink>
                    <NavLink
                        exact
                        className="p-4 px-6 rounded-md flex-shrink-0 text-sm  ring-1 ring-primary-blue-dim text-center mr-3"
                        activeClassName="bg-primary-blue text-white"
                        to="/dashboard/setting/change-password"
                    >
                        Change Password
                    </NavLink>
                    {/* <NavLink
                        exact
                        className="p-4 px-6 rounded-md flex-shrink-0 text-sm ring-1 ring-primary-blue-dim text-center mr-3"
                        activeClassName="bg-primary-blue text-white "
                        to="/dashboard/setting/billing-address"
                    >
                        Billing Address
                    </NavLink>
                    <NavLink
                        exact
                        className="p-4 px-6 rounded-md flex-shrink-0 text-sm  ring-1 ring-primary-blue-dim text-center mr-3"
                        activeClassName="bg-primary-blue text-white"
                        to="/dashboard/setting/notification"
                    >
                        Notification
                    </NavLink> */}
                </div>

                <div className="my-2">
                    <Switch>
                        <Route exact path="/dashboard/setting/change-password" component={ChangePassword} />
                        <Route exact path="/dashboard/setting" component={SavedCards} />
                        <Route exact path="/dashboard/setting/billing-address" component={BillingAddress} />
                        <Route exact path="/dashboard/setting/notification" component={Notification} />
                    </Switch>
                </div>
            </div>
        </div>
    )
}

export default Setting
