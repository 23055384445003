import React, { useRef } from "react";
import { BsChevronDown } from "react-icons/bs";
import { Swiper, SwiperSlide } from "swiper/react";
import Section from "@layout/Section";

import "swiper/swiper-bundle.css";

// // import Swiper core and required modules
import SwiperCore, { Navigation, Autoplay } from "swiper";

// // install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

function Card() {
  return (
    <div className="py-12 px-10">
      <div className="z-10  rounded-lg shadow-lg bg-white p-4 md:py-10  px-auto relative text-gray-600  ">
        <img
          alt="smiling person"
          className="h-14 w-14 rounded-full absolute -top-10 -left-10"
          src="/assets/resources/smile.png"
        />
        <p className=" z-10 text-sm  mb-10">
          "Lorem Ipsum standard dummy text ever since the 1500s, when an unknown
          printer took a galley of type and scrambled it to make a type specimen
          book."
        </p>

        <div className="">
          <h4 className="text-base  font-medium text-gray-600 ">Mike taylor</h4>
          <span className="text-sm">Banglore, India</span>
        </div>
      </div>
    </div>
  );
}

function Testimonials() {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  return (
    <Section className="  md:my-20  overflow-hidden lg:overflow-visible px-4 md:p-auto md:py-10  ">
      <div className="w-full lg:w-11/12 mx-auto flex flex-col md:flex-row justify-around items-center  ">
        <div className="w-full md:w-6/12 xl:w-4/12">
          <h3 className="text-left primary__heading__caption leading-10 line ">
            Testimonials
          </h3>
          <h2 className="text-left  primary__heading">
            What Our <br /> Customer Say About Us
          </h2>
        </div>
        <div className="w-full md:w-6/12  h-96 md:my-auto flex items-center">
          <Swiper
            className="w-full h-full"
            slidesPerView={1}
            spaceBetween={20}
            loop
            autoplay
            delay={1000}
            direction={"vertical"}
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            onSwiper={(swiper) => {
              // Delay execution for the refs to be defined
              setTimeout(() => {
                // Override prevEl & nextEl now that refs are defined
                swiper.params.navigation.prevEl = prevRef.current;
                swiper.params.navigation.nextEl = nextRef.current;

                // Re-init navigation
                swiper.navigation.destroy();
                swiper.navigation.init();
                swiper.navigation.update();
              });
            }}
          >
            <SwiperSlide className="flex-center ">
              <Card />
            </SwiperSlide>
            <SwiperSlide className="flex-center ">
              <Card />
            </SwiperSlide>
            <SwiperSlide className="flex-center ">
              <Card />
            </SwiperSlide>
            <SwiperSlide className="flex-center ">
              <Card />
            </SwiperSlide>
          </Swiper>
          <div className="">
            <div
              className="h-10 w-10 rounded-full hover:bg-gray-100 flex-center cursor-pointer"
              ref={prevRef}
            >
              <BsChevronDown size={18} className="transform rotate-180  " />
            </div>
            <div
              className="h-10 w-10 rounded-full hover:bg-gray-100 flex-center cursor-pointer"
              ref={nextRef}
            >
              <BsChevronDown size={18} />
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default Testimonials;
