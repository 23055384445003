import React, { useState, useEffect } from "react";
import { IoIosClose as CloseIcon } from "react-icons/io";
import { useHistory, useLocation } from "react-router-dom";
import Persona from "persona";
import Header from "@front/Header";
import Footer from "@front/Footer";
import Section from "@layout/Section";
import { constant } from "@config/constant";
import Button from "@ui/Button";
// import TextInput from "@ui/TextInput";
// import { AiOutlineMail } from "react-icons/ai";
import OtpInput from "react-otp-input";
import Modal from "@components/Modal";
import { forgetPassword, logout } from "@services/user";
import { setResetToken, removeToken, setToken } from "@helper/header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  verifyEmail,
  verifyPhone,
  updateIdVerificationStatus,
} from "@services/user";

function Step1({ onNext, isPhoneVerified = false }) {
  const history = useHistory();
  const [otp, setOtp] = useState("");
  const [isRequesting, setRequesting] = useState(false);

  const handleVerification = async () => {
    try {
      if (otp.length === 4) {
        setRequesting(true);
        const { data } = await verifyEmail({ otp });
        setRequesting(false);
        if (data.status) {
          toast.success(data.message,{toastId:17});

          // send phone otp
          const user = JSON.parse(
            localStorage.getItem(constant.USER_REGISTER_REQUEST)
          );
          if (user) {
            const res = await forgetPassword({
              authType: "phone",
              emailOrPhone: user.phone,
              countryCode: user.countryCode,
            });
            if (res.data.status && !isPhoneVerified) {
              setResetToken(res.data.data.token);
              toast.success("OTP sent",{toastId:18});
            } else if (!res.data.status) {
              toast.error("Something wrong happened!");
            }
          }
          // set new step
          onNext();
        } else {
          toast.error(data.message);
        }
      }
    } catch (error) {
      toast.error("something went wrong");
      setTimeout(logout, 1000);
    }
  };

  const handleResendOtp = async () => {
    const user = JSON.parse(
      localStorage.getItem(constant.USER_REGISTER_REQUEST)
    );
    if (user) {
      const res = await forgetPassword({
        authType: "email",
        emailOrPhone: user.personalEmail,
      });
      if (res.data.status) {
        setResetToken(res.data.data.token);
        toast.success("OTP sent",{toastId:19});
      } else toast.error("Something wrong happened!");
    } else {
      toast.error("Something went wrong");
      history.push("/login");
    }
  };

  return (
    <div className="w-auto mx-4 lg:w-6/12 lg:mx-auto p-4 pt-0 px-0 shadow-md my-20 rounded overflow-hidden">
      <div className="bg-primary-blue p-4 ">
        <p className="text-center text-white text-sm">
          Crewmates Verification Process
        </p>
      </div>

      {/* <p className="text-center text-sm my-2">Step 1 of 3</p> */}
      <h2 className="text-base mt-10 text-center">
        Email Address Verification
      </h2>
      <p className="text-sm text-center mt-2 w-10/12 mx-auto">
        Please check your email and enter the OTP. Check your spam folder if the
        first email doesn't reach your inbox.
      </p>
      <OtpInput
        value={otp}
        onChange={(e) => {
          setOtp(e);
        }}
        isInputNum
        containerStyle="flex-center space-x-4 my-10"
        inputStyle="h-10  w-10 bg-white rounded p-0"
        separator={<span>{"  "}</span>}
        numInputs={4}
      />
      <button
        className="bg-primary-blue p-2 px-4 mx-auto w-max block rounded text-sm text-white
        "
        onClick={handleVerification}
        disabled={isRequesting}
      >
        {isRequesting ? "Please wait ..." : "Next"}
      </button>
      <p
        onClick={handleResendOtp}
        className="text-primary-blue cursor-pointer  text-center text-sm my-4"
      >
        Resend OTP
      </p>
    </div>
  );
}

function Step2({ onNext }) {
  const history = useHistory();
  const [otp, setOtp] = useState("");
  const [isRequesting, setRequesting] = useState(false);

  const handleVerification = async () => {
    try {
      if (otp.length === 4) {
        setRequesting(true);
        const { data } = await verifyPhone({ otp });
        setRequesting(false);

        if (data.status) {
          toast.success(data.message,{toastId:20});
          onNext();
        } else {
          toast.error(data.message,{toastId:21});
        }
      }
    } catch (error) {
      toast.error("something went wrong",{toastId:22});
      setTimeout(logout, 1000);
    }
  };

  const handleResendOtp = async () => {
    const user = JSON.parse(
      localStorage.getItem(constant.USER_REGISTER_REQUEST)
    );
    if (user) {
      const res = await forgetPassword({
        authType: "phone",
        emailOrPhone: user.phone,
        countryCode: user.countryCode,
      });
      if (res.data.status) {
        setResetToken(res.data.data.token);
        toast.success("OTP sent",{toastId:23});
      } else toast.error("Something wrong happened!");
    } else {
      toast.error("Something wrong happened!");
      history.push("/login");
    }
  };

  return (
    <div className="w-auto mx-4 lg:w-6/12 lg:mx-auto  p-4 pt-0 px-0 shadow-md my-20 rounded overflow-hidden">
      <div className="bg-primary-blue p-4 ">
        <p className="text-center text-white text-sm">
          Crewmates Verification Process
        </p>
      </div>

      {/* <p className="text-center text-sm my-2">Step 2 of 3</p> */}
      <h2 className="text-base mt-10 text-center">
        Mobile Number Verification
      </h2>
      <p className="text-sm text-center mt-2">
        Crewmates has sent the OTP to your registered mobile number.
      </p>

      <OtpInput
        value={otp}
        onChange={(e) => {
          setOtp(e);
        }}
        isInputNum
        containerStyle="flex-center space-x-4 my-10"
        inputStyle="h-10  w-10 bg-white rounded p-0"
        separator={<span>{"  "}</span>}
        numInputs={4}
      />
      <button
        className="bg-primary-blue p-2 px-4 mx-auto w-max block rounded text-sm text-white
        "
        onClick={handleVerification}
        disabled={isRequesting}
      >
        {isRequesting ? "Please wait ..." : "Next"}
      </button>
      <p
        onClick={handleResendOtp}
        className="text-primary-blue text-center  cursor-pointer text-sm my-4"
      >
        Resend OTP
      </p>
    </div>
  );
}

function Step3({ onNext }) {
  const [nextBtn, setNextBtn] = useState(true);
  const [personaModal, setPersonaModal] = useState(false);
  const [isRequesting, setRequesting] = useState(false);

  const updatePersonaStatus = async () => {
    setRequesting(true);
    const { data } = await updateIdVerificationStatus();
    setRequesting(false);
    if (data.status) {
      toast.success(data.message,{toastId:24});
      onNext();
    } else {
      toast.error(data.message);
    }
  };
  return (
    <div className="w-auto mx-4 lg:w-6/12 lg:mx-auto  p-4 pt-0 px-0 shadow-md my-20 rounded overflow-hidden">
      <div className="bg-primary-blue p-4 ">
        <p className="text-center text-white text-sm">
          Crewmates Verification Process
        </p>
      </div>
      <h2 className="text-base mt-10 text-center">Selfie & ID Verification</h2>
      <button
        className="bg-primary-blue p-2 my-4 px-4 mx-auto w-max block rounded text-sm text-white"
        onClick={() => setPersonaModal(true)}
      >
        Verify ID and Selfie with Persona
      </button>
      <button
        disabled={nextBtn}
        className={`${
          nextBtn ? "bg-primary-blue-dim " : "bg-primary-blue"
        } p-2 px-4 mx-auto w-max block rounded text-sm text-white`}
        onClick={updatePersonaStatus}
      >
        {isRequesting ? "Please wait ..." : "Confirm"}
      </button>
      {personaModal && (
        <Modal className="w-11/12 mx-4 lg:w-6/12 overflow-scroll scrollbar-hide bg-white">
          <div>
            <Persona.Inquiry
              templateId={constant.persona_template_id}
              environment={constant.persona_mode}
              onLoad={() => {}}
              onComplete={({ inquiryId, status, fields }) => {
                // Inquiry completed. Optionally tell your server about it.

                updatePersonaStatus();
                setNextBtn(false);
                setPersonaModal(false);
              }}
            />
          </div>
        </Modal>
      )}
    </div>
  );
}

function Step4() {
  const history = useHistory();
  const [userNotificationModal, setUserNotificationModal] = useState(false);

  useEffect(() => {
    const userRegisterData = JSON.parse(
      localStorage.getItem(constant.REGISTER_USER_DATA)
    );
    localStorage.removeItem(constant.REGISTER_USER_DATA);
    localStorage.removeItem(constant.USER_REGISTER_REQUEST);
    if (userRegisterData && userRegisterData.user.type === "Host") {
      setTimeout(() => {
        // window.location.replace(
        //   `${constant.HOST_WEB_URL}?token=${
        //     userRegisterData.token
        //   }&data=${JSON.stringify(userRegisterData.user)}`
        // );

        window.location.replace(
          `${constant.HOST_WEB_URL}`
        );
      }, 500);
    } else {
      setToken(userRegisterData.token);
      // setTimeout(() => {
      //   setUserNotificationModal(true);
      // }, 500);
      history.push("/");
    }

    if (!userRegisterData) {
      return toast.warning("Something went wrong, Please login again.");
    }
  }, []);
  return (
    <div className="w-auto mx-4 lg:w-6/12 lg:mx-auto  p-4 pt-0 px-0 shadow-md my-20 rounded overflow-hidden">
      {userNotificationModal && (
        <Modal className="bg-white rounded p-4 w-10/12 mx-auto lg:w-4/12">
          <div className="flex justify-between mb-2 items-center">
            <h4 className="text-sm font-medium text-gray-600">
              Welcome to Crewmates!
            </h4>
            <span
              onClick={() => setUserNotificationModal(false)}
              className="h-10 w-10 rounded-full bg-gray-100 flex-center"
            >
              <CloseIcon className="text-3xl text-gray-500 cursor-pointer" />
            </span>
          </div>
          <div className="text-sm text-gray-600 ">
            Thank you for registering with Crewmates. We are currently in the
            process of onboarding hosts. Join our Crewmates Crashpads Community
            Facebook Group for further assistance finding accommodations.{" "}
            <Button btnType="filled" className="mt-4 mx-auto block">
              <a href="https://www.facebook.com/groups/crewmatescrashpads/">
                Search for Crashpads
              </a>
            </Button>
          </div>
        </Modal>
      )}
      <div className="bg-primary-blue p-4 ">
        <img
          className="h-24 mx-auto mb-4"
          src="/assets/resources/verification-check.png"
          alt="Verification done"
        />
        <p className="text-center text-white text-sm">Congratulations!</p>
      </div>
      <div className="p-10">
        <p className="text-sm text-center w-full lg:w-8/12 mx-auto">
          {/* Your account has been successfully registered of Crewmates, Let's Get
          Started With Listing Your Property! */}
          Your Crewmates account has been successfully registered. Let’s get
          started with listing your property!
        </p>
      </div>
    </div>
  );
}

function Verification() {
  const [userNotificationModal, setUserNotificationModal] = useState(false);
  const location = useLocation();
  const [verifiedField, setVerifiedField] = useState("");
  const [verificationStep, setVerificationStep] = useState(0);

  // useEffect(() => {
  //   const query = new URLSearchParams(location.search).get("verified");
  //   if (query === constant.EMAIL) {
  //     setVerifiedField("Email");
  //   } else if (query === constant.MOBILE) {
  //     setVerifiedField("Phone");
  //   }
  //
  //   if (!query) {
  //     setVerificationStep(0);
  //   }
  // }, []);

  return (
    <>
      <Header type="fixed" />
      <ToastContainer />
      <Section>
        {/* {verifiedField === "Email" && (
          <Step2
            onNext={() => {
              setVerificationStep(2);
              setVerifiedField("");
            }}
          />
        )}
        {verifiedField === "Phone" && (
          <Step1
            onNext={() => {
              setVerificationStep(2);
              setVerifiedField("");
            }}
            isPhoneVerified
          />
        )} */}

        {verificationStep === 0 && (
          <Step1 onNext={() => setVerificationStep(verificationStep + 1)} />
        )}
        {verificationStep === 1 && (
          <Step2 onNext={() => setVerificationStep(verificationStep + 1)} />
        )}
        {/* {verificationStep === 2 && (
          <Step3 onNext={() => setVerificationStep(verificationStep + 1)} />
        )}
        {verificationStep === 3 && <Step4 />} */}

        {verificationStep === 2 && <Step4 />}
      </Section>
      <Footer />
    </>
  );
}

export default Verification;
