import React, { useContext, useState, useEffect, useRef, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import Navbar from '@dashboard/Navbar'
import { GetChatToken, UpdateUserResource } from '../../../_services/chat'
import { Context as AuthContext } from '@context/authContext'
import Conversation from './Conversation'
import ChatList from './ChatList'
import { Client } from 'twilio-chat'

const Messages = () => {
    const history = useHistory()
    const client = useRef(null)
    const { user } = useContext(AuthContext)

    const [initialLoading, setInitialLoading] = useState(true)
    const [activeChannel, setActiveChannel] = useState(null)
    const [error, setError] = useState(null)
    const [viewList, setViewList] = useState(false)
    const [refreshChatlist, setRefreshChatlist] = useState(false)

    const fetchChatData = useCallback(async () => {
        try {
            setInitialLoading(true)
            const { data } = await GetChatToken(user.id)
            await UpdateUserResource(user.id, {
                id: user.id,
                name: user.name,
                profile: user.avatar,
                email: user.personalEmail,
                reservationId: history?.location?.state?.property?.reservationId || '',
            })

            client.current = new Client(data.data.token)

            client.current.on('stateChanged', (state) => {
                console.log('me called check here', state)
            })

            client.current.on('connectionStateChanged', (state) => {
                if (state === 'disconnected' || state === 'denied') {
                    console.warn('Connection lost, attempting to reconnect...')
                    handleReconnection()
                }
            })

            client.current.on('tokenAboutToExpire', async () => {
                try {
                    const { data } = await GetChatToken(user.id)
                    await client.current.updateToken(data.data.token)
                } catch (error) {
                    console.error('Failed to refresh token:', error)
                    setError('Token refresh failed!')
                }
            })

            client.current.on('messageAdded', (message) => {
                if (message.author !== user.id) {
                    setRefreshChatlist((prev) => !prev)
                }
            })

            client.current.on('channelUpdated', (channel) => {
                setRefreshChatlist((prev) => !prev)
            })

            if (history?.location?.state?.channelId) {
                setActiveChannel({ ...history.location.state })
                setViewList(true)
            }
        } catch (error) {
            console.error('Error fetching chat data:', error)
            setError('Something went wrong!')
        } finally {
            setInitialLoading(false)
        }
    }, [history.location.state, user.id])

    const handleReconnection = useCallback(async () => {
        if (client.current && client.current.connectionState === 'disconnected') {
            try {
                const { data } = await GetChatToken(user.id)
                await client.current.updateToken(data.data.token)
            } catch (error) {
                console.error('Reconnection attempt failed:', error)
                setError('Reconnection failed!')
            }
        }
    }, [user.id])

    useEffect(() => {
        if (user.id) {
            fetchChatData()
        }

        const intervalId = setInterval(handleReconnection, 30000)

        return () => {
            if (client.current) {
                client.current.removeAllListeners()
                client.current.shutdown()
            }
            clearInterval(intervalId)
        }
    }, [fetchChatData, handleReconnection, user.id])

    const handleChannelsLoaded = (channels) => {
        if (channels.length > 0 && !activeChannel) {
            setActiveChannel(channels[0])
        }
    }

    return (
        <div>
            <Navbar title="Messages" />
            {error && <p className="text-sm text-center text-gray-600 my-12">{error}</p>}
            {initialLoading && (
                <div className="flex-center h-screen">
                    <div className="loader"></div>
                </div>
            )}
            {!error && !initialLoading && (
                <div className="grid md:grid-cols-6 h-[72vh] mt-10 relative overflow-hidden">
                    <ChatList
                        viewList={viewList}
                        setViewList={setViewList}
                        activeChannel={activeChannel}
                        onSelectChannel={setActiveChannel}
                        client={client}
                        refreshChatlist={refreshChatlist}
                        setRefreshChatlist={setRefreshChatlist}
                        onChannelsLoaded={handleChannelsLoaded}
                    />
                    <Conversation
                        setViewList={setViewList}
                        channelDetails={activeChannel}
                        client={client}
                        userId={user.id}
                        setRefreshChatlist={setRefreshChatlist}
                    />
                </div>
            )}
        </div>
    )
}

export default Messages
