import axios from 'axios'
import { constant } from '@config/constant'
import { getAuthHeader } from '@helper/header'
import { handleErrorResponse } from '@helper/utils'
const api = axios.create({ baseURL: constant.apiUrl })

export const GetChatToken = async (id) => {
    try {
        const response = await api.get(`/user/chat/token?identity=${id}`, getAuthHeader())
        return response
    } catch (error) {
        handleErrorResponse(error)
    }
}

export const UpdateUserResource = async (id, data) => {
    try {
        const response = await api.post(
            `/user/chat/update`,
            {
                identity: id,
                user: data,
            },
            getAuthHeader()
        )
        return response
    } catch (error) {
        handleErrorResponse(error)
    }
}
