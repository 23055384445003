import React, { useContext } from 'react'
import { IoMdNotifications } from 'react-icons/io'
import { Context as AuthContext } from '@context/authContext'

import { constant } from '@config/constant'
import { processMediaUrl } from '../_helper/utils'

function Navbar({ title, className }) {
    const { user } = useContext(AuthContext)

    return (
        <div className="flex justify-between flex-wrap items-center">
            <p className="font-semibold text-primary-blue flex items-center justify-start text-xl sm:text-2xl md:text-2xl lg:text-2xl xl:text-3xl 2xl:text-4xl">
                {title}
            </p>

            <div className="flex justify-start items-center ">
                {/* <div className="relative mr-4 sm:mr-10">
          <IoMdNotifications className="text-primary-blue " size={30} />
          <div className="h-4 w-4 rounded-full bg-red-500 text-white absolute text-xs  -top-1 right-0  flex-center">
            3
          </div>
        </div> */}
                <div className="h-14 pr-20 pl-5 relative p3 hidden bg-primary-blue rounded-full text-white md:flex items-center justify-between">
                    {/* <div className="w-10">
                        <img
              className="h-6 z-10 absolute bottom-0 left-0"
              src={"/assets/images/cm-verified.png"}
              alt="Crewmates Verified Badge"
            />
                    </div> */}
                    <div className="text-xs">
                        <p className="">Welcome, </p>
                        <p className=" ">{user.name}</p>
                    </div>
                    <img
                        onError={function ({ currentTarget }) {
                            currentTarget.src = '/user.png'
                        }}
                        className="h-16 w-16 absolute top-0 bottom-0 my-auto right-0 origin-center  rounded-full object-cover"
                        src={processMediaUrl(user.avatar)}
                        alt="dev"
                    />
                </div>
                <div className=" rounded-full gap-2 sm:gap-4 text-black flex md:hidden items-center justify-center">
                    <div className="w-10">
                        {/* <img
              className="h-6 z-10 absolute bottom-0 left-0"
              src={"/assets/images/cm-verified.png"}
              alt="Crewmates Verified Badge"
            /> */}
                    </div>
                    <div className="text-xs ">
                        <p className="">Welcome, </p>
                        <p className=" ">{user.name}</p>
                    </div>
                    <img
                        onError={function ({ currentTarget }) {
                            currentTarget.src = '/user.png'
                        }}
                        className="h-16 w-16  my-auto origin-center rounded-full object-cover"
                        src={processMediaUrl(user.avatar)}
                        alt="dev"
                    />
                </div>
            </div>
        </div>
    )
}

export default Navbar
