import React, { useEffect } from 'react'
import { FiChevronDown } from 'react-icons/fi'
function SelectText({
    optionList,
    setOptionList,
    value,
    setValue,
    label,
    defaultValue = '',
    leftIcon: LeftIcon,
    placeholder,
    datalist = [],
    listId,
    ...props
}) {
    useEffect(() => {
        setValue(defaultValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps

        // setOptionList(true)
    }, [])

    return (
        <div className="flex flex-col space-y-1 justify-start items-start cursor-pointer">
            {label && <label className="text-sm text-gray-600">{label}</label>}
            <div
                className="flex items-center ring-1 ring-gray-200 text-gray-600 rounded w-full relative z-50 bg-gray-100"
                onClick={() => setOptionList(!optionList)}
            >
                {LeftIcon && <span className="border-r p-2   border-gray-300">{LeftIcon}</span>}
                <input
                    size={1}
                    disabled
                    placeholder={placeholder}
                    className="p-2 text-sm flex-grow cursor-pointer outline-none bg-transparent pointer-events-none"
                    value={value}
                    list={listId}
                />
                <span className="p-2 border-gray-300">
                    <FiChevronDown size={24} />
                </span>
                {optionList && (
                    <div className="absolute right-0 w-full top-14 rounded bg-white shadow-md ring-1 ring-gray-100 max-h-44 overflow-y-auto scrollbar-hide ">
                        {datalist.map((curr, i) => {
                            return (
                                <p
                                    key={i}
                                    onClick={() => {
                                        setValue(curr.value)
                                        setOptionList(!optionList)
                                    }}
                                    className="p-2  cursor-pointer hover:bg-gray-100 text-sm text-gray-800 border-l-4   border-blue-500"
                                >
                                    {curr.key}
                                </p>
                            )
                        })}
                    </div>
                )}
            </div>
        </div>
    )
}

export default SelectText
