import React from "react";
import Section from "@layout/Section";

function Card({ icon, title, description }) {
  return (
    <div className="z-10 bg-white  rounded-3xl mx-10 mb-10 md:mb-auto w-10/12 mx-auto  p-5 hover:shadow-xl cursor-pointer ">
      <img
        alt="Home icon"
        className="h-14 w-14 mx-auto my-2 mb-10"
        src={icon}
      />
      <h4 className="text-lg mb-2 font-semibold  text-gray-700">{title}</h4>
      <p className="text-xs text-gray-600">{description}</p>
    </div>
  );
}

function Services() {
  return (
    <Section className=" px-4">
      <h3 className="text-center primary__heading__caption">What we serve</h3>
      <h2 className="text-center primary__heading">Top Values For You</h2>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4  w-8/12  md:w-7/12  py-20 mx-auto justify-around relative">
        <Card
          icon="/assets/resources/home-color.png"
          description="Engrossed listening. Park gate sell they west for the."
          title="Lots of Properties"
        />
        <Card
          icon="/assets/resources/checkpoint.png"
          title="Best Locations"
          description=" It has survived not only five centuries, but also the leap into
            electronic."
        />
        <Card
          icon="/assets/resources/easy-book.png"
          title="Easy Bookings"
          description="We deliver outsourced aviation services for millitary customers."
        />
      </div>
    </Section>
  );
}

export default Services;
