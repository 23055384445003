import { useState, useRef } from "react";
import useOutsideClickHandler from "@hooks/useClickOutside";
import { HiChevronDown } from "react-icons/hi";
import { Range } from "rc-slider";

export const DropdownRange = ({
  title,
  max,
  min,
  onChange,
  step = 1,
  value,
  prefix,
  suffix,
  subHeading = "Range",
}) => {
  const [list, setlist] = useState(false);
  const listRef = useRef();
  useOutsideClickHandler(listRef, () => setlist(false));
  return (
    <div className="w-max flex-shrink-0">
      <div className="cursor-pointer text-gray-500  relative flex items-center justify-between text-sm rounded-full ring-1 ring-gray-400 ">
        <h4
          className="p-2 lg:p-1 text-xs  lg:px-4 px-4  flex items-center justify-between"
          onClick={() => setlist(!list)}
        >
          {title}
          <HiChevronDown size={20} className="text-gray-600 ml-4" />
        </h4>
        {list && (
          <div
            ref={listRef}
            className="absolute top-10 w-max z-50   p-4 text-white rounded ring-1 ring-primary-blue-light bg-primary-blue"
          >
            <p className="text-base font-medium">{subHeading}</p>
            <p className="text-xs my-2">Filter By</p>

            <Range
              value={value}
              min={min}
              max={max}
              step={step}
              onChange={onChange}
              className="col-span-full text-primary-blue w-52"
            />
            <p className="text-sm font-medium flex justify-between items-center">
              <span>
                {prefix}
                {value[0]}
                {suffix}
              </span>
              <span>
                {prefix}
                {value[1] === max ? `${max - step}+` : value[1]}
                {suffix}
              </span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export const DropdownList = ({ title, data, id, setValue, value, unique }) => {
  const [list, setlist] = useState(false);
  const listRef = useRef();
  useOutsideClickHandler(listRef, () => setlist(false));
  return (
    <div className="w-max flex-shrink-0">
      <div className="cursor-pointer text-gray-500  relative flex items-center justify-between text-sm rounded-full  ring-1 ring-gray-400 ">
        <h4
          onClick={() => setlist(!list)}
          className="flex  text-xs  items-center justify-between p-2 lg:p-1 lg:px-4 px-4"
        >
          {title}
          <HiChevronDown size={20} className="ml-4 text-gray-600" />
        </h4>
        {list && (
          <div
            ref={listRef}
            className="absolute top-10 w-max z-50 text-sm   p-4 text-white rounded bg-primary-blue ring-1 ring-primary-blue-light"
          >
            {data.map((item, index) => {
              return (
                <div
                  key={index}
                  className="flex items-center justify-start mb-2 text-sm"
                >
                  <input
                    id={item.key}
                    type="checkbox"
                    defaultChecked={value.includes(item.value)}
                    onChange={(e) => {
                      if (!e.target.checked) {
                        setValue((e) => {
                          if (unique) {
                            const obj = { ...e };
                            obj[id] = [];
                            return obj;
                          } else {
                            const obj = { ...e };
                            obj[id] = obj[id].filter(
                              (curr) => curr !== item.value
                            );

                            return obj;
                          }
                        });
                      } else {
                        setValue((e) => {
                          if (unique) {
                            const obj = { ...e };
                            obj[id] = item.value;
                            return obj;
                          } else {
                            const obj = { ...e };
                            obj[id] = obj[id].filter(
                              (curr) => curr !== item.value
                            );
                            obj[id].push(item.value);

                            return obj;
                          }
                        });
                      }
                    }}
                    className="mr-2"
                    name={title}
                    value={item.value}
                  />
                  <label htmlFor={item.key} className=" text-sm lg:text-xs">
                    {item.key}
                  </label>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export const DropdownListUnique = ({
  title,
  data,
  id,
  setValue,
  value,
  unique,
}) => {
  const [list, setlist] = useState(false);
  const listRef = useRef();
  useOutsideClickHandler(listRef, () => setlist(false));
  return (
    <div className="w-max flex-shrink-0">
      <div className="cursor-pointer text-gray-500  relative flex items-center justify-between text-sm rounded-full  ring-1 ring-gray-400 ">
        <h4
          onClick={() => setlist(!list)}
          className="flex  text-xs  items-center justify-between p-2 lg:p-1 lg:px-4 px-3.5"
        >
          {title}
          <HiChevronDown size={20} className="ml-4 text-gray-600" />
        </h4>
        {list && (
          <div
            ref={listRef}
            className="absolute top-10 w-max z-50 text-sm   p-4 text-white rounded bg-primary-blue ring-1 ring-primary-blue-light"
          >
            {data.map((item, index) => {
              return (
                <div
                  key={index}
                  className="flex items-center justify-start mb-2 text-sm"
                >
                  <input
                    id={item.key}
                    type="radio"
                    defaultChecked={value === item.value}
                    onChange={() => {
                      setValue(item.value);
                    }}
                    className="mr-2"
                    name={title}
                    value={item.value}
                  />
                  <label htmlFor={item.key} className=" text-sm lg:text-xs">
                    {item.key}
                  </label>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
