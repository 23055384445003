import React from 'react';

const MessageInput = ({ text, setText, sendMessage, setShowPicker }) => (
    <div className="flex border-t border-[#E4ECF8] pt-4 pl-4">
        <div className="bg-[#E4ECF8] p-3 px-4 flex items-center flex-grow rounded-md">
            <form
                className="w-full"
                onSubmit={(e) => {
                    e.preventDefault();
                    sendMessage(text);
                }}
            >
                <input
                    placeholder="Enter Message..."
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    className="w-full focus:outline-none bg-transparent"
                />
            </form>
        </div>
        <div className="flex">
            <button className="ml-5" onClick={() => setShowPicker((val) => !val)}>
                <img src="/assets/resources/smily.svg" alt="Emoji" className="w-4 h-4" />
            </button>
            <button
                onClick={() => sendMessage(text)}
                className="flex-shrink-0 flex justify-between rounded-md items-center ml-5 bg-[#23426e] px-4 py-3"
            >
                <img src="/assets/resources/send.png" alt="Send" className="h-4 w-4" />
            </button>
        </div>
    </div>
);

export default MessageInput;
