import React, { useRef } from "react";
import Section from "@layout/Section";
import { Swiper, SwiperSlide } from "swiper/react";

// // import Swiper core and required modules
import SwiperCore, { Navigation, Autoplay } from "swiper";

// // install Swiper modules
SwiperCore.use([Navigation, Autoplay]);
function Recommended() {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <Section className="flex flex-col items-center my-20 py-10 space-y-16 relative">
      <h2 className="text-center primary__heading  p-4">Recommended For You</h2>
      <div className="flex items-center relative w-full">
        <img
          ref={prevRef}
          className="z-40 cursor-pointer  h-14 absolute top-0 bottom-0 left-10  my-auto"
          src={`/assets/resources/arrow-l.png`}
          alt="crewmate"
        ></img>
        <img
          ref={nextRef}
          className="z-40 cursor-pointer  h-14 transform rotate-180 absolute top-0 bottom-0 right-10 my-auto"
          src={`/assets/resources/arrow-l.png`}
          alt="crewmate"
        ></img>
        <Swiper
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          loop
          autoplay
          delay={1000}
          slidesPerView={"auto"}
          loopAdditionalSlides={3}
          spaceBetween={30}
          centeredSlides
          onSwiper={(swiper) => {
            // Delay execution for the refs to be defined
            setTimeout(() => {
              // Override prevEl & nextEl now that refs are defined
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;

              // Re-init navigation
              swiper.navigation.destroy();
              swiper.navigation.init();
              swiper.navigation.update();
            });
          }}
          className="mx-auto w-full h-full flex items-center justify-center"
        >
          {new Array(5).fill(0).map((item, el) => (
            <SwiperSlide className="w-max" key={el}>
              <div className="lg:w-72 lg:h-96 w-72   h-96 rounded-2xl group cursor-pointer   relative overflow-hidden">
                <img
                  src={`/assets/resources/room${el + 1}.png`}
                  className="absolute top-0 bottom-0 left-0 right-0 h-full w-full object-cover"
                />
                <p className="text-center  absolute bottom-10 left-0 right-0 mx-auto w-max text-base text-white">
                  New york
                  <span className="text-xs font-light  hidden group-hover:block">
                    $147/Night Average
                  </span>
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Section>
  );
}

export default Recommended;
