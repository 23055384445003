import React, { useState } from "react";

function SwitchButton({ onChange, initial = false }) {
  const [key, setkey] = useState(initial);

  return (
    <div
      className="rounded-full cursor-pointer p-1 w-14 h-8 bg-[#D8E9EF] relative"
      onClick={() => {
        setkey(!key);
        onChange(!key);
      }}
    >
      <div
        className={`rounded-full ${
          key ? "bg-primary-blue" : "bg-white"
        } h-6 w-6 transition-all transform ${key ? "translate-x-full" : ""} `}
      ></div>
    </div>
  );
}

export default SwitchButton;
