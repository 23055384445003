import React, { useState, useEffect } from 'react'
import Navbar from '@dashboard/Navbar'
import { IoMdAdd } from 'react-icons/io'
import ReactPaginate from 'react-paginate'
import { notification } from '@services'
import { toast } from 'react-toastify'
import moment from 'moment'

const NotificationCard = ({ profile, data }) => {
    return (
        <div className="mb-2 py-2 border-b border-[#D3DDEF]  flex justify-start items-center">
            {/* <img
        src={profile}
        alt="user profile"
        className="h-20 w-20 mr-4 rounded-full object-cover"
      /> */}
            <div>
                {/* <h4 className="text-gray-600 text-sm ">
          <span className="text-primary-blue  ">John Doe</span> reacted to your
          post
        </h4> */}
                <p className="text-sm text-primary-blue-dim">{data.message}</p>
            </div>

            <p className="ml-auto text-sm text-parrot flex justify-start items-center">
                {moment(new Date(data.createdAt)).fromNow()}
                {/* <span className="ml-5 h-6 w-6 flex-center rounded-full ring-1 ring-primary-blue-dark">
            <IoMdAdd
              size={22}
              className="text-gray-600 cursor-pointer  transform rotate-45 flex-shrink-0"
            />
          </span> */}
            </p>
        </div>
    )
}

function Notification() {
    const [list, setList] = useState([])
    const [fetching, setFetching] = useState(false)
    const [total, setTotal] = useState(1)
    const [page, setPage] = useState(1)

    const handlePageChange = (e) => {
        setPage(e.selected + 1)
    }

    useEffect(() => {
        const fetchList = async () => {
            try {
                setFetching(true)
                const { data } = await notification.getAllNotification(page)

                setList(data.data.docs)
                setTotal(data.data.pages)
            } catch (error) {
                toast.error('something went wrong')
            } finally {
                setFetching(false)
            }
        }
        fetchList()
    }, [page])

    return (
        <div>
            <Navbar title={'Notifications'} />
            <div>
                <div className="flex justify-between items-center">
                    {/* <h4 className="text-lg text-primary-blue-dim font-medium mt-8 mb-4">
            Today
          </h4> */}
                    {/* <span className="flex-center rounded-full p-2 px-4 text-primary-blue-dark font-medium text-sm ring-1 ring-primary-blue-dark cursor-pointer">
            Clear All{" "}
            <IoMdAdd
              size={22}
              className="ml-2 transform rotate-45 flex-shrink-0"
            />
          </span> */}
                </div>
                <div className="my-4">
                    {fetching ? (
                        <p className="text-center my-28">Loading...</p>
                    ) : (
                        list.map((curr) => <NotificationCard profile="/assets/resources/team1.jpg" data={curr} key={curr._id} />)
                    )}
                </div>
                <div className="w-8/12 mx-auto my-10">
                    <ReactPaginate
                        nextClassName="bg-primary-blue  h-10 w-10 rounded-full flex-center"
                        disabledClassName="pagination-dissabled"
                        nextLabel={<img alt="" src="/assets/resources/arrow-left.png" className="w-4 transform rotate-180" />}
                        activeClassName="pagination-active"
                        previousClassName="bg-primary-blue  h-10 w-10 rounded-full flex-center"
                        previousLabel={<img alt="" src="/assets/resources/arrow-left.png" className="w-4 " />}
                        onPageChange={handlePageChange}
                        pageRangeDisplayed={3}
                        pageCount={total}
                        containerClassName="flex justify-center items-center flex-shrink-0 "
                        pageClassName="text-white rounded-full pagination-btn mx-2"
                        pageLinkClassName="flex-center  text-xs h-10 w-10 "
                    />
                </div>
            </div>
        </div>
    )
}

export default Notification
