import React, { useState, useContext, useCallback } from 'react'
import airlineEmails from '@config/airlineEmails.json'
import { Context as AuthContext } from '@context/authContext'
import { constant } from '@config/constant'
import { updateProfile } from '@services/user'
import PhoneInput from 'react-phone-input-2'
import { toast, ToastContainer } from 'react-toastify'
import { BsFacebook } from 'react-icons/bs'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { FcGoogle } from 'react-icons/fc'
import { useGoogleLogin } from '@react-oauth/google'
import axios from 'axios'
import {
    verifyEmail,
    verifyPhone,
    updateSocialAuthenticationStatus,
} from '@services/user'
import { FiChevronDown } from 'react-icons/fi'
import notVefiedIcon from '../../assets/circle-cross-red.svg'
import vefiedIcon from '../../assets/circle-check-green.svg'
import { setResetToken } from '@helper/header'
import OtpInput from 'react-otp-input'
import {
    sendAirlineEmailVerificationLinkOTP,
    sendVerifyPhoneOtp,
    updatePersonalEmailAndOtp,
    verifyPersonalEmailOTP,
    verifyPhoneOTP,
} from '../../_services/user'
import { AppConfig } from '../../Config'
import { processMediaUrl } from '../../_helper/utils'

const availableCountries=['us']
if (AppConfig.app_env !== 'production') {
    airlineEmails.data.push({ Domain: '@maildrop.cc', 'Airline name': 'Test Airline' })
    availableCountries.push('in')
}

function UpdateProfile() {
    const { user, fetchUser, updateUser } = useContext(AuthContext)
    const [requesting, setRequesting] = useState(false)
    const [photo, setPhoto] = useState(processMediaUrl(user.avatar))
    const [firstName, setFirstName] = useState(user.name.split(' ')[0])
    const [lastName, setLastName] = useState(user.name.split(' ')[1])
    const [personalEmail, setPersonalEmail] = useState(user.personalEmail)
    const [personalEmailError, setPersonalEmailError] = useState(false)
    const [phone, setPhone] = useState(user.phone)
    const [countryCode, setCountryCode] = useState(user.countryCode)
    const [airlineEmail, setAirlineEmail] = useState(user.airlineEmail === 'null' ? '' : user.airlineEmail)
    const [airlineEmailError, setAirlineEmailError] = useState(false)
    const [dob, setDob] = useState(user.dob)
    const [crewType, setCrewType] = useState(user.crewType)
    const [profile, setProfile] = useState()
    const [optionList, setOptionList] = useState(false)
    const [enterOTP, showEnterOTPModal] = useState(false)
    const [otp, setOtp] = useState('')
    const [verificationType, setVerificationType] = useState('')
    const [personalEmailVerification, setPersonalEmailVerification] = useState(user.personalEmailVerification)
    const [personalEmailVerificationStatusBackup, setPersonalEmailVerificationStatusBackup] = useState(user.personalEmailVerification)
    const [personalEmailBackup, setPersonalEmailBackup] = useState(user.personalEmail)
    const [airlineEmailVerification, setAirlineEmailVerification] = useState(user.airlineEmailVerification)
    const [airlineEmailVerificationStatusBackup, setAirlineEmailVerificationStatusBackup] = useState(user.airlineEmailVerification)

    const [phoneVerification, setPhoneVerification] = useState(user.phoneVerification)
    const [phoneVerificationError, setPhoneVerificationError] = useState(false)
    const [phoneVerificationModal, setPhoneVerificationModal] = useState(false)


    const handlePersonalEmail = (email = '') => {
        setPersonalEmail(email)
        setPersonalEmailError(false)

        const emailRegex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!emailRegex.test(email)) {
            console.log('personalEmail is not valid', email)
            setPersonalEmailError(true)
            setPersonalEmailVerification(1)
            return
        }

        const domain = email.split('@')[1]
        const isValid = airlineEmails.data.filter((el) => el.Domain === `@${domain}`)
        if (isValid.length > 0) {
            console.log('email contain airline email', email)
            setPersonalEmailError(true)
            setPersonalEmailVerification(1)
            return
        }

        if (personalEmailBackup !== email) {
            console.log('personalEmail is not equal to current url', email)
            setPersonalEmailVerification(0)
        } else {
            console.log('personalEmail is equal', email)
            setPersonalEmailVerification(personalEmailVerificationStatusBackup)
        }
    }

    const handleAirlineEmail = (email = '') => {
        setAirlineEmail(email)
        setAirlineEmailError(false)
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (!emailRegex.test(email)) {
            setAirlineEmailVerification(1)
            setAirlineEmailError(true)
            console.log('Airline Email not valid', email)
            return
        }

        // domain includes listed airline check
        const domain = email.split('@')[1]
        const isValid = airlineEmails.data.filter((el) => {
            console.log(el.Domain === domain)
            return el.Domain === `@${domain}`
        })

        if (isValid.length === 0) {
            console.log(isValid, domain, 'Airline Domain is not valid')
            setAirlineEmailError(true)
            setAirlineEmailVerification(1)
            return
        }

        if (email !== user.airlineEmail) {
            console.log('given email not equal, so verify button should show', email)
            setAirlineEmailVerification(0)
        } else {
            setAirlineEmailVerification(airlineEmailVerificationStatusBackup)
            console.log('Both Email are same', email)
        }
    }

    const handlePhoneNumber = (value, e) => {
        if (user.phone !== value) {
            setPhoneVerification(0)
        } else {
            setPhoneVerification(user.phoneVerification)
        }
        const phoneInput = value.slice(e.dialCode.length)
        if (phoneInput.length === 0) {
            setPhoneVerificationError(true)
            setPhoneVerification(1)
        }
        console.log(phoneInput, user.phone)
        if (phoneInput === user.phone && user.phoneVerification === 1) {
            setPhoneVerification(1)
            setPhoneVerificationError(false)
        }

        setPhone(phoneInput)
        setCountryCode(e.dialCode)
    }

    const isAdult = (date) => {
        let Bday = new Date(date) //formatting input as date
        let ageY = (Date.now() - Bday) / 31557600000
        if (ageY < 18) return false
        else return true
    }

    const handleProfileUpdate = async () => {
        try {
            const data = {
                name: firstName + ' ' + lastName,
                personalEmail,
                phone,
                countryCode,
                airlineEmail,
                dob,
                crewType,
                fileData: profile,
            }
            if (!personalEmail) {
                toast.error('Personal email is required.')
                return
            }

            // if (personalEmailError) {
            //     toast.error('Personal email should not have the airline domains.')
            //     return
            // }
            // if (airlineEmailError) {
            //     toast.error('Invalid Airline Email')
            //     return
            // }
            if (!isAdult(dob)) {
                toast.error('You must be 18 years old or over to create an account.')
                return
            }
            // if (phone && phone.length <= 4 || phone.length >= 15) {
            //     toast.error('Invalid Phone')
            //     return
            // }
            setRequesting(true)
            const res = await updateProfile(data)
            if (!res.data.status) {
                toast.error(res.data.message)
            } else toast.success(res.data.message, { toastId: 37 })

            setRequesting(false)
            fetchUser()
        } catch (error) {
            setRequesting(false)
            toast.error('Something went wrong!', { toastId: 38 })
        }
    }

    const connectUserSocialAccount = async (data) => {
        //check if user email is equal to social account email
        // if (user.personalEmail === data.email) {
        let obj = {
            socialType: data.authType,
            socialId: data.id,
        }
        if (data.authType === 'Google') {
            obj['googleEmail'] = data.email
        }
        if (data.authType === 'Facebook') {
            obj['facebookEmail'] = data.email
        }
        const res = await updateSocialAuthenticationStatus(obj)
        if (res.data.status) {
            toast.success(res.data.message)
            updateUser({ [data.dataKey]: 1 })
        } else {
            toast.error(res.data.message)
        }
    }

    const authenticateUsingGoogle = useGoogleLogin({
        onSuccess: async ({ access_token }) => {
            const { data } = await axios.get(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${access_token}`)
            connectUserSocialAccount({
                email: data.email,
                firstName: data.givenName,
                lastName: data.familyName,
                id: data.sub,
                authType: 'Google',
                dataKey: 'googleAuthentication',
            })
        },
        onError: (err) => {
            toast.error(err)
        },
        onNonOAuthError: (err) => {
            toast.error(err)
        },
    })

    const sentOTP = async (requestObj) => {
        try {
            const res = await updatePersonalEmailAndOtp(requestObj)
            if (res.data.status) {
                toast.success('OTP Sent Successfully')
                setResetToken(res.data.data.token)
            }
        } catch (error) {
            toast.error('Something went wrong!')
        }
    }

    const resendEmailOtp = async (emailOrPhone) => {
        try {
            const res = await updatePersonalEmailAndOtp({
                emailId: personalEmail,
            })
            if (res.data.status) {
                showEnterOTPModal(true)
                toast.success('OTP sent successfully!', { zIndex: 9999 })
            } else toast.error(res.data.message)
        } catch {
            toast.error('Something went wrong!', { zIndex: 9999 })
        }
    }

    const verifyPhoneOtp = (otp) => {
        if (otp.length < 3) {
            return toast.error('OTP is required.')
        }
        setPhoneVerification(1)
        verifyPhoneOTP({ otp }).then((objS) => {
            if (objS.status) {
                toast.success(objS.message)
                setPhoneVerificationModal(false)
                // window.$("#addRoomModal").modal("hide");
                // window.$(".modal-backdrop").remove();
                setPhoneVerification(1)
            } else {
                toast.error(objS.message)
                setPhoneVerification(0)
                setPhoneVerificationModal(false)
                return
            }
        })
    }

    const verifyEmailOtp = async (otp) => {
        try {
            if (otp.length === 4) {
                setRequesting(true)
                //const res = await userService.verifyPersonalEmailOTP({ otp });

                const { data } = await verifyPersonalEmailOTP({ otp })
                if (data.status) {
                    toast.success(data.message)
                    setPersonalEmailVerification(1)
                    updateUser({ personalEmailVerification: 1 })
                    onClose()
                } else {
                    toast.error(data.message)
                }
                setRequesting(false)
            } else {
                toast.error('OTP is required.')
            }
        } catch (error) {
            toast.error('Something went wrong!')
            setRequesting(false)
        }
    }

    const handleVerification = async () => {
        try {
            if (otp.length === 4) {
                setRequesting(true)
                if (verificationType === 'phone') {
                    const { data } = await verifyPhone({ otp })
                    if (data.status) {
                        toast.success(data.message)
                        onClose()
                        updateUser({ phoneVerification: 1 })
                    } else {
                        toast.error(data.message)
                    }
                } else {
                    const { data } = await verifyEmail({ otp })
                    if (data.status) {
                        toast.success(data.message)

                        updateUser({ personalEmailVerification: 1 })
                        onClose()
                    } else {
                        toast.error(data.message)
                    }
                }
                setRequesting(false)
            } else {
                toast.error('OTP is required.')
            }
        } catch (error) {
            toast.error('something went wrong')
            setRequesting(false)
        }
    }
    const onClose = () => {
        showEnterOTPModal(false)
    }

    const handleSendAirlineEmailVerificationLink = async (email) => {
        try {
            setRequesting(true)
            const res = await sendAirlineEmailVerificationLinkOTP({
                emailType: 'airline',
                emailId: email,
            })

            setRequesting(false)
            if (res.data.status) {
                toast.success(res.data.message)
            } else toast.error(res.data.message)
        } catch (error) {
            toast.error('Something went wrong!')
        }
    }

    const resendMobileOtp = ({ phone, countryCode }) => {
        sendVerifyPhoneOtp({
            phone: phone,
            countryCode,
        }).then((objS) => {
            console.log(objS)
            if (objS.data.status) {
                setPhoneVerificationModal(true)
                toast.success(objS.data.message, { zIndex: 9999 })
            } else {
                toast.error(objS.data.message, { zIndex: 9999 })
                setPhoneVerificationModal(false)
            }
        })
    }

    return (
        <div>
            <ToastContainer />
            <div className="md:mr-32">
                <span className="h-32 w-32 relative rounded-full object-cover p-2 block ring-1 ring-primary-blue-dark mx-auto">
                    <label htmlFor="profile_picture">
                        <img
                            onError={function ({ currentTarget }) {
                                currentTarget.src = '/user.png'
                            }}
                            className="h-full w-full  rounded-full object-cover"
                            src={photo}
                            alt="dev"
                        />
                        <img
                            className="h-8 absolute bottom-0 right-0 cursor-pointer  rounded-full object-cover"
                            src="/assets/resources/material-pen.png"
                            alt="dev"
                        />
                    </label>
                    <input
                        className="hidden"
                        id="profile_picture"
                        onChange={(e) => {
                            setProfile(e.target.files[0])
                            setPhoto(URL.createObjectURL(e.target.files[0]))
                        }}
                        accept="image/*"
                        type="file"
                        name="profile"
                    />
                </span>
                <div className="grid sm:grid-cols-2 gap-x-10 gap-y-8 py-10">
                    <div className="flex flex-col justify-start items-start">
                        <label className="text-xs  mb-2">{'First Name'}</label>
                        <div className={`flex justify-start items-center rounded shadow-md w-full p-3 text-gray-700 `}>
                            <input
                                type="text"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                className={`border-0 ring-0 focus:ring-0 rounded w-full text-sm p-0`}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col justify-start items-start">
                        <label className="text-xs mb-2">{'Last Name'}</label>
                        <div className={`flex justify-start items-center rounded shadow-md w-full p-3 text-gray-700`}>
                            <input
                                type="text"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                className={`border-0 ring-0 focus:ring-0 rounded w-full text-sm p-0`}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col justify-start items-start">
                        <label className="text-xs mb-2">
                            {'Personal Email'}
                            {personalEmailError ? <span className="text-red-500 ml-2">Invalid Email</span> : ''}
                        </label>
                        <div
                            className={`flex justify-start items-center rounded shadow-md w-full p-3 ${
                                personalEmailError ? 'ring-1 ring-red-500 text-red-500' : 'text-gray-700'
                            }`}
                        >
                            <input
                                type="text"
                                value={personalEmail}
                                onChange={(e) => {
                                    handlePersonalEmail(e.target.value)
                                }}
                                className={`border-0 ring-0 focus:ring-0 rounded w-full text-sm p-0`}
                                placeholder="john@gmail.com"
                            />
                            {Boolean(personalEmailVerification) ? (
                                <div className="flex items-center justify-end ml-2 text-green-500 w-full">
                                    {personalEmailError ? (
                                        <>
                                            <div>
                                                <img src={notVefiedIcon} alt={'check'} className="pl-2" />
                                            </div>
                                            <div className="ml-1 text-sm text-red-500">Invalid</div>
                                        </>
                                    ) : (
                                        <>
                                            <div>
                                                <img src={vefiedIcon} alt={'check'} className="pl-2" />
                                            </div>
                                            <div className="ml-1 text-sm">Verified</div>
                                        </>
                                    )}
                                </div>
                            ) : (
                                <div className="flex items-center justify-end w-full">
                                    <button
                                        className="rounded-full bg-primary-blue text-white text-xs py-1 px-2 cursor-pointer"
                                        onClick={() => resendEmailOtp(personalEmail)}
                                    >
                                        Verify Now
                                    </button>
                                    <div className="flex items-center ml-2 text-red-500">
                                        <div className="ml-1 text-sm">Not Verified</div>
                                        <div>
                                            <img src={notVefiedIcon} alt={'cross'} className="pl-2" />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex flex-col justify-start items-start">
                        <label className="text-xs mb-2">
                            {'Mobile Number'}
                            {phoneVerificationError && phone && phone.length < 9 ? <span className="text-red-500 ml-2">Invalid Phone</span> : ''}
                        </label>
                        <div className={`flex justify-start items-center rounded shadow-md w-full p-3 text-gray-700 `}>
                            <PhoneInput
                                country={'us'}
                                onlyCountries={availableCountries}
                                autoFormat
                                value={`${countryCode}${phone}`}
                                onChange={handlePhoneNumber}
                                inputClass="border-0 ring-0 focus:ring-0  rounded h-auto  w-full text-sm p-1 pl-12 relative"
                            />
                            {Boolean(phoneVerification) ? (
                                <div className="flex items-center justify-end ml-2 text-green-500 w-full">
                                    {phone && phone.length > 0 ? (
                                        <>
                                            <div>
                                                <img src={vefiedIcon} alt={'check'} className="pl-2" />
                                            </div>
                                            <div className="ml-1 text-sm">Verified</div>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            ) : (
                                <div className="flex items-center justify-end w-full">
                                    {phone && phone.length > 0 && (
                                        <>
                                            {phone.length >= 10 && (
                                                <button
                                                    className="rounded-full bg-primary-blue text-white text-xs py-1 px-2 cursor-pointer"
                                                    onClick={() => resendMobileOtp({phone,countryCode})}
                                                >
                                                    Verify Now
                                                </button>
                                            )}

                                            <div className="flex items-center ml-2 text-red-500">
                                                <div className="ml-1 text-sm">Not Verified</div>
                                                <div>
                                                    <img src={notVefiedIcon} alt={'cross'} className="pl-2" />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="tw-flex tw-flex-col tw-justify-start tw-items-start">
                        <label className="text-xs mb-2">
                            {'Airline Email (Optional)'}
                            {airlineEmailError ? <span className="text-red-500 ml-2">Invalid Email</span> : ''}
                        </label>

                        {/* <label className="text-xs mb-2">
                            {'Mobile Number'}
                            {phoneVerificationError && phone && phone.length<9? <span className="text-red-500 ml-2">Invalid Phone</span> : ''}
                        </label> */}

                        <div
                            className={`flex justify-start items-center rounded shadow-md w-full p-3 ${
                                airlineEmailError ? 'ring-1 ring-red-500 text-red-500' : 'text-gray-700'
                            }`}
                        >
                            <input
                                type="text"
                                value={airlineEmail}
                                onChange={(e) => handleAirlineEmail(e.target.value)}
                                className={`border-0 ring-0 focus:ring-0 rounded w-full text-sm p-0`}
                                placeholder="Airline@gmail.com"
                            />
                            {Boolean(airlineEmailVerification) ? (
                                <div className="flex items-center justify-end ml-2  w-full">
                                    {airlineEmailError ? (
                                        <>
                                            <div>
                                                <img src={notVefiedIcon} alt={'check'} className="pl-2" />
                                            </div>
                                            <div className="ml-1 text-sm text-red-500">Invalid</div>
                                        </>
                                    ) : (
                                        <>
                                            <div>
                                                <img src={vefiedIcon} alt={'check'} className="pl-2" />
                                            </div>
                                            <div className="ml-1 text-sm text-green-500">Verified</div>
                                        </>
                                    )}
                                </div>
                            ) : (
                                <>
                                    {airlineEmailVerification === 0 && airlineEmail && (
                                        <div className="flex items-center justify-end w-full">
                                            <button
                                                className="rounded-full bg-primary-blue text-white text-xs py-1 px-2 cursor-pointer"
                                                onClick={() => handleSendAirlineEmailVerificationLink(airlineEmail)}
                                            >
                                                Verify Now
                                            </button>
                                            <div className="flex items-center ml-2 text-red-500">
                                                <div className="ml-1 text-sm">Not Verified</div>
                                                <div>
                                                    <img src={notVefiedIcon} alt={'cross'} className="pl-2" />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col justify-start items-start">
                        <label className="text-xs mb-2">{'Date of Birth'}</label>
                        <div className={`flex justify-start items-center rounded shadow-md w-full p-3 text-gray-700`}>
                            <input
                                type="date"
                                value={dob}
                                onChange={(e) => setDob(e.target.value)}
                                className={`border-0 ring-0 focus:ring-0 rounded w-full text-sm p-0`}
                                placeholder="22 June, 1998"
                            />
                        </div>
                    </div>
                    {user.type !== 'Host' && (
                        <div className="flex flex-col space-y-1 justify-start items-start cursor-pointer">
                            <label className="text-sm text-gray-600">{'Crew Type'}</label>
                            <div
                                className="flex items-center ring-0  text-gray-700 rounded w-full  shadow-md relative z-50"
                                onClick={() => setOptionList(!optionList)}
                            >
                                <input
                                    size={1}
                                    disabled
                                    placeholder={'Flight Attendant'}
                                    className="p-2 text-sm flex-grow cursor-pointer outline-none bg-transparent pointer-events-none"
                                    value={crewType}
                                    list={['Flight Attendant', 'Pilot', 'Other Airline Professional']}
                                />
                                <span className="p-2 border-gray-300">
                                    <FiChevronDown size={24} />
                                </span>
                                {optionList && (
                                    <div className="absolute right-0 w-full top-14 rounded bg-white shadow-md ring-1 ring-gray-100 max-h-44 overflow-y-auto scrollbar-hide ">
                                        {['Flight Attendant', 'Pilot', 'Other Airline Professional'].map((curr, i) => {
                                            return (
                                                <p
                                                    key={i}
                                                    onClick={(e) => {
                                                        setCrewType(curr)
                                                        setOptionList(!optionList)
                                                    }}
                                                    className="p-2  cursor-pointer hover:bg-gray-100 text-sm text-gray-800 border-l-4   border-blue-500"
                                                >
                                                    {curr}
                                                </p>
                                            )
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {/* <div className="flex flex-col justify-start items-start">
                        <label className="text-xs mb-2">{'Selfie & ID Verification'}</label>
                        <div className={`flex justify-start items-center w-full p-3 text-gray-700`}>
                            {Boolean(user.idVerificationStatus) ? (
                                <div className="flex items-center ml-2 text-green-500 w-full">
                                    <div>
                                        <img src={vefiedIcon} alt={'check'} className="pl-2" />
                                    </div>
                                    <div className="ml-1 text-sm">Verified</div>
                                </div>
                            ) : (
                                <div className="flex items-center w-full">
                                    <button className="p-2 rounded-full bg-primary-blue text-white text-xs px-4 cursor-pointer">Verify Now</button>
                                    <div className="flex items-center ml-2 text-red-500 w-[22%]">
                                        <div className="ml-1 text-sm">Not Verified</div>
                                        <div>
                                            <img src={notVefiedIcon} alt={'cross'} className="pl-2" />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div> */}
                    <button
                        onClick={handleProfileUpdate}
                        disabled={requesting}
                        className="w-max col-span-full text-sm p-2 px-4 rounded bg-primary-blue text-white "
                    >
                        {requesting ? 'Please wait...' : 'Save'}
                    </button>
                </div>
            </div>
            <div className="md:mr-32">
                <div className="flex items-center p-4 mb-4 bg-gray-light rounded">
                    <BsFacebook size={32} className="text-blue-500" />
                    <p className="text-sm text-primary-blue font-medium mx-4">Facebook</p>
                    <p className="text-gray-400 text-sm mr-4">
                        {user.facebookAuthentication ? <span className="text-parrot">Connected</span> : 'Not Connected'}
                    </p>
                    <div className="ml-auto">
                        {!user.facebookAuthentication && (
                            <FacebookLogin
                                appId={constant.FACEBOOK_APPID}
                                callback={(e) => {
                                    if (e.email) {
                                        connectUserSocialAccount({
                                            email: e.email,
                                            firstName: e.name.split(' ')[0],
                                            lastName: e.name.split(' ')[1],
                                            authType: 'Facebook',
                                            id: e.id,
                                            dataKey: 'facebookAuthentication',
                                        })
                                    } else {
                                        toast.error('Something went very wrong.')
                                    }
                                }}
                                fields="name,email,picture,gender,birthday"
                                render={(renderProps) => (
                                    <button onClick={renderProps.onClick} className=" p-2 px-2 rounded-md text-white bg-primary-blue text-xs">
                                        Connect Now
                                    </button>
                                )}
                            />
                        )}
                    </div>
                </div>
                <div className="flex items-center p-4 mb-4 bg-gray-light rounded">
                    <FcGoogle size={32} className="text-blue-500" />
                    <p className="text-sm text-primary-blue font-medium mx-4">Google</p>
                    <p className="text-gray-400 text-sm mr-4">
                        {user.googleAuthentication ? <span className="text-parrot">Connected</span> : 'Not Connected'}
                    </p>
                    <div className="ml-auto">
                        {!user.googleAuthentication && (
                            <button onClick={() => authenticateUsingGoogle()} className=" p-2 px-2 rounded-md text-white bg-primary-blue text-xs">
                                Connect Now
                            </button>
                        )}
                    </div>
                </div>
            </div>
            {enterOTP && (
                <div className="z-high fixed top-0 bottom-0 left-0 bg-gray-600 bg-opacity-50 right-0 flex-center">
                    <div className={`w-full mx-4 md:max-h-4/6 md:w-8/12 lg:w-4/12 bg-white overflow-y-auto scrollbar scrollbar-hide rounded`}>
                        <div className="py-3 flex justify-center items-center bg-primary-blue text-white">
                            <p className="text-sm">{'Crewmates Verification Process'}</p>
                        </div>
                        <div className="bg-white">
                            <h2 className="text-base text-center">{'Email Address Verification'}</h2>
                            <p className="text-sm text-center mt-2 w-10/12 mx-auto">
                                {"Please check your email and enter the OTP. Check your spam folder if the first email doesn't reach your inbox."}
                            </p>
                            <OtpInput
                                value={otp}
                                onChange={(e) => {
                                    setOtp(e)
                                }}
                                isInputNum
                                containerStyle="flex-center space-x-4 my-6"
                                inputStyle="h-10 w-10 bg-white rounded p-0"
                                separator={<span>{'  '}</span>}
                                numInputs={4}
                            />
                            <button
                                onClick={() => verifyEmailOtp(otp)}
                                className="bg-primary-blue p-2 px-4 mx-auto w-max block rounded text-sm text-white "
                            >
                                {requesting ? 'Please wait...' : 'Verify'}
                            </button>
                            <p onClick={() => resendEmailOtp(personalEmail)} className="text-primary-blue cursor-pointer  text-center text-sm my-4">
                                Resend OTP
                            </p>
                        </div>
                    </div>
                </div>
            )}

            {phoneVerificationModal && (
                <div className="z-high fixed top-0 bottom-0 left-0 bg-gray-600 bg-opacity-50 right-0 flex-center">
                    <div className={`w-full mx-4 md:max-h-4/6 md:w-8/12 lg:w-4/12 bg-white overflow-y-auto scrollbar scrollbar-hide rounded`}>
                        <div className="py-3 flex justify-center items-center bg-primary-blue text-white">
                            <p className="text-sm">{'Crewmates Verification Process'}</p>
                        </div>
                        <div className="bg-white">
                            <h2 className="text-base text-center">{'Mobile Number Verification'}</h2>
                            <p className="text-sm text-center mt-2 w-10/12 mx-auto">
                                {'Crewmates has sent the OTP to your registered mobile number.'}
                            </p>
                            <OtpInput
                                value={otp}
                                onChange={(e) => {
                                    setOtp(e)
                                }}
                                isInputNum
                                containerStyle="flex-center space-x-4 my-6"
                                inputStyle="h-10 w-10 bg-white rounded p-0"
                                separator={<span>{'  '}</span>}
                                numInputs={4}
                            />
                            <button
                                onClick={() => verifyPhoneOtp(otp)}
                                className="bg-primary-blue p-2 px-4 mx-auto w-max block rounded text-sm text-white "
                            >
                                {requesting ? 'Please wait...' : 'Verify'}
                            </button>
                            <p
                                onClick={() => resendMobileOtp({ phone, countryCode })}
                                className="text-primary-blue cursor-pointer  text-center text-sm my-4"
                            >
                                Resend OTP
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default UpdateProfile
