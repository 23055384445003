import React, { useRef } from "react";
import Section from "@layout/Section";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper-bundle.css";

// // import Swiper core and required modules
import SwiperCore, { Navigation, Autoplay } from "swiper";

// // install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

function CrewmateApp() {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  return (
    <Section className="flex flex-col items-center my-20 overflow-hidden py-10 space-y-16 relative">
      <h2 className="text-center primary__heading  p-4">
        Check Our Crewmate Mobile App
      </h2>

      {/* <div className="grid grid-cols-3  gap-x-16 relative"> */}
      <div className="relative w-full transform scale-110 md:scale-100  md:w-11/12 mx-auto my-20">
        <Swiper
          slideActiveClass="mobile_carsouel-active"
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          loop
          // autoplay
          delay={1000}
          slidesPerView={3}
          loopAdditionalSlides={10}
          spaceBetween={30}
          centeredSlides
          onSwiper={(swiper) => {
            // Delay execution for the refs to be defined
            setTimeout(() => {
              // Override prevEl & nextEl now that refs are defined
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;

              // Re-init navigation
              swiper.navigation.destroy();
              swiper.navigation.init();
              swiper.navigation.update();
            });
          }}
          className="mx-auto w-full h-full flex items-center justify-center"
        >
          <SwiperSlide className="mobile_carsouel">
            <div className="   md:w-52   flex items-center justify-center mx-auto">
              <img
                className="w-full "
                src="/assets/images/mobile 01.png"
                alt="crewmate"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="mobile_carsouel ">
            <div className="   md:w-52   flex items-center justify-center mx-auto">
              <img
                className="w-full "
                src="/assets/images/mobile 02.png"
                alt="crewmate"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="mobile_carsouel ">
            <div className="   md:w-52   flex items-center justify-center mx-auto">
              <img
                className="w-full "
                src="/assets/images/mobile 03.png"
                alt="crewmate"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="mobile_carsouel ">
            <div className="   md:w-52   flex items-center justify-center mx-auto">
              <img
                className="w-full "
                src="/assets/images/mobile 04.png"
                alt="crewmate"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="mobile_carsouel">
            <div className="md:w-52    flex items-center justify-center mx-auto">
              <img
                className="w-full "
                src="/assets/images/mobile 05.png"
                alt="crewmate"
              />
            </div>
          </SwiperSlide>
        </Swiper>

        <img
          ref={prevRef}
          className=" z-40 cursor-pointer h-8 md:h-14 absolute top-0 bottom-0 left-10  my-auto"
          src={`/assets/resources/arrow-l.png`}
          alt="crewmate"
        ></img>
        <img
          ref={nextRef}
          className=" z-40 cursor-pointer h-8 md:h-14 transform rotate-180 absolute top-0 bottom-0 right-10 my-auto"
          src={`/assets/resources/arrow-l.png`}
          alt="crewmate"
        ></img>
      </div>
    </Section>
  );
}

export default CrewmateApp;
