import axios from "axios";
import { constant } from "@config/constant";
import { getAuthHeader } from "@helper/header";
import { handleErrorResponse } from "../_helper/utils";

const api = axios.create({ baseURL: constant.apiUrl });

export const getAllNotification = async (page) => {
  try {
    const res = await api.get(
      `/user/notification/guest?page=${page}`,
      getAuthHeader()
    );
    return res;
  } catch (error) {
    handleErrorResponse(error);
  }
};
