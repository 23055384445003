import React from 'react';

function Section({ children, className, ...props }) {
  return (
    <div
      {...props}
      className={`w-full mx-auto lg:w-11/12  ${className}`}
    >
      {children}
    </div>
  );
}

export default Section;
