import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { IoIosClose as CloseIcon } from 'react-icons/io'
import Header from '@front/Header'
import Footer from '@front/Footer'
import Section from '@layout/Section'
import TextInput from '@ui/TextInput'
import SelectInputText from '@ui/Select'
import Button from '@ui/Button'
import { Link } from 'react-router-dom'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
// import { GoogleLogin } from 'react-google-login'
import { useGoogleLogin } from '@react-oauth/google'
import Modal from '@components/Modal'
// import AuthModal from '@layout/AuthModal';
import { AiOutlineUser } from 'react-icons/ai'
import { BsCalendarDate } from 'react-icons/bs'
import { BiLock } from 'react-icons/bi'
import { FiCheckCircle } from 'react-icons/fi'
import { FcGoogle } from 'react-icons/fc'
import { BsFacebook } from 'react-icons/bs'
// import { AiFillApple } from "react-icons/ai";
import { AiOutlineMail, AiOutlineUserAdd } from 'react-icons/ai'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import airlineEmails from '@config/airlineEmails.json'

import { register, checkIsRegistered, login } from '@services/user'
import { setResetToken, setToken } from '@helper/header'
import { constant } from '../_config/constant'
import axios from 'axios'
import useQuery from '@hooks/useQuery'
import { AppConfig } from '../Config'

function Register() {
    const history = useHistory()
    const [authType, setAuthtype] = useState('Normal')
    const [socialId, setSocialId] = useState('')
    const [registrationProcessing, setRegisterationProcessing] = useState(false)
    const [notificationModal, setNotificationModal] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [airlineEmail, setAirlineEmail] = useState('')
    const [airlineEmailError, setAirlineEmailError] = useState('')
    const [crewType, setCrewType] = useState('')
    const [dob, setDob] = useState('')
    const [memberType, setMemberType] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isRequestion, setRequesting] = useState(false)
    const [termsAgree, setTermsAgree] = useState(false)
    const [phone, setPhone] = useState('')
    const [countryCode, setcountryCode] = useState('')
    const [isAlreadyRegisteredModal, setIsAlreadyRegisteredModal] = useState(false)
    const [userData, setUserData] = useState({})
    const [optionList, setOptionList] = useState(false)
    const [crewTypeoptionList, setCrewTypeOptionList] = useState(false)

    const onlyCountriesForPhone = ['us']
    if (AppConfig.app_env === 'dev') {
        onlyCountriesForPhone.push('in')
    }

    const query = useQuery()
    const isAdult = (date) => {
        let Bday = new Date(date) //formatting input as date
        let ageY = (Date.now() - Bday) / 31557600000
        if (ageY < 18) return false
        else return true
    }

    const handleAirlineEmail = (email) => {
        setAirlineEmail(email)
        if (email) {
            const domain = email.split('@')[1]
            const isValid = airlineEmails.data.filter((el) => el.Domain === `@${domain}`)
            if (isValid.length === 0) {
                setAirlineEmailError(true)
            } else setAirlineEmailError(false)
        } else {
            setAirlineEmailError(false)
        }
    }

    const handlePersonalEmail = (email) => {
        setEmail(email)
        const domain = email.split('@')[1]
        const isValid = airlineEmails.data.filter((el) => el.Domain === `@${domain}`)
        if (isValid.length !== 0) {
            setEmailError(true)
        } else setEmailError(false)
    }

    const handleDob = (value) => {
        if (new Date(value) < new Date()) {
            setDob(value)
        }
    }

    const handlePhone = (value, ev) => {
        const input = value.slice(ev.dialCode.length)
        setPhone(input)
        setcountryCode(ev.dialCode)
    }

    const handleSocialLogin = async (e) => {
        try {
            setRequesting(true)
            const { data, ...res } = await login({
                emailId: e.email,
                type: memberType,
                socialId: e.id,
                loginType: e.authType,
            })

            if (data.statusCode === '201') {
                setUserData({ ...data.data, message: data.message })
                return
            }
            //chage guest type code here----
            setRequesting(false)

            if (data.status) {
                toast.success(data.message, { toastId: 11 })

                if (memberType === 'Host') {
                    window.location.replace(`${constant.HOST_WEB_URL}?token=${data.data.token}&data=${JSON.stringify(data.data.user)}`)
                } else {
                    // setNotificationModal(true);
                    // setToken(data.data.token);
                    // const redirect = query.get("redirect");
                    // if (redirect) {
                    //   window.location.replace(redirect);
                    //   return;
                    // }
                    setToken(data.data.token)
                    const redirect = query.get('redirect')
                    if (redirect) {
                        window.location.replace(redirect)
                        return
                    }
                    // setData(data.data.user);
                    // setNotificationModal(true);
                    history.push('/dashboard')
                }
            } else {
                toast.error(data.message)
            }
        } catch (error) {
            setRequesting(false)
            toast.error('Something went wrong!')
        }
    }

    const handleRegister = async (e) => {
        e.preventDefault()

        if (!email || !password || !firstName || !lastName || !memberType || !dob) {
            return
        }

        if (emailError) {
            toast.error('Personal email should not have the airline domains')
            return
        }

        if (airlineEmailError) {
            toast.error('Invalid Airline Email')
            return
        }

        if (!termsAgree) {
            toast.error('Please agree to terms & condition.')
            return
        }

        if (!isAdult(dob)) {
            toast.error('You must be 18 years old or over to create an account.')
            return
        }

        if (password.length < 8) {
            toast.error('Password must have at least 8 characters.')
            return
        }

        if (password != confirmPassword) {
            toast.error('Password and confirm password must be same.')
            return
        }

        if (phone.length <= 4 || phone.length >= 15) {
            toast.error('Invalid Phone')
            return
        }

        let reqObject = {
            personalEmail: email,
            phone: phone,
            countryCode: countryCode,
            password: password,
            name: `${firstName.trim()} ${lastName.trim()}`,
            type: memberType,
            dob: dob,
            accountType: authType,
        }

        // if (authType === 'Normal') {
        //     reqObject['personalEmail'] = email
        // } else {
        //     reqObject['personalEmail'] = ""
        // }

        if (authType === 'Google') {
            reqObject['googleEmail'] = email
        }

        if (authType === 'Facebook') {
            reqObject['facebookEmail'] = email
        }

        if (socialId) {
            reqObject['socialId'] = socialId
        }

        if (airlineEmail) {
            reqObject['airlineEmail'] = airlineEmail
        }

        if (memberType === 'Crewmember') {
            reqObject['crewType'] = crewType
        }
        setRequesting(true)

        const { data } = await register(reqObject)
        setRequesting(false)
        localStorage.setItem(constant.REGISTER_USER_DATA, JSON.stringify({ token: data.data.token, user: data.data.user }))
        if (data.status) {
            setResetToken(data.data.token)
            localStorage.setItem(
                constant.USER_REGISTER_REQUEST,
                JSON.stringify({
                    personalEmail: reqObject.personalEmail,
                    phone: reqObject.phone,
                    countryCode: reqObject.countryCode,
                    memberType,
                })
            )
            history.push(`/verification`)
        } else {
            console.log(data)
            toast.error(data.message)
            if (data.statusCode === '200' && data.message === 'Email address is already in use.') {
                setIsAlreadyRegisteredModal(true)
            }
        }
    }

    const handleSocialRegistration = async (e) => {
        try {
            setRegisterationProcessing(true)
            setFirstName(e.firstName)
            setLastName(e.lastName)
            setEmail(e.email)
            setAuthtype(e.authType)
            setSocialId(e.id)
            handleAirlineEmail(null)
            setTimeout(() => {
                setRegisterationProcessing(false)
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            }, 500)
        } catch (error) {
            // setTimeout(window.location.reload, 1000);
        }
    }

    const authenticateUsingGoogle = useGoogleLogin({
        onSuccess: async ({ access_token }) => {
            const { data } = await axios.get(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${access_token}`)
            const checkUserIsRegistered = await checkIsRegistered({ emailId: data.email, loginType: 'Google', socialId: data.sub, type: memberType })

            if (checkUserIsRegistered.data.status) {
                handleSocialLogin({
                    email: data.email,
                    firstName: data.givenName,
                    lastName: data.familyName,
                    id: data.sub,
                    authType: 'Google',
                })
            } else {
                handleSocialRegistration({
                    email: data.email,
                    firstName: data.given_name,
                    lastName: data.family_name,
                    id: data.sub,
                    authType: 'Google',
                })
            }
        },
        onError: (err) => {
            toast.error(err)
        },
        onNonOAuthError: (err) => {
            toast.error(err)
        },
    })

    const authenticateUsingFacebook = async (e) => {
        const checkUserIsRegistered = await checkIsRegistered({ emailId: e.email, loginType: 'Facebook', socialId: e.id, type: memberType })
        if (checkUserIsRegistered.data.status) {
            handleSocialLogin({
                email: e.email,
                firstName: e.name.split(' ')[0],
                lastName: e.name.split(' ')[1],
                authType: 'Facebook',
                id: e.id,
            })
        } else {
            handleSocialRegistration({
                email: e.email,
                firstName: e.name.split(' ')[0],
                lastName: e.name.split(' ')[1],
                authType: 'Facebook',
                id: e.id,
            })
        }
    }

    // Temp fix to hard reaload the app
    useEffect(() => {
        const checkTempRef = localStorage.getItem(constant.TEMP_REF)
        console.log(checkTempRef)
        if (checkTempRef === 'false' || checkTempRef == null) {
            setTimeout(() => {
                localStorage.setItem(constant.TEMP_REF, 'true')
                window.location.reload()
            }, 1000)
        }
    }, [])

    return (
        <>
            <Header type="fixed" />
            <ToastContainer />
            {isAlreadyRegisteredModal && (
                <Modal className="bg-white rounded-md px-8 py-6 w-auto mx-auto">
                    <div className="mb-2">
                        <h4 className="text-center font-semibold text-gray-800">This email is already registered to an account.</h4>
                    </div>
                    <div className="text-center text-sm font-medium text-gray-800">
                        Log in to Crewmates using this email and the password you set.
                        <Button btnType="filled" className="mt-4 mx-auto block text-primary-blue rounded-full">
                            <Link to="/login">Login</Link>
                        </Button>
                        <Button className="mt-4 mx-auto block" onClick={() => setIsAlreadyRegisteredModal(false)}>
                            <span className="text-gray-800 font-medium">Close</span>
                        </Button>
                    </div>
                </Modal>
            )}
            {notificationModal && (
                <Modal className="bg-white rounded p-4 w-10/12 mx-auto lg:w-4/12">
                    <div className="flex justify-between mb-2 items-center">
                        <h4 className="text-sm font-medium text-gray-600">Welcome to Crewmates!</h4>
                        <span onClick={() => setNotificationModal(false)} className="h-10 w-10 rounded-full bg-gray-100 flex-center">
                            <CloseIcon className="text-3xl text-gray-500 cursor-pointer" />
                        </span>
                    </div>
                    <div className="text-sm text-gray-600 ">
                        Thank you for registering with Crewmates. We are currently in the process of onboarding hosts. Join our Crewmates Crashpads
                        Community Facebook Group for further assistance finding accommodations.{' '}
                        <Button btnType="filled" className="mt-4 mx-auto block">
                            <a href="https://www.facebook.com/groups/crewmatescrashpads/">Search for Crashpads</a>
                        </Button>
                    </div>
                </Modal>
            )}
            {registrationProcessing && (
                <Modal>
                    <div className="bg-white rounded shadow-md p-4">
                        <div className="loader"></div>
                    </div>
                </Modal>
            )}
            <Section className="mt-10 px-4">
                <div className="mt-8 w-full lg:w-10/12 xl:w-6/12 3xl:w-5/12 mx-auto">
                    <h4 className="mb-2 text-center text-primary-blue text-3xl font-bold ">Register</h4>
                    <div className="grid grid-cols-1 md:grid-cols-2  my-4 w-full">
                        <p className="hidden sm:flex items-center text-sm">Are you a Host or Crewmember?</p>
                        <SelectInputText
                            label={<p className="block sm:hidden">Are you a Host or Crewmember?</p>}
                            defaultValue="Crewmember"
                            placeholder=" Are you a Host or Crewmember?"
                            value={memberType}
                            setValue={setMemberType}
                            listId="crew-type"
                            leftIcon={null}
                            datalist={[
                                { key: 'Crewmember', value: 'Crewmember' },
                                { key: 'Host', value: 'Host' },
                            ]}
                            setOptionList={setOptionList}
                            optionList={optionList}
                        />
                    </div>
                    <form onSubmit={handleRegister} className="grid grid-cols-1 md:grid-cols-2 gap-4 gap-y-4">
                        {/* <TextInput
              label="Name"
              placeholder="John Doe"
              field="text"
              value={name}
              setValue={setName}
              required
              leftIcon={<AiOutlineUser size={24} />}
            /> */}
                        <TextInput
                            label="First Name"
                            placeholder="John"
                            field="text"
                            value={firstName}
                            setValue={setFirstName}
                            required
                            leftIcon={<AiOutlineUser size={24} />}
                        />
                        <TextInput
                            label="Last Name"
                            placeholder="Doe"
                            field="text"
                            value={lastName}
                            setValue={setLastName}
                            required
                            leftIcon={<AiOutlineUser size={24} />}
                        />
                        <TextInput
                            label="Email"
                            type="email"
                            field="email"
                            required
                            placeholder="johndoe@gmail.com"
                            value={email}
                            setValue={handlePersonalEmail}
                            leftIcon={<AiOutlineMail size={24} />}
                        />
                        <div className={`flex items-start flex-col justify-start w-full `}>
                            <label className="text-gray-600 text-sm mb-1">Mobile</label>
                            <PhoneInput
                                country={'us'}
                                onlyCountries={onlyCountriesForPhone}
                                autoFormat
                                value={`${countryCode}${phone}`}
                                onChange={handlePhone}
                                buttonClass="bg-gray-100 border-0"
                                inputClass="border-0 pl-12 focus:ring-0 tw-w-full focus:border-0 flex-grow text-sm w-full outline-none bg-gray-100 p-2 rounded ring-1 ring-gray-200"
                            />
                        </div>

                        <TextInput
                            validateField={false}
                            label="Airline Email (Optional)"
                            type="email"
                            field="email"
                            placeholder="name@aa.com"
                            value={airlineEmail}
                            setValue={handleAirlineEmail}
                            leftIcon={<AiOutlineMail size={24} />}
                        />
                        {memberType === 'Host' ? null : (
                            <SelectInputText
                                label="Crew Type"
                                value={crewType}
                                setValue={setCrewType}
                                listId="crew-type"
                                placeholder="crew type"
                                datalist={[
                                    { key: 'Pilot', value: 'Pilot' },
                                    { key: 'Flight Attendent', value: 'Flight Attendant' },
                                    {
                                        key: 'Other Airline Professional',
                                        value: 'Other Airline Professional',
                                    },
                                ]}
                                leftIcon={<AiOutlineUserAdd size={24} />}
                                setOptionList={setCrewTypeOptionList}
                                optionList={crewTypeoptionList}
                            />
                        )}
                        <div className="md:col-span-2">
                            <TextInput
                                label="Date of Birth"
                                type="date"
                                required
                                value={dob}
                                setValue={handleDob}
                                leftIcon={<BsCalendarDate size={24} />}
                            />
                        </div>
                        <TextInput
                            label="Password"
                            type="password"
                            field="password"
                            placeholder="********"
                            value={password}
                            setValue={setPassword}
                            required
                            leftIcon={<BiLock size={24} />}
                        />
                        <TextInput
                            label="Confirm Password"
                            type="password"
                            field="password"
                            placeholder="********"
                            value={confirmPassword}
                            setValue={setConfirmPassword}
                            required
                            leftIcon={<BiLock size={24} />}
                            rightIcon={password && password === confirmPassword ? <FiCheckCircle size={24} className="text-green-500" /> : null}
                        />
                        <div className="flex items-start justify-start md:col-span-2 text-gray-700">
                            <input
                                onChange={(e) => {
                                    setTermsAgree((e) => {
                                        return !e
                                    })
                                }}
                                defaultValue={termsAgree}
                                type="checkbox"
                                className="mr-2 mt-1 text-primary-blue"
                            />
                            <p className="text-sm">
                                I accept{' '}
                                <span className="underline">
                                    <Link target="_blank" to="/terms-and-condition">
                                        Terms & Condition
                                    </Link>
                                </span>{' '}
                                and{' '}
                                <span className="underline">
                                    <Link target="_blank" to="/privacy-policy">
                                        Privacy Policy
                                    </Link>
                                </span>
                            </p>
                        </div>
                        <Button type="submit" className="block mx-auto my-4 md:col-span-2" btnType="filled" disabled={isRequestion}>
                            {isRequestion ? 'Please wait ...' : 'Sign Up'}
                        </Button>
                    </form>
                    <p className="text-sm text-center">
                        Already have an account?{' '}
                        <Link to="/login" className="underline text-primary-blue">
                            Login{' '}
                        </Link>
                        here
                    </p>

                    <div className="flex justify-center items-center py-4 ">
                        <div className="cursor-pointer">
                            <FcGoogle onClick={() => authenticateUsingGoogle()} size={40} className="cursor-pointer mx-2 text-gray-300" />
                        </div>
                        {/* <GoogleLogin
                            clientId={constant.GOOGLE_CLIENTID}
                            onSuccess={(e) => {
                                if (e.profileObj.email) {
                                    handleSocialRegistration({
                                        email: e.profileObj.email,
                                        firstName: e.profileObj.givenName,
                                        lastName: e.profileObj.familyName,
                                        id: e.profileObj.googleId,
                                        authType: 'Google',
                                    })
                                } else toast.error('Something went wrong!')
                            }}
                            onFailure={(e) => {
                                console.log(e)
                                // toast.error('Something went wrong while fetching your google account.')
                            }}
                            render={(renderProps) => (
                                <FcGoogle onClick={renderProps.onClick} size={40} className="cursor-pointer mx-2 text-gray-300" />
                            )}
                        /> */}

                        <FacebookLogin
                            appId={constant.FACEBOOK_APPID}
                            callback={(e) => {
                                if (e.email) {
                                    authenticateUsingFacebook(e)
                                } else toast.error('Your facebook id is not connected with any email, Please register using your email id.')
                            }}
                            fields="name,email,picture,gender,birthday"
                            render={(renderProps) => (
                                <BsFacebook onClick={renderProps.onClick} size={36} className="cursor-pointer mx-2 text-blue-500" />
                            )}
                        />

                        {/* <AiFillApple size={46} className="mx-2" /> */}
                    </div>
                    {/* <p className="text-sm text-gray-500 text-center my-2">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry
          </p> */}
                </div>
            </Section>

            <Footer />
        </>
    )
}

export default Register
