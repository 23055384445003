import React, { useState, useEffect } from "react";
import Modal from "@components/Modal";
import useDiscloser from "@hooks/useDiscloser";
import { BsFillCaretDownFill } from "react-icons/bs";
import MessageAlert from "../../../components/MessageAlert";
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  FieldArray,
  useFormikContext,
} from "formik";
import * as Yup from "yup";
import ErrorWrapper from "@components/ErrorWrapper";
import { IoMdTrash } from "react-icons/io";
import Button from "@ui/Button";

import {
  getAddressList,
  addNewAddress,
  deleteAddress,
  updateAddress,
} from "@services/user";
import { toast } from "react-toastify";

const addressSchema = Yup.object({
  city: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  zipCode: Yup.string().min(5).max(5).required("Required"),
  line1: Yup.string().required("Required"),
  line2: Yup.string().required("Required"),
  isPrimary: Yup.bool(),
});

function FormikControl({ name, label, className, ...props }) {
  return (
    <div className={`flex flex-col ${className} mb-2`}>
      <label className="text-sm text-gray-600 mb-1">{label}</label>
      <Field
        name={name}
        className="p-2 text-xs rounded-sm ring-1 ring-gray-400 outline-none"
      />
      <ErrorMessage name={name} component={ErrorWrapper} />
    </div>
  );
}

function AddNewAddress({ onClose }) {
  const handleAddNewAddress = async (data) => {
    try {
      const res = await addNewAddress(data);
      if (res.data.status) {
        toast.success(res.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        onClose();
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  return (
    <Modal title="Add Address" onClose={onClose}>
      <Formik
        onSubmit={handleAddNewAddress}
        validationSchema={addressSchema}
        initialValues={{
          city: "",
          state: "",
          country: "",
          zipCode: "",
          line1: "",
          line2: "",
          isPrimary: false,
        }}
        className="mb-4 text-sm"
      >
        {(data) => {
          return (
            <Form>
              <FormikControl autocomplete="off" label="Address 1" name="line1" />
              <FormikControl autocomplete="off" label="Address 2" name="line2" />
              <FormikControl autocomplete="off" label="City" name="city" />
              <FormikControl autocomplete="off" label="State" name="state" />
              <FormikControl
                autocomplete="off"
                label="Zipcode"
                name="zipCode"
              />
              <FormikControl
                autocomplete="off"
                label="Country"
                name="country"
              />
              <div className="flex items-center gap-2 text-xs my-4">
                <Field
                  id="primary_address"
                  name="isPrimary"
                  type="checkbox"
                  className="rounded-sm outline-none ring-0"
                />
                <label htmlFor="primary_address">
                  Make it as primary address.
                </label>
              </div>
              <div className="mb-4">
                <Button btnType="filled" disabled={data.isSubmitting}>
                  {data.isSubmitting ? "Please wait..." : "Save"}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

function AddressList({ label, ind, id, values, setRefreshKey }) {
  const { isOpen, setOpen, toggle } = useDiscloser(false);
  const [requesting, setRequesting] = useState(false);
  const [alert, setAlert] = useState(false);

  const handleDeleteAddress = async (id) => {
    try {
      const res = await deleteAddress(id);
      if (res.data.status) {
        toast.success(res.data.message);
        setAlert(false);
        setRefreshKey((e) => e + 1);
      } else toast.error(res.data.message);
    } catch (error) {
      setRefreshKey((e) => e + 1);
      setAlert(false);
      toast.error("Something went wrong");
    }
  };

  const handleUpdateAddress = async (id) => {
    try {
      const data = values.addresses[id];
      setRequesting(true);
      const res = await updateAddress(data.id, data);
      if (res.data.status) {
        toast.success(res.data.message);
        setRefreshKey((e) => e + 1);
      } else toast.error(res.data.message);
      setRequesting(false);
    } catch (error) {
      toast.error("Something went wrong");
      setRefreshKey((e) => e + 1);
      setRequesting(false);
    }
  };

  return (
    <div className="my-2">
      <React.Fragment>
        {alert && (
          <MessageAlert
            message="Are you sure want to delete this address?"
            onProceed={() => handleDeleteAddress(id)}
            onCancle={() => setAlert(false)}
          />
        )}
        <div
          onClick={toggle}
          className="bg-primary-blue flex items-center justify-between text-sm rounded p-2 text-white"
        >
          {label}
          <BsFillCaretDownFill
            className="text-white cursor-pointer"
            size={18}
          />
        </div>
        {isOpen && (
          <div className="grid  md:grid-cols-2 gap-x-4 p-4">
            <FormikControl label="State" name={`addresses.${ind}.state`} />
            <FormikControl label="City" name={`addresses.${ind}.city`} />
            <FormikControl label="Country" name={`addresses.${ind}.country`} />
            <FormikControl label="Zipcode" name={`addresses.${ind}.zipCode`} />
            <FormikControl label="Address 1" name={`addresses.${ind}.line1`} />
            <FormikControl label="Address 2" name={`addresses.${ind}.line2`} />
            <label className="mb-4 text-parrot text-sm gap-2 items-center flex">
              <Field
                type="checkbox"
                className="rounded-sm text-parrot ring-0"
                name={`addresses.${ind}.isPrimary`}
              />
              Make it as default.
            </label>
            <div className="md:col-span-2 flex gap-2 items-center">
              <Button
                onClick={() => setAlert(true)}
                btnType="outline"
                type="button"
                className="text-sm bg-parrot ring-1 ring-parrot text-white w-max block md:col-span-2"
              >
                Delete
              </Button>
              <Button
                btnType="outline"
                type="button"
                onClick={() => handleUpdateAddress(ind)}
                className="text-sm my-4 ring-1 ring-primary-blue text-primary-blue w-max block md:col-span-2"
              >
                {requesting ? "Please wait..." : "Update"}
              </Button>
            </div>
          </div>
        )}
      </React.Fragment>
    </div>
  );
}

function BillingAddress() {
  const [addressList, setAddressList] = useState({
    addresses: [],
  });
  const [refreshKey, setRefreshKey] = useState(false);
  const [fetching, setFetching] = useState(false);
  const validationSchema = Yup.object({
    addresses: Yup.array().of(addressSchema),
  });

  useEffect(() => {
    const fetchAddress = async () => {
      try {
        setFetching(true);
        const res = await getAddressList();
        setAddressList({
          addresses: res.data.data.map((el) => {
            const obj = { ...el };
            obj.isPrimary = obj.isPrimary ? true : false;
            return obj;
          }),
        });
      } catch (error) {
        toast.error("Something went wrong!");
      } finally {
        setFetching(false);
      }
    };
    fetchAddress();
  }, [refreshKey]);

  const { isOpen: addressModal, setOpen, toggle } = useDiscloser(false);
  return (
    <div>
      {fetching && <p className="text-sm text-gray-600">Please wait...</p>}
      <Formik
        initialValues={addressList}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {({ values, errors }) => {
          return (
            <Form>
              <FieldArray name="addresses">
                {({ push, remove }) => {
                  return values.addresses.map((curr, ind) => {
                    return (
                      <AddressList
                        setRefreshKey={setRefreshKey}
                        values={values}
                        id={curr.id}
                        key={ind}
                        label={`Address ${ind + 1}`}
                        ind={ind}
                      />
                    );
                  });
                }}
              </FieldArray>
            </Form>
          );
        }}
      </Formik>
      {addressModal && <AddNewAddress onClose={toggle} />}
      <Button btnType="filled" className="my-4" onClick={toggle}>
        Add New Address
      </Button>
    </div>
  );
}

export default BillingAddress;

// function AddressCard({ title, defaultAddress = false }) {
//   const [visible, setVisible] = useState(defaultAddress);
//   const [line1, setline1] = useState("");
//   const [line2, setline2] = useState("");
//   const [city, setCity] = useState("");
//   const [state, setState] = useState("");
//   const [country, setCountry] = useState("");
//   const [zipCode, setZipCode] = useState("");

//   return (
//     <div className="rounded-md overflow-hidden">
//       <div className="grid grid-cols-12 items-baseline gap-2 p-2 pl-0">
//         <div className="col-span-10 ">
//           <div
//             onClick={() => setVisible(!visible)}
//             className="p-3 cursor-pointer   bg-primary-blue text-white text-sm flex justify-between items-center rounded-md"
//           >
//             {title}
//             <img
//               src="/assets/resources/arrow-dropdown.png"
//               alt="down arrow"
//               className={`w-6  cursor-pointer transform ${
//                 visible ? "rotate-180" : ""
//               }`}
//             />
//           </div>

//           {visible && (
//             <div className="p-4 py-8 rounded-b-md  bg-[#F1F5FB]">
//               <div className="grid grid-cols-2 gap-4">
//                 <TextInput
//                   value={line1}
//                   setValue={setline1}
//                   label="Address Line 1"
//                   placeholder="111 S Michigan Ave"
//                 />
//                 <TextInput
//                   value={line2}
//                   setValue={setline2}
//                   label="Address Line 2"
//                   placeholder="Street No. 03"
//                 />
//                 <TextInput
//                   value={city}
//                   setValue={setCity}
//                   label="City"
//                   placeholder="Michigan"
//                 />
//                 <TextInput
//                   value={state}
//                   setValue={setState}
//                   label="State"
//                   placeholder="Chicago"
//                 />
//                 <TextInput
//                   value={country}
//                   setValue={setCountry}
//                   label="Country"
//                   placeholder="United State"
//                 />
//                 <TextInput
//                   value={zipCode}
//                   setValue={setZipCode}
//                   label="Zip Code"
//                   placeholder="606112"
//                 />
//                 <div className="col-span-2 flex justify-start items-center my-2">
//                   <input
//                     id="set-default-address"
//                     type="checkbox"
//                     defaultChecked={defaultAddress}
//
//                     className="w-3 h-3 text-parrot focus:ring-0"
//                   />
//                   <label
//                     htmlFor="set-default-address"
//                     className="ml-2 text-parrot text-xs"
//                   >
//                     Make the address as Primary Billing Address.
//                   </label>
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>
//         {!defaultAddress && (
//           <button className="col-span-2 w-max  flex-center p-3 px-5 ring-1 ring-primary-blue text-primary-blue text-xs rounded-md flex-shrink-0 ml-2">
//             Delete <IoMdTrash size={20} className="text-primary-blue ml-4" />
//           </button>
//         )}
//       </div>
//     </div>
//   );
// }

// function BillingAddress() {
//   return (
//     <div>
//       <AddressCard title="Address 1" defaultAddress />
//       <AddressCard title="Address 2" />
//       <AddressCard title="Address 3" />
//       <button className="rounded-md p-3 px-8 bg-primary-blue text-white text-sm ">
//         SAVE ADDRESS
//       </button>
//     </div>
//   );
// }
