import React, { useRef, useEffect, useState, useMemo } from "react";
import Header from "@front/Header";
import Footer from "@front/Footer";
import Section from "@layout/Section";
import Button from "@ui/Button";
import BreadCrunb from "@components/BreadCrunb";
import useCaptcha from "@hooks/useCaptcha";
import { submitContactQuery } from "./../_services/general";
import { toast, ToastContainer } from "react-toastify";

function ContactForm({ onSubmit, captcha, className, requesting }) {
  const captchaRef = useRef(null);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [userCaptcha, setUserCaptcha] = useState("");

  const handleFormSubmission = (e) => {
    e.preventDefault();
    onSubmit({ name, email, mobile, message, userCaptcha });
  };

  useEffect(() => {
    const ctx = captchaRef.current.getContext("2d");
    ctx.clearRect(0, 0, captchaRef.current.width, captchaRef.current.height);
    ctx.font = "24px Roboto";
    ctx.fillStyle = "#08e5ff";
    ctx.fillText(captcha, 0, 40);
    ctx.textAlign = "center";
  }, []);
  return (
    <form className={className} onSubmit={handleFormSubmission}>
      <input
        className="h-12 col-span-2 md:col-span-1 outline-none text-sm  w-full rounded p-2 px-4 bg-white"
        placeholder="Name"
        value={name}
        required
        onChange={(el) => setName(el.target.value)}
      />
      <input
        className="h-12 col-span-2 md:col-span-1 outline-none text-sm  w-full rounded p-2 px-4 bg-white"
        placeholder="Mobile"
        value={mobile}
        type="number"
        required
        onChange={(el) => setMobile(el.target.value)}
      />
      <input
        className="h-12 outline-none text-sm col-span-2  w-full rounded p-2 px-4 bg-white"
        placeholder="Email"
        type="email"
        value={email}
        required
        onChange={(el) => setEmail(el.target.value)}
      />
      <textarea
        className="h-24 outline-none text-sm col-span-2  w-full rounded p-2 px-4 bg-white"
        placeholder="Message"
        value={message}
        required
        onChange={(el) => setMessage(el.target.value)}
      ></textarea>

      <div className="my-2 col-span-2 p-4 flex items-start justify-between bg-primary-blue-light text-white ">
        {/* <h4 className="text-xl mr-2">{captcha}</h4> */}
        <canvas height={80} width={120} ref={captchaRef}></canvas>
        <div className="">
          <p className="text-sm mb-2">Enter Captcha</p>
          <input
            size={25}
            value={userCaptcha}
            onChange={(el) => setUserCaptcha(el.target.value)}
            className="ring-2 ring-white w-full placeholder-gray-50 ring-opacity-70 rounded bg-transparent p-2 outline-none"
            placeholder="DD3G1"
          />
        </div>
      </div>

      <Button
        type="submit"
        className="text-primary-blue  bg-white font-bold py-4 "
      >
        {requesting ? "Please wait..." : "Submit"}
      </Button>
    </form>
  );
}

function ContactUs() {
  const { validate, captcha } = useCaptcha();
  const [requesting, setRequesting] = useState(false);
  const handleContactForm = async (e) => {
    try {
      if (!e.email || !e.message || !e.mobile || !e.name) {
        return toast.error("Invalid form data!");
      }

      if (e.mobile.length > 14 || e.mobile.length < 4) {
        return toast.error("Invalid mobile");
      }
      setRequesting(true);
      if (validate(e.userCaptcha)) {
        const res = await submitContactQuery({
          email: e.email,
          mobile: e.mobile,
          message: e.message,
          name: e.name,
        });
        setRequesting(false);
        if (res.data.status) {
          toast.success(res.data.message,{toastId:1});
        } else toast.error(res.data.message,{toastId:2});
      } else {
        setRequesting(false);
        toast.error("Invalid captcha!",{toastId:3});
      }
    } catch (error) {
      setRequesting(false);
      toast.error("Something went wrong!",{toastId:4});
    }
  };

  return (
    <div>
      <Header />
      <ToastContainer />
      <Section className="px-4 mt-24  ">
        <BreadCrunb
          className="mb-10 mt-5"
          title="Contact us"
          bread={[
            { name: "Home", path: "/" },
            { name: "Contact us", path: "/contact" },
          ]}
        />

        <div className="md:w-10/12 lg:w-8/12 my-20 mx-auto flex flex-col md:flex-row space-y-10 md:space-y-0 md:space-x-10 justify-center items-center">
          <div className="flex justify-center items-center w-max flex-col ">
            <img
              className="mb-5 w-12"
              src="/assets/resources/email.png"
              alt="email"
            />
            <h4 className="capitalize text-primary-blue text-lg md:text-xl ">
              Official Email
            </h4>
            <p className="text-gray-800 font-semibold text-base md:text-lg ">
              support@crewmatesapp.com
            </p>
          </div>
          <div className="flex justify-center items-center flex-col w-max">
            <img
              className="mb-5 w-9"
              src="/assets/resources/location1.png"
              alt="email"
            />
            <h4 className="capitalize text-primary-blue  text-lg md:text-xl ">
              Official Address
            </h4>
            <p className="text-gray-800 font-semibold text-base md:text-lg ">
              8 The Green, Ste B, Dover, DE 19901
            </p>
          </div>
          {/* <div className="flex justify-center items-center flex-col w-max">
            <img
              className="mb-5 text-gray-500 "
              src="/assets/resources/telephone.png"
              alt="email"
            />
            <h4 className="capitalize text-primary-blue text-xl ">
              Official Mobile
            </h4>
            <p className="text-gray-800 font-semibold text-lg ">
              +91 72678899227
            </p>
          </div> */}
        </div>
        <div className="bg-primary-blue lg:w-8/12 items-stretch rounded overflow-hidden  mx-auto my-20 flex flex-col md:flex-row ">
          <div className=" md:w-6/12 justify-items-stretch flex-grow overflow-hidden relative ">
            <img
              src="/assets/resources/lady.jpg"
              alt="lady on call"
              className="w-full h-full object-cover absolute top-0 left-0 bottom-0 right-0 "
            />
          </div>
          <div className=" md:w-6/12 p-4 md:p-8 text-white">
            <h4 className=" font-medium text-xl md:text-3xl">Get In Touch</h4>
            <p className="text-base md:text-lg">
              Please fill your details below
            </p>
            <hr className="w-[50%] bg-white border-2 rounded-full my-4" />
            <div>
              <ContactForm
                requesting={requesting}
                captcha={captcha}
                onSubmit={handleContactForm}
                className="grid grid-cols-2 gap-4 my-5 text-gray-800"
              />
            </div>
          </div>
        </div>
      </Section>
      <Footer />
    </div>
  );
}

export default ContactUs;
