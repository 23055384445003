import React from "react";
import { routes } from "./routes";
import { NavLink } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import { logout } from "../_services/user";
export function SidebarButton({ icon: Icon, title, path, onClick }) {
  return (
    <NavLink
      to={onClick ? "#" : path}
      activeClassName={onClick ? "" : "text-white bg-primary-blue block rounded-xl"}
    >
      <div
        onClick={onClick ? onClick : () => {}}
        className="p-4 relative cursor-pointer hover:bg-primary-blue rounded-xl hover:text-white transition-colors flex items-center justify-start"
      >
        {Icon}
        <h4 className="text-sm font-light">{title}</h4>
        {/* <span className="block h-3/4  w-2 rounded-l-md absolute right-0 top-0 bottom-0 my-auto bg-primary-blue "></span> */}
      </div>
    </NavLink>
  );
}

function Sidebar() {
  return (
    <div className="w-full px-2 text-black flex flex-col gap-2 pt-1.5">
      {routes.map((route, i) => (
        <SidebarButton
          key={i}
          icon={route.icons}
          title={route.title}
          path={route.path}
        />
      ))}

      <SidebarButton
        icon={<MdLogout size={22} className="mr-2" />}
        title="Logout"
        onClick={logout}
      />
    </div>
  );
}

export default Sidebar;
