import React from "react";

function TextInput({ label, value, setValue, type, rightIcon, ...props }) {
  return (
    <div className="flex flex-col">
      <label className="mb-1 text-xs text-primary-blue-dim ">{label}</label>
      <div className="flex justify-start items-center ring-1 ring-primary-blue-dim rounded-md bg-white">
        <input
          size={5}
          className=" flex-grow border-0 ring-0 focus:ring-0  text-xs p-2 h-10 outline-none bg-transparent placeholder-primary-blue "
          {...props}
          type={type}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <div className="flex-shrink-0 px-2 cursor-pointer">{rightIcon}</div>
      </div>
    </div>
  );
}

export default TextInput;
