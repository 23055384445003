import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Navbar from '@dashboard/Navbar'
import Modal from '@components/Modal'
import OtpInput from 'react-otp-input'
import { toast, ToastContainer } from 'react-toastify'
import { Context as AuthContext } from '@context/authContext'
import { getUpcomingReservation } from '@services/property'
import { verifyEmail, verifyPhone, forgetPassword, logout } from '@services/user'
import { setResetToken } from '@helper/header'
import { constant } from '@config/constant'

import moment from 'moment'
import { processMediaUrl } from '../../_helper/utils'

function ReservationCard({ title, data }) {
    return (
        <div className='col-span-2'>
            <h3 className="text-primary-blue text-xl font-medium mb-4">{title}</h3>
            <div className=" flex flex-col md:flex-row sm:mx-8 mx-1.5 md:mx-0 lg:flex-col xl:flex-row justify-start md:gap-4 lg:gap-5 xl:gap-0 gap-4 xl:space-x-4 items-stretch p-2 rounded-xl">
                <img alt="" className=" w-full md:w-1/2 lg:w-full xl:w-1/2 rounded-xl image-shadow" src={processMediaUrl(data.propertyId.images[0])} />
                <div className="flex-grow flex flex-col gap-3 marging-none">
                    <p className="flex items-center border border-solid border-[#F2F2F2] px-[1.625rem] py-2.5 gap-1.5 font-medium w-full p-1 rounded-full bg-white text-primary-blue  text-xs">
                        Property: <span className="font-light truncate">{data.propertyId.listingName}</span>
                    </p>
                    <p className="flex items-center border border-solid border-[#F2F2F2] px-[1.625rem] py-2.5 gap-1.5 font-medium w-full p-1 rounded-full bg-white text-primary-blue  text-xs">
                        Check In: <span className="font-light">{moment(data.checkInDate.split('T')[0]).format('MMMM Do, YYYY')}</span>
                    </p>
                    <p className="flex items-center border border-solid border-[#F2F2F2] px-[1.625rem] py-2.5 gap-1.5 font-medium w-full p-1 rounded-full bg-white text-primary-blue  text-xs">
                        Check Out:{' '}
                        <span className="font-light">
                            {data.checkOutDate ? moment(data.checkOutDate.split('T')[0]).format('MMMM Do, YYYY') : '--'}
                        </span>
                    </p>
                    <p className="flex items-center border border-solid border-[#F2F2F2] px-[1.625rem] py-2.5 gap-1.5 font-medium w-full p-1 rounded-full bg-white text-primary-blue  text-xs">
                        Price:{' '}
                        <span className="font-light">
                            {data.totalPrice.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })}
                        </span>
                    </p>
                    {/* <button className="ml-auto p-2 px-2 rounded-md text-white bg-primary-blue-dark text-xs">
            View More
          </button> */}
                </div>
            </div>
        </div>
    )
}

function VerificationModal({ onClose, verificationType, header, description }) {
    const [requesting, setRequesting] = useState(false)
    const history = useHistory()
    const { user, updateUser } = useContext(AuthContext)
    const [otp, setOtp] = useState('')

    const handleResendOtp = async () => {
        if (user.authStatus) {
            const requestObj = {}
            requestObj['authType'] = verificationType
            requestObj['emailOrPhone'] = verificationType === 'email' ? user.personalEmail : user.phone

            if (verificationType === 'phone') {
                requestObj['countryCode'] = user.countryCode
            }
            const res = await forgetPassword(requestObj)
            if (res.data.status) {
                setResetToken(res.data.data.token)
                toast.success('OTP sent',{toastId:25})
            } else toast.error('Something wrong happened!')
        } else {
            toast.error('Something went wrong 33')
            // history.push("/login");
        }
    }

    const handleVerification = async () => {
        try {
            if (otp.length === 4) {
                setRequesting(true)
                if (verificationType === 'phone') {
                    const { data } = await verifyPhone({ otp })
                    if (data.status) {
                        toast.success(data.message,{toastId:26})
                        updateUser({ phoneVerification: 1 })
                        onClose()
                    } else {
                        toast.error(data.message)
                    }
                } else {
                    const { data } = await verifyEmail({ otp })
                    if (data.status) {
                        toast.success(data.message,{toastId:27})
                        onClose()
                        updateUser({ personalEmailVerification: 1 })
                    } else {
                        toast.error(data.message,{toastId:28})
                    }
                }
                setRequesting(false)
            }
        } catch (error) {
            toast.error('something went wrong')
            setRequesting(false)
        }
    }

    useEffect(() => {
        handleResendOtp()
    }, [])

    return (
        <Modal title="&nbsp;" onClose={onClose}>
            <div className="grid place-content-center my-10">
                <h2 className="text-base  text-center">{header}</h2>
                <p className="text-sm text-center mt-2 w-10/12 mx-auto">{description}</p>
                <OtpInput
                    value={otp}
                    onChange={(e) => {
                        setOtp(e)
                    }}
                    isInputNum
                    containerStyle="flex-center space-x-4 my-4"
                    inputStyle="h-10  w-10 bg-white rounded p-0"
                    separator={<span>{'  '}</span>}
                    numInputs={4}
                />
                <button onClick={handleVerification} className="bg-primary-blue p-2 px-4 mx-auto w-max block rounded text-sm text-white ">
                    {requesting ? 'Please wait...' : 'Verify'}
                </button>
                <p onClick={handleResendOtp} className="text-primary-blue cursor-pointer  text-center text-sm my-4">
                    Resend OTP
                </p>
            </div>
        </Modal>
    )
}

function Home() {
    const { user } = useContext(AuthContext)
    const [verifyEmail, setVerifyEmail] = useState(false)
    const [verifyPhone, setVerifyPhone] = useState(false)
    const toggleVerifyEmail = () => setVerifyEmail(!verifyEmail)
    const toggleVerifyPhone = () => setVerifyPhone(!verifyPhone)
    const [reservations, setReservations] = useState({
        currentBooking: null,
        upcommingBooking: null,
    })
    const [fetchingReservations, setFetchingReservations] = useState(false)
    useEffect(() => {
        const fetch = async () => {
            try {
                setFetchingReservations(true)
                const { data } = await getUpcomingReservation()

                if (data.status) {
                    setReservations({
                        currentBooking: data.data.currentBooking,
                        upcommingBooking: data.data.upcomingBooking,
                    })
                }
            } catch (error) {
                toast.error('Something went wrong')
            } finally {
                setFetchingReservations(false)
            }
        }

        fetch()
    }, [])
    return (
        <div>
            <ToastContainer />
            <Navbar title="Dashboard" user={user.name} />
            {verifyEmail && (
                <VerificationModal
                    verificationType="email"
                    header="Email Address Verification"
                    description="Please check your email and enter the OTP. Check your spam folder if the
        first email doesn't reach your inbox."
                    onClose={toggleVerifyEmail}
                />
            )}
            {verifyPhone && (
                <VerificationModal
                    verificationType="phone"
                    header="Mobile Number Verification"
                    description="Crewmates has sent the OTP to your registered mobile number."
                    onClose={toggleVerifyPhone}
                />
            )}
            <div>
                <div className="grid lg:grid-cols-4 gap-10 my-10">
                    {fetchingReservations && (
                        <p className="text-center col-span-full text-sm font-medium text-primary-blue">Loading reservations..</p>
                    )}
                    {reservations.currentBooking && <ReservationCard data={reservations.currentBooking} title="Current Reservation" />}
                    {reservations.upcommingBooking && <ReservationCard data={reservations.upcommingBooking} title="Upcomming Reservation" />}
                </div>
                <div>
                    <h3 className="text-primary-blue text-xl font-medium mb-4">Verification Status</h3>
                    <div>
                        <div className="flex flex-row items-center justify-between gap-2 p-4 mb-4 bg-gray-light rounded">
                            <div className='flex items-center gap-2.5'>
                            <span className="h-12 w-12 flex-center ring-1 ring-primary-blue rounded-full p-3 ">
                                <img alt="" src="/assets/resources/material-email.png" />
                            </span>
                            <p className="text-sm text-primary-blue font-medium md:mx-4">Email ID</p>
                            </div>
                            {user.personalEmailVerification === 1 ? (
                                <p className="text-green-500 text-sm md:mr-4 sm:ml-auto">Verified</p>
                            ) : (
                                    <div className='flex gap-2.5 sm:flex-row sm:gap-4 items-center flex-col sm:flex-cols'>
                                    <p className="text-red-500 text-sm sm:ml-auto">Not Verified</p>
                                    <button onClick={toggleVerifyEmail} className=" p-2 px-2 rounded-md text-white bg-primary-blue-dark text-xs">
                                        Verify Now
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="flex items-center flex-row justify-between gap-2 p-4 mb-4 bg-gray-light rounded">
                            <div className='flex items-center gap-2.5'>
                            <span className="h-12 w-12 flex-center ring-1 ring-primary-blue rounded-full p-3 ">
                                <img alt="" src="/assets/resources/material-phone.png" />
                            </span>
                            <p className="text-sm text-primary-blue font-medium md:mx-4">Mobile Number</p>
                            </div>
                            {user.phoneVerification === 1 ? (
                                <p className="text-green-500 text-sm sm:ml-auto">Verified</p>
                            ) : (
                                <div className='flex gap-2.5 sm:flex-row sm:gap-4 items-center flex-col sm:flex-cols'>
                                    <p className="text-red-500 text-sm sm:ml-auto">Not Verified</p>
                                    <button onClick={toggleVerifyPhone} className=" p-2 px-2 rounded-md text-white bg-primary-blue-dark text-xs">
                                        Verify Now
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
