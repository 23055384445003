import axios from 'axios'
import { constant } from '@config/constant'
import { getBasicAuthHeader, getAuthHeader } from '@helper/header'
import { handleResponse, handleErrorResponse } from '../_helper/utils'

const api = axios.create({ baseURL: constant.apiUrl })

export const getHomePropertyList = async (id, latlng) => {
    try {
        const response = await api.post('/user/property/home', { userId: id, latlng: latlng.reverse() }, getBasicAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export const getPropertyDetails = async (id, option) => {
    try {
        const response = await api.get(
            `/user/property/${id}/detail?lat=${option?.lat || ''}&lng=${option?.lng || ''}&userId=${option.userid}&airportName=${
                option.airportName
            }&checkIn=${option.checkIn}&checkOut=${option.checkOut}`,
            getBasicAuthHeader()
        )
        // const response = await api.get(
        //   `/user/property/${id}/detail?lat=${option?.lat || ""}&lng=${
        //     option?.lng || ""
        //   }&airportName=${option.airportName}`,
        //   getBasicAuthHeader()
        // );

        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export const bookProperty = async (data) => {
    try {
        const response = await api.post(`/user/booking`, data, getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export const updateCardForBookedProperty = async (data) => {
    try {
        const response = await api.post(`/user/booking/update-card`, data, getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export const getBookingDetails = async (id) => {
    try {
        const response = await api.get(`/user/booking/${id}`, getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export const getMyBooking = async ({ page, limit, search, status }) => {
    try {
        const response = await api.get(`/user/booking?page=${page}&limit=${limit}&search=${search}&s=${status}`, getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export const cancleBooking = async ({ bookingId }) => {
    try {
        const response = await api.post(`/user/booking/cancelBookingByGuest`, { bookingId }, getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export const updateBookingNoticePeriod = async ({ bookingId, checkOutDate = new Date() }) => {
    try {
        const response = await api.post(`/user/booking/update/notice`, { bookingId, checkOutDate }, getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export const getUpcomingReservation = async () => {
    try {
        const response = await api.get(`/user/booking/dashboard/reservation`, getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export const getPropertyList = async (filter) => {
    try {
        const response = await api.post(`/user/property`, filter, getBasicAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export const getFavouriteProperties = async (data) => {
    try {
        const response = await api.get(
            `/user/property/favourite?search=${data.search}&page=${data.page}&limit=${data.limit}&lat=${data.coords.lat}&lng=${data.coords.lng}`,
            getAuthHeader()
        )
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export const getSimilarProperties = async ({ userId, propertyId, coords, airportName }) => {
    try {
        const response = await api.post(
            `/user/property/similar`,
            {
                userId,
                propertyId,
                latlng: coords.reverse(),
                airportName,
            },
            getBasicAuthHeader()
        )
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export const makePropertyFavourite = async (id) => {
    const response = await api.post(`/user/property/favourite`, { propertyId: id }, getAuthHeader())
    return handleResponse(response)
}

export const makePayment = async (data) => {
    try {
        const response = await api.post(`/user/booking/updatePayment`, data, getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export const addReview = async (data) => {
    try {
        const response = await api.post(`/user/review`, data, getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export const getReviewList = async (query) => {
    try {
        const response = await api.get(`/user/review?${new URLSearchParams(query).toString()}`, getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export const featuredProperties = async () => {
    try {
        const response = await api.get(`/user/property/featured_properties`, getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}
