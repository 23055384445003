import React, { useRef, useState, useEffect, useContext } from "react";
import { getHomePropertyList } from "../_services/property";
import { Context as AuthContext } from "@context/authContext";
import { constant } from "@config/constant";
import Section from "@layout/Section";
import ProductCard from "@components/RoomCard";
import Modal from "@components/Modal";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { getCoords } from "@utils/utility";
// import { Swiper, SwiperSlide } from "swiper/react";

// // import Swiper core and required modules
import SwiperCore, { Navigation } from "swiper";

// // install Swiper modules
SwiperCore.use([Navigation]);

const COLD = "Cold bed";
const HOT = "Hot bed";

const CardScreen = ({ properties, type, searchBookingType }) => {
  return (
    <div className="w-full xl:w-[75%] mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-10  py-10">
      {properties.map((property) => (
        <ProductCard
          searchBookingType={searchBookingType}
          navigationBookingType={type}
          key={property._id}
          property={property}
          className="mb-4"
        />
      ))}
    </div>
  );
};

function HotBed({ properties }) {
  const [leftArrow, setLeftArrow] = useState(false);
  const [rightArrow, setRightArrow] = useState(true);

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  return (
    <>
      <div className=" my-10 mb-4 flex md:hidden mx-auto  justify-between items-center">
        <p className="text-lg  font-medium">Hot bed (Nightly)</p>
        {/* <p className="text-parrot  ">View All</p> */}
      </div>
      <div className="my-4 block md:hidden mx-auto">
        {properties.map((property) => (
          <ProductCard
            searchBookingType={HOT}
            key={property._id}
            property={property}
            className="mb-4"
            navigationBookingType={HOT}
          />
        ))}
      </div>

      <div className="relative hidden md:block">
        {/* <div
          ref={prevRef}
          className={`${
            leftArrow ? "visible" : "invisible"
          } absolute   top-0 bottom-0 my-auto left-5 text-[#00DEF7]  z-100   cursor-pointer flex-center`}
        >
          <HiOutlineChevronLeft size={60} className=" " />
        </div>

        <div
          ref={nextRef}
          className={`${
            rightArrow ? "visible" : "invisible"
          } absolute top-0 bottom-0 my-auto right-5 text-[#00DEF7]     z-100 cursor-pointer flex-center`}
        >
          <HiOutlineChevronLeft size={60} className="transform rotate-180   " />
        </div> */}
        <div className="w-full xl:w-[75%] my-10 mb-0 flex  mx-auto  justify-between items-center">
          <p className="text-sm lg:text-lg  font-medium">Hot bed (Nightly)</p>
          {/* <p className="text-parrot  ">View All</p> */}
        </div>
        <CardScreen
          type={HOT}
          properties={properties}
          searchBookingType={HOT}
        />
        {/* <Swiper
          onSlideChange={(e) => {
            if (e.isEnd) {
              setRightArrow(false);
            } else setRightArrow(true);

            if (e.isBeginning) {
              setLeftArrow(false);
            } else setLeftArrow(true);
          }}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          onSwiper={(swiper) => {
            // Delay execution for the refs to be defined
            setTimeout(() => {
              // Override prevEl & nextEl now that refs are defined
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;

              // Re-init navigation
              swiper.navigation.destroy();
              swiper.navigation.init();
              swiper.navigation.update();
            });
          }}
          className="h-full w-full"
        >
          <SwiperSlide className="h-full w-full">
            <CardScreen />
          </SwiperSlide>
          <SwiperSlide className="h-full w-full">
            <CardScreen />
          </SwiperSlide>
          <SwiperSlide className="h-full w-full">
            <CardScreen />
          </SwiperSlide>
          <SwiperSlide className="h-full w-full">
            <CardScreen />
          </SwiperSlide>
        </Swiper> */}
      </div>
    </>
  );
}

function ColdBed({ properties }) {
  const [leftArrow, setLeftArrow] = useState(false);
  const [rightArrow, setRightArrow] = useState(true);

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  return (
    <>
      <div className=" my-10 mb-4 flex md:hidden mx-auto  justify-between items-center">
        <p className="text-lg  font-medium">Cold bed (Monthly)</p>
        {/* <p className="text-parrot  ">View All</p> */}
      </div>
      <div className="my-4 block md:hidden mx-auto">
        {properties.map((property) => (
          <ProductCard
            key={property._id}
            searchBookingType={COLD}
            property={property}
            className="mb-4"
            navigationBookingType={COLD}
          />
        ))}
      </div>

      <div className="relative hidden md:block">
        {/* <div
          ref={prevRef}
          className={`${
            leftArrow ? "visible" : "invisible"
          } absolute   top-0 bottom-0 my-auto left-5 text-[#00DEF7]  z-100   cursor-pointer flex-center`}
        >
          <HiOutlineChevronLeft size={60} className=" " />
        </div>

        <div
          ref={nextRef}
          className={`${
            rightArrow ? "visible" : "invisible"
          } absolute top-0 bottom-0 my-auto right-5 text-[#00DEF7]     z-100 cursor-pointer flex-center`}
        >
          <HiOutlineChevronLeft size={60} className="transform rotate-180   " />
        </div> */}
        <div className="w-full xl:w-[75%] my-10 mb-0 flex  mx-auto  justify-between items-center">
          <p className="text-sm lg:text-lg  font-medium">Cold bed (Monthly)</p>
          {/* <p className="text-parrot  ">View All</p> */}
        </div>
        <CardScreen
          type={COLD}
          properties={properties}
          searchBookingType={COLD}
        />
      </div>
    </>
  );
}

function FeaturesProducts() {
  const [fetchingProperties, setFetchingProperties] = useState(true);
  const [coldBedProperties, setColdBedProperties] = useState([]);
  const [hotBedProperties, setHotBedProperties] = useState([]);
  const [fetchingLocation, setFetchingLocation] = useState(true);
  const { user, fetchUser } = useContext(AuthContext);
  const [location, setLocation] = useState([]);

  useEffect(() => {
    if (!user.id && localStorage.getItem(constant.TOKEN_KEY)) fetchUser();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      try {
        const pos = await getCoords();
        const coords = {
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
        };
        setLocation([coords.lat, coords.lng]);
      } catch (error) {
      } finally {
        setFetchingLocation(false);
      }
    };
    fetch();
  }, []);

  useEffect(() => {
    async function fetchProperty() {
      const res = await getHomePropertyList(user.id, location);

      setColdBedProperties([...res.data.data.coldBed]);
      setHotBedProperties([...res.data.data.hotBed]);
      setFetchingProperties(false);
    }
    if (!fetchingLocation) {
      fetchProperty();
    }
  }, [user.id, fetchingLocation]);
  return (
    <Section className="my-20 px-4">
      <h3 className="text-center primary__heading__caption">
        Explore Top Properties
      </h3>
      <h2 className="text-center primary__heading mb-4">Featured Properties</h2>
      {fetchingProperties && (
        <Modal>
          <div className="bg-white rounded shadow-md p-4">
            <div className="loader"></div>
          </div>
        </Modal>
      )}
      <HotBed properties={hotBedProperties} />
      <ColdBed properties={coldBedProperties} />
    </Section>
  );
}

export default FeaturesProducts;
