import React from "react";
import Header from "@front/Header";
import Footer from "@front/Footer";
import Section from "@layout/Section";

function TermsCondition() {
  return (
    <div>
      <Header />
      <Section className="my-20 font-roboto ">
        <div className="text-primary-blue-dark  md:w-8/12 mx-4 md:mx-auto text-sm lg:text-base ">
          <h3 className=" text-xl lg:text-3xl font-medium text-center">
            Terms & Condition
          </h3>
          <p className="text-sm md:text-lg font-medium text-center">
            Last updated: July 27th, 2021
          </p>
          <p className="my-4">
            Please read these terms and conditions carefully before using Our
            Service.
          </p>
          <p className="my-4">Interpretation and Definitions</p>
          <p className="text-sm mb-2">Interpretation</p>

          <p className="">
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural
          </p>
          <p className="text-sm mb-2 mt-4 ">Definitions</p>
          <div>
            <p>For the purposes of these Terms and Conditions:</p>
            <ul className="px-4 list-disc ml-4 space-y-2 my-4">
              <li>
                Affiliate means an entity that controls, is controlled by or is
                under common control with a party, where "control" means
                ownership of 50% or more of the shares, equity interest or other
                securities entitled to vote for election of directors or other
                managing authority.
              </li>
              <li>
                Account means a unique account created for You to access our
                Service or parts of our Service.
              </li>
              <li>Country refers to: Delaware, United States</li>
              <li>
                Company (referred to as either "the Company", "We", "Us" or
                "Our" in this Agreement) refers to Crewmates, LLC, 8 The Green
                Ste B, Dover, DE 19901.
              </li>
              <li>
                Content refers to content such as text, images, or other
                information that can be posted, uploaded, linked to or otherwise
                made available by You, regardless of the form of that content.
              </li>
              <li>
                Device means any device that can access the Service such as a
                computer, a cellphone or a digital tablet.
              </li>
              <li>
                Feedback means feedback, innovations or suggestions sent by You
                regarding the attributes, performance or features of our
                Service.
              </li>
              <li>
                Promotions refer to contests, sweepstakes or other promotions
                offered through the Service.
              </li>
              <li>Service refers to the Website.</li>
              <li>
                Terms and Conditions (also referred as "Terms") mean these Terms
                and Conditions that form the entire agreement between You and
                the Company regarding the use of the Service.
              </li>
              <li>
                Third-party Social Media Service means any services or content
                (including data, information, products or services) provided by
                a third-party that may be displayed, included or made available
                by the Service.
              </li>
              <li>
                Website refers to Crewmates, accessible from
                www.crewmatesapp.com
              </li>
              <li>
                You means the individual accessing or using the Service, or the
                company, or other legal entity on behalf of which such
                individual is accessing or using the Service, as applicable.
              </li>
            </ul>
          </div>
          <p className="font-medium my-4 ">Acknowledgment</p>
          <p className="my-4">
            These are the Terms and Conditions governing the use of this Service
            and the agreement that operates between You and the Company. These
            Terms and Conditions set out the rights and obligations of all users
            regarding the use of the Service.
          </p>
          <p className="my-4">
            Your access to and use of the Service is conditioned on Your
            acceptance of and compliance with these Terms and Conditions. These
            Terms and Conditions apply to all visitors, users and others who
            access or use the Service.
          </p>
          <p className="my-4">
            By accessing or using the Service You agree to be bound by these
            Terms and Conditions. If You disagree with any part of these Terms
            and Conditions then You may not access the Service.
          </p>
          <p className="my-4">
            You represent that you are over the age of 18. The Company does not
            permit those under 18 to use the Service.
          </p>
          <p className="my-4">
            Your access to and use of the Service is also conditioned on Your
            acceptance of and compliance with the Privacy Policy of the Company.
            Our Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your personal information when You
            use the Application or the Website and tells You about Your privacy
            rights and how the law protects You. Please read Our Privacy Policy
            carefully before using Our Service.
          </p>

          <p className="font-medium my-4 ">Promotions</p>
          <p className="my-4">
            Any Promotions made available through the Service may be governed by
            rules that are separate from these Terms.
          </p>
          <p className="my-4">
            If You participate in any Promotions, please review the applicable
            rules as well as our Privacy policy. If the rules for a Promotion
            conflict with these Terms, the Promotion rules will apply.
          </p>

          <p className="font-medium my-4 ">User Accounts</p>
          <p className="my-4">
            When You create an account with Us, You must provide Us information
            that is accurate, complete, and current at all times. Failure to do
            so constitutes a breach of the Terms, which may result in immediate
            termination of Your account on Our Service.
          </p>
          <p className="my-4">
            You are responsible for safeguarding the password that You use to
            access the Service and for any activities or actions under Your
            password, whether Your password is with Our Service or a Third-Party
            Social Media Service.
          </p>
          <p className="my-4">
            You agree not to disclose Your password to any third party. You must
            notify Us immediately upon becoming aware of any breach of security
            or unauthorized use of Your account.
          </p>
          <p className="my-4">
            You may not use as a username the name of another person or entity
            or that is not lawfully available for use, a name or trademark that
            is subject to any rights of another person or entity other than You
            without appropriate authorization, or a name that is otherwise
            offensive, vulgar or obscene.
          </p>
          <p className="font-medium my-4 ">Content</p>
          <p className="my-4">
            Your Right to Post Content Our Service allows You to post Content.
            You are responsible for the Content that You post to the Service,
            including its legality, reliability, and appropriateness.
          </p>
          <p className="my-4">
            By posting Content to the Service, You grant Us the right and
            license to use, modify, publicly perform, publicly display,
            reproduce, and distribute such Content on and through the Service.
            You retain any and all of Your rights to any Content You submit,
            post or display on or through the Service and You are responsible
            for protecting those rights. You agree that this license includes
            the right for Us to make Your Content available to other users of
            the Service, who may also use Your Content subject to these Terms.
          </p>
          <p className="my-4">
            You represent and warrant that: (i) the Content is Yours (You own
            it) or You have the right to use it and grant Us the rights and
            license as provided in these Terms, and (ii) the posting of Your
            Content on or through the Service does not violate the privacy
            rights, publicity rights, copyrights, contract rights or any other
            rights of any person.
          </p>
          <p className="font-medium my-4 ">Content Restrictions</p>
          <p className="my-4">
            The Company is not responsible for the content of the Service's
            users. You expressly understand and agree that You are solely
            responsible for the Content and for all activity that occurs under
            your account, whether done so by You or any third person using Your
            account.
          </p>
          <p className="my-4">
            You may not transmit any Content that is unlawful, offensive,
            upsetting, intended to disgust, threatening, libelous, defamatory,
            obscene or otherwise objectionable. Examples of such objectionable
            Content include, but are not limited to, the following:
          </p>
          <div>
            <ul className="px-4 list-disc ml-4 space-y-2 my-4">
              <li>Unlawful or promoting unlawful activity.</li>
              <li>
                Defamatory, discriminatory, or mean-spirited content, including
                references or commentary about religion, race, sexual
                orientation, gender, national/ethnic origin, or other targeted
                groups.
              </li>
              <li>
                Spam, machine – or randomly – generated, constituting
                unauthorized or unsolicited advertising, chain letters, any
                other form of unauthorized solicitation, or any form of lottery
                or gambling.
              </li>
              <li>
                Containing or installing any viruses, worms, malware, trojan
                horses, or other content that is designed or intended to
                disrupt, damage, or limit the functioning of any software,
                hardware or telecommunications equipment or to damage or obtain
                unauthorized access to any data or other information of a third
                person.
              </li>
              <li>
                Infringing on any proprietary rights of any party, including
                patent, trademark, trade secret, copyright, right of publicity
                or other rights.
              </li>
              <li>
                Impersonating any person or entity including the Company and its
                employees or representatives.
              </li>
              <li>Violating the privacy of any third person.</li>
              <li>False information and features.</li>
            </ul>
          </div>
          <p className="my-4">
            The Company reserves the right, but not the obligation, to, in its
            sole discretion, determine whether or not any Content is appropriate
            and complies with this Terms, refuse or remove this Content. The
            Company further reserves the right to make formatting and edits and
            change the manner any Content. The Company can also limit or revoke
            the use of the Service if You post such objectionable Content. As
            the Company cannot control all content posted by users and/or third
            parties on the Service, you agree to use the Service at your own
            risk. You understand that by using the Service You may be exposed to
            content that You may find offensive, indecent, incorrect or
            objectionable, and You agree that under no circumstances will the
            Company be liable in any way for any content, including any errors
            or omissions in any content, or any loss or damage of any kind
            incurred as a result of your use of any content.
          </p>

          <p className="font-medium my-4 ">Content Backups</p>
          <p className="my-4">
            Although regular backups of Content are performed, the Company do
            not guarantee there will be no loss or corruption of data.
          </p>
          <p className="my-4">
            Corrupt or invalid backup points may be caused by, without
            limitation, Content that is corrupted prior to being backed up or
            that changes during the time a backup is performed.
          </p>
          <p className="my-4">
            The Company will provide support and attempt to troubleshoot any
            known or discovered issues that may affect the backups of Content.
            But You acknowledge that the Company has no liability related to the
            integrity of Content or the failure to successfully restore Content
            to a usable state.
          </p>
          <p className="my-4">
            You agree to maintain a complete and accurate copy of any Content in
            a location independent of the Service.
          </p>

          <p className="font-medium my-4 ">Copyright Policy</p>
          <p className="text-sm my-2">Intellectual Property Infringement</p>

          <p className="my-4">
            We respect the intellectual property rights of others. It is Our
            policy to respond to any claim that Content posted on the Service
            infringes a copyright or other intellectual property infringement of
            any person.
          </p>
          <p className="my-4">
            If You are a copyright owner, or authorized on behalf of one, and
            You believe that the copyrighted work has been copied in a way that
            constitutes copyright infringement that is taking place through the
            Service, You must submit Your notice in writing to the attention of
            our copyright agent via email at support@crewmatesapp.com and
            include in Your notice a detailed description of the alleged
            infringement.
          </p>
          <p className="my-4">
            You may be held accountable for damages (including costs and
            attorneys' fees) for misrepresenting that any Content is infringing
            Your copyright.
          </p>

          <p className="text-sm my-2">
            DMCA Notice and DMCA Procedure for Copyright Infringement Claims
          </p>

          <p className="my-4">
            You may submit a notification pursuant to the Digital Millennium
            Copyright Act (DMCA) by providing our Copyright Agent with the
            following information in writing (see 17 U.S.C 512(c)(3) for further
            detail):
          </p>

          <div>
            <ul className="px-4 list-disc ml-4 space-y-2 my-4">
              <li>
                An electronic or physical signature of the person authorized to
                act on behalf of the owner of the copyright's interest.
              </li>
              <li>
                A description of the copyrighted work that You claim has been
                infringed, including the URL (i.e., web page address) of the
                location where the copyrighted work exists or a copy of the
                copyrighted work.
              </li>
              <li>
                Identification of the URL or other specific location on the
                Service where the material that You claim is infringing is
                located.
              </li>
              <li>Your address, telephone number, and email address.</li>
              <li>
                A statement by You that You have a good faith belief that the
                disputed use is not authorized by the copyright owner, its
                agent, or the law.
              </li>
              <li>
                A statement by You, made under penalty of perjury, that the
                above information in Your notice is accurate and that You are
                the copyright owner or authorized to act on the copyright
                owner's behalf.
              </li>
            </ul>
          </div>

          <p className="my-4">
            You can contact our copyright agent via email at
            support@crewmatesapp.com. Upon receipt of a notification, the
            Company will take whatever action, in its sole discretion, it deems
            appropriate, including removal of the challenged content from the
            Service.
          </p>

          <p className="font-medium my-4 ">Intellectual Property</p>

          <p className="my-4">
            The Service and its original content (excluding Content provided by
            You or other users), features and functionality are and will remain
            the exclusive property of the Company and its licensors.
          </p>
          <p className="my-4">
            The Service is protected by copyright, trademark, and other laws of
            both the Country and foreign countries.
          </p>
          <p className="my-4">
            Our trademarks and trade dress may not be used in connection with
            any product or service without the prior written consent of the
            Company.
          </p>

          <p className="font-medium my-4 ">Your Feedback to Us</p>

          <p className="my-4">
            You assign all rights, title and interest in any Feedback You
            provide the Company. If for any reason such assignment is
            ineffective, You agree to grant the Company a non-exclusive,
            perpetual, irrevocable, royalty free, worldwide right and license to
            use, reproduce, disclose, sub-license, distribute, modify and
            exploit such Feedback without restriction.
          </p>

          <p className="font-medium my-4 ">Links to Other Websites</p>

          <p className="my-4">
            Our Service may contain links to third-party web sites or services
            that are not owned or controlled by the Company.
          </p>
          <p className="my-4">
            The Company has no control over, and assumes no responsibility for,
            the content, privacy policies, or practices of any third party web
            sites or services. You further acknowledge and agree that the
            Company shall not be responsible or liable, directly or indirectly,
            for any damage or loss caused or alleged to be caused by or in
            connection with the use of or reliance on any such content, goods or
            services available on or through any such web sites or services.
          </p>
          <p className="my-4">
            We strongly advise You to read the terms and conditions and privacy
            policies of any third-party web sites or services that You visit.
          </p>

          <p className="font-medium my-4 ">Termination</p>
          <p className="my-4">
            We may terminate or suspend Your Account immediately, without prior
            notice or liability, for any reason whatsoever, including without
            limitation if You breach these Terms and Conditions.
            <br /> Upon termination, Your right to use the Service will cease
            immediately. If You wish to terminate Your Account, You may simply
            discontinue using the Service.
          </p>

          <p className="font-medium my-4 ">Limitation of Liability</p>

          <p className="my-4">
            Notwithstanding any damages that You might incur, the entire
            liability of the Company and any of its suppliers under any
            provision of this Terms and Your exclusive remedy for all of the
            foregoing shall be limited to the amount actually paid by You
            through the Service or 100 USD if You haven't purchased anything
            through the Service. <br />
            To the maximum extent permitted by applicable law, in no event shall
            the Company or its suppliers be liable for any special, incidental,
            indirect, or consequential damages whatsoever (including, but not
            limited to, damages for loss of profits, loss of data or other
            information, for business interruption, for personal injury, loss of
            privacy arising out of or in any way related to the use of or
            inability to use the Service, third-party software and/or
            third-party hardware used with the Service, or otherwise in
            connection with any provision of this Terms), even if the Company or
            any supplier has been advised of the possibility of such damages and
            even if the remedy fails of its essential purpose.
            <br className="my-4 block" />
            Some states do not allow the exclusion of implied warranties or
            limitation of liability for incidental or consequential damages,
            which means that some of the above limitations may not apply. In
            these states, each party's liability will be limited to the greatest
            extent permitted by law.
          </p>

          <p className="font-medium my-4 ">
            "AS IS" and "AS AVAILABLE" Disclaimer
          </p>

          <p className="my-4">
            The Service is provided to You "AS IS" and "AS AVAILABLE" and with
            all faults and defects without warranty of any kind. To the maximum
            extent permitted under applicable law, the Company, on its own
            behalf and on behalf of its Affiliates and its and their respective
            licensors and service providers, expressly disclaims all warranties,
            whether express, implied, statutory or otherwise, with respect to
            the Service, including all implied warranties of merchantability,
            fitness for a particular purpose, title and non-infringement, and
            warranties that may arise out of course of dealing, course of
            performance, usage or trade practice. Without limitation to the
            foregoing, the Company provides no warranty or undertaking, and
            makes no representation of any kind that the Service will meet Your
            requirements, achieve any intended results, be compatible or work
            with any other software, applications, systems or services, operate
            without interruption, meet any performance or reliability standards
            or be error free or that any errors or defects can or will be
            corrected.
            <br /> Without limiting the foregoing, neither the Company nor any
            of the company's provider makes any representation or warranty of
            any kind, express or implied: (i) as to the operation or
            availability of the Service, or the information, content, and
            materials or products included thereon; (ii) that the Service will
            be uninterrupted or error-free; (iii) as to the accuracy,
            reliability, or currency of any information or content provided
            through the Service; or (iv) that the Service, its servers, the
            content, or e-mails sent from or on behalf of the Company are free
            of viruses, scripts, trojan horses, worms, malware, timebombs or
            other harmful components. <br /> Some jurisdictions do not allow the
            exclusion of certain types of warranties or limitations on
            applicable statutory rights of a consumer, so some or all of the
            above exclusions and limitations may not apply to You. But in such a
            case the exclusions and limitations set forth in this section shall
            be applied to the greatest extent enforceable under applicable law.
          </p>
          <p className="text-sm my-4 ">Attorney Fees</p>
          <p className="my-4">
            You agree to pay reasonable attorney fees and court costs incurred
            by Crewmates in enforcing rights under this agreement. Should this
            portion of the agreement be found unenforceable under the law, all
            remaining provisions will continue to be valid and enforceable
            without exception. You recognize and understand that Crewmates is
            operating in a limited capacity as an agent for the parties, and
            furthermore agrees to indemnify and hold harmless Crewmates and
            Crewmates agents from any damages not directly a result from
            Crewmates or Crewmates actions.
          </p>

          <p className="font-medium my-4 ">Governing Law</p>
          <p className="my-4">
            The laws of the Country, excluding its conflicts of law rules, shall
            govern this Terms and Your use of the Service. Your use of the
            Application may also be subject to other local, state, national, or
            international laws.
          </p>
          <p className="font-medium my-4 "> Disputes Resolution </p>
          <p className="my-4">
            If You have any concern or dispute about the Service, You agree to
            first try to resolve the dispute informally by contacting the
            Company.
          </p>
          <p className="font-medium my-4 "> For European Union (EU) Users </p>
          <p className="my-4">
            If You are a European Union consumer, you will benefit from any
            mandatory provisions of the law of the country in which you are
            resident in. You represent and warrant that (i) You are not located
            in a country that is subject to the United States government
            embargo, or that has been designated by the United States government
            as a "terrorist supporting" country, and (ii) You are not listed on
            any United States government list of prohibited or restricted
            parties.
          </p>
          <p className="font-medium my-4 "> United States Legal Compliance </p>
          <p className="my-4">
            You represent and warrant that (i) You are not located in a country
            that is subject to the United States government embargo, or that has
            been designated by the United States government as a "terrorist
            supporting" country, and (ii) You are not listed on any United
            States government list of prohibited or restricted parties.
          </p>
          <p className="font-medium my-4 "> Severability and Waiver </p>
          <p className="my-4">
            You represent and warrant that (i) You are not located in a country
            that is subject to the United States government embargo, or that has
            been designated by the United States government as a "terrorist
            supporting" country, and (ii) You are not listed on any United
            States government list of prohibited or restricted parties.
          </p>

          <p className="text-sm my-4 ">Severability</p>
          <p className="my-4">
            If any provision of these Terms is held to be unenforceable or
            invalid, such provision will be changed and interpreted to
            accomplish the objectives of such provision to the greatest extent
            possible under applicable law and the remaining provisions will
            continue in full force and effect.
          </p>
          <p className="text-sm my-4 ">Waiver</p>
          <p className="my-4">
            Except as provided herein, the failure to exercise a right or to
            require performance of an obligation under this Terms shall not
            effect a party's ability to exercise such right or require such
            performance at any time thereafter nor shall be the waiver of a
            breach constitute a waiver of any subsequent breach.
          </p>
          <p className="text-sm my-4 ">Translation Interpretation</p>
          <p className="my-4">
            These Terms and Conditions may have been translated if We have made
            them available to You on our Service. You agree that the original
            English text shall prevail in the case of a dispute.
          </p>
          <p className="text-sm my-4 ">Changes to These Terms and Conditions</p>
          <p className="my-4">
            We reserve the right, at Our sole discretion, to modify or replace
            these Terms at any time. If a revision is material We will make
            reasonable efforts to provide at least 30 days' notice prior to any
            new terms taking effect. What constitutes a material change will be
            determined at Our sole discretion.
            <br /> By continuing to access or use Our Service after those
            revisions become effective, You agree to be bound by the revised
            terms. If You do not agree to the new terms, in whole or in part,
            please stop using the website and the Service.
          </p>

          <p className="font-medium my-4 "> Contact Us</p>
          <p className="my-4">
            If you have any questions about these Terms and Conditions, You can
            contact us:
          </p>
          <ul className="px-4 ml-4 list-disc">
            <li>By email: support@crewmatesapp.com</li>
          </ul>
          <p className="my-4 font-medium"> Crewmates Service</p>
          <p className="my-4">
            Crewmates provides an online platform that connects hosts who have
            accommodations to rent with guests seeking to rent such
            accommodations (collectively, the "Services"), which Services are
            accessible at crewmatesapp.com and any other websites through which
            Crewmates makes the Services available (collectively, the "Site")
            and as applications for mobile devices (the "Application"). By using
            the Site or Application, you agree to comply with and be legally
            bound by the terms and conditions of these Terms of Service
            ("Terms"), whether or not you become a registered user of the
            Services. These Terms govern your access to and use of the Site,
            Application and Services and all Collective Content (defined below),
            and your participation in the Referral Program (defined below), and
            constitute a binding legal agreement between you and Crewmates.
            Please also read carefully our Privacy Policy at
            crewmatesapp.com/privacy_policy. If you do not agree to these Terms,
            you have no right to obtain information from or otherwise continue
            using the Site, Application or Services. Failure to use the Site,
            Application or Services in accordance with these Terms may subject
            you to civil and criminal penalties.
          </p>
          <p className="my-4">
            THE SITE, APPLICATION AND SERVICES COMPRISE AN ONLINE PLATFORM
            THROUGH WHICH HOSTS MAY CREATE LISTINGS FOR ACCOMMODATIONS AND
            GUESTS MAY LEARN ABOUT AND BOOK ACCOMMODATIONS DIRECTLY WITH THE
            HOSTS. YOU UNDERSTAND AND AGREE THAT CREWMATES IS NOT A PARTY TO ANY
            AGREEMENTS ENTERED INTO BETWEEN HOSTS AND GUESTS, NOR IS CREWMATES A
            REAL ESTATE BROKER, AGENT OR INSURER. CREWMATES HAS NO CONTROL OVER
            THE CONDUCT OF HOSTS, GUESTS AND OTHER USERS OF THE SITE,
            APPLICATION AND SERVICES OR ANY ACCOMMODATIONS, AND DISCLAIMS ALL
            LIABILITY IN THIS REGARD TO THE MAXIMUM EXTENT PERMITTED BY LAW.
            <br />
            HOSTS SHOULD UNDERSTAND HOW THE LAWS WORK IN THEIR RESPECTIVE
            CITIES. SOME CITIES HAVE LAWS THAT RESTRICT THEIR ABILITY TO HOST
            PAYING GUESTS FOR SHORT PERIODS. THESE LAWS ARE OFTEN PART OF A
            CITY'S ZONING OR ADMINISTRATIVE CODES. IN MANY CITIES, HOSTS MUST
            REGISTER, GET A PERMIT, OR OBTAIN A LICENSE BEFORE LISTING A
            PROPERTY OR ACCEPTING GUESTS. CERTAIN TYPES OF SHORT-TERM BOOKINGS
            MAY BE PROHIBITED ALTOGETHER. LOCAL GOVERNMENTS VARY GREATLY IN HOW
            THEY ENFORCE THESE LAWS. PENALTIES MAY INCLUDE FINES OR OTHER
            ENFORCEMENT. HOSTS SHOULD REVIEW LOCAL LAWS BEFORE LISTING A SPACE
            ON CREWMATES..
          </p>

          <p className="text-sm my-4 ">
            How the Site, Application and Services Work
          </p>

          <p className="my-4">
            The Site, Application and Services can be used to facilitate the
            listing and booking of residential and other properties
            ("Accommodations"). Such Accommodations are included in Listings on
            the Site, Application and Services by Hosts. You may view Listings
            as an unregistered visitor to the Site, Application and Services;
            however, if you wish to book an Accommodation or create a Listing,
            you must first register to create an Crewmates Account (defined
            below).
          </p>
          <p className="my-4">
            As stated above, Crewmates makes available an online platform or
            marketplace with related technology for Guests and Hosts to meet
            online and arrange for bookings of Accommodations directly with each
            other. Crewmates is not an owner or operator of properties,
            including, but not limited to, hotel rooms, motel rooms, other
            lodgings or Accommodations, nor is it a provider of properties,
            including, but not limited to, hotel rooms, motel rooms, other
            lodgings or Accommodations and Crewmates does not own, sell, resell,
            furnish, provide, rent, re-rent, manage and/or control properties,
            including, but not limited to, hotel rooms, motel rooms, other
            lodgings or Accommodations or transportation or travel services.
            Unless explicitly specified otherwise in the Crewmates platform,
            Crewmates's responsibilities are limited to: (i) facilitating the
            availability of the Site, Application and Services and (ii) serving
            as the limited payment collection agent of each Host for the purpose
            of accepting payments from Guests on behalf of the Host. <br />{" "}
            PLEASE NOTE THAT, AS STATED ABOVE, THE SITE, APPLICATION AND
            SERVICES ARE INTENDED TO BE USED TO FACILITATE HOSTS AND GUESTS
            CONNECTING AND BOOKING ACCOMMODATIONS DIRECTLY WITH EACH OTHER.
            CREWMATES CANNOT AND DOES NOT CONTROL THE CONTENT CONTAINED IN ANY
            LISTINGS AND THE CONDITION, LEGALITY OR SUITABILITY OF ANY
            ACCOMMODATIONS. CREWMATES IS NOT RESPONSIBLE FOR AND DISCLAIMS ANY
            AND ALL LIABILITY RELATED TO ANY AND ALL LISTINGS AND
            ACCOMMODATIONS. ACCORDINGLY, ANY BOOKINGS WILL BE MADE OR ACCEPTED
            AT THE MEMBER'S OWN RISK.
          </p>

          <p className="text-sm my-4 ">Account Registration</p>
          <p className=" my-4 ">
            In order to access certain features of the Site and Application, and
            to book an Accommodation or create a Listing, you must register to
            create an account ("Crewmates Account") and become a Member. You may
            register to join the Services directly via the Site or Application
            or as described in this section.
          </p>
          <p className=" my-4 ">
            You can also register to join by logging into your account with
            certain third-party social networking sites ("SNS") (including, but
            not limited to, Facebook; each such account, a "Third-Party
            Account"), via our Site or Application, as described below. As part
            of the functionality of the Site, Application and Services, you may
            link your Crewmates Account with Third-Party Accounts, by either:
            (i) providing your Third-Party Account login information to
            Crewmates through the Site, Services or Application; or (ii)
            allowing Crewmates to access your Third-Party Account, as permitted
            under the applicable terms and conditions that govern your use of
            each Third-Party Account. You represent that you are entitled to
            disclose your Third-Party Account login information to Crewmates
            and/or grant Crewmates access to your Third-Party Account
            (including, but not limited to, for use for the purposes described
            herein), without breach by you of any of the terms and conditions
            that govern your use of the applicable Third-Party Account and
            without obligating Crewmates to pay any fees or making Crewmates
            subject to any usage limitations imposed by such third-party service
            providers. By granting Crewmates access to any Third-Party Accounts,
            you understand that Crewmates will access, make available and store
            (if applicable) any Content that you have provided to and stored in
            your Third-Party Account ("SNS Content") so that it is available on
            and through the Site, Services and Application via your Crewmates
            Account and Crewmates Account profile page. Unless otherwise
            specified in these Terms, all SNS Content, if any, will be
            considered to be Member Content for all purposes of these Terms.
            Depending on the Third-Party Accounts you choose and subject to the
            privacy settings that you have set in such Third-Party Accounts,
            personally identifiable information that you post to your
            Third-Party Accounts will be available on and through your Crewmates
            Account on the Site, Services and Application. Please note that if a
            Third-Party Account or associated service becomes unavailable or
            Crewmates's access to such Third-Party Account is terminated by the
            third-party service provider, then SNS Content will no longer be
            available on and through the Site, Services and Application. You
            have the ability to disable the connection between your Crewmates
            Account and your Third-Party Accounts, at any time, by accessing the
            "Settings" section of the Site and Application. PLEASE NOTE THAT
            YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED
            WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR
            AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS. Crewmates
            makes no effort to review any SNS Content for any purpose, including
            but not limited to for accuracy, legality or non-infringement and
            Crewmates is not responsible for any SNS Content.
          </p>
          <p className="my-4">
            Your Crewmates Account and your Crewmates Account profile page will
            be created for your use of the Site and Application based upon the
            personal information you provide to us or that we obtain via an SNS
            as described above. You may not have more than one (1) active
            Crewmates Account. You agree to provide accurate, current and
            complete information during the registration process and to update
            such information to keep it accurate, current and complete.
            Crewmates reserves the right to suspend or terminate your Crewmates
            Account and your access to the Site, Application and Services if you
            create more than one (1) Crewmates Account, or if any information
            provided during the registration process or thereafter proves to be
            inaccurate, fraudulent, not current or incomplete. You are
            responsible for safeguarding your password. You agree that you will
            not disclose your password to any third party and that you will take
            sole responsibility for any activities or actions under your
            Crewmates Account, whether or not you have authorized such
            activities or actions. You will immediately notify Crewmates of any
            unauthorized use of your Crewmates Account.
          </p>

          <p className="text-sm my-4 ">Accommodation Listings</p>
          <p className="my-4">
            As a Member, you may create Listings. To create a Listing, you will
            be asked a variety of questions about the Accommodation to be
            listed, including, but not limited to, the location, capacity, size,
            features, and availability of the Accommodation and pricing and
            related rules and financial terms. In order to be featured in
            Listings via the Site, Application and Services, all Accommodations
            must have valid physical addresses. Listings will be made publicly
            available via the Site, Application and Services. Other Members will
            be able to book your Accommodation via the Site, Application and
            Services based upon the information provided in your Listing. You
            understand and agree that once a Guest requests a booking of your
            Accommodation, you may not request the Guest to pay a higher price
            than in the booking request.
          </p>
          <p className="my-4">
            You acknowledge and agree that you alone are responsible for any and
            all Listings and Member Content you post. Accordingly, you represent
            and warrant that any Listing you post and the booking of, or a
            Guest's stay at, an Accommodation in a Listing you post (i) will not
            breach any agreements you have entered into with any third parties,
            such as homeowners association, condominium, lease or rental
            agreements, and (ii) will (a) be in compliance with all applicable
            laws (such as zoning laws and laws governing rentals of residential
            and other properties), Tax requirements, and rules and regulations
            that may apply to any Accommodation included in a Listing you post
            (including having all required permits, licenses and registrations),
            and (b) not conflict with the rights of third parties. Please note
            that Crewmates assumes no responsibility for a Host's compliance
            with any agreements with or duties to third parties, applicable
            laws, rules and regulations. Crewmates reserves the right, at any
            time and without prior notice, to remove or disable access to any
            Listing for any reason, including Listings that Crewmates, in its
            sole discretion, considers to be objectionable for any reason, in
            violation of these Terms, or otherwise harmful to the Site,
            Application or Services.
          </p>
          <p className="my-4">
            If you are a Host, you understand and agree that Crewmates does not
            act as an insurer or as your contracting agent. If a Guest requests
            a booking of your Accommodation and stays at your Accommodation, any
            agreement you enter into with such Guest is between you and the
            Guest and Crewmates is not a party to it. Notwithstanding the
            foregoing, Crewmates Payments serves as the limited authorized
            payment collection agent of the Host for the purpose of accepting,
            on behalf of the Host, payments from Guests of such amounts
            stipulated by the Host (including cleaning or other fees and/or
            Taxes).
          </p>
          <p className="my-4">
            When you create a Listing, you may also choose to include certain
            requirements which must be met by the Members who are eligible to
            request a booking of your Accommodation, such as requiring Members
            to have a profile picture or verified phone number, in order to book
            your Accommodation. Any Member wishing to book Accommodations
            included in Listings with such requirements must meet these
            requirements.
          </p>
          <p className="my-4">
            If you are a Host, Crewmates makes certain tools available to you to
            help you to make informed decisions about which Members you choose
            to confirm or preapprove for booking for your Accommodation. You
            acknowledge and agree that, as a Host, you are responsible for your
            own acts and omissions and are also responsible for the acts and
            omissions of any individuals who reside at or are otherwise present
            at the Accommodation at your request or invitation, excluding the
            Guest (and the individuals the Guest invites to the Accommodation,
            if applicable.)
          </p>
          <p className="my-4">
            Crewmates recommends that Hosts obtain appropriate insurance for
            their Accommodations. Please review any insurance policy that you
            may have for your Accommodation carefully, and in particular please
            make sure that you are familiar with and understand any exclusions
            to, and any deductibles that may apply for, such insurance policy,
            including, but not limited to, whether or not your insurance policy
            will cover the actions or inactions of Guests (and the individuals
            the Guest invites to the Accommodation, if applicable) while at your
            Accommodation.
          </p>

          <p className="font-medium my-4 ">Bookings and Financial Terms</p>
          <p className="text-sm  my-4 ">Key definitions </p>

          <p className="  my-4 ">
            "Accommodation Fees" means the amounts that are due and payable by a
            Guest in exchange for that Guest's stay in an Accommodation. The
            Host alone, and not Crewmates, is responsible for the Accommodation
            Fees for his or her Listing. The Host may in his or her sole
            discretion decide to include in these amounts (i) a cleaning fee or
            any other fee permitted on the Crewmates platform, or (ii) Taxes
            that the Host determines that he or she has to collect. <br />
            "Guest Fees" means the fee that Crewmates charges a Guest for the
            use of the Services, which is calculated as a percentage of the
            applicable Accommodation Fees. The Guest Fees will be displayed to
            the Guest when the Guest is asked whether to send a booking request
            to a Host. <br />
            "Host Fees" means the fee that Crewmates charges a Host for the use
            of the Services, which is calculated as a percentage of the
            applicable Accommodation Fees. The Host Fees will be displayed to
            the Host when the Host is asked whether to confirm or reject a
            booking request from a prospective Guest. <br /> "Service Fees"
            means collectively the Guest Fees and the Host Fees. <br /> "Total
            Fees" means collectively the Accommodation Fees and the Guest Fees
            plus any Taxes.
          </p>
          <p className="text-sm  my-4 ">
            Bookings and Financial Terms for Hosts and Guests
          </p>
          <p className="my-4">
            If you are a Host and a booking is requested for your Accommodation
            via the Site, Application or Services, you will be required to
            either confirm or reject the booking request within the Booking
            Request Period, otherwise the booking request will automatically
            expire. If you are unable to confirm or decide to reject a booking
            request within the Booking Request Period, any amounts collected by
            Crewmates for the requested booking will be refunded to the
            applicable Guest's credit card and any pre-authorization of such
            credit card will be released.
            <br /> When you confirm a booking requested by a Guest, Crewmates
            will send you an email, text message or message via the Application
            confirming such booking, depending on the selections you make via
            the Site, Application and Services. Crewmates Payments will collect
            the Total Fees at the time of booking confirmation (i.e. when the
            Host confirms the booking request) and will initiate payment of the
            Accommodation Fees (less Crewmates's Host Fees and Taxes in respect
            of the Host Fees when applicable) to the Host within 24 hours of
            when the Guest arrives at the applicable Accommodation (except to
            the extent that a refund is due to the Guest).
            <br /> The time it takes for the Host to receive payouts may depend
            upon the payout method chosen by the Host.
            <br /> Some methods involve the use of third-party payment
            processors, who may impose their own additional charges for the use
            of their services on the Host, including by deducting their charges
            from the payout amount.
            <br /> If you owe or agree to pay any amount via Crewmates Payments
            to Crewmates (whether as a result of your bookings or actions as a
            Guest or otherwise), then Crewmates Payments may (but is not obliged
            to) withhold the amount owing to Crewmates from any payout amounts
            due to you as a Host, and use the withheld amount to setoff the
            amount owed by you to Crewmates.
            <br /> If Crewmates Payments does so, then your obligation to pay
            Crewmates will be extinguished to the extent of the amount withheld
            by Crewmates Payments, and Crewmates will cease to owe to you any
            obligations (including, but not limited to, any obligation to pay
            you) with respect to the amount withheld.
            <br /> In addition to the amount due, if your account is delinquent
            or you otherwise have chargebacks on your account, you may be
            charged fees that are incidental to our collection of these
            delinquent amounts and chargebacks. Such fees or charges may include
            collection fees, convenience fees, or other third party charges. You
            hereby explicitly agree that all communication in relation to
            delinquent accounts will be made by electronic mail or by phone, as
            provided to Crewmates by you. Such communication may be made by
            Crewmates or by anyone on its behalf, including but not limited to a
            third party collection agent.
          </p>

          <p className="my-4">
            Appointment of Crewmates Payments as Limited Payment Collection
            Agent for Host Each Host hereby appoints Crewmates Payments as the
            Host's limited payment collection agent solely for the purpose of
            accepting the Accommodation Fees from Guests.
          </p>
          <p className="my-4">
            Each Host agrees that payment made by a Guest through Crewmates
            Payments, shall be considered the same as a payment made directly to
            the Host, and the Host will make the Accommodation available to the
            Guest in the agreed-upon manner as if the Host has received the
            Accommodation Fees. <br />
            Each Host agrees that Crewmates may, in accordance with the
            cancellation policy selected by the Host and reflected in the
            relevant Listing, (i) permit the Guest to cancel the booking and
            (ii) refund (via Crewmates Payments) to the Guest that portion of
            the Accommodation Fees specified in the applicable cancellation
            policy. Each Host understands that as Crewmates Payments accepts
            payments from Guests as the Host's limited payment collection agent
            and that Crewmates Payments' obligation to pay the Host is subject
            to and conditional upon successful receipt of the associated
            payments from Guests. Crewmates does not guarantee payments to Hosts
            for amounts that have not been successfully received by Crewmates
            Payments from Guests. In accepting appointment as the limited
            authorized agent of the Host, Crewmates Payments assumes no
            liability for any acts or omissions of the Host. <br /> Crewmates
            reserves the right, in its sole discretion, to charge you for and
            collect fees from you for the creation of Listings and payments
            processed. Please note that Crewmates will provide notice of any
            Listing fee collection via the Site, Application and Services, prior
            to charging you.
          </p>

          <p className="text-sm my-4">
            Bookings and Financial Terms for Guests
          </p>
          <p className=" my-4">
            The Hosts, not Crewmates, are solely responsible for honoring any
            confirmed bookings and making available any Accommodations reserved
            through the Site, Application and Services. If you, as a Guest,
            choose to enter into a transaction with a Host for the booking of an
            Accommodation, you agree and understand that you will be required to
            enter into an agreement with the Host and you agree to accept any
            terms, conditions, rules and restrictions associated with such
            Accommodation imposed by the Host. You acknowledge and agree that
            you, and not Crewmates, will be responsible for performing the
            obligations of any such agreements, that Crewmates is not a party to
            such agreements, and that, with the exception of its payment
            obligations hereunder, Crewmates disclaims all liability arising
            from or related to any such agreements. You acknowledge and agree
            that, notwithstanding the fact that Crewmates is not a party to the
            agreement between you and the Host, Crewmates Payments acts as the
            Host's payment collection agent for the limited purpose of accepting
            payments from you on behalf of the Host. Upon your payment of the
            Total Fees to Crewmates Payments, your payment obligation to the
            Host for the Accommodation Fees is extinguished, and Crewmates
            Payments is responsible for remitting the Accommodation Fees (less
            the Host Fees and any Taxes in respect of the Host Fees when
            applicable), in the manner described in these Terms. In the event
            that Crewmates Payments does not remit any such amounts as described
            in these Terms, such Host will have recourse only against such
            Crewmates entity.
          </p>

          <p className="my-4">
            The Total Fees payable will be displayed to a Guest before the Guest
            sends a booking request to a Host. As noted above, the Host is
            required to either confirm or reject the booking request within the
            Booking Request Period; otherwise, the requested booking will be
            automatically cancelled. If a requested booking is cancelled (i.e.
            not confirmed by the applicable Host), any amounts collected by
            Crewmates Payments will be refunded to such Guest, depending on the
            selections the Guest makes via the Site and Application, and any
            pre-authorization of such Guest's credit card will be released, if
            applicable.
          </p>
          <p className="my-4">
            You as a Guest agree to pay Crewmates for the Total Fees for any
            booking requested in connection with your Crewmates Account if such
            requested bookings are confirmed by the applicable Host. In order to
            establish a booking pending the applicable Host's confirmation of
            your requested booking, you understand and agree that Crewmates
            Payments, on behalf of the Host, reserve the right, in its sole
            discretion, to (i) obtain a pre-authorization via your credit card
            for the Total Fees or (ii) charge your credit card a nominal amount,
            not to exceed one dollar ($1), or a similar sum in the currency in
            which you are transacting (e.g. one euro or one British pound), to
            verify your credit card. As a general rule, Crewmates Payments will
            collect the Total Fees due once Crewmates receives confirmation of
            your booking from the applicable Host; if necessary, Total Fees may
            instead be collected at a later point. Please note that Crewmates
            cannot control any fees that may be charged to a Guest by his or her
            bank related to Crewmates's collection of the Total Fees, and
            Crewmates disclaims all liability in this regard.
          </p>
          <p className="my-4">
            In connection with your requested booking, you will be asked to
            provide customary billing information such as name, billing address
            and credit card information either to Crewmates Payments or its
            third-party payment processor(s). You agree to pay Crewmates
            Payments for any confirmed bookings made in connection with your
            Crewmates Account in accordance with these Terms by one of the
            methods described on the Site or Application, e.g. by PayPal or
            credit card. You hereby authorize the collection of such amounts by
            charging the credit card provided as part of requesting the booking,
            either directly by Crewmates Payments or indirectly, via a
            third-party online payment processor or by one of the payment
            methods described on the Site or Application. You also authorize
            Crewmates Payments to charge your credit card in the event of damage
            caused at an Accommodation as contemplated under "Damage to
            Accommodations" below and for Security Deposit, if applicable. If
            you are directed to Crewmates Payments' third-party payment
            processor(s), you may be subject to terms and conditions governing
            use of that third party's service and that third party's personal
            information collection practices. Please review such terms and
            conditions and privacy policy before using the services. Once your
            confirmed booking transaction is complete you will receive a
            confirmation email summarizing your confirmed booking.
          </p>
          <p className="text-sm my-4 ">Security Deposit</p>
          <p className=" my-4 ">
            Hosts may choose to include Security Deposit in their Listings
            ("Security Deposit"). Each Listing will describe whether a security
            deposit is required for the applicable Accommodation. If a Security
            Deposit is included in a Listing for a confirmed booking of an
            Accommodation, and a Host makes a claim against such Security
            Deposit, Crewmates Payments will, in its capacity as the payment
            collection agent of the Host, use its commercially reasonable
            efforts to charge the Guest's payment method in the amount of the
            Security Deposit, within a reasonable period of time after the
            Guest's check-out from the Host's Accommodation. Crewmates will also
            use its commercially reasonable efforts to address Hosts' requests
            and claims related to Security Deposit, but Crewmates is not
            responsible for administering or accepting any claims by Hosts
            related to Security Deposit, and disclaims any and all liability in
            this regard.
          </p>
          <p className="text-sm my-4 ">Service Fees</p>
          <p className=" my-4 ">
            In consideration for the use of Crewmates's online marketplace and
            platform, Crewmates charges the Service Fees. Where applicable,
            Taxes may also be charged in respect of the Host Fees and Guest
            Fees. Crewmates Payments deducts the Host Fees from the
            Accommodation Fees before remitting the balance to the Host as
            described in these Terms. Guest Fees are, as noted above, included
            in the Total Fees.
            <br />
            Balances will be remitted by Crewmates Payments to Hosts via check,
            direct deposit or other payment methods, as described on the Site or
            via the Application, in the Host's currency of choice, depending
            upon the selections the Host makes via the Site, Application and
            Services. Please note that Crewmates Payments, may impose or deduct
            foreign currency processing costs on or from any payments or payouts
            by Crewmates in currencies other than U.S. dollars. More information
            on any such costs or deductions will be available via the Site and
            Application. Except as otherwise provided herein, Service Fees are
            non-refundable.
          </p>

          <p className="font-medium  my-4">
            General Booking and Financial Terms
          </p>
          <p className=" my-4">
            If, as a Guest, you cancel your requested booking before the
            requested booking is confirmed by a Host, Crewmates Payments will
            cancel any pre-authorization to your credit card and/or refund any
            nominal amounts charged to your credit card in connection with the
            requested booking within a commercially reasonable time. If, as a
            Guest, you wish to cancel a confirmed booking made via the Site,
            Application and Services, either prior to or after arriving at the
            Accommodation, the cancellation policy of the Host contained in the
            applicable Listing will apply to such cancellation. Our ability to
            refund the Accommodation Fees and other amounts charged to you will
            depend upon the terms of the applicable cancellation policy. Details
            regarding refunds and cancellation policies are available via the
            Site and Application. The Guest Fee is non-refundable regardless of
            the cancellation policy selected by the Host.
          </p>
          <p className=" my-4">
            If a Host cancels a confirmed booking made via the Site, Services,
            and Application, (i) Crewmates Payments will refund the Total Fees
            for such booking to the applicable Guest within a commercially
            reasonable time of the cancellation and (ii) the Guest will receive
            an email or other communication from Crewmates containing
            alternative Listings and other related information. If the Guest
            requests a booking from one of the alternative Listings and the Host
            associated with such alternative Listing confirms the Guest's
            requested booking, then the Guest agrees to pay Crewmates the Total
            Fees relating to the confirmed booking for the Accommodation in the
            alternative Listing, in accordance with these Terms.
          </p>
          <p className=" my-4">
            If, as a Host, you cancel a confirmed booking, you agree that
            Crewmates may apply penalties or consequences to you or your
            Listing, including (i) publishing an automated review on your
            Listing indicating that a reservation was cancelled, (ii) keeping
            the calendar for your Listing unavailable or blocked for the dates
            of the cancelled booking, or (iii) imposing a cancellation fee (to
            be withheld from your future payouts or charged to the credit card
            on file in your Crewmates Account). You will be notified of the
            situations in which a cancellation fee applies before you decide to
            cancel.
          </p>
          <p className=" my-4">
            In certain circumstances, Crewmates may decide, in its sole
            discretion, that it is necessary or desirable to cancel a confirmed
            booking made via the Site, Application and Services. This may be for
            reasons set forth or for any other reason. Crewmates Payments may
            also determine, in its sole discretion, to refund to the Guest part
            or all of the amounts charged to the Guest. You agree that Crewmates
            and the relevant Guest or Host will not have any liability for such
            cancellations or refunds.
          </p>
          <p className=" my-4">
            If, as a Host, your Guest cancels a confirmed booking or Crewmates
            decides that it is necessary to cancel a confirmed booking, and
            Crewmates issues a refund to the Guest in accordance with the Guest
            Refund Policy or other applicable cancellation policies, you agree
            that in the event you have already been paid Crewmates shall be
            entitled to recover the amount of any such Guest refund from you,
            including by subtracting such refund amount out from any future
            Accommodation Fees due to you.
          </p>

          <p className="text-sm my-4 ">Recurring Payments</p>
          <p className="my-4 ">
            In some instances, Guests may be required to make recurring,
            incremental payments toward the Total Fees owed for a confirmed
            booking before beginning his or her stay at the applicable
            Accommodation (collectively, "Recurring Payments"). More information
            on Recurring Payments will be made available via the Site,
            Application and Services, if applicable. If Recurring Payments apply
            to a confirmed booking, then the Guest authorizes Crewmates
            Payments, on behalf of the Host, to collect the Total Fees and the
            Host agrees that such Crewmates entity will initiate payouts to the
            Host, in the increments and at the frequency associated with the
            applicable Recurring Payments, each as identified on the Site,
            Application and Services.
          </p>

          <p className="text-sm my-4 ">Payment Processing Errors</p>
          <p className="my-4 ">
            We will take steps to rectify any payment processing errors that we
            become aware of. These steps may include crediting or debiting (as
            appropriate) the same payment method used for the original payout to
            or payment by you, so that you end up receiving or paying the
            correct amount.
          </p>

          <p className="text-sm my-4 ">Donations</p>
          <p className="my-4 ">
            Some Hosts may pledge to donate a portion of the funds they receive
            from confirmed bookings made via the Site, Application and Services
            to a particular cause or charity. We do not take any responsibility
            or liability for whether the Host does in fact make the donation he
            or she pledged to make. In such cases, the Host in question is
            responsible for his or her own compliance with all laws and
            regulations applicable to such pledges and/or fund-raising.
          </p>
          <p className="text-sm my-4 ">Taxes</p>
          <p className="my-4 ">
            Tax regulations may require us to collect appropriate tax
            information from our Hosts, or to withhold taxes from payouts to
            Hosts, or both. For instance, IRS regulations stipulate that we must
            collect an IRS Form W-9 from our US Hosts, and an appropriate IRS
            Form W-8 (e.g. Form W-8BEN) from non-US hosts with at least one
            Listing in the US. You as a Host are solely responsible for keeping
            the information in your tax forms current, complete and accurate. If
            you as a Host fail to provide us with documentation that we
            determine to be sufficient to alleviate our obligation (if any) to
            withhold taxes from payments to you (e.g. where you are a US Host
            and you fail to provide us with a completed IRS Form W-9), we
            reserve the right in our sole discretion to freeze all payouts to
            you until resolution, to withhold such amounts as required by law,
            or to do both.
          </p>
          <p className="my-4 ">
            You as a Host understand and agree that you are solely responsible
            for determining (i) your applicable Tax reporting requirements, and
            (ii) the Taxes that should be included, and for including Taxes to
            be collected or obligations relating to applicable Taxes in
            Listings. You are also solely responsible for remitting to the
            relevant authority any Taxes included or received by you. Crewmates
            cannot and does not offer Tax-related advice to any Members. Where
            applicable, or based upon request from a Host, Crewmates may issue a
            valid VAT invoice to such Host. <br />
            You understand and acknowledge that appropriate governmental
            agencies, departments or authorities (the "Tax Authority") where
            your Accommodation is located may require Taxes to be collected from
            Guests or Hosts on the amount paid for the right to use and/or
            occupancy of accommodations, and to be remitted to the respective
            Tax Authority. The laws in jurisdictions may vary, but these taxes
            may be required to be collected and remitted as a percentage of the
            rent or Accommodation Fees set by Hosts, a set amount per day, or
            other variations, and are sometimes called "occupancy taxes," "hotel
            taxes," "lodging taxes," "transient taxes," "sales and use taxes,"
            "value added taxes," "room taxes" or "tourist taxes" (hereafter,
            "Occupancy Taxes").
          </p>

          <p className="text-sm my-4 ">
            Occupancy Tax Collection and Remittance
          </p>

          <p className="my-4">
            In certain jurisdictions, Crewmates may decide in its sole
            discretion to facilitate collection and remittance of Occupancy
            Taxes from or on behalf of Guests or Hosts, in accordance with your
            directions in this Agreement ("Collection and Remittance") if such
            tax jurisdiction asserts Crewmates or Hosts have a tax collection
            and remittance obligation. In any jurisdiction in which we decide to
            facilitate direct Collection and Remittance, whether you are a Guest
            or Host, in lieu of the Host collecting Occupancy taxes from Guests
            and remitting to the Tax Authority, you hereby instruct and
            authorize Crewmates to collect Occupancy Taxes from Guests on the
            Host's behalf at the time Accommodation Fees are collected, and to
            remit such Occupancy Taxes to the Tax Authority. When Crewmates
            facilitates Collection and Remittance of Occupancy Taxes in a
            jurisdiction for the first time, Crewmates will provide notice to
            existing Hosts with Listings for Accommodations in such
            jurisdictions. When a new listing is created in a jurisdiction where
            Crewmates facilitates Collection and Remittance of Occupancy Taxes,
            the Host will be notified in the listing creation flow.
          </p>
          <p className="my-4">
            The amount of Occupancy Taxes, if any, collected and remitted by
            Crewmates will be visible to and separately stated to both Guests
            and Hosts on their respective transaction documents. Where Crewmates
            is directly facilitating Collection and Remittance, Guests and Hosts
            agree that Hosts are not permitted to attempt collection, or collect
            any Occupancy Taxes relating to their Accommodations on Crewmates in
            that such jurisdiction. You expressly agree to release, defend,
            indemnify, and hold Crewmates and its affiliates and subsidiaries,
            and their officers, directors, employees and agents, harmless from
            and against any claims, liabilities, damages, losses, debts,
            obligations, and expenses, including, without limitation, reasonable
            legal and/or accounting fees, arising out of or in any way related
            to Occupancy Taxes, including, without limitation, the applicability
            of, calculation, collection or remittance of Occupancy Taxes in any
            amount or at all as to your transactions or Accommodations. For any
            jurisdiction in which we facilitate Collection and Remittance, Hosts
            and Guests expressly grant us permission, without further notice, to
            store, transfer and disclose data and other information relating to
            them or to their Transactions, Bookings, Accommodations and
            Occupancy Taxes, including, but not limited to, personally
            identifiable information such as Host or Guest's name, listing
            addresses, transaction dates and amounts, tax identification
            number(s), the amount of taxes collected from Guests or allegedly
            due, contact information and similar information, to the relevant
            Tax Authority.
          </p>

          <p className="text-sm my-4 ">Opt-in to Host Remittance of Taxes</p>
          <p className="my-4">
            In other jurisdictions, Crewmates may decide in its sole discretion
            to facilitate Occupancy Tax collection on behalf of Hosts and Guests
            in accordance with a Host's direction to opt in to a specific
            Occupancy Tax line item in the booking process, in which the Host
            directs that Occupancy Taxes from Guests be sent directly to the
            Host so that the Host will remit such taxes directly to Tax
            Authority -(-"Opt-in for Host Remittance"). In any jurisdiction in
            which we decide to facilitate Collection by Opt-in for Host
            Remittance, whether you are a Guest or Host, you hereby instruct and
            authorize Crewmates to send Occupancy Taxes received from Guests at
            the time Accommodation Fees are collected to the Host who is
            obligated to send such taxes to the Tax Authority directly. If
            Crewmates offers and a Host selects Opt-in for Host Remittance in
            any jurisdiction, Hosts and Guests remain solely responsible and
            liable for the payment and remittance of any and all taxes that may
            apply to Accommodations; you agree and understand that Crewmates is
            not responsible for, and will not send any such Occupancy Taxes to
            the Tax Authority under Opt-in to Host Remittance of Taxes. You
            hereby agree that through third party payment processors, Crewmates
            is merely processing Your election and direction to have Occupancy
            Taxes from Guests sent directly to the Host for remittance by the
            Host to the Tax Authority, and that You will remit all amounts
            collected from Guests as Occupancy Taxes to such Tax Authority. You
            expressly agree to release, defend, indemnify, and hold Crewmates
            and its affiliates and subsidiaries, and their officers, directors,
            employees and agents, harmless from and against any claims,
            liabilities, damages, losses, debts, obligations, and expenses,
            including, without limitation, reasonable legal and/or accounting
            fees, arising out of or in any way related to Occupancy Taxes,
            including, without limitation, the applicability of, calculation of,
            collection of Occupancy Taxes in any amount or at all as to your
            transactions or Accommodations. For any jurisdiction in which we
            facilitate Opt-in for Host Remittance, Hosts and Guests expressly
            grant us permission, without further notice, to store, transfer and
            disclose data and other information relating to them or to their
            Transactions, Bookings, Accommodations and Occupancy Taxes,
            including, but not limited to, personally identifiable information
            such as Host or Guest's name, listing addresses, transaction dates
            and amounts, tax identification number(s), the amount of taxes
            received by Hosts from Guests, or allegedly due, contact information
            and similar information, to the relevant Tax Authority.
          </p>
          <p className="text-sm my-4 ">
            Miscellaneous Occupancy Tax Provisions
          </p>

          <p className="my-4">
            Whether you are a Guest or Host, you agree that any claim or cause
            of action relating to Crewmates's facilitation of Opt-in Remittance
            or Collection and Remittance of Taxes shall not extend to any
            supplier or vendor that may be used by Crewmates in connection with
            facilitation of Opt-in Remittance or Collection and Remittance of
            Occupancy Taxes, if any. Guests and Hosts agree that we may seek
            additional amounts from You in the event that the Taxes collected
            and/or remitted are insufficient to fully discharge your obligations
            to the Tax Authority, and agree that your sole remedy for Occupancy
            Taxes collected is a refund of Occupancy Taxes collected by
            Crewmates from the applicable Tax Authority in accordance with
            applicable procedures set by that Tax Authority. In any jurisdiction
            in which we have not provided notice of, or are not facilitating (or
            are no longer facilitating) the collection or remittance of
            Occupancy Taxes by Collection and Remittance, Opt-in for Host
            Remittance or any other means or method, in your jurisdiction, Hosts
            and Guests remain solely responsible and liable for the collection
            and/or remittance of any and all Occupancy Taxes that may apply to
            Accommodations.
          </p>
          <p className="my-4">
            In any jurisdiction in which we have not provided notice of, or are
            not facilitating (or are no longer facilitating) the collection or
            remittance of Occupancy Taxes by Collection and Remittance, Opt-in
            for Host Remittance or any other means or method, in your
            jurisdiction, Hosts and Guests remain solely responsible and liable
            for the collection and/or remittance of any and all Occupancy Taxes
            that may apply to Accommodations. <br /> Hosts and Guests
            acknowledge and agree that in some jurisdictions, Crewmates may
            decide not to facilitate collection or remittance of Occupancy Taxes
            or may not be able to facilitate the collection and/or remittance of
            Occupancy Taxes, and nothing contained in this Agreement is a
            representation or guarantee that Crewmates will facilitate
            collection and/or remittance of Occupancy Tax anywhere at all,
            including in any specific jurisdiction, or that Crewmates will
            continue to facilitate any collection or remittance of Occupancy Tax
            in any specific jurisdiction in which it may have been offered.
            Crewmates reserves the right, in its sole determination, to cease
            any facilitation of any collection and remittance of Occupancy Tax
            (regardless of method used or to be used in the future) for any
            reason or no reason at all, provided that it will give Hosts
            reasonable notice in any jurisdiction in which Crewmates determines
            to cease any such facilitation.
          </p>

          <p className="text-sm my-4 ">Damage to Accommodations</p>
          <p className=" my-4 ">
            As a Guest, you are responsible for leaving the Accommodation in the
            condition it was in when you arrived. You acknowledge and agree
            that, as a Guest, you are responsible for your own acts and
            omissions and are also responsible for the acts and omissions of any
            individuals whom you invite to, or otherwise provide access to, the
            Accommodation. In the event that a Host claims otherwise and
            provides evidence of damage, including but not limited to
            photographs, you agree to pay the cost of replacing the damaged
            items with equivalent items. After being notified of the claim and
            given forty eight (48) hours to respond, the payment will be charged
            to and taken from the credit card on file in your Crewmates Account.
            Crewmates also reserves the right to charge the credit card on file
            in your Crewmates Account, or otherwise collect payment from you and
            pursue any avenues available to Crewmates in this regard, including
            using Security Deposit, in situations in which you have been
            determined, in Crewmates's sole discretion, to have damaged any
            Accommodation, and in relation to any payments made by Crewmates to
            Hosts. If we are unable to charge the credit card on file or
            otherwise collect payment from you, you agree to remit payment for
            any damage to the Accommodation to the applicable Host or to
            Crewmates (if applicable).
          </p>
          <p className=" my-4 ">
            Both Guests and Hosts agree to cooperate with and assist Crewmates
            in good faith, and to provide Crewmates with such information and
            take such actions as may be reasonably requested by Crewmates, in
            connection with any complaints or claims made by Members relating to
            Accommodations or any personal or other property located at an
            Accommodation or with respect to any investigation undertaken by
            Crewmates or a representative of Crewmates regarding use or abuse of
            the Site, Application or the Services. If you are a Guest, upon
            Crewmates's reasonable request, and to the extent you are reasonably
            able to do so, you agree to participate in mediation or similar
            resolution process with a Host, at no cost to you, which process
            will be conducted by Crewmates or a third party selected by
            Crewmates or its insurer, with respect to losses for which the Host
            is requesting payment. If you are a Guest, you understand and agree
            that Crewmates may make a claim under your homeowner's, renter's or
            other insurance policy related to any damage or loss that you may
            have caused or been responsible for or to an Accommodation or any
            personal or other property located at an Accommodation. You agree to
            cooperate with and assist Crewmates in good faith, and to provide
            Crewmates with such information as may be reasonably requested by
            Crewmates, in order to make a claim under your homeowner's, renter's
            or other insurance policy, including, but not limited to, executing
            documents and taking such further acts as Crewmates may reasonably
            request to assist Crewmates in accomplishing the foregoing.
          </p>
          <p className="font-medium my-4 ">
            Overstaying without the Host's Consent
          </p>
          <p className="my-4">
            Guests agree that a confirmed reservation is merely a license
            granted by the Host to the Guest to enter and use the Listing for
            the limited duration of the confirmed reservation and in accordance
            with the Guest's agreement with the Host. Guests further agree to
            leave the Accommodation no later than the checkout time that the
            Host specifies in the Listing or such other time as mutually agreed
            upon between the Host and Guest. If a Guest stays past the agreed
            upon checkout time without the Host's consent, they no longer have a
            license to stay in the Listing and the Host is entitled to make the
            Guest leave. In addition, Guests agree that the Host can charge the
            Guest, for each 24 hour period that the Guest stays over the agreed
            period without the Host's consent, an additional nightly fee of two
            times the average nightly Accommodation Fee originally paid by the
            Guest to cover the inconvenience suffered by the Host, plus all
            applicable Service Fees, Taxes, and any legal expenses incurred by
            the Host to make the Guest leave (collectively, "Additional Sums").
            Guests agree that Crewmates Payments, in its role as limited
            collection agent for the Host, shall charge the Guest's credit card
            or other payment methods it has on file to collect these Additional
            Sums. In addition, Crewmates Payments may recover any costs and
            expenses it incurs in collecting the Additional Sums by charging the
            Guest's credit card or other payment method on file.
          </p>
          <p className="text-sm ">User Conduct</p>
          <p className="my-4">
            You understand and agree that you are solely responsible for
            compliance with any and all laws, rules, regulations, and Tax
            obligations that may apply to your use of the Site, Application,
            Services and Collective Content. In connection with your use of the
            Site, Application, Services and Collective Content, you may not and
            you agree that you will not:
          </p>
          <div>
            <ul className="px-4 list-disc ml-4 space-y-2 my-4">
              <li>
                violate any local, state, provincial, national, or other law or
                regulation, or any order of a court, including, without
                limitation, zoning restrictions and Tax regulations;
              </li>
              <li>
                use manual or automated software, devices, scripts, robots,
                backdoors or other means or processes to access, "scrape,"
                "crawl" or "spider" any web pages or other services contained in
                the Site, Application, Services or Collective Content;
              </li>
              <li>
                access or use our Site, Application, Services or the Crewmates
                API to use, expose, or allow to be used or exposed, any
                Crewmates Content: (i) that is not publicly displayed by
                Crewmates in its search results pages or listing pages before a
                booking is confirmed; (ii) in any way that is inconsistent with
                the Crewmates Privacy Policy or Terms of Service; or (iii) in
                any way that otherwise violates the privacy rights or any other
                rights of Crewmates's users or any other third party;
              </li>
              <li>
                use the Site, Application, Services or Collective Content for
                any commercial or other purposes that are not expressly
                permitted by these Terms;
              </li>
              <li>
                copy, store or otherwise access or use any information contained
                on the Site, Application, Services or Collective Content for
                purposes not expressly permitted by these Terms;
              </li>
              <li>
                infringe the rights of any person or entity, including without
                limitation, their intellectual property, privacy, publicity or
                contractual rights;
              </li>
              <li>
                interfere with or damage our Site, Application or Services,
                including, without limitation, through the use of viruses,
                cancel bots, Trojan horses, harmful code, flood pings,
                denial-of-service attacks, backdoors, packet or IP spoofing,
                forged routing or electronic mail address information or similar
                methods or technology
              </li>
              <li>
                use our Site, Application or Services to transmit, distribute,
                post or submit any information concerning any other person or
                entity, including without limitation, photographs of others
                without their permission, personal contact information or
                credit, debit, calling card or account numbers;
              </li>
              <li>
                use our Site, Application, Services or Collective Content in
                connection with the distribution of unsolicited commercial email
                ("spam") or advertisements unrelated to lodging in a private
                residence;
              </li>
              <li>
                "stalk" or harass any other user of our Site, Application,
                Services or Collective Content, or collect or store any
                personally identifiable information about any other user other
                than for purposes of transacting as an Crewmates Guest or Host;
              </li>
              <li>
                offer, as a Host, any Accommodation that you do not yourself own
                or have permission to rent as a residential or other property
                (without limiting the foregoing, you will not list
                Accommodations as a Host if you are serving in the capacity of a
                rental agent or listing agent for a third party);
              </li>
              <li>
                offer, as a Host, any Accommodation that may not be rented or
                subleased pursuant to the terms and conditions of an agreement
                with a third party, including, but not limited to, a property
                rental agreement;
              </li>
              <li>
                register for more than one Crewmates Account or register for a
                Crewmates Account on behalf of an individual other than
                yourself;
              </li>
              <li>
                unless Crewmates explicitly permits otherwise, request or book a
                stay at any Accommodation if you will not actually be staying at
                the Accommodation yourself;
              </li>
              <li>
                contact a Host for any purpose other than asking a question
                related to a booking, such Host's Accommodations or Listings;
              </li>
              <li>
                contact a Guest for any purpose other than asking a question
                related to a booking or such Guest's use of the Site,
                Application and Services;
              </li>
              <li>
                recruit or otherwise solicit any Host or other Member to join
                third-party services or websites that are competitive to
                Crewmates, without Crewmates's prior written approval;
              </li>
              <li>
                impersonate any person or entity, or falsify or otherwise
                misrepresent yourself or your affiliation with any person or
                entity;
              </li>
              <li>
                use automated scripts to collect information from or otherwise
                interact with the Site, Application, Services or Collective
                Content;
              </li>
              <li>
                use the Site, Application, Services or Collective Content to
                find a Host or Guest and then complete a booking of an
                Accommodation independent of the Site, Application or Services,
                in order to circumvent the obligation to pay any Service Fees
                related to Crewmates's provision of the Services or for any
                other reasons;
              </li>
              <li>
                as a Host, submit any Listing with false or misleading price
                information, or submit any Listing with a price that you do not
                intend to honor;
              </li>
              <li>
                post, upload, publish, submit or transmit any Content that: (i)
                infringes, misappropriates or violates a third party's patent,
                copyright, trademark, trade secret, moral rights or other
                intellectual property rights, or rights of publicity or privacy;
                (ii) violates, or encourages any conduct that would violate, any
                applicable law or regulation or would give rise to civil
                liability; (iii) is fraudulent, false, misleading or deceptive;
                (iv) is defamatory, obscene, pornographic, vulgar or offensive;
                (v) promotes discrimination, bigotry, racism, hatred, harassment
                or harm against any individual or group; (vi) is violent or
                threatening or promotes violence or actions that are threatening
                to any other person; or (vii) promotes illegal or harmful
                activities or substances;
              </li>
              <li>
                systematically retrieve data or other content from our Site,
                Application or Services to create or compile, directly or
                indirectly, in single or multiple downloads, a collection,
                compilation, database, directory or the like, whether by manual
                methods, through the use of bots, crawlers, or spiders, or
                otherwise;
              </li>
              <li>
                use, display, mirror or frame the Site, Application, Services or
                Collective Content, or any individual element within the Site,
                Application, Services or Collective Content, Crewmates's name,
                any Crewmates trademark, logo or other proprietary information,
                or the layout and design of any page or form contained on a page
                in the Site, Application or Services, without Crewmates's
                express written consent;
              </li>
              <li>
                access, tamper with, or use non-public areas of the Site,
                Application or Services, Crewmates's computer systems, or the
                technical delivery systems of Crewmates's providers;
              </li>
              <li>
                attempt to probe, scan, or test the vulnerability of any
                Crewmates system or network or breach any security or
                authentication measures;
              </li>
              <li>
                avoid, bypass, remove, deactivate, impair, descramble, or
                otherwise circumvent any technological measure implemented by
                Crewmates or any of Crewmates's providers or any other third
                party (including another user) to protect the Site, Services,
                Application or Collective Content;
              </li>
              <li>
                forge any TCP/IP packet header or any part of the header
                information in any email or newsgroup posting, or in any way use
                the Site, Services, Application or Collective Content to send
                altered, deceptive or false source-identifying information;
              </li>
              <li>
                attempt to decipher, decompile, disassemble or reverse engineer
                any of the software used to provide the Site, Services,
                Application or Collective Content; or
              </li>
              <li>
                advocate, encourage, or assist any third party in doing any of
                the foregoing.
              </li>
              <li>
                accept or make a payment for Accommodation Fees outside
                Crewmates Payments. If you do so, you acknowledge and agree that
                you: (i) would be in breach of these Terms; (ii) accept all
                risks and responsibility for such payment, and (iii) hold
                Crewmates harmless from any liability for such payment.
              </li>
            </ul>
          </div>

          <p className="my-4">
            Crewmates has the right to investigate and prosecute violations of
            any of the above to the fullest extent of the law. Crewmates may
            access, preserve and disclose any of your information if we are
            required to do so by law, or if we believe in good faith that it is
            reasonably necessary to (i) respond to claims asserted against
            Crewmates or to comply with legal process (for example, subpoenas or
            warrants), (ii) enforce or administer our agreements with users,
            such as these Terms (iii) for fraud prevention, risk assessment,
            investigation, customer support, product development and de-bugging
            purposes, or (iv) protect the rights, property or safety of
            Crewmates, its users, or members of the public. You acknowledge that
            Crewmates has no obligation to monitor your access to or use of the
            Site, Application, Services or Collective Content or to review or
            edit any Member Content, but has the right to do so for the purpose
            of operating and improving the Site, Application and Services
            (including without limitation for fraud prevention, risk assessment,
            investigation and customer support purposes), to ensure your
            compliance with these Terms, to comply with applicable law or the
            order or requirement of a court, administrative agency or other
            governmental body, to respond to content that it determines is
            otherwise objectionable or as set forth in these Terms. Crewmates
            reserves the right, at any time and without prior notice, to remove
            or disable access to any Collective Content that Crewmates, at its
            sole discretion, considers to be objectionable for any reason, in
            violation of these Terms or otherwise harmful to the Site,
            Application or Services.
          </p>
          <p className="font-medium my-4 ">Privacy</p>
          <p className="my-4">
            You agree that Crewmates's Privacy Policy (as may be updated from
            time to time) governs Crewmates's collection and use of your
            personal information.
          </p>
          <p className="text-sm my-4 ">Ownership</p>
          <p className="my-4">
            The Site, Application, Services, and Collective Content may be
            protected by copyright, trademark, and other laws of the United
            States and foreign countries. You acknowledge and agree that the
            Site, Application, Services and Collective Content, including all
            associated intellectual property rights, are the exclusive property
            of Crewmates and its licensors. You will not remove, alter or
            obscure any copyright, trademark, service mark or other proprietary
            rights notices incorporated in or accompanying the Site,
            Application, Services, or Collective Content.
          </p>
          <p className="text-sm my-4 ">Additional Terms</p>
          <p className="my-4">
            Our Services have different products and offerings, so sometimes
            additional terms or product requirements may apply to your use of
            those products. For example, additional terms apply if you refer new
            usersto Crewmates ("Referral Program"). If additional terms are
            available for the relevant product or Services you use, those
            additional terms become part of these Terms.
          </p>
          <p className="text-sm my-4 ">
            Specific Terms Regarding Crewmates Travel Credits
          </p>

          <p className="my-4">
            Crewmates Travel Credits may only be redeemed for confirmed bookings
            via the Site, Application and Services and after you accrue
            Crewmates Travel Credits, such Crewmates Travel Credits may be
            applied to your next confirmed booking made via the Site,
            Application and Services. Crewmates Travel Credits are credit only
            and have no cash value nor can they be refunded or exchanged for
            cash. You may only redeem Crewmates Travel Credits after the
            Crewmates Travel Credits are reflected in your Crewmates Account.
            The scope, variety, and type of services and products that you may
            obtain by redeeming Crewmates Travel Credits can change at any time.
          </p>
          <p className="my-4">
            Crewmates Travel Credits expire three (1) year from the date that
            any Crewmates Travel Credits are last accrued in your Crewmates
            Account. If for some reason you believe that there is a discrepancy
            regarding your balance of Crewmates Travel Credits, please contact
            us. Crewmates may require you to submit additional information in
            order to make a determination regarding your balance. All decisions
            regarding your balance will be final and at Crewmates's sole
            discretion. You are responsible for any Tax consequences, if any,
            that may result from your redemption or use of Crewmates Travel
            Credits. Where applicable, Crewmates may be required to account for
            VAT on any services for which the Crewmates Travel Credits are
            redeemed. Without limiting any other terms of these Terms and
            subject to applicable law, all Crewmates Travel Credits are
            forfeited if your Crewmates Account is terminated or suspended for
            any reason, in Crewmates's sole discretion, or if Crewmates
            discontinues providing the Site, Application, Services or the
            Referral Program.
          </p>
          <p className="text-sm my-4 ">Application License</p>
          <p className="my-4">
            Subject to your compliance with these Terms, Crewmates grants you a
            limited non-exclusive, non-transferable license to download and
            install a copy of the Application on each mobile device or computer
            that you own or control and run such copy of the Application solely
            for your own personal use. Furthermore, with respect to any App
            Store Sourced Application (defined below), you will only use the App
            Store Sourced Application (i) on an Apple-branded product that runs
            the iOS (Apple's proprietary operating system) and (ii) as permitted
            by the "Usage Rules" set forth in the Apple App Store Terms of
            Service. Crewmates reserves all rights in the Application not
            expressly granted to you by these Terms.
          </p>
          <p className="text-sm my-4 ">
            Crewmates Content and Member Content License
          </p>
          <p className="my-4">
            Subject to your compliance with the terms and conditions of these
            Terms, Crewmates grants you a limited, non-exclusive,
            non-transferable license, to (i) access and view any Crewmates
            Content solely for your personal and non-commercial purposes and
            (ii) access and view any Member Content to which you are permitted
            access, solely for your personal and non-commercial purposes. You
            have no right to sublicense the license rights granted in this
            section. You will not use, copy, adapt, modify, prepare derivative
            works based upon, distribute, license, sell, transfer, publicly
            display, publicly perform, transmit, broadcast or otherwise exploit
            the Site, Application, Services, or Collective Content, except as
            expressly permitted in these Terms. No licenses or rights are
            granted to you by implication or otherwise under any intellectual
            property rights owned or controlled by Crewmates or its licensors,
            except for the licenses and rights expressly granted in these Terms.
          </p>

          <p className="text-sm my-4 ">Member Content</p>
          <p className="my-4">
            We may, in our sole discretion, permit you to post, upload, publish,
            submit or transmit Member Content. By making available any Member
            Content on or through the Site, Application and Services, you hereby
            grant to Crewmates a worldwide, irrevocable, perpetual (or for the
            term of the protection), non-exclusive, transferable, royalty-free
            license, with the right to sublicense, to use, view, copy, adapt,
            modify, distribute, license, sell, transfer, publicly display,
            publicly perform, transmit, stream, broadcast, access, view, and
            otherwise exploit such Member Content on, through, by means of or to
            promote or market the Site, Application and Services. Crewmates does
            not claim any ownership rights in any such Member Content and
            nothing in these Terms will be deemed to restrict any rights that
            you may have to use and exploit any such Member Content.
          </p>
          <p className="my-4">
            You acknowledge and agree that you are solely responsible for all
            Member Content that you make available through the Site, Application
            and Services. Accordingly, you represent and warrant that: (i) you
            either are the sole and exclusive owner of all Member Content that
            you make available through the Site, Application and Services or you
            have all rights, licenses, consents and releases that are necessary
            to grant to Crewmates the rights in such Member Content, as
            contemplated under these Terms; and (ii) neither the Member Content
            nor your posting, uploading, publication, submission or transmittal
            of the Member Content or Crewmates's use of the Member Content (or
            any portion thereof) on, through or by means of the Site,
            Application and the Services will infringe, misappropriate or
            violate a third party's patent, copyright, trademark, trade secret,
            moral rights or other proprietary or intellectual property rights,
            or rights of publicity or privacy, or result in the violation of any
            applicable law or regulation.
          </p>
          <p className="my-4">
            Suspension, Termination and Crewmates Account Cancellation <br /> We
            may, in our discretion and without liability to you, with or without
            cause, with or without prior notice and at any time, decide to
            limit, suspend, deactivate or cancel your Crewmates Account. If we
            exercise our discretion under these Terms to do so, any or all of
            the following can occur with or without prior notice or explanation
            to you: (a) your Crewmates Account will be deactivated or suspended,
            your password will be disabled, and you will not be able to access
            the Site, Application, Services, your Crewmates Account, your Member
            Content, or receive assistance from Crewmates Customer Service, (b)
            any pending or accepted future bookings as either Host or Guest will
            be immediately terminated, (c) we may communicate to your Guests or
            Hosts that a potential or confirmed booking has been cancelled, (d)
            we may refund your Guests in full for any and all confirmed
            reservations, irrespective of preexisting cancellation policies, (e)
            we may contact your Guests to inform them about potential alternate
            Accommodations with other Hosts that may be available on the Site,
            Application and Services, and (f) you will not be entitled to any
            compensation for reservations or bookings (even if confirmed) that
            were cancelled as a result of a suspension, deactivation or
            termination of your Crewmates Account. You may cancel your Crewmates
            Account at any time by sending us an email. Please note that if your
            Crewmates Account is cancelled, we do not have an obligation to
            delete or return to you any Content you have posted to the Site,
            Application and Services, including, but not limited to, any reviews
            or Feedback.
          </p>

          <p className="text-sm my-4  ">Disclaimers</p>
          <p className=" my-4  ">
            IF YOU CHOOSE TO USE THE SITE, APPLICATION, SERVICES OR COLLECTIVE
            CONTENT OR PARTICIPATE IN THE REFERRAL PROGRAM, YOU DO SO AT YOUR
            SOLE RISK. YOU ACKNOWLEDGE AND AGREE THAT CREWMATES DOES NOT HAVE AN
            OBLIGATION TO CONDUCT BACKGROUND OR REGISTERED SEX OFFENDER CHECKS
            ON ANY MEMBER, INCLUDING, BUT NOT LIMITED TO, GUESTS AND HOSTS, BUT
            MAY CONDUCT SUCH BACKGROUND OR REGISTERED SEX OFFENDER CHECKS IN ITS
            SOLE DISCRETION. THE SITE, APPLICATION, SERVICES, COLLECTIVE CONTENT
            AND REFERRAL PROGRAM ARE PROVIDED "AS IS", WITHOUT WARRANTY OF ANY
            KIND, EITHER EXPRESS OR IMPLIED. WITHOUT LIMITING THE FOREGOING,
            CREWMATES EXPLICITLY DISCLAIMS ANY WARRANTIES OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT OR
            NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF
            DEALING OR USAGE OF TRADE. CREWMATES MAKES NO WARRANTY THAT THE
            SITE, APPLICATION, SERVICES, COLLECTIVE CONTENT, INCLUDING, BUT NOT
            LIMITED TO, THE LISTINGS OR ANY ACCOMMODATIONS, OR THE REFERRAL
            PROGRAM WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN
            UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. CREWMATES MAKES NO
            WARRANTY REGARDING THE QUALITY OF ANY LISTINGS, ACCOMMODATIONS,
            HOSTS, GUESTS, YOUR ACCRUAL OF CREWMATES TRAVEL CREDITS, THE
            SERVICES OR COLLECTIVE CONTENT OR THE ACCURACY, TIMELINESS,
            TRUTHFULNESS, COMPLETENESS OR RELIABILITY OF ANY COLLECTIVE CONTENT
            OBTAINED THROUGH THE SITE, APPLICATION, SERVICES OR REFERRAL
            PROGRAM.
          </p>
          <p className=" my-4  ">
            NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM
            CREWMATES OR THROUGH THE SITE, APPLICATION, SERVICES OR COLLECTIVE
            CONTENT, WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN. YOU ARE
            SOLELY RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS AND INTERACTIONS
            WITH OTHER USERS OF THE SITE, APPLICATION OR SERVICES AND WITH OTHER
            PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR
            USE OF THE SITE, APPLICATION OR SERVICES, INCLUDING, BUT NOT LIMITED
            TO, ANY HOSTS OR GUESTS. YOU UNDERSTAND THAT CREWMATES DOES NOT MAKE
            ANY ATTEMPT TO VERIFY THE STATEMENTS OF USERS OF THE SITE,
            APPLICATION OR SERVICES OR TO REVIEW OR VISIT ANY ACCOMMODATIONS.
            CREWMATES MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT
            OF USERS OF THE SITE, APPLICATION OR SERVICES OR THEIR COMPATIBILITY
            WITH ANY CURRENT OR FUTURE USERS OF THE SITE, APPLICATION OR
            SERVICES. YOU AGREE TO TAKE REASONABLE PRECAUTIONS IN ALL
            COMMUNICATIONS AND INTERACTIONS WITH OTHER USERS OF THE SITE,
            APPLICATION OR SERVICES AND WITH OTHER PERSONS WITH WHOM YOU
            COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE SITE,
            APPLICATION OR SERVICES, INCLUDING, BUT NOT LIMITED TO, GUESTS AND
            HOSTS, PARTICULARLY IF YOU DECIDE TO MEET OFFLINE OR IN PERSON
            REGARDLESS OF WHETHER SUCH MEETINGS ARE ORGANIZED BY CREWMATES.
            NOTWITHSTANDING CREWMATES'S APPOINTMENT AS THE LIMITED PAYMENT
            COLLECTION AGENT OF THE HOSTS FOR THE PURPOSE OF ACCEPTING PAYMENTS
            FROM GUESTS ON BEHALF OF THE HOSTS, CREWMATES EXPLICITLY DISCLAIMS
            ALL LIABILITY FOR ANY ACT OR OMISSION OF ANY GUEST OR OTHER THIRD
            PARTY.
          </p>
          <p className="text-sm my-4  ">Limitation of Liability</p>
          <p className=" my-4  ">
            YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY
            LAW, THE ENTIRE RISK ARISING OUT OF YOUR ACCESS TO AND USE OF THE
            SITE, APPLICATION, SERVICES AND COLLECTIVE CONTENT, YOUR LISTING OR
            BOOKING OF ANY ACCOMMODATIONS VIA THE SITE, APPLICATION AND
            SERVICES, YOUR PARTICIPATION IN THE REFERRAL PROGRAM, AND ANY
            CONTACT YOU HAVE WITH OTHER USERS OF CREWMATES WHETHER IN PERSON OR
            ONLINE REMAINS WITH YOU. NEITHER CREWMATES NOR ANY OTHER PARTY
            INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SITE,
            APPLICATION, SERVICES, COLLECTIVE CONTENT OR THE REFERRAL PROGRAM
            WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR
            CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA OR LOSS
            OF GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE
            OR THE COST OF SUBSTITUTE PRODUCTS OR SERVICES, OR FOR ANY DAMAGES
            FOR PERSONAL OR BODILY INJURY OR EMOTIONAL DISTRESS ARISING OUT OF
            OR IN CONNECTION WITH THESE TERMS, FROM THE USE OF OR INABILITY TO
            USE THE SITE, APPLICATION, SERVICES OR COLLECTIVE CONTENT, FROM ANY
            COMMUNICATIONS, INTERACTIONS OR MEETINGS WITH OTHER USERS OF THE
            SITE, APPLICATION, OR SERVICES OR OTHER PERSONS WITH WHOM YOU
            COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE SITE,
            APPLICATION, SERVICES, OR YOUR PARTICIPATION IN THE REFERRAL PROGRAM
            OR FROM YOUR LISTING OR BOOKING OF ANY ACCOMMODATION VIA THE SITE,
            APPLICATION AND SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT
            (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY,
            AND WHETHER OR NOT CREWMATES HAS BEEN INFORMED OF THE POSSIBILITY OF
            SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO
            HAVE FAILED OF ITS ESSENTIAL PURPOSE.
          </p>
          <p className=" my-4  ">
            EXCEPT FOR OUR OBLIGATIONS TO PAY AMOUNTS TO APPLICABLE HOSTS
            PURSUANT TO THESE TERMS OR AN APPROVED PAYMENT REQUEST UNDER THE
            CREWMATES HOST GUARANTEE, IN NO EVENT WILL CREWMATES'S AGGREGATE
            LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS AND YOUR
            USE OF THE SITE, APPLICATION AND SERVICES INCLUDING, BUT NOT LIMITED
            TO, FROM YOUR LISTING OR BOOKING OF ANY ACCOMMODATION VIA THE SITE,
            APPLICATION AND SERVICES, OR FROM THE USE OF OR INABILITY TO USE THE
            SITE, APPLICATION, SERVICES, OR COLLECTIVE CONTENT OR YOUR
            PARTICIPATION IN THE REFERRAL PROGRAM AND IN CONNECTION WITH ANY
            ACCOMMODATION OR INTERACTIONS WITH ANY OTHER MEMBERS, EXCEED THE
            AMOUNTS YOU HAVE PAID OR OWE FOR BOOKINGS VIA THE SITE, APPLICATION
            AND SERVICES AS A GUEST IN THE TWELVE (12) MONTH PERIOD PRIOR TO THE
            EVENT GIVING RISE TO THE LIABILITY, OR IF YOU ARE A HOST, THE
            AMOUNTS PAID BY CREWMATES TO YOU IN THE TWELVE (12) MONTH PERIOD
            PRIOR TO THE EVENT GIVING RISE TO THE LIABILITY, OR ONE HUNDRED U.S.
            DOLLARS (US$100), IF NO SUCH PAYMENTS HAVE BEEN MADE, AS APPLICABLE.
            THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS
            OF THE BASIS OF THE BARGAIN BETWEEN CREWMATES AND YOU. SOME
            JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY
            FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY
            NOT APPLY TO YOU.
          </p>

          <p className="text-sm my-4  ">Indemnification</p>
          <p className=" my-4  ">
            You agree to release, defend, indemnify, and hold Crewmates and its
            affiliates and subsidiaries, and their officers, directors,
            employees and agents, harmless from and against any claims,
            liabilities, damages, losses, and expenses, including, without
            limitation, reasonable legal and accounting fees, arising out of or
            in any way connected with (a) your access to or use of the Site,
            Application, Services, or Collective Content or your violation of
            these Terms; (b) your Member Content; (c) your (i) interaction with
            any Member, (ii) booking of an Accommodation, or (iii) creation of a
            Listing; (d) the use, condition or rental of an Accommodation by
            you, including but not limited to any injuries, losses, or damages
            (compensatory, direct, incidental, consequential or otherwise) of
            any kind arising in connection with or as a result of a rental,
            booking or use of an Accommodation; and (e) your participation in
            the Referral Program or your accrual of any Crewmates Travel
            Credits.
          </p>

          <p className="text-sm my-4  ">
            Export Control and Restricted Countries
          </p>
          <p className=" my-4  ">
            You may not use, export, re-export, import, or transfer the
            Application except as authorized by United States law, the laws of
            the jurisdiction in which you obtained the Application, and any
            other applicable laws. In particular, but without limitation, the
            Application may not be exported or re-exported: (a) into any United
            States embargoed countries; or (b) to anyone on the U.S. Treasury
            Department's list of Specially Designated Nationals or the U.S.
            Department of Commerce's Denied Persons List or Entity List. By
            using the Site, Application and Services, you represent and warrant
            that (i) neither you nor your listed Accommodation is located in a
            country that is subject to a U.S. Government embargo, or that has
            been designated by the U.S. Government as a "terrorist supporting"
            country and (ii) you are not listed on any U.S. Government list of
            prohibited or restricted parties. You also will not use the Site,
            Application and Services for any purpose prohibited by U.S. law,
            including the development, design, manufacture or production of
            missiles, or nuclear, chemical or biological weapons. Crewmates does
            not permit Listings associated with certain countries due to U.S.
            embargo restrictions.
          </p>

          <p className="text-sm my-4  ">Reporting Misconduct</p>
          <p className=" my-4  ">
            If you stay with or host anyone who you feel is acting or has acted
            inappropriately, including but not limited to anyone who (i) engages
            in offensive, violent or sexually inappropriate behavior, (ii) you
            suspect of stealing from you, or (iii) engages in any other
            disturbing conduct, you should immediately report such person to the
            appropriate authorities and then to Crewmates by contacting us with
            your police station and report number; provided that your report
            will not obligate us to take any action beyond that required by law
            (if any) or cause us to incur any liability to you.
          </p>

          <p className="text-sm my-4  ">Entire Agreement</p>
          <p className=" my-4  ">
            These Terms constitute the entire and exclusive understanding and
            agreement between Crewmates and you regarding the Site, Application,
            Services, Collective Content, and any bookings or Listings of
            Accommodations made via the Site, Application and Services, and
            these Terms supersede and replace any and all prior oral or written
            understandings or agreements between Crewmates and you regarding
            bookings or listings of Accommodations, the Site, Application,
            Services, and Collective Content.
          </p>

          <p className="text-sm my-4  ">Assignment</p>
          <p className=" my-4  ">
            You may not assign or transfer these Terms, by operation of law or
            otherwise, without Crewmates's prior written consent. Any attempt by
            you to assign or transfer these Terms, without such consent, will be
            null and of no effect. Crewmates may assign or transfer these Terms,
            at its sole discretion, without restriction. Subject to the
            foregoing, these Terms will bind and inure to the benefit of the
            parties, their successors and permitted assigns.
          </p>

          <p className="text-sm my-4  ">Controlling Law and Jurisdiction</p>
          <p className=" my-4  ">
            These Terms and your use of the Services will be interpreted in
            accordance with the laws of the State of Delaware and the United
            States of America, without regard to its conflict-of-law provisions.
            You and we agree to submit to the personal jurisdiction of a state
            court located in Delaware, or a United States District Court for any
            actions for which the parties retain the right to seek injunctive or
            other equitable relief in a court of competent jurisdiction to
            prevent the actual or threatened infringement, misappropriation or
            violation of a party's copyrights, trademarks, trade secrets,
            patents, or other intellectual property rights.
          </p>

          <p className="text-sm my-4  ">General</p>
          <p className=" my-4  ">
            The failure of Crewmates to enforce any right or provision of these
            Terms will not constitute a waiver of future enforcement of that
            right or provision. The waiver of any such right or provision will
            be effective only if in writing and signed by a duly authorized
            representative of Crewmates. Except as expressly set forth in these
            Terms, the exercise by either party of any of its remedies under
            these Terms will be without prejudice to its other remedies under
            these Terms or otherwise. If for any reason an arbitrator or a court
            of competent jurisdiction finds any provision of these Terms invalid
            or unenforceable, that provision will be enforced to the maximum
            extent permissible and the other provisions of these Terms will
            remain in full force and effect.
          </p>
          <p className="text-sm my-4  ">Third party beneficiary</p>
          <p className=" my-4  ">
            These Terms do not and are not intended to confer any rights or
            remedies upon any person other than the parties. Notwithstanding the
            foregoing, the parties agree that the payment card networks are
            third party beneficiaries of these Terms for purposes of enforcing
            provisions related to payments, but that their consent or agreement
            is not necessary for any changes or modifications to these Terms.
          </p>
          <p className="text-sm my-4  ">Contacting Crewmates</p>
          <p className=" my-4  ">
            If you have any questions about these Terms or any App Store Sourced
            Application, please contact Crewmates.
          </p>
        </div>
      </Section>
      <Footer />
    </div>
  );
}

export default TermsCondition;
