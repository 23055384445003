import React, { useRef, useState, useEffect, useContext } from 'react'
import moment from 'moment'
import { Link as ScrollToLink } from 'react-scroll'
import { Link, useParams, useHistory } from 'react-router-dom'
import StarRatings from 'react-star-ratings'
import MetaTags from 'react-meta-tags'
import Section from '@layout/Section'
import Header from '@front/Header'
import Footer from '@front/Footer'
import AddressGoogleMap from '../components/AddressGoogleMap'
import { BsChatDots } from 'react-icons/bs'
import { MdPermContactCalendar } from 'react-icons/md'
import { AiOutlineCalendar } from 'react-icons/ai'
import { MdLocationOn } from 'react-icons/md'
import { FaRegCheckCircle } from 'react-icons/fa'
import { HiOutlineChevronLeft } from 'react-icons/hi'
import { Context as AuthContext } from '@context/authContext'
import { MobileNavOptions } from '@pages/Property-listing'
import BreadCrunb from '@components/BreadCrunb'
import PropertyCard from '@components/RoomCard'
import { Swiper, SwiperSlide } from 'swiper/react'
import { getCoords, getFormattedDate, getTommorow } from '@utils/utility'

import { getPropertyDetails, getReviewList, getSimilarProperties } from '@services/property'
import { SearchTopBar } from './Property-listing'

import BookingCard from '@components/BookingCard'
// // import Swiper core and required modules
import useQuery from '@hooks/useQuery'
import SwiperCore, { Navigation } from 'swiper'
import { constant } from '../_config/constant'
import { toast } from 'react-toastify'
import { calculateRoomAvgPrice } from '@components/RoomCard'
import { usePropertyFilter } from '@context/propertyFilterContext'
import { processMediaUrl } from '../_helper/utils'

// // install Swiper modules
SwiperCore.use([Navigation])

const HOT = 'Hot bed'
const COLD = 'Cold bed'
const FLEXIBLE = 'Flexible'

const ENTIREPLACE = 'Entire Place'
const CRASHPAD = 'Crashpad'

function About({ propertyData, showStayLimit }) {
    return (
        <div className="text-primary-blue ">
            <h2 className="text-xl font-medium mb-5">About this listing</h2>
            <p className="text-sm">{propertyData.aboutListing}</p>
            <div className="grid grid-cols-2  md:grid-cols-3 w-full xl:w-10/12 my-5">
                <h2 className="text-xl col-span-full md:col-auto   font-medium  mb-5">Summary</h2>
                <div className="text-sm space-y-2">
                    <p>
                        Rental Type: <span className="font-medium">{propertyData.rentalType}</span>
                    </p>
                    <p>
                        Booking Type: <span className="font-medium">{propertyData.bookingType}</span>
                    </p>
                    <p>
                        Bedrooms : <span className="font-medium">{propertyData.sleepingArrangements.length}</span>
                    </p>
                    <p>Beds: {propertyData.sleepingArrangements.reduce((tt, cu) => tt + cu.numberOfBeds, 0)}</p>
                </div>
                <div className="text-sm space-y-2">
                    <p>
                        Property Type: <span className="font-medium capitalize">{propertyData.propertyType}</span>
                    </p>
                    {propertyData.rentalType === ENTIREPLACE && (
                        <p>
                            Extra Charge Per Guest:{' '}
                            <span className="font-medium capitalize">
                                {parseFloat(propertyData.extraChargePerGuest || 0).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                })}
                            </span>
                        </p>
                    )}

                    <p>Accomodates : {propertyData.accomodationCapacity}</p>
                    <p>Bathrooms: {propertyData.sleepingArrangements.reduce((tt, cu) => tt + cu.numberOfBathroom, 0)}</p>
                </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 w-full xl:w-10/12 my-5">
                <h2 className="text-xl   font-medium col-span-full md:col-auto  mb-5">Prices</h2>
                <div className="text-sm space-y-2">
                    <p>Cleaning fee: ${propertyData.cleaningFee}</p>
                </div>
                <div className="text-sm space-y-2">
                    <p>Security Deposit: ${propertyData.securityDeposite}</p>
                </div>
            </div>
            {/* <div className="grid grid-cols-3 w-full lg:w-10/12 my-5">
        <h2 className="text-xl   font-medium  mb-5">Description</h2>
        <div className="text-sm col-span-2 space-y-2">
          <p>
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using 'Content here, content
            here', making it look like readable English. Many desktop publishing
            packages and web page editors now use Lorem Ipsum as their default
            model text, and a search for 'lorem ipsum' will uncover many web
            sites still in their infancy. Various versions have evolved over the
            years,
          </p>
        </div>
      </div> */}
            {(propertyData.bookingType === 'Cold bed' || (propertyData.bookingType === 'Flexible' && showStayLimit)) && (
                <div className="grid grid-cols-9 w-full lg:w-10/12 my-5">
                    <h2 className="text-2xl font-medium col-span-9  mb-5">Stay Limits</h2>
                    <div className="text-lg space-y-2 col-span-4 ">
                        <h4 className="text-xl font-medium">Reserve Stay Limit</h4>

                        <p className="text-sm  flex items-center ">
                            <FaRegCheckCircle size={18} className="text-parrot  mr-2" />
                            {propertyData.reservedDays}
                        </p>
                    </div>
                    <div className="flex-center">
                        <span className="border-r border-gray-300 h-full"></span>
                    </div>
                    <div className="text-lg space-y-2 pl-5 col-span-4">
                        <h4 className="text-xl font-medium ">Lineholder Stay Limit</h4>

                        <p className="text-sm  flex items-center ">
                            <FaRegCheckCircle size={18} className="text-parrot  mr-2" />
                            {propertyData.lineholderDays}
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}

function Amenities({ propertyData }) {
    const [viewAll, setViewAll] = useState(false)
    return (
        <div className="text-primary-blue-dim">
            <h2 className="text-2xl font-medium mb-5">Amenities</h2>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-5  ">
                {viewAll ? (
                    <>
                        {propertyData.amenities.map((el) => (
                            <div key={el._id} className="flex items-center flex-shrink-0   ">
                                <img className="h-14 mr-2" src={processMediaUrl(el.image)} alt="iron" />
                                <p className="text-sm capitalize">{el.name}</p>
                            </div>
                        ))}
                    </>
                ) : (
                    <>
                        {propertyData.amenities.slice(0, 6).map((el) => (
                            <div key={el._id} className="flex items-center flex-shrink-0   ">
                                <img className="h-14 mr-2" src={processMediaUrl(el.image)} alt="iron" />
                                <p className="text-sm capitalize">{el.name}</p>
                            </div>
                        ))}
                    </>
                )}
                {propertyData.amenities.length > 6 && (
                    <div onClick={() => setViewAll(!viewAll)} className="flex items-center flex-shrink-0 cursor-pointer   ">
                        <p className="text-sm underline">{viewAll ? 'View less' : 'View all'}</p>
                    </div>
                )}
            </div>
        </div>
    )
}

function SafetyFeatures({ propertyData }) {
    const [viewAll, setViewAll] = useState(false)
    return (
        <div className=" text-primary-blue-dim  ">
            <h2 className="text-2xl font-medium mb-5">Safety Features</h2>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4  gap-y-5  ">
                {viewAll ? (
                    <>
                        {propertyData.safety.map((el) => (
                            <div key={el._id} className="flex items-center flex-shrink-0   ">
                                <img className="h-14 mr-2" src={processMediaUrl(el.image)} alt="iron" />
                                <p className="text-sm capitalize">{el.name}</p>
                            </div>
                        ))}
                    </>
                ) : (
                    <>
                        {propertyData.safety.slice(0, 6).map((el) => (
                            <div key={el._id} className="flex items-center flex-shrink-0   ">
                                <img className="h-14 mr-2" src={processMediaUrl(el.image)} alt="iron" />
                                <p className="text-sm capitalize">{el.name}</p>
                            </div>
                        ))}
                    </>
                )}

                {propertyData.safety.length > 6 && (
                    <div onClick={() => setViewAll(!viewAll)} className="flex items-center flex-shrink-0 cursor-pointer   ">
                        <p className="text-sm underline">{viewAll ? 'View less' : 'View all'}</p>
                    </div>
                )}
            </div>
        </div>
    )
}

function Address({ propertyData }) {
    function renderCancelationPolicy(policy) {
        switch (policy) {
            case 'Strict':
                return 'No refunds'
            case 'Relaxed':
                return 'Guests can receive a full refund up until 24 hours before check-in'
            case 'Moderate':
                return 'Guests can receive a full refund up until 5 days before check-in'
            default:
                return ''
        }
    }
    return (
        <div className="text-primary-blue-dim ">
            <h2 className="text-2xl font-medium mb-5" id="location">
                Address
            </h2>
            <div className="h-96 z-0 ">
                <AddressGoogleMap location={propertyData.location} />
            </div>

            <div className="grid grid-cols-4 mt-5 " id="rules">
                <p className="col-span-full  md:col-auto font-medium text-xl">House Rules</p>
                <div className=" col-span-full md:col-span-3 text-sm">
                    <ol className="">
                        {propertyData.houseRules.map((rule, ind) => (
                            <li className="my-2" key={ind}>
                                {ind + 1}.{rule}
                            </li>
                        ))}
                    </ol>
                </div>
            </div>
            <div className="my-5">
                <p className="col-span-1 font-medium text-xl">Transportation and Parking</p>
                <div className="grid md:grid-cols-2 gap-10 my-5">
                    <div className="flex w-full p-2 ring-1 ring-gray-200 rounded-md ">
                        <div className="bg-gray-200 p-2  h-16  w-16 flex-shrink-0   mr-4  rounded-md flex-center">
                            <img src="/assets/resources/truck.png" alt="map" className="object-cover" />
                        </div>
                        <div className="">
                            <h4 className="text-base font-medium mb-2 text-primary-blue ">Transportation</h4>
                            <p className="text-sm text-gray-600">{propertyData.transportation}</p>
                        </div>
                    </div>
                    <div className="flex w-full p-2 ring-1 ring-gray-200 rounded-md ">
                        <div className="bg-gray-200 p-2  h-16  w-16 flex-shrink-0   mr-4  rounded-md flex-center">
                            <img src="/assets/resources/parking.png" alt="map" className="object-cover" />
                        </div>
                        <div className="">
                            <h4 className="text-base font-medium mb-2 text-primary-blue ">Parking</h4>
                            <p className="text-sm text-gray-600">{propertyData.parking}</p>
                        </div>
                    </div>
                </div>
                <div className="flex md:items-start md:space-x-10 flex-col md:flex-row md:justify-between my-10" id="policy">
                    <p className="col-span-1 font-medium text-xl">Cancellation and Refund Policy</p>
                    <p className=" text-sm">
                        {propertyData.cancelationPolicy} <br /> {renderCancelationPolicy(propertyData.cancelationPolicy)}
                    </p>
                </div>
            </div>
        </div>
    )
}

function ReviewCard({ data }) {
    return (
        <div className="flex items-start text-gray-700 space-x-2 py-4 border-b border-gray-300 ">
            <img
                onError={function ({ currentTarget }) {
                    currentTarget.src = '/user.png'
                }}
                src={processMediaUrl(data.userId.avatar)}
                alt="user"
                className="flex-shrink-0  h-14 w-14 object-cover  rounded-full"
            />
            <div className="flex-grow ">
                <div className="flex items-center justify-between">
                    <div>
                        <h4 className="text-sm font-medium">{data.userId.name}</h4>
                        <div className="flex items-center justify-start  ">
                            <StarRatings rating={parseFloat(data.rating)} starDimension="18px" starSpacing="0px" starRatedColor="#eab308" />
                            <p>{data.rating}</p>
                        </div>
                    </div>
                    <p className="font-medium flex items-center justify-between ml-auto">
                        <AiOutlineCalendar size={24} className="flex-shrink-0 mr-2" /> {moment(data.createdAt).format('MMM Do YY')}
                    </p>
                </div>
                <p className="text-sm    mt-5">{data.message}</p>
            </div>
        </div>
    )
}

function Bar({ percent, color, title, rating }) {
    return (
        <div className="flex flex-col justify-center items-start">
            <p className="text-xs">{title}</p>
            <div className="flex justify-start items-center w-full  ">
                <div className="h-2 flex-grow mr-2 rounded bg-gray-100 relative overflow-hidden">
                    <div style={{ width: `${percent}%` }} className={`h-full z-0   absolute top-0 bottom-0 right-0 left-0 bg-${color} `}></div>
                </div>
                <span className="p-1 px-2 rounded-md bg-gray-300 text-xs">{rating}</span>
            </div>
        </div>
    )
}

function Reviews() {
    const params = useParams()
    const [fetching, setFetching] = useState(false)
    const [reviewList, setReviewList] = useState([])
    const [totalPage, setTotalPage] = useState(0)
    const [page, setPage] = useState(1)

    function loadMoreReview() {
        setPage((e) => Math.min(totalPage, e + 1))
    }

    useEffect(() => {
        const fetch = async () => {
            try {
                setFetching(true)
                const { data } = await getReviewList({
                    page: page,
                    propertyId: params.id,
                })

                setReviewList([...reviewList, ...data.data.docs])
                setTotalPage(data.data.pages)
            } catch (error) {
                toast.error('Something went wrong')
            } finally {
                setFetching(false)
            }
        }

        fetch()
    }, [page])
    return (
        <div className="text-primary-blue-dim  ">
            <h2 className="text-2xl font-medium mb-5 ">Reviews</h2>
            {/* <div className="grid grid-cols-2 gap-4 w-10/12 mx-auto px-10 border-r border-l border-gray-300 my-10">
        <Bar
          title="Cleanliness"
          percent={40}
          color="parrot-light"
          rating="4.6"
        />

        <Bar
          title="Value For Money"
          percent={50}
          color="parrot "
          rating="4.0"
        />
        <Bar title="Location" percent={30} color="primary-blue" rating="4.3" />
        <Bar title="Service" percent={90} color="sun" rating="4.8" />
      </div> */}

            {reviewList.map((c) => (
                <ReviewCard key={c._id} data={c} />
            ))}

            {!Boolean(reviewList.length) && <p className="text-base text-center my-4 text-primary-blue ">No Review Yet</p>}
            {fetching && <p className="text-sm text-center my-4 text-primary-blue ">Loading more...</p>}
            {page < totalPage && (
                <p onClick={loadMoreReview} className="text-sm underline cursor-pointer text-primary-blue my-4 text-right ">
                    Load More
                </p>
            )}
        </div>
    )
}

const MemoizedReviews = React.memo(Reviews, (prv, nxt) => {
    return true
})

function SimilarProperty({ list = [] }) {
    const { property_filter } = usePropertyFilter()
    const prevRef = useRef(null)
    const nextRef = useRef(null)

    console.log(property_filter)
    return (
        <div className={`px-4 relative mt-10 ${Boolean(list.length) ? '' : 'hidden'}`}>
            <Section>
                <h2 className="text-2xl font-medium text-primary-blue  ">Similar Properties</h2>
                <div className="lg:mt-20 mt-[2.1rem] md:mt-10 relative flex items-center">
                    <div
                        ref={prevRef}
                        className="h-12 w-12 shadow-md hidden md:flex items-center justify-center bg-white rounded-full z-50 absolute top-1/2 left-[40px] transform -translate-x-1/2 -translate-y-1/2"
                    >
                        <HiOutlineChevronLeft size={34} className="cursor-pointer text-gray-500" />
                    </div>
                    <div
                        ref={nextRef}
                        className="h-12 w-12 shadow-md hidden md:flex items-center justify-center bg-white rounded-full z-50 absolute top-1/2 !right-[0] transform -translate-x-1/2 -translate-y-1/2"
                    >
                        <HiOutlineChevronLeft size={34} className="transform rotate-180 cursor-pointer text-gray-500 " />
                    </div>

                    <div
                        className="absolute w-[100px] h-full top-0 right-0 z-10"
                    ></div>
                    <div
                        className="absolute w-[100px] h-full top-0 left-0 z-10"
                        
                    ></div>
                    <Swiper
                        className="flex justify-center items-center mx-auto relative"
                        navigation={{
                            prevEl: prevRef.current,
                            nextEl: nextRef.current,
                        }}
                        onSwiper={(swiper) => {
                            // Delay execution for the refs to be defined
                            setTimeout(() => {
                                // if (!swiper.destroy) {
                                //   // Override prevEl & nextEl now that refs are defined
                                //   swiper.params.navigation.prevEl = prevRef.current;
                                //   swiper.params.navigation.nextEl = nextRef.current;

                                //   // Re-init navigation
                                //   swiper.navigation.destroy();
                                //   swiper.navigation.init();
                                //   swiper.navigation.update();
                                // }
                                swiper.params.navigation.prevEl = prevRef.current
                                swiper.params.navigation.nextEl = nextRef.current

                                // Re-init navigation
                                swiper.navigation.destroy()
                                swiper.navigation.init()
                                swiper.navigation.update()
                            })
                        }}
                        spaceBetween={20}
                        slidesPerView={'auto'}
                    >
                        {list.map((curr, ind) => (
                            <SwiperSlide key={ind} className="w-full sm:w-[320px]">
                                <PropertyCard property={curr} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </Section>
        </div>
    )
}

const propertyTypeList = ['Hot bed ', 'Cold bed', 'Flexible']

function PropertyDetails() {
    const history = useHistory()
    const params = useParams()
    const query = useQuery()
    const { updateFilter } = usePropertyFilter()
    const bookingCardRef = useRef()
    const { user } = useContext(AuthContext)
    const [location, setLocation] = useState([])
    const [airportName, setAirportName] = useState('')
    const [fetchingLocation, setFetchingLocation] = useState(true)
    const [similarPropertyList, setSimilarPropertyList] = useState([])
    const [sleepingArrangemntRefreshKey, setSleepingArrangemntRefreshKey] = useState(0)
    const [showStayLimit, setShowStayLimit] = useState(true)

    const [propertyData, setPropertyData] = useState({
        aboutListing: 'testing 1',
        accomodationCapacity: '',
        addedBy: '',
        address: 'Amsterdam, Netherlands',
        amenities: [],
        apartmentNumber: '',
        bookingType: '',
        cancelationPolicy: 'Relaxed',
        cleaningFee: 0,
        covidVerified: '',
        createdAt: '2021-11-27T17:37:45.178Z',
        extraChargePerGuest: '0',
        hostId: '0',
        houseRules: ['testing 1'],
        id: params.id,
        images: ['1638034661567.png'],
        lineholderDays: '19',
        lineholderDiscount: '',
        lineholderDiscountType: '',
        listingId: '-1',
        listingName: '',
        location: [4, 4],
        parking: '',
        perMonthPrice: 0,
        perNightPrice: 0,
        propertyType: '',
        rejectReason: '',
        rentalType: '',
        reservedDays: '0',
        safety: [],
        securityDeposite: 0,
        sleepingArrangements: [],
        status: '',
        stayLimit: '',
        transportation: '',
        updatedAt: '2021-12-24T04:24:13.372Z',
    })
    const [fetchingPropertyDetails, setFetchingPropertyDetails] = useState(true)
    const [sleepingArrangements, setSleepingArrangements] = useState([])
    const [fetchingSleepingArrangement, setFetchingSleepingArrangement] = useState(false)

    const prevRef = useRef(null)
    const nextRef = useRef(null)

    function handleGuestMessaging() {
        if (!user.id) {
            return toast.error(
                <>
                    You are not a Registered User, Please{' '}
                    <Link to="/login" className="text-primary-blue ">
                        Register/Login
                    </Link>{' '}
                    in order to message host.
                </>
            )
        }

        if (user.id === propertyData.hostId.id) {
            return history.push({
                pathname: '/dashboard/messages',
            })
        }

        history.push({
            pathname: '/dashboard/messages',
            state: {
                channelId: `${propertyData._id}-${user.id}-${propertyData.hostId.id}`,
                friendlyName: `${propertyData._id}-${user.id}-${propertyData.hostId.id}`,
                property: {
                    name: propertyData.listingName,
                    id: propertyData._id,
                    host: propertyData.hostId.name,
                    user: user.name,
                    picture: propertyData.images[0],
                },
                user: {
                    id: user.id,
                    name: user.name,
                    profile: user.avatar,
                    email: user.personalEmail,
                },
                host: {
                    id: propertyData.hostId.id,
                    name: propertyData.hostId.name,
                    profile: propertyData.hostId.avatar,
                    email: propertyData.hostId.personalEmail,
                },
            },
        })
    }

    useEffect(() => {
        const fetch = async () => {
            try {
                const lat = query.get('lat')
                const lng = query.get('lng')
                const airportName = query.get('airportName')

                setAirportName(() => {
                    if (!airportName) return ''
                    else return airportName
                })
                if (lat && lng) {
                    setLocation([lat, lng])
                } else {
                    const pos = await getCoords()
                    const coords = {
                        lat: pos.coords.latitude,
                        lng: pos.coords.longitude,
                    }
                    setLocation([coords.lat, coords.lng])
                }
            } catch (error) {
                setLocation([])
            } finally {
                setFetchingLocation(false)
            }
        }
        fetch()
    }, [])

    useEffect(() => {
        async function fetchSleepingArrangement() {
            try {
                setFetchingSleepingArrangement(true)
                bookingCardRef.current.scrollIntoView()
                const res = await getPropertyDetails(params.id, {
                    lat: location[0],
                    lng: location[1],
                    airportName: airportName,
                    checkIn: query.get('checkIn') || getFormattedDate(new Date()),
                    checkOut: query.get('checkOut') || '',
                    userid: user.id,
                })
                if (!res.data.status) {
                    history.push('/')
                }
                const propertyResponse = res.data.data.property
                setSleepingArrangements(propertyResponse.sleepingArrangements)
            } catch (error) {
                history.push('/')
            } finally {
                setFetchingSleepingArrangement(false)
            }
        }
        if (propertyData._id) {
            fetchSleepingArrangement()
        }
    }, [query, sleepingArrangemntRefreshKey])

    useEffect(() => {
        async function fetchProperty() {
            try {
                setFetchingPropertyDetails(true)

                const res = await getPropertyDetails(params.id, {
                    lat: location[0],
                    lng: location[1],
                    airportName: airportName,
                    checkIn: query.get('checkIn') || getFormattedDate(new Date()),
                    checkOut: query.get('checkOut') || getTommorow(),
                    userid: user.id,
                })

                updateFilter({
                    latlng: location,
                    airportName,
                    checkIn: query.get('checkIn') || getFormattedDate(new Date()),
                    checkOut: query.get('checkOut') || getTommorow(),
                    booking_type: query.get('bookingType') || 'Cold bed',
                })

                if (!res.data.status) {
                    history.push('/')
                }

                const propertyResponse = res.data.data.property

                setSleepingArrangements(propertyResponse.sleepingArrangements)
                setPropertyData(propertyResponse)
                setSimilarPropertyList(res.data.data.similarProperties)
            } catch (error) {
                history.push('/')
            } finally {
                setFetchingPropertyDetails(false)
            }
        }
        if (!fetchingLocation) {
            fetchProperty()
        }
    }, [params.id, fetchingLocation])

    //fetch similar properties
    useEffect(() => {
        async function fetchSimilarProperty() {
            try {
                let res = null
                res = await getSimilarProperties({
                    userId: user.id,
                    propertyId: params.id,
                    coords: location,
                    airportName: airportName,
                })
                if (!res.data.status) {
                    history.push('/')
                } else {
                    setSimilarPropertyList(res.data.data)
                }
            } catch (error) {
                history.push('/')
            }
        }

        if (!fetchingLocation) {
            // fetchSimilarProperty();
        }
    }, [fetchingLocation])

    function priceCalculator() {
        let searchBookingType = query.get('bookingType') || HOT
        if (propertyData.rentalType === ENTIREPLACE) {
            if (propertyData.bookingType === COLD || (propertyData.bookingType === FLEXIBLE && searchBookingType === COLD)) {
                return {
                    str: `${propertyData.perMonthPrice}/ month`,
                    price: propertyData.perMonthPrice,
                    suffix: 'Per month',
                }
            } else if (propertyData.bookingType === HOT || (propertyData.bookingType === FLEXIBLE && searchBookingType === HOT)) {
                return {
                    str: `${propertyData.perNightPrice}/ night`,
                    price: propertyData.perNightPrice,
                    suffix: 'Per night',
                }
            }
        } else {
            const calc = calculateRoomAvgPrice(
                propertyData?.sleepingArrangements || [],
                searchBookingType,
                propertyData.bookingType,
                propertyData.rentalType,
                propertyData
            )
            return calc
        }
    }

    return (
        <div>
            <Header type="fixed" />
            <Section className="px-4">
                <MobileNavOptions />
                <SearchTopBar />
            </Section>
            <Section className="px-4 overflow-hidden">
                <BreadCrunb
                    className="my-10 hidden md:block"
                    bread={[
                        { name: 'Home', path: '/' },
                        { name: 'Properties', path: '/property-listing' },
                        { name: 'Search result', path: '/property-listing' },
                        {
                            name: propertyData.listingName,
                            path: `/property-details/${propertyData._id}`,
                        },
                    ]}
                />
                <BreadCrunb
                    className="my-10 md:hidden block"
                    bread={[
                        { name: 'Search result', path: '/property-listing' },
                        {
                            name: propertyData.listingName,
                            path: `/property-details/${propertyData._id}`,
                        },
                    ]}
                />
            </Section>
            {!fetchingPropertyDetails ? (
                <div>
                    {/* <MetaTags>
            <title>{propertyData.listingName}</title>
            <meta property="og:title" content={propertyData.listingName} />
            <meta
              property="og:description"
              content={propertyData.aboutListing}
            />
            <meta property="og:type" content="article" />
          </MetaTags> */}
                    <div className="mx-4 relative">
                        <div
                            ref={prevRef}
                            className="hidden md:flex absolute top-0 bottom-0 my-auto left-2.5 text-white px-4 rounded-full bg-gray-400 shadow-3xl h-16 w-16 bg-opacity-40 cursor-pointer z-100 flex-center"
                        >
                            <HiOutlineChevronLeft size={48} className="cursor-pointer " />
                        </div>

                        <div
                            ref={nextRef}
                            className="hidden md:flex absolute top-0 bottom-0 my-auto right-2.5 text-white px-4 rounded-full bg-gray-400 shadow-3xl h-16 w-16 bg-opacity-40 cursor-pointer z-100 flex-center"
                        >
                            <HiOutlineChevronLeft size={48} className="transform rotate-180 cursor-pointer  " />
                        </div>
                        <Swiper
                            navigation={{
                                prevEl: prevRef.current,
                                nextEl: nextRef.current,
                            }}
                            onSwiper={(swiper) => {
                                // Delay execution for the refs to be defined
                                setTimeout(() => {
                                    // if (!swiper.destroy) {
                                    //   // Override prevEl & nextEl now that refs are defined
                                    //   swiper.params.navigation.prevEl = prevRef.current;
                                    //   swiper.params.navigation.nextEl = nextRef.current;

                                    //   // Re-init navigation
                                    //   swiper.navigation.destroy();
                                    //   swiper.navigation.init();
                                    //   swiper.navigation.update();
                                    // }
                                    // Override prevEl & nextEl now that refs are defined
                                    swiper.params.navigation.prevEl = prevRef.current
                                    swiper.params.navigation.nextEl = nextRef.current

                                    // Re-init navigation
                                    swiper.navigation.destroy()
                                    swiper.navigation.init()
                                    swiper.navigation.update()
                                })
                            }}
                            spaceBetween={20}
                            slidesPerView="auto"
                        >
                            {propertyData.images.map((curr, ind) => (
                                <SwiperSlide key={ind} className="h-64 w-full md:w-96 rounded-3xl  overflow-hidden">
                                    <img className="w-full h-full object-cover" src={processMediaUrl(curr)} alt="crewmate" />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    <div className="">
                        <Section className="px-4 my-10 text-gray-800 flex flex-col lg:flex-row space-y-2 justify-between">
                            <div>
                                <h2 className="text-4xl font-medium flex flex-wrap items-center">
                                    {propertyData.listingName}{' '}
                                    {Boolean(propertyData.hostId.airlineEmailVerification) && (
                                        <img src="/assets/images/cm-verified.png" alt="cm verified" className="h-8 ml-2  " />
                                    )}
                                </h2>
                                <div className="flex  items-center mt-2 flex-wrap">
                                    {(query.get('distance') || propertyData.distance) && (
                                        <div className="flex items-center mr-10">
                                            <MdLocationOn className="text-parrot-dark " size={35} />
                                            <p>{query.get('distance') || propertyData.distance}</p>
                                        </div>
                                    )}
                                    <div className="flex items-end justify-start    ">
                                        <div className="mr-2  flex-shrink-0 ">
                                            <StarRatings
                                                className="flex-nowrap"
                                                rating={propertyData.rating}
                                                starDimension="23px"
                                                starSpacing="1px"
                                                starRatedColor="#eab308"
                                            />
                                        </div>
                                        <p className="text-sm text-parrot-dark  ">{propertyData.rating || '0'} Rating</p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <span onClick={handleGuestMessaging} className="h-12 w-12 mr-3 flex-center rounded-full cursor-pointer  bg-parrot">
                                    <BsChatDots size={28} className="text-white" />
                                </span>
                                <div className="h-14 pr-20 pl-5 relative p3  bg-primary-blue rounded-full text-white flex items-center justify-between">
                                    <div className="text-xs">
                                        <p className="flex items-center ">
                                            <MdPermContactCalendar className="mr-2" />
                                            {propertyData.hostId.id}
                                        </p>
                                        <p className="font-normal">Host {propertyData.hostId.name}</p>
                                    </div>
                                    <img
                                        onError={function ({ currentTarget }) {
                                            currentTarget.src = '/user.png'
                                        }}
                                        className="h-16 w-16 absolute top-0 bottom-0 my-auto right-0 origin-center  rounded-full object-cover"
                                        src={processMediaUrl(propertyData.hostId.avatar)}
                                        alt="dev"
                                    />
                                </div>
                            </div>
                        </Section>
                        <Section BsChatDots className=" mt-5 lg:mt-20">
                            <div className="lg:grid grid-cols-6 gap-5 ">
                                <div className="col-span-6  lg:col-span-4">
                                    <div className="sticky top-0 z-high">
                                        <div className="bg-white  flex items-center justify-between flex-nowrap overflow-x-auto scrollbar-hide">
                                            <ScrollToLink
                                                offset={-30}
                                                spy
                                                smooth
                                                duration={500}
                                                to="about"
                                                activeClass="border-b-4"
                                                className="p-2  font-medium underline md:no-underline flex-shrink-0   text-base text-primary-blue border-primary-blue cursor-pointer"
                                            >
                                                ABOUT
                                            </ScrollToLink>
                                            <ScrollToLink
                                                offset={-30}
                                                spy
                                                smooth
                                                duration={500}
                                                to="amenities"
                                                activeClass="border-b-4"
                                                className="p-2  font-medium  flex-shrink-0  underline md:no-underline text-base text-primary-blue border-primary-blue cursor-pointer"
                                            >
                                                AMENITIES
                                            </ScrollToLink>
                                            <ScrollToLink
                                                offset={-50}
                                                spy
                                                smooth
                                                duration={500}
                                                to="location"
                                                activeClass="border-b-4"
                                                className="p-2  font-medium  flex-shrink-0 underline md:no-underline  text-base text-primary-blue border-primary-blue cursor-pointer"
                                            >
                                                LOCATION
                                            </ScrollToLink>
                                            <ScrollToLink
                                                offset={-50}
                                                spy
                                                smooth
                                                duration={500}
                                                to="rules"
                                                activeClass="border-b-4"
                                                className="p-2  font-medium  flex-shrink-0  underline md:no-underline text-base text-primary-blue border-primary-blue cursor-pointer"
                                            >
                                                PROPERTY RULES
                                            </ScrollToLink>
                                            <ScrollToLink
                                                offset={-50}
                                                spy
                                                smooth
                                                duration={500}
                                                to="policy"
                                                activeClass="border-b-4"
                                                className="p-2  font-medium  flex-shrink-0 underline md:no-underline  text-base text-primary-blue border-primary-blue cursor-pointer"
                                            >
                                                CANCELLATION & REFUND POLICY
                                            </ScrollToLink>
                                            <ScrollToLink
                                                offset={-30}
                                                spy
                                                smooth
                                                duration={500}
                                                to="review"
                                                activeClass="border-b-4"
                                                className="p-2  font-medium  flex-shrink-0 underline md:no-underline  text-base text-primary-blue border-primary-blue cursor-pointer"
                                            >
                                                USER REVIEW
                                            </ScrollToLink>
                                        </div>
                                    </div>
                                    <div className="p-4 md:p-5" id="about">
                                        <div className="hidden lg:block">
                                            <About showStayLimit={showStayLimit} propertyData={propertyData} />
                                        </div>
                                        <div className="lg:hidden block">
                                            <About showStayLimit={showStayLimit} propertyData={propertyData} />
                                        </div>
                                    </div>
                                    <div className="w-mx-auto lg:hidden">
                                        <BookingCard
                                            setShowStayLimit={setShowStayLimit}
                                            price={priceCalculator().price}
                                            suffix={priceCalculator().suffix}
                                            user={user}
                                            ref={bookingCardRef}
                                            propertyData={propertyData}
                                            sleepingArrangements={sleepingArrangements}
                                            setSleepingArrangemntRefreshKey={setSleepingArrangemntRefreshKey}
                                            fetchingSleepingArrangement={fetchingSleepingArrangement}
                                        />
                                    </div>
                                    <div className="p-4 md:p-5" id="amenities">
                                        <Amenities propertyData={propertyData} />
                                    </div>
                                    <div className="p-4 md:p-5">
                                        <SafetyFeatures propertyData={propertyData} />
                                    </div>
                                    <div className="p-4 md:p-5">
                                        <Address propertyData={propertyData} />
                                    </div>
                                    <div className="p-4 md:p-5" id="review">
                                        {/* <Reviews propertyData={propertyData} /> */}
                                        <MemoizedReviews />
                                    </div>
                                </div>

                                <div className="col-span-6 hidden lg:block lg:col-span-2 ">
                                    <div className="w-mx-auto">
                                        <BookingCard
                                            setShowStayLimit={setShowStayLimit}
                                            price={priceCalculator().price}
                                            suffix={priceCalculator().suffix}
                                            user={user}
                                            ref={bookingCardRef}
                                            propertyData={propertyData}
                                            sleepingArrangements={sleepingArrangements}
                                            setSleepingArrangemntRefreshKey={setSleepingArrangemntRefreshKey}
                                            fetchingSleepingArrangement={fetchingSleepingArrangement}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Section>
                        <SimilarProperty list={similarPropertyList} />
                    </div>
                </div>
            ) : (
                <div className="bg-gray-100 flex-center h-screen">
                    <div className="bg-white rounded shadow-md p-4">
                        <div className="loader"></div>
                    </div>
                </div>
            )}
            <Footer />
        </div>
    )
}

export default PropertyDetails
