import axios from "axios";
import { constant } from "@config/constant";
import { getBasicAuthHeader } from "@helper/header";

const api = axios.create({ baseURL: constant.apiUrl });

export const getAmenitiesList = async () => {
  const response = await api.get("/user/amenity", getBasicAuthHeader());
  return response;
};
