import React, { useRef, useCallback, useState } from 'react'
import { throttle } from 'lodash'
import { IoIosClose as CloseIcon } from 'react-icons/io'
import { calculateRoomAvgPrice } from './RoomCard'
import useQuery from '@hooks/useQuery'
import { GoogleMap, InfoWindow, InfoBox, Marker as GoogleMarker, LoadScript } from '@react-google-maps/api'
import Tippy from '@tippy.js/react'
import { MarkerLabel } from 'react-google-maps'

import { constant } from '../_config/constant'
import { Link, useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { processMediaUrl } from '../_helper/utils'

const containerStyle = {
    width: '100%',
    height: '100%',
}

function calculateMapcenter(list) {
    let mxLat = Number.MIN_SAFE_INTEGER,
        miLat = Number.MAX_SAFE_INTEGER,
        mxLng = Number.MIN_SAFE_INTEGER,
        miLng = Number.MAX_SAFE_INTEGER

    list.forEach((e) => {
        let lt = e.location[1],
            ln = e.location[0]
        mxLat = Math.max(mxLat, lt)
        miLat = Math.min(miLat, lt)
        mxLng = Math.max(mxLng, ln)
        miLng = Math.min(miLng, ln)
    })

    return [(mxLat + miLat) / 2.0, (mxLng + miLng) / 2.0]
}

const HOT = 'Hot bed'
const COLD = 'Cold bed'
const FLEXIBLE = 'Flexible'
const ENTIREPLACE = 'Entire Place'
const CRASHPAD = 'Crashpad'

const getTommorow = () => {
    const tommorow = new Date()
    tommorow.setDate(new Date().getDate() + 1)

    return tommorow.toISOString().split('T')[0]
}

function PropertyPin({ property, searchBookingType = HOT, navigationBookingType, setActivePin, activePin }) {
    // const [priceTag, setPriceTag] = useState(activePin === property._id);
    const priceTag = !(activePin === property._id) ? true : false
    const history = useHistory()
    const query = useQuery()
    const value = property.hasOwnProperty('rating') ? property['rating'] : 0
    const rating = new Array(Math.ceil(value)).fill(-1)

    function priceCalculator() {
        if (property.rentalType === ENTIREPLACE) {
            if (property.bookingType === COLD || (property.bookingType === FLEXIBLE && searchBookingType === COLD)) {
                return {
                    str: `${property.perMonthPrice}/ month`,
                    price: property.perMonthPrice,
                    suffix: 'Per month',
                }
            } else if (property.bookingType === HOT || (property.bookingType === FLEXIBLE && searchBookingType === HOT)) {
                return {
                    str: `${property.perNightPrice}/ night`,
                    price: property.perNightPrice,
                    suffix: 'Per night',
                }
            }
        } else {
            const calc = calculateRoomAvgPrice(
                property?.sleepingArrangements || [],
                searchBookingType,
                property.bookingType,
                property.rentalType,
                property
            )
            return calc
        }
    }

    function handleNavigation() {
        const newSearchParam = new URLSearchParams(history.location.search)

        if (!query.get('bookingType') && !navigationBookingType) {
            if (property.bookingType === COLD || property.bookingType === FLEXIBLE) {
                newSearchParam.set('bookingType', COLD)
            } else {
                newSearchParam.set('bookingType', HOT)
            }
        }

        if (navigationBookingType) {
            newSearchParam.set('bookingType', navigationBookingType)
        }

        if (query.get('checkIn')) {
            newSearchParam.set('checkIn', new Date(query.get('checkIn') || new Date()).toISOString().split('T')[0])
        } else {
            newSearchParam.set('checkIn', new Date().toISOString().split('T')[0])
        }

        if (query.get('checkOut')) {
            newSearchParam.set('checkOut', new Date(query.get('checkOut') || new Date()).toISOString().split('T')[0])
        } else {
            newSearchParam.set('checkOut', getTommorow())
        }

        newSearchParam.set('distance', property.distance)
        newSearchParam.set('price', priceCalculator().price)
        newSearchParam.set('suffix', priceCalculator().suffix)

        history.push({
            pathname: `/property-details/${property._id}`,
            search: newSearchParam.toString(),
        })
    }

    const togglePriceTag = () => {
        // setPriceTag((e) => !e);
        setActivePin((e) => (e == property._id ? '' : property._id))
    }

    return (
        <div className="relative ">
            <h1
                onClick={togglePriceTag}
                className={`p-2 shadow-md absolute top-0 left-0 text-white cursor-pointer bg-primary-blue w-max font-medium rounded-full px-4 text-xs pointer-events-auto ${
                    !priceTag ? 'invisible' : 'visible'
                }`}
            >
                ${priceCalculator().str}
            </h1>
            <div className={`absolute ${priceTag ? 'pointer-events-none' : 'z-[1000]'}`}>
                <div
                    onClick={togglePriceTag}
                    className={`h-6 w-6 cursor-pointer my-[2px]  rounded-full text-gray-600 bg-white flex-center ${
                        priceTag ? 'invisible pointer-events-none' : 'visible'
                    }`}
                >
                    <CloseIcon size={18} />
                </div>
                <div className={`flex w-[260px]  p-2 space-x-2 bg-white rounded-xl ${priceTag ? 'invisible pointer-events-none' : 'visible'}`}>
                    <div onClick={handleNavigation} className="h-18  w-32 cursor-pointer  relative flex-shrink-0">
                        <img src={processMediaUrl(property.images[0])} alt="pin" className="absolute w-full h-full object-cover rounded-xl " />
                    </div>

                    <div className=" z-0 w-7/12 overflow-hidden">
                        <h4 className="text-xs font-medium  line-clamp-2 mb-2">{property.listingName}</h4>
                        <div>
                            <span className="flex items-center justify-start text-yellow-500">
                                {rating.length
                                    ? rating.map((e, ind) => (
                                          <img
                                              key={ind}
                                              src="/assets/resources/star.svg"
                                              alt="review star"
                                              className={`w-3 mr-1 ${ind !== property.rating - 1 ? ' mr-1 ' : ''}`}
                                          />
                                      ))
                                    : ''}
                            </span>
                            <p className="text-xs font-medium text-green-600">{property.totalReview || '0'} Reviews</p>
                        </div>
                        <p className="text-xs font-semibold  text-gray-700">${priceCalculator().str}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

function Marker({ lat, lng, property, mapRef, id, setActivePin, activePin }) {
    const query = useQuery()
    const infoRef = useRef()
    let searchBookingType = query.get('bookingType')

    function priceCalculator() {
        if (property.rentalType === ENTIREPLACE) {
            if (property.bookingType === COLD || (property.bookingType === FLEXIBLE && searchBookingType === COLD)) {
                return {
                    str: `${property.perMonthPrice}/ month`,
                    price: property.perMonthPrice,
                    suffix: 'Per month',
                }
            } else if (property.bookingType === HOT || (property.bookingType === FLEXIBLE && searchBookingType === HOT)) {
                return {
                    str: `${property.perNightPrice}/ night`,
                    price: property.perNightPrice,
                    suffix: 'Per night',
                }
            }
        } else {
            const calc = calculateRoomAvgPrice(
                property?.sleepingArrangements || [],
                searchBookingType,
                property.bookingType,
                property.rentalType,
                property
            )
            return calc
        }
    }
    if (!property) return null
    return (
        <InfoBox
            ref={infoRef}
            options={{
                closeBoxURL: '',
                enableEventPropagation: true,
                className: 'overflow-visible',
            }}
            position={{ lat, lng }}
        >
            <PropertyPin setActivePin={setActivePin} activePin={activePin} property={property} searchBookingType={searchBookingType} />
        </InfoBox>
    )
    // return (
    //   <GoogleMarker
    //     // icon={"/assets/resources/map-pin.png"}
    //     label={priceCalculator().str}
    //     // label={new google.maps.Marker({ text: "hi" })}
    //     clickable
    //     onClick={() => infoRef.current.open(mapRef.current)}
    //     position={{ lat, lng }}
    //   >
    //     <InfoBox
    //       ref={infoRef}
    //       options={{ closeBoxURL: "", enableEventPropagation: true }}
    //       position={{ lat, lng }}
    //     >
    //       <PropertyPin
    //         property={property}
    //         searchBookingType={searchBookingType}
    //       />
    //     </InfoBox>
    //     {/* <InfoBox
    //       ref={infoRef}
    //       onLoad={(el) => {
    //         infoRef.current = el;

    //         // el.close();
    //       }}
    //       visible
    //       options={{ closeBoxURL: "", enableEventPropagation: true }}
    //       position={{ lat, lng }}
    //     >
    //       <PropertyPin
    //         property={property}
    //         searchBookingType={searchBookingType}
    //       />
    //     </InfoBox> */}
    //   </GoogleMarker>
    // );
}

function PinListing({ propertyList, mapRef }) {
    const [activePin, setActivePin] = useState(0)
    return (
        <>
            {propertyList.map((el) => (
                <Marker
                    setActivePin={setActivePin}
                    activePin={activePin}
                    key={el._id}
                    mapRef={mapRef}
                    lat={el.location[1]}
                    lng={el.location[0]}
                    property={el}
                />
            ))}
        </>
    )
}

function getUSACoordinates(lat, lng) {
    // Bounding box for the contiguous United States
    const bounds = {
        north: 49.3457868, // Northernmost point
        south: 24.396308, // Southernmost point
        west: -125.0, // Westernmost point
        east: -66.93457, // Easternmost point
    }

    // Default coordinates for the center of the contiguous United States
    const defaultCoordinates = {
        lat: 39.8283,
        lng: -98.5795,
    }

    // Check if the given coordinates are within the bounds
    const isWithinBounds = lat >= bounds.south && lat <= bounds.north && lng >= bounds.west && lng <= bounds.east

    // Return the given coordinates if within bounds, else return default coordinates
    return isWithinBounds ? [lat, lng] : [defaultCoordinates.lat, defaultCoordinates.lng]
}

function PropertyListingMap({ propertyList = [], mapkey, filterOptions, lat, lng }) {
    // const latlng = calculateMapcenter(propertyList);
    const [latlng, setlatLng] = useState([parseFloat(39.8283), parseFloat(-98.5795)])

    const mapRef = useRef()

    const calculateMapcenterCallback = useCallback(
        (propertyList) => {
            return calculateMapcenter(propertyList)
        },
        [propertyList]
    )

    useEffect(() => {
        const propertyCenter = calculateMapcenterCallback(propertyList)

        setlatLng(propertyCenter)

        if (mapRef.current) {
            fitBounds(mapRef.current)
        }
    }, [filterOptions, propertyList, lat, lng])

    const getZoom = () => {
        const radius = filterOptions['radiusToAirport'][1]
        const z = Math.log2((38000 * Math.cos((latlng[0] * Math.PI) / 180)) / radius)
        const s = parseInt(Math.round(z)) || 10
        return s
    }

    const throtteledGetZoom = useCallback(throttle(getZoom, 500), [])

    const fitBounds = (map) => {
        const bounds = new window.google.maps.LatLngBounds()
        propertyList.map((el) => {
            const latlng = {
                lat: el.location[1],
                lng: el.location[0],
            }
            bounds.extend(new window.google.maps.LatLng(latlng.lat, latlng.lng))
            return el._id
        })
        map.fitBounds(bounds)
    }

    return (
        <div className="h-full ">
            <LoadScript googleMapsApiKey={constant.googleAPI_KEY}>
                <GoogleMap
                    options={{
                        maxZoom: 14,
                    }}
                    key={mapkey}
                    center={{
                        lat: latlng[0],
                        lng: latlng[1],
                    }}
                    zoom={throtteledGetZoom()}
                    onLoad={(el) => {
                        mapRef.current = el
                        fitBounds(el)
                    }}
                    mapContainerStyle={containerStyle}
                >
                    <PinListing mapRef={mapRef} propertyList={propertyList} />
                </GoogleMap>
            </LoadScript>
        </div>
    )
}

export default PropertyListingMap
