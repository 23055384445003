import React, { useContext, useEffect, useState } from "react";
import ProductCard from "@components/RoomCard";
import ReactPaginate from "react-paginate";
import { AiFillHeart } from "react-icons/ai";
import Navbar from "@dashboard/Navbar";
import { getFavouriteProperties } from "@services/property";
import { Context as AuthContext } from "@context/authContext";
import { toast } from "react-toastify";
import { getCoords } from "@utils/utility";

function Favourites({ setKey, setUiRefreshKey, ...props }) {
  const { user } = useContext(AuthContext);
  const [properties, setProperties] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [requesting, setRequesting] = useState(true);
  const [refreshKey, setRefreshKey] = useState(0);
  const [fetchingLocation, setFetchingLocation] = useState(true);
  const [location, setLocation] = useState([]);
  const handlePageChange = (e) => {
    setPage(e.selected + 1);
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        setFetchingLocation(true);
        const pos = await getCoords();
        const coords = {
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
        };
        setLocation([coords.lat, coords.lng]);
      } catch (error) {
      } finally {
        setFetchingLocation(false);
      }
    };
    fetch();
  }, [refreshKey]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setUiRefreshKey((e) => e + 1);

    const fetchProperties = async () => {
      try {
        setRequesting(true);
        const res = await getFavouriteProperties({
          page: page,
          limit: 6,
          coords: { lat: location[0] || "", lng: location[1] || "" },
        });
        if (res.data.status) {
          setRequesting(false);
          setProperties(res.data.data.docs);
          setTotalPage(res.data.data.pages);
          if (page > res.data.data.pages) {
            setPage(res.data.data.pages);
          }
        } else {
          toast.error(res.data.message);
        }
      } catch (error) {
        setRequesting(false);
        toast.error("Something went wrong!");
      }
    };
    if (!fetchingLocation) {
      fetchProperties();
    }
  }, [page, fetchingLocation]);
  return (
    <div>
      <Navbar
        user={user.name}
        title={
          <>
            Favorite Properties{" "}
            {/* <AiFillHeart size="30" className="text-red-500 ml-2" /> */}
          </>
        }
      />
      <div className="mx-auto ">
        {requesting && (
          <div className="bg-gray-100 flex-center mt-4 h-screen w-full">
            <div className="bg-white rounded shadow-md p-4">
              <div className="loader"></div>
            </div>
          </div>
        )}
        {/* <div className="px-2 sm:px-6 mx-auto flex items-center flex-wrap justify-center my-10"> */}
        <div className="flex flex-wrap justify-center md:grid lg:grid-cols-2 xl:grid-cols-3 md:w-max mx-auto my-10">
          {!requesting &&
            properties.map((curr) => (
              <ProductCard
                setRefreshKey={setRefreshKey}
                key={curr.id}
                className="w-full sm:w-[300px] m-4 flex-shrink-0"
                property={{ ...curr.propertyId }}
              />
            ))}
          {!requesting && properties.length === 0 && (
            <div className="grid place-content-center col-span-full text-center text-2xl font-medium min-h-full">
              No Favorite Property
            </div>
          )}
        </div>
      </div>
      <div className="w-8/12 mx-auto">
        {Boolean(properties.length) && (
          <ReactPaginate
            nextClassName="bg-primary-blue h-10 w-10 rounded-full flex-center"
            disabledClassName="pagination-dissabled"
            nextLabel={
              <img
                alt=""
                src="/assets/resources/arrow-left.png"
                className="w-4 transform rotate-180"
              />
            }
            activeClassName="pagination-active"
            previousClassName="bg-primary-blue h-10 w-10 rounded-full flex-center"
            previousLabel={
              <img
                alt=""
                src="/assets/resources/arrow-left.png"
                className="w-4 "
              />
            }
            onPageChange={handlePageChange}
            pageRangeDisplayed={3}
            pageCount={totalPage}
            containerClassName="flex justify-center items-center flex-shrink-0 "
            pageClassName="text-white rounded-full pagination-btn mx-2"
            pageLinkClassName="flex-center text-xs h-10 w-10 "
          />
        )}
      </div>
    </div>
  );
}

export default Favourites;
