import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useQuery from '@hooks/useQuery'
import { IoIosClose as CloseIcon } from 'react-icons/io'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
// import { GoogleLogin } from 'react-google-login'
import { useGoogleLogin } from '@react-oauth/google'
import Header from '@front/Header'
import Footer from '@front/Footer'
import Section from '@layout/Section'
import Modal from '@components/Modal'
import TextInput from '@ui/TextInput'
import SelectInputText from '@ui/Select'
import Button from '@ui/Button'
// import AuthModal from '@layout/AuthModal';
import { Link } from 'react-router-dom'
import { BiLock } from 'react-icons/bi'
import { FcGoogle } from 'react-icons/fc'
import { BsFacebook } from 'react-icons/bs'
import { AiOutlineMail } from 'react-icons/ai'
import { AiOutlineUserAdd } from 'react-icons/ai'
import 'react-phone-input-2/lib/style.css'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { setToken } from '@helper/header'
import { login, switchUserType } from '@services/user'
import { constant } from '../_config/constant'
import axios from 'axios'
import { AppConfig } from '../Config'

const CREWMEMBER = 'Crewmember'
const HOST = 'Host'

function SwitchUserAccount({
    userType = HOST,
    user = {
        firstName: '',
        lastName: '',
        personalEmail: '',
        dob: null,
        crewType: 'Host',
        countryCode: null,
        id: '',
        phone: '',
    },
}) {
    const history = useHistory()
    const [crewType, setCrewType] = useState('')
    const [step, setstep] = useState(0)
    const [isRequestion, setRequesting] = useState(false)

    async function handleSwitchAccount(e) {
        try {
            e.preventDefault()
            let res = null
            setRequesting(true)

            if (userType === CREWMEMBER) {
                res = await switchUserType({
                    userId: user.id,
                    crewType: '',
                    type: HOST,
                })
            } else {
                res = await switchUserType({
                    userId: user.id,
                    crewType: crewType,
                    type: CREWMEMBER,
                })
            }

            let token = res.data.data.token
            if (res.data.status) {
                toast.success(res.data.message, { toastId: 6 })
            } else toast.error(res.data.message, { toastId: 7 })

            if (userType === CREWMEMBER) {
                return window.location.replace(`${constant.HOST_WEB_URL}?token=${token}&data=${JSON.stringify(res.data.data.user)}`)
            } else {
                setToken(token)
                history.push('/dashboard')
            }
        } catch (error) {
            toast.error('Something went wrong')
        } finally {
            setRequesting(false)
        }
    }
    return (
        <>
            {step === 0 && (
                <>
                    <p className="mx-auto my-4 text-center">{user.message}</p>
                    <Button
                        className="block mx-auto my-4 md:col-span-2"
                        btnType="filled"
                        onClick={userType === HOST ? () => setstep((e) => e + 1) : handleSwitchAccount}
                    >
                        {isRequestion ? 'Please wait ...' : 'Proceed'}
                    </Button>
                </>
            )}
            {step === 1 && (
                <form onSubmit={handleSwitchAccount} className="">
                    {userType === CREWMEMBER ? (
                        <TextInput label="Crew Type" value={user.crewType} disabled leftIcon={<AiOutlineUserAdd size={24} />} />
                    ) : (
                        <div className="my-6">
                            <label className="text-gray-600 text-sm mb-2 block">Crew Type</label>
                            <div className={`flex items-center ring-1 ring-gray-200 text-gray-600 rounded w-full bg-gray-100`}>
                                <select
                                    placeholder="Crew Type"
                                    onChange={(e) => setCrewType(e.target.value)}
                                    className="p-2 flex-grow text-sm  outline-none bg-transparent ring-0 border-0 focus:ring-0 focus:border-0"
                                >
                                    <option value={''} disabled>
                                        Crew Type
                                    </option>
                                    {[
                                        { key: 'Pilot', value: 'Pilot' },
                                        { key: 'Flight Attendent', value: 'Flight Attendant' },
                                        {
                                            key: 'Other Airline Professional',
                                            value: 'Other Airline Professional',
                                        },
                                    ].map((e) => (
                                        <option value={e.value}>{e.key}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    )}

                    <Button type="submit" className="block mx-auto my-4 md:col-span-2" btnType="filled" disabled={isRequestion}>
                        {isRequestion ? 'Please wait ...' : 'Proceed'}
                    </Button>
                </form>
            )}
        </>
    )
}

function Login() {
    const history = useHistory()
    const query = useQuery()
    const [userData, setUserData] = useState({})
    const [switchUserModal, setSwitchUserModal] = useState(false)
    const [notificationModal, setNotificationModal] = useState(false)
    const [email, setEmail] = useState('')
    const [memberType, setMemberType] = useState('Crewmember')
    const [password, setPassword] = useState('')
    const [isRequestion, setRequesting] = useState(false)
    const [optionList, setOptionList] = useState(false)

    useLayoutEffect(() => {
        if (localStorage.getItem(constant.TOKEN_KEY)) {
            history.push('/')
            history.push('/dashboard/home')
        }
    }, [])

    // Temp fix to hard reaload the app
    useEffect(() => {
        const checkTempRef = localStorage.getItem(constant.TEMP_REF)
        if (checkTempRef === 'false' || checkTempRef == null) {
            setTimeout(() => {
                localStorage.setItem(constant.TEMP_REF, 'true')
                window.location.reload()
            }, 1000)
        }
    }, [])

    const handleSocialLogin = async (e) => {
        try {
            setRequesting(true)
            const { data, ...res } = await login({
                emailId: e.email,
                type: memberType,
                socialId: e.id,
                loginType: e.authType,
            })

            if (data.statusCode === '201') {
                setSwitchUserModal(true)
                setUserData({ ...data.data, message: data.message })
                return
            }
            //chage guest type code here----
            setRequesting(false)

            if (data.status) {
                if (data.message !== 'User Logged-in Successful!') {
                    toast.success(data.message, { toastId: 8 })
                }

                if (memberType === 'Host') {
                    window.location.replace(`${constant.HOST_WEB_URL}?token=${data.data.token}&data=${JSON.stringify(data.data.user)}`)
                } else {
                    // setNotificationModal(true);
                    // setToken(data.data.token);
                    // const redirect = query.get("redirect");
                    // if (redirect) {
                    //   window.location.replace(redirect);
                    //   return;
                    // }
                    setToken(data.data.token)
                    const redirect = query.get('redirect')
                    if (redirect) {
                        window.location.replace(redirect)
                        return
                    }
                    // setData(data.data.user);
                    // setNotificationModal(true);
                    history.push('/dashboard')
                }
            } else {
                toast.error(data.message)
            }
        } catch (error) {
            setRequesting(false)
            toast.error('Something went wrong!')
        }
    }

    const handleLogin = async (e) => {
        try {
            e.preventDefault()
            if (!email || !password) return
            if (!memberType) {
                toast.info('Please select crew type')
                return
            }
            setRequesting(true)
            const { data, ...res } = await login({
                emailId: email,
                password,
                type: memberType,
                loginType: 'Normal',
            })

            if (data.statusCode === '201') {
                setSwitchUserModal(true)
                setUserData({ ...data.data, message: data.message })
                return
            }
            //chage guest type code here----
            setRequesting(false)

            if (data.status) {
                if (data.message !== 'User Logged-in Successful!') {
                    toast.success(data.message, { toastId: 9 })
                }
                if (memberType === 'Host') {
                    console.log('Redirecting')
                    window.location.href = `${constant.HOST_WEB_URL}?token=${data.data.token}&data=${JSON.stringify(data.data.user)}`
                } else {
                    setToken(data.data.token)
                    const redirect = query.get('redirect')
                    if (redirect) {
                        window.location.replace(redirect)
                        return
                    }
                    // setData(data.data.user);
                    // setNotificationModal(true);
                    history.push('/dashboard')
                }
            } else {
                toast.error(data.message)
            }
        } catch (error) {}
    }

    const authenticateUsingGoogle = useGoogleLogin({
        onSuccess: async ({ access_token }) => {
            const { data } = await axios.get(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${access_token}`)
            handleSocialLogin({
                email: data.email,
                firstName: data.givenName,
                lastName: data.familyName,
                id: data.sub,
                authType: 'Google',
            })
        },
        onError: (err) => {
            toast.error(err)
        },
        onNonOAuthError: (err) => {
            toast.error(err)
        },
    })

    return (
        <>
            <Header type="fixed" />
            <ToastContainer />
            {switchUserModal && (
                <Modal
                    title="‎"
                    onClose={() => {
                        setSwitchUserModal((e) => !e)
                        setRequesting(false)
                    }}
                    className="bg-white rounded p-2 md:p-4 w-11/12 md:w-6/12 mx-auto lg:w-4/12"
                >
                    {<SwitchUserAccount user={userData} userType={userData.type} />}
                </Modal>
            )}
            {notificationModal && (
                <Modal className="bg-white rounded p-4 w-10/12 mx-auto lg:w-4/12">
                    <div className="flex justify-between mb-2 items-center">
                        <h4 className="text-sm font-medium text-gray-600"> Welcome to Crewmates!</h4>
                        <span onClick={() => setNotificationModal(false)} className="h-10 w-10 rounded-full bg-gray-100 flex-center">
                            <CloseIcon className="text-3xl text-gray-500 cursor-pointer" />
                        </span>
                    </div>
                    <div className="text-sm text-gray-600 ">
                        We are currently in the process of onboarding hosts. Join our Crewmates Crashpads Facebook Group for further assistance
                        finding accommodations.
                        <Button btnType="filled" className="mt-4 mx-auto block">
                            <a href="https://www.facebook.com/groups/crewmatescrashpads/">Search for Crashpads</a>
                        </Button>
                    </div>
                </Modal>
            )}
            <Section className="mt-10 px-4">
                <div className="mt-8 w-full lg:w-10/12 xl:w-5/12 mx-auto">
                    <h4 className="mb-4 text-center text-primary-blue text-3xl font-bold ">Login</h4>
                    <div className="grid grid-cols-1 md:grid-cols-2  my-4 w-full">
                        <p className="hidden text-sm sm:flex items-center">Are you a Host or Crewmember?</p>
                        <SelectInputText
                            defaultValue="Crewmember"
                            label={<p className="block sm:hidden text-sm">Are you a Host or Crewmember?</p>}
                            value={memberType}
                            setValue={(text) => {
                                if (text === 'Host') {
                                    setMemberType(text)
                                    window.location.replace(AppConfig.HOST_DOMAIN + 'login')
                                } else {
                                    setMemberType(text)
                                }
                            }}
                            listId="crew-type"
                            leftIcon={null}
                            datalist={[
                                { key: 'Crewmember', value: 'Crewmember' },
                                { key: 'Host', value: 'Host' },
                            ]}
                            setOptionList={setOptionList}
                            optionList={optionList}
                        />
                    </div>
                    <form onSubmit={handleLogin} className="grid grid-cols-1 gap-y-4">
                        <TextInput
                            label="Email"
                            type="email"
                            field="email"
                            required
                            placeholder="Johndoe@gmail.com"
                            value={email}
                            setValue={setEmail}
                            leftIcon={<AiOutlineMail size={24} />}
                        />

                        <TextInput
                            label="Password"
                            type="password"
                            field="password"
                            required
                            placeholder="********"
                            value={password}
                            setValue={setPassword}
                            leftIcon={<BiLock size={24} />}
                        />
                        <div className="flex items-center justify-between">
                            <span className="flex text-sm items-center justify-start">
                                <input id="remember-me" type="checkbox" className="mr-2 text-primary-blue" />
                                <label htmlFor="remember-me">Remember me</label>
                            </span>
                            <Link to="/forget-password" className="text-primary-blue text-sm">
                                Forgot Your Password?
                            </Link>
                        </div>
                        <Button type="submit" className="block mx-auto my-4" btnType="filled" disabled={isRequestion}>
                            {isRequestion ? 'Please wait ...' : 'Sign In'}
                        </Button>
                    </form>

                    <p className="text-base text-center my-4 ">
                        <Link to="/register" className="text-primary-blue  mr-2">
                            Don't have an account? <span className="underline">Register</span> here.
                        </Link>
                    </p>

                    <div className="flex justify-center items-center my-2">
                        <div className="cursor-pointer">
                            <FcGoogle onClick={() => authenticateUsingGoogle()} size={40} className="cursor-pointer mx-2 text-gray-300" />
                        </div>

                        {/* <GoogleLogin
                            clientId={constant.GOOGLE_CLIENTID}
                            onSuccess={(e) => {
                                if (e.profileObj.email) {
                                    handleSocialLogin({
                                        email: e.profileObj.email,
                                        firstName: e.profileObj.givenName,
                                        lastName: e.profileObj.familyName,
                                        id: e.profileObj.googleId,
                                        authType: 'Google',
                                    })
                                } else toast.error('Something went wrong!')
                            }}
                            onFailure={(e) => {
                                console.log(e)
                                // toast.error('Something went wrong while fetching your google account.')
                            }}
                            render={(renderProps) => (
                            )}
                        /> */}

                        <FacebookLogin
                            appId={constant.FACEBOOK_APPID}
                            callback={(e) => {
                                console.log('0000000000000', e)
                                if (e.email) {
                                    handleSocialLogin({
                                        email: e.email,
                                        firstName: e.name.split(' ')[0],
                                        lastName: e.name.split(' ')[1],
                                        authType: 'Facebook',
                                        id: e.id,
                                    })
                                } else {
                                    toast.error('Your facebook id is not linked with any email, please add email id and try again.')
                                }
                            }}
                            fields="name,email,picture,gender,birthday"
                            render={(renderProps) => (
                                <BsFacebook onClick={renderProps.onClick} size={36} className="cursor-pointer mx-2 text-blue-500" />
                            )}
                        />
                        {/* <AiFillApple size={46} className="mx-2 cursor-pointer" /> */}
                    </div>
                    {/* <p className="text-sm text-center text-gray-500 my-2">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry
          </p> */}
                    {/* <p className="text-sm text-center text-gray-500">
            &copy; 2021 crewmates. All rights reserved
          </p> */}
                </div>
            </Section>

            <Footer />
        </>
    )
}

export default Login
