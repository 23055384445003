import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import DashboardWrapper from "@layout/DashboardWrapper";
import Notification from "./Notification";
import Favourites from "./Favourites";
import Setting from "./setting";
import MyBookings from "./MyBookings";
import Home from "./Home";
import Profile from "./Profile";
import Messages from "./messages/index";
import Report from "./Report";
import { ToastContainer } from "react-toastify";

function Routes(props) {
  return (
    <>
      <ToastContainer />
      <Switch>
        <Route path="/dashboard/home" component={Home} />
        <Route path="/dashboard/profile" component={Profile} />
        <Route path="/dashboard/notification" component={Notification} />
        <Route
          path="/dashboard/favourites"
          render={() => {
            return <Favourites setUiRefreshKey={props.setKey} />;
          }}
        />
        <Route path="/dashboard/setting" component={Setting} />
        {/* <Route path="/dashboard/report" component={Report} /> */}
        <Route path="/dashboard/my-booking" component={MyBookings} />
        <Route path="/dashboard/messages" component={Messages} />
        <Redirect from="/dashboard" to="/dashboard/home" />
      </Switch>
    </>
  );
}

function Dashboard() {
  return (
    <DashboardWrapper>
      <Routes />
    </DashboardWrapper>
  );
}

export default Dashboard;
