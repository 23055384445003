import React from 'react'
import { createPortal } from 'react-dom'

export default function Modal({ title, onClose, children, className, ...props }) {
    return createPortal(
        <div className="z-high fixed top-0 bottom-0 left-0 bg-gray-600 bg-opacity-50 right-0 flex-center">
            <div
                className={`w-full mx-4 md:max-h-4/6 md:w-8/12 lg:w-4/12 bg-white overflow-y-auto scrollbar scrollbar-hide rounded ${className}`}
                {...props}
            >
                <div className="py-3 flex justify-center items-center bg-primary-blue text-white">
                    <p className="text-sm">{'Crewmates Verification Process'}</p>
                </div>
                <div className="">{children}</div>
            </div>
        </div>,
        document.getElementById('portal')
    )
}
