import React from 'react'
import { createPortal } from 'react-dom'
import { IoIosClose as CloseIcon } from 'react-icons/io'

export default function Modal({ title, onClose, children, className, ...props }) {
    return createPortal(
        <div className="z-high fixed top-0 bottom-0 left-0 bg-gray-600 bg-opacity-50 right-0 flex-center ">
            {title && (
                <div
                    className={`px-5 w-full mx-4 md:max-h-4/6 md:w-8/12 lg:w-4/12 bg-white overflow-y-auto scrollbar scrollbar-hide rounded ${className}`}
                    {...props}
                >
                    <div className=" py-3  flex justify-between items-center">
                        {title && <h4 className="text-xl text-gray-600">{title}</h4>}
                        {onClose && <span onClick={onClose} className="h-10 w-10 rounded-full bg-gray-100 flex-center"><CloseIcon className="text-3xl text-gray-500 cursor-pointer" /></span>}
                    </div>
                    <div className="">{children}</div>
                </div>
            )}
            {!title && (
                <div className={`flex-shrink-0 ${className}`} {...props}>
                    {children}
                </div>
            )}
        </div>,
        document.getElementById('portal')
    )
}
