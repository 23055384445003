import React, { useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { FiCheck } from 'react-icons/fi'

const SocialCard = ({ icon, label, verified, Popup, handleResendOtp, verificationType }) => {
    const [popup, setpopup] = useState(false)
    return (
        <div className="shadow-md flex-center p-10 flex-col">
            <span className="h-12 w-12 flex-center ring-1 ring-primary-blue rounded-full p-3 ">
                <img src={icon} alt="" />
            </span>
            <p className="text-xs text-center my-4">{label}</p>
            {popup && <Popup onClose={() => setpopup(false)} popup={popup} />}
            {!verified && (
                <>
                    <button className="flex items-center ring-1 ring-red-600 justify-start mb-2 rounded-full bg-white text-red-600 text-xs pr-2 ">
                        <span className="ring-1 ring-red-600 bg-red-600 h-6 w-6 mr-2 rounded-full  flex-center">
                            <AiOutlinePlus className="text-white transform rotate-45" size={22} />
                        </span>
                        Not Verified
                    </button>

                    <button
                        onClick={() => {
                            setpopup(!popup)
                            handleResendOtp(verificationType)
                        }}
                        className="p-2 mb-2 rounded-full bg-primary-blue text-white text-xs px-4   "
                    >
                        Verify Now
                    </button>
                </>
            )}
            {verified && (
                <button className="flex items-center ring-1 ring-green-600 justify-start mb-2 rounded-full bg-white text-green-600 text-xs pr-2 ">
                    <span className="ring-1 ring-green-600 bg-green-600 h-6 w-6 mr-2 rounded-full  flex-center">
                        <FiCheck size={18} className="text-white" />
                    </span>
                    Verified
                </button>
            )}
        </div>
    )
}

export default SocialCard
