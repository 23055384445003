import React, { useCallback, useEffect, useState } from 'react'
import Header from '@front/Header'
import Footer from '@front/Footer'
import { ToastContainer, toast } from 'react-toastify'
import PinPrefix from '@assets/pin-prefix.svg'
import CalenderPrefix from '@assets/calender-prefix.svg'
import landingVideo from '@assets/crewmates.mp4'
import HomeIcon from '@assets/home-color.svg'
import EasyBookPinsIcon from '@assets/easy-book.svg'
import airPlaneIcon from '../assets/crewmates-airplane-logo.svg'
import CheckPointIcon from '@assets/checkpoint.svg'
import HostBannner from '@assets/host_banners_landing_page.svg'
import StarRatings from 'react-star-ratings'
import useWindowDimensions from '@hooks/useWindowDimension'
import { HiOutlineChevronLeft } from 'react-icons/hi'
import booking1 from '@assets/booking1-image.svg'
import { BsHeart, BsHeartFill } from 'react-icons/bs'
import Section from '@layout/Section'
import { Link, NavLink } from 'react-router-dom'
import { featuredProperties, makePropertyFavourite } from '../_services/property'
import { getAirportList, getIataInfo } from '@services/airport'
import moment from 'moment'
import { throttle } from 'lodash'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import HomeRoomCard from '../components/HomeRoomCard'
import bookingIcon from '../assets/booking-type-icon.svg'
import { constant } from '../_config/constant'

function LandingPage() {
    const [iataCode, setIataCode] = useState('')
    const history = useHistory()
    const [airportlist, setAirportList] = useState([])
    const bookingTypeList = ['Cold bed', 'Hot bed']
    const [propertyOption, setPropertyOption] = useState(false)
    const [bookingType, setBookingType] = useState('Cold bed')
    const [refreshKey, setRefreshKey] = useState(0)
    const [airportDetails, setAirportDetails] = useState({
        name: '',
        iata: '',
    })
    const [iataRefreshKey, setIataRefreshKey] = useState(0)
    const [featuredPropertiesList, setfeaturedPropertiesList] = useState([])
    useEffect(() => {
        featuredProperties().then((res) => {
            console.log(res, 'featred')
            setfeaturedPropertiesList(res.data.data)
        })
    }, [refreshKey])

    const fetchAirport = async (term) => {
        try {
            setAirportList([])
            getAirportList(
                term,
                (data) => {
                    setAirportList(data.airports)
                },
                (data) => {
                    setAirportList([])
                    setAirportDetails({ name: '' })
                }
            )
        } catch (error) {}
    }
    const throtteledFetchAirport = useCallback(throttle(fetchAirport, 500), [])

    const handleFavouriteProperty = async (property) => {
        try {
            const res = await makePropertyFavourite(property._id ? property._id : property.id)
            if (res.data.status) {
                setRefreshKey(refreshKey + 1)
            } else toast.error(res.data.message, { toastId: 97 })
        } catch (error) {
            if (!error.response.data.status) {
                toast.error(
                    <>
                        Please{' '}
                        <Link to="/register" className="text-primary-blue ">
                            {' '}
                            register
                        </Link>{' '}
                        or{' '}
                        <Link to="/login" className="text-primary-blue ">
                            log in{' '}
                        </Link>{' '}
                        to add properties to your favorites list.
                    </>
                )
            }
        }
    }

    // Temp fix to hard reaload the app
    useEffect(() => {
        const checkTempRef = localStorage.getItem(constant.TEMP_REF)
        if (checkTempRef === 'false' || checkTempRef == null) {
            setTimeout(() => {
                localStorage.setItem(constant.TEMP_REF, 'true')
                window.location.reload()
            }, 1000)
        }
    }, [])

    return (
        <div>
            <Header />
            {/* <Section className="px-4 mt-28"> */}
            <ToastContainer />
            <div className="md:mt-16 mt-12">
                {/* hero section */}
                <div className="md:h-[500px] h-[390px] bg-bottom md:bg-center flex items-center flex-col justify-center bg-home-banner image-cover bg-no-repeat">
                    <div className="mx-[24px] mt-[30px] mb-[36px] xl:w-3/5">
                        <div className="flex justify-center items-center flex-col">
                            <h1 className="font-bold text-center 2xl:text-left text-[1.625rem] leading-10 md:text-4xl lg:text-6xl text-white">
                                Safe Nights, Safe Flights
                            </h1>
                            <h4 className="font-normal text-[0.938rem] leading-4 px-8 text-center text-white mt-[0.9rem] md:mt-5">
                                Rent crashpads from local hosts nationwide
                            </h4>
                        </div>
                        <div className="md:h-24 h-auto bg-[#F7F7F8] flex-col md:flex-row rounded-xl md:rounded-full flex items-center justify-between p-[12px] lg:p-5 mt-10 gap-[12px] md:gap-5  lg:gap-10 ">
                            <div className="flex items-center w-full relative  lg:pl-3">
                                <img src={airPlaneIcon} alt={'pin prefix'} className="absolute pl-2 " />
                                <input
                                    className="bg-white rounded-md w-full placeholder-cahnge md:h-16 h-9 md:p-5 py-2.5 border-none pl-10 md:pl-10"
                                    onChange={(e) => {
                                        setIataCode(e.target.value)
                                        throtteledFetchAirport(e.target.value)
                                    }}
                                    value={iataCode}
                                    placeholder="Airport Code"
                                />
                                {Boolean(airportlist.length) && (
                                    <div className="absolute top-16  mt-2 !w-[18rem] md:!w-[24.5rem] z-50 text-black rounded bg-white">
                                        {airportlist.map((curr, ind) => (
                                            <p
                                                key={ind}
                                                onClick={() => {
                                                    setIataCode(curr.iata)
                                                    setIataRefreshKey((e) => e + 1)
                                                    setAirportList([])
                                                    setAirportDetails({ ...curr })
                                                }}
                                                className="p-4 text-xs cursor-pointer hover:bg-opacity-40 hover:bg-gray-900"
                                            >
                                                {curr.iata}, {curr.name}
                                            </p>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="flex items-center w-full relative ">
                                <img src={bookingIcon} alt={'calender prefix'} className="absolute pl-4 " />
                                <div
                                    className="bg-white rounded-md w-full cursor-pointer md:h-16 h-9 md:p-5 py-2.5 border-none flex items-center pl-10 md:pl-10"
                                    onClick={() => {
                                        setPropertyOption(!propertyOption)
                                    }}
                                >
                                    {bookingType}
                                </div>
                                {propertyOption && (
                                    <div className="absolute top-[1.875rem] md:top-[3.625rem] mt-2 md:w-[24.5rem] w-11/12 z-50 text-black rounded bg-white">
                                        {bookingTypeList.map((list, index) => {
                                            return (
                                                <p
                                                    onClick={() => {
                                                        setPropertyOption(!propertyOption)
                                                        setBookingType(list)
                                                    }}
                                                    className="p-4 text-xs capitalize cursor-pointer hover:bg-opacity-40 hover:bg-gray-900"
                                                    value={list}
                                                    key={index}
                                                >
                                                    {list}
                                                </p>
                                            )
                                        })}
                                    </div>
                                )}
                            </div>
                            <div
                                onClick={() => {
                                    history.push(`/property-listing?bookingType=${bookingType}&iata=${iataCode}&airportName=${airportDetails.name}`)
                                }}
                                className="md:h-16 h-12 w-full bg-[#94BA33] text-[0.938rem] leading-5 text-white md:text-xl font-bold flex justify-center items-center rounded-full cursor-pointer"
                            >
                                Search
                            </div>
                        </div>
                    </div>
                </div>
                {/* host */}
                <div className="lg:my-24 my-[3.8rem] mx-4">
                    <div className="text-center">
                        <h2 className="text-black font-semibold text-[1.625rem] leading-[2.25rem] md:text-[2.125rem] md:leading-[2.125rem] px-6">
                            Connect with hosts near you
                        </h2>
                        <h4 className="text-black font-light text-sm mt-2">Find suitable airline crash pads near your domicile</h4>
                    </div>
                    <div className="grid lg:grid-cols-2 grid-cols-1 xl:grid-cols-[654px,476px] gap-10 md:flex-row mt-10 md:my-20 justify-center xl:gap-[3.7rem] 2xl:gap-[7.5rem] lg:mx-[3rem] xl:mx-[10rem] 2xl:mx-[15rem]">
                        <div className=" sm:w-3/5 mx-auto lg:mx-0 lg:w-auto">
                            <video controls controlsList="nodownload" className="rounded-2xl w-full h-auto ">
                                <source src={landingVideo} />
                            </video>
                        </div>
                        <div className=" w-auto text-center lg:text-left">
                            <h2 className="text-black md:text-3xl text-[1.625rem] font-semibold leading-[2.25rem] lg:px-0 px-4">
                                Hosting opens up a world of opportunities
                            </h2>
                            <div className="text-black font-light text-sm mt-5 md:mt-8">
                                Crewmates connects flight attendants and pilots to unique crashpads, at any price point, in states nationwide. With
                                world-class customer service and a growing community of users, Crewmates is the easiest way for people to monetize
                                their extra space and showcase it to a passionate industry of airline employees.
                            </div>
                            <div className="bg-[#94BA33] text-white text-lg font-medium w-36 mx-auto lg:mx-0 rounded-full h-12 flex items-center justify-center mt-5 md:mt-10 cursor-pointer">
                                <Link to="/become-host">Why Host?</Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/* places */}
                <div className="lg:my-24 mt-[4.688rem] mx-4">
                    <div className="text-center">
                        <h2 className="text-black font-semibold text-[1.625rem] leading-[2.25rem] md:text-[2.125rem] md:leading-[2.125rem] px-10">
                            Search Flight Attendant and Pilot Crash Pad Listings
                        </h2>
                        <h4 className="text-black font-light text-sm mt-2">
                            Use our web app’s advanced search feature to find the right space for you.
                        </h4>
                    </div>
                    <HomeRoomCard
                        booking1={booking1}
                        featuredPropertiesList={featuredPropertiesList}
                        handleFavouriteProperty={handleFavouriteProperty}
                    />
                    <div className="flex items-center justify-center mt-10 md:mt-20">
                        <div className="bg-[#94BA33] text-white text-lg font-medium w-44 rounded-full h-12 flex items-center justify-center cursor-pointer">
                            <Link to="/property-listing?bookingType=Hot+bed">View All Listings</Link>
                        </div>
                    </div>
                </div>
                {/* values */}
                <div className="lg:my-24 mt-[4.688rem] mx-4">
                    <div className="flex flex-col justify-center items-center mt-0 text-center md:mt-20 lg:mx-[15%]">
                        <h2 className="text-[#222222] font-semibold text-[1.625rem] leading-[2.25rem] md:text-[2.125rem] mt-4">
                            No More Sleeping in Airports
                        </h2>
                    </div>
                    <div className="xl:grid flex flex-col xl:grid-cols-[208px,187px,208px] gap-10 xl:gap-[5.75rem] md:flex-row md:items-start my-[3.375rem] md:my-20 items-center justify-center lg:mx-[0rem]">
                        <div className="flex justify-between w-[17.375rem] lg:w-52 mx-auto md:mx-0">
                            <div className="flex flex-col items-center justify-center">
                                <img src={HomeIcon} width={`56`} height={`56`} alt="property icon" />
                                <div className="flex flex-col text-center items-center justify-center mt-10 pb-5">
                                    <h3 className="text-[#374151] text-2xl md:text-[1.188rem] md:leading-8 font-semibold">Hassle-Free Booking</h3>
                                    <p className="text-[#4B5563] text-sm font-light mt-2">Simple, flexible reservation options for ease of use</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-between w-[17.375rem] lg:w-[11.688rem] mx-auto md:mx-0">
                            <div className="flex flex-col items-center justify-center">
                                <img src={CheckPointIcon} width={`56`} height={`56`} alt="property icon" />
                                <div className="flex flex-col text-center items-center justify-center mt-10">
                                    <h3 className="text-[#374151] text-2xl md:text-[1.188rem] md:leading-8 font-semibold">Ideal Locations</h3>
                                    <p className="text-[#4B5563] text-sm font-light mt-2">Conveniently located close to airports</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-between w-[17.375rem] lg:w-52 mx-auto md:mx-0">
                            <div className="flex flex-col items-center justify-center">
                                <img src={EasyBookPinsIcon} width={`56`} height={`56`} alt="property icon" />
                                <div className="flex flex-col text-center items-center justify-center mt-10">
                                    <h3 className="text-[#374151] text-2xl md:text-[1.188rem] md:leading-8 font-semibold">Secure Transactions</h3>
                                    <p className="text-[#4B5563] text-sm font-light mt-2">
                                        Confidently book with verified hosts on our secure platform
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* near you */}
                <div className="lg:my-24 my-[4.688rem] mx-4 md:mx-[3rem]">
                    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-[440px,696px] items-center xl:gap-[4.5rem] gap-10 md:flex-row text-center lg:text-left my-10 md:my-20 justify-center  lg:mx-[2rem]">
                        <div className="flex justify-center ">
                            <div className="w-full md:max-w-full">
                                <h2 className="text-[#222222]  text-[1.625rem] leading-[2.25rem]md:text-[2.125rem] md:leading-[2.125rem] font-semibold">
                                    How It Works
                                </h2>
                                <ul className=" flex flex-col text-left mx-5 mt-5 lg:mx-0 gap-2.5">
                                    <li className="text-[#666666] font-bold list-decimal list-outside lg:list-inside text-[0.813rem] gap-[0.875rem] leading-5">
                                        Select your location, dates, and booking type (Cold Bed or Hot Bed)
                                    </li>
                                    <li className="text-[#666666] font-bold list-decimal list-outside lg:list-inside text-[0.813rem] gap-[0.875rem] leading-5">
                                        Securely pay for your rental
                                    </li>
                                    <li className="text-[#666666] font-bold list-decimal list-outside lg:list-inside text-[0.813rem] gap-[0.875rem] leading-5">
                                        Receive a confirmation email with your reservation details when your booking request is approved
                                    </li>
                                </ul>
                                <div className="bg-[#94BA33] mt-8 text-white text-lg font-medium w-[16rem] hidden lg:flex rounded-full h-12  items-center justify-center cursor-pointer">
                                    <Link to="/property-listing?bookingType=Hot+bed">Search Crashpads</Link>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <img src={HostBannner} alt="host banner" className="w-full" />
                        </div>
                        <div className="bg-[#94BA33]  text-white text-lg font-medium w-[16rem] flex lg:hidden rounded-full h-12 mx-auto items-center justify-center cursor-pointer">
                            <Link to="/property-listing?bookingType=Hot+bed">Search Crashpads</Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* </Section> */}
            <Footer />
        </div>
    )
}

export default LandingPage
