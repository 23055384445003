import React from "react";
import { Link } from "react-router-dom";

import { FiChevronRight } from "react-icons/fi";

function BreadCrunb({ bread = [], title, className }) {
  const renderBreads = () => {
    return bread.map((item, i) => {
      return (
        <React.Fragment key={i}>
          <Link to={item.path || "#"}>
            <span className="overflow-hidden truncate ">{item.name}</span>
          </Link>
          {i !== bread.length - 1 ? (
            <FiChevronRight className=" mx-2 flex-shrink-0" />
          ) : null}
        </React.Fragment>
      );
    });
  };

  return (
    <div className={` ${className}`}>
      <h3 className="text-2xl  md:text-3xl text-primary-blue font-extrabold ">
        {title}
      </h3>
      <p className="text-parrot-dark    mt-4 text-sm flex items-center justify-start">
        {renderBreads()}
      </p>
    </div>
  );
}

export default BreadCrunb;
