import React, { useState, useContext } from "react";
import SwitchButton from "@ui/SwitchButton";
import Button from "@ui/Button";
import { Context as AuthContext } from "@context/authContext";
import useDiscloser from "@hooks/useDiscloser";
import { setNotificationStatus } from "@services/user";
import { toast } from "react-toastify";

function Notification() {
  const { isOpen, toggle } = useDiscloser();
  const { user, fetchUser } = useContext(AuthContext);

  const [messageSetting, setMessageSetting] = useState({
    email: user.messageEmail,
    text: user.messageText,
  });
  const [remainderSetting, setRemainderSetting] = useState({
    email: user.reminderEmail,
    text: user.reminderText,
  });

  const handleChange = (field, func) => {
    func((el) => ({ ...el, [field]: !el[field] }));
  };

  const handleSetting = async () => {
    try {
      toggle();
      const data = {
        messageEmail: Boolean(messageSetting.email),
        messageText: Boolean(messageSetting.text),
        reminderEmail: Boolean(remainderSetting.email),
        reminderText: Boolean(remainderSetting.text),
      };

      const res = await setNotificationStatus(data);

      if (res.data.status) {
        toast.success(res.data.message);
        fetchUser();
      } else toast.error(res.data.message);
      toggle();
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="px-4 md:px-10">
      <div className="md:w-6/12 mb-10">
        <h4 className="text-base mb-4">Messages</h4>
        <div className="flex justify-between items-center mb-3">
          <p className="text-sm text-primary-blue-dim">Email</p>
          <SwitchButton
            initial={messageSetting.email}
            onChange={() => handleChange("email", setMessageSetting)}
          />
        </div>
        <div className="flex justify-between items-center">
          <p className="text-sm text-primary-blue-dim">Text Message</p>
          <SwitchButton
            initial={messageSetting.text}
            onChange={() => handleChange("text", setMessageSetting)}
          />
        </div>
      </div>
      <div className="md:w-6/12">
        <h4 className="text-base mb-4">Remainders</h4>
        <div className="flex justify-between items-center mb-3">
          <p className="text-sm text-primary-blue-dim">Email</p>
          <SwitchButton
            initial={remainderSetting.email}
            onChange={() => handleChange("email", setRemainderSetting)}
          />
        </div>
        <div className="flex justify-between items-center">
          <p className="text-sm text-primary-blue-dim">Text Message</p>
          <SwitchButton
            initial={remainderSetting.text}
            onChange={() => handleChange("text", setRemainderSetting)}
          />
        </div>
      </div>
      <Button
        btnType="outline"
        onClick={handleSetting}
        className="text-sm my-4 ring-1 ring-primary-blue text-primary-blue w-max block col-span-2"
      >
        {isOpen ? "Please wait..." : " Save setting"}
      </Button>
    </div>
  );
}

export default Notification;
