import React, { useEffect, useContext, useRef } from "react";
import Sidebar from "@dashboard/Sidebar";
import Content from "@dashboard/Content";
import Header from "@front/Header";
import Footer from "@front/Footer";
import userClickOutside from "@hooks/useClickOutside";
import { Context as AuthContext } from "@context/authContext";
import useWindowDimensions from "@hooks/useWindowDimension";

import {
  Context as DashboardContext,
  useSidebarContext,
} from "@context/dashboardContext";
import { useHistory } from "react-router-dom";
import { routes } from "../Dashboard/routes";

function DashboardWrapper({ children }) {
  const sidebarRef = useRef();
  const sidebar = useSidebarContext();
  const { user, fetchUser } = useContext(AuthContext);
  const { sidebarState } = useContext(DashboardContext);
  const { width } = useWindowDimensions();
  const history = useHistory();

  userClickOutside(sidebarRef, () => {
    if (width <= 767) {
      sidebar.hideSidebar();
    }
  });

  useEffect(() => {
    if (width <= 767) {
      sidebar.hideSidebar();
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (!user.authStatus) fetchUser();
  }, [fetchUser, user]);

  return (
    <>
      <Header type="fixed" />
      <div className="grid grid-cols-12 min-h-screen relative  border-t border-gray-200">
        <div
          ref={sidebarRef}
          className={`transform  ${
            sidebarState ? "" : " translate-x-[-150%] md:translate-x-0"
          } transition-all absolute md:static top-0 left-0 w-[240px] md:w-auto z-50 md:col-span-3 navbard-shadow lg:col-span-2 h-full self-stretch bg-white `}
        >
          <Sidebar route={routes}/>
        </div>
        <div className="col-span-full md:col-span-9 lg:col-span-10 relative h-full">
          <Content className="md:px-4 lg:px-10 ">
            {user.authStatus ? (
              children
            ) : (
              <div className="bg-gray-100 flex-center h-screen">
                <div className="bg-white rounded shadow-md p-4">
                  <div className="loader"></div>
                </div>
              </div>
            )}
          </Content>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default DashboardWrapper;
