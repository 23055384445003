import React from "react";
import Modal from "@components/Modal";
function MessageAlert({ message, onProceed, onCancle }) {
  return (
    <Modal>
      <div className="bg-white p-4 rounded-md w-full md:w-[425px]">
        <p className="text-sm">{message}</p>
        <div className="flex gap-2 justify-end mt-2">
          <button
            className="text-xs bg-gray-200 text-gray-600 rounded-md p-2"
            onClick={onCancle}
          >
            Cancel
          </button>
          <button
            className="text-xs bg-primary-blue  text-white rounded-md p-2"
            onClick={onProceed}
          >
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default MessageAlert;
