import React, { useState } from 'react'
import Modal from '@components/Modal'
import MessageAlert from '@components/MessageAlert'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import ErrorWrapper from '@components/ErrorWrapper'
import * as Yup from 'yup'
import { VscAdd } from 'react-icons/vsc'
import { IoMdTrash } from 'react-icons/io'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import getCreditCardImage from '@utils/getCreditCardImage'
import * as bankServices from '@services/bank'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'

const validationSchema = Yup.object({})

function AddBankCardModal({ onClose, setRefreshKey }) {
    const stripe = useStripe()
    const elements = useElements()
    const [errors, setErrors] = useState({})

    const initialValue = {
        cardNumber: '',
        cvv: '',
        expiryDate: '',
        holdername: '',
    }
    const [isCardAddingInProgress, setIsCardAddingInProgress] = useState(false)
    const [name, setName] = useState('')

    const handleNewAdd = async (e) => {
        if (!stripe || !elements) {
            return
        }

        try {
            setIsCardAddingInProgress(true)
            const cardElement = elements.getElement(CardNumberElement)

            const { error } = await stripe.createToken(cardElement)
            if (name.trim().length === 0) {
                setErrors((prev) => ({ ...prev, name: 'Card holder name is required.' }))
            }
            if (error) {
                let er = {}
                if (error.type === 'validation_error') {
                    if (error.code === 'incomplete_number') {
                        er['cardNumber'] = error.message
                    }
                    if (error.code === 'invalid_expiry_year_past' || error.code === 'exp_year') {
                        er['cardExpiry'] = error.message
                    }
                    if (error.code === 'incomplete_cvc') {
                        er['cardCvc'] = error.message
                    }
                    setErrors((prev) => ({ ...prev, ...er }))
                }

                setIsCardAddingInProgress(false)
                return
            }
            if (!error && name.length > 0) {
                const { token } = await stripe.createToken(cardElement, { name })
                const res = await bankServices.addNewCard(token)

                if (res.data.status) {
                    toast.success(res.data.message, { toastId: 'card-added' })
                } else toast.error(res.data.message)
                onClose()
                setRefreshKey((e) => e + 1)
                setIsCardAddingInProgress(false)
            }
        } catch (error) {
            setIsCardAddingInProgress(false)
            toast.error('Something went wrong!')
        } finally {
            setIsCardAddingInProgress(false)
        }
    }

    return (
        <Modal className="w-11/12 md:w-3/12 lg:w-4/12 relative">
            <div className="bg-white rounded-xl ">
                <div className="bg-primary-blue p-4  text-white rounded-xl">
                    <h4 className="text-lg font-semibold ">Add New Card</h4>
                    <p className="text-xs font-light">Please fill your details below</p>
                </div>
                <div onClick={onClose} className="h-8 w-8 bg-white absolute -right-3 -top-3 flex-center rounded-full ring-2 ring-primary-blue ">
                    <VscAdd size={20} className="text-primary-blue cursor-pointer  transform rotate-45 " />
                </div>
                <div className="p-4 bg-white rounded-b-xl">
                    <Formik validationSchema={validationSchema} initialValues={initialValue} onSubmit={handleNewAdd}>
                        {() => {
                            return (
                                <Form className="grid grid-cols-1 md:grid-cols-2 gap-2 space-y-2">
                                    <div className="md:col-span-2 ">
                                        <label className="block text-xs mb-1 text-primary-blue-dim " htmlFor="address-1">
                                            Card Number
                                        </label>
                                        <CardNumberElement
                                            onChange={(e) => {
                                                if (e) {
                                                    if (e.empty) {
                                                        setErrors((prev) => ({ ...prev, [e.elementType]: 'Card number is required.' }))
                                                    } else if (e.error) {
                                                        setErrors((prev) => ({ ...prev, [e.elementType]: e.error.message }))
                                                    } else if (e.complete) {
                                                        setErrors((prev) => ({ ...prev, [e.elementType]: null }))
                                                    }
                                                }
                                            }}
                                            className="rounded-md border-0  bg-white text-primary-blue-dim ring-1 ring-gray-400  p-3 text-xs w-full outline-none placeholder-primary-blue-dim "
                                        />
                                        {errors && errors.cardNumber && <ErrorWrapper>{errors.cardNumber}</ErrorWrapper>}
                                    </div>
                                    <div>
                                        <label className="block text-xs mb-1 text-primary-blue-dim " htmlFor="expiry-date">
                                            Expiration Date
                                        </label>
                                        <CardExpiryElement
                                            onChange={(e) => {
                                                if (e) {
                                                    if (e.empty) {
                                                        setErrors((prev) => ({ ...prev, [e.elementType]: 'Card expiration date is required.' }))
                                                    } else if (e.error) {
                                                        setErrors((prev) => ({ ...prev, [e.elementType]: e.error.message }))
                                                    } else if (e.complete) {
                                                        setErrors((prev) => ({ ...prev, [e.elementType]: null }))
                                                    }
                                                }
                                            }}
                                            className="rounded-md border-0  bg-white text-primary-blue-dim ring-1 ring-gray-400  p-3 text-xs w-full outline-none placeholder-primary-blue-dim "
                                        />
                                        {errors && errors.cardExpiry && <ErrorWrapper>{errors.cardExpiry}</ErrorWrapper>}
                                    </div>
                                    <div>
                                        <label className="block text-xs mb-1 text-primary-blue-dim " htmlFor="cvv">
                                            CVV
                                        </label>

                                        <CardCvcElement
                                            options={{ placeholder: 'CVV' }}
                                            onChange={(e) => {
                                                if (e) {
                                                    if (e.empty) {
                                                        setErrors((prev) => ({ ...prev, [e.elementType]: 'Card CVV is required.' }))
                                                    } else if (e.error) {
                                                        setErrors((prev) => ({ ...prev, [e.elementType]: e.error.message }))
                                                    } else if (e.complete) {
                                                        setErrors((prev) => ({ ...prev, [e.elementType]: null }))
                                                    }
                                                }
                                            }}
                                            className="rounded-md border-0  bg-white text-primary-blue-dim ring-1 ring-gray-400  p-3 text-xs w-full outline-none placeholder-primary-blue-dim "
                                        />
                                        {errors && errors.cardCvc && <ErrorWrapper>{errors.cardCvc}</ErrorWrapper>}
                                    </div>
                                    <div className="md:col-span-2">
                                        <label className="block text-xs mb-1 text-primary-blue-dim " htmlFor="card-holder-name">
                                            Card Holder Name
                                        </label>
                                        <input
                                            name="holderName"
                                            type="text"
                                            value={name}
                                            onChange={(e) => {
                                                setName(e.target.value)
                                                if (name.trim().length > 0) {
                                                    errors.name = ''
                                                }
                                            }}
                                            placeholder="Exact name on credit card"
                                            className="rounded-md border-0  bg-white text-primary-blue-dim ring-1 ring-gray-400  p-3 text-xs w-full outline-none placeholder-primary-blue-dim "
                                        />
                                        {errors && errors.name && <ErrorWrapper>{errors.name}</ErrorWrapper>}
                                    </div>

                                    <div className="flex items-center justify-between md:col-span-2"></div>
                                    <button
                                        disabled={isCardAddingInProgress}
                                        type="submit"
                                        className="rounded-xl  md:col-span-2 p-4 text-center font-medium text-white bg-primary-blue "
                                    >
                                        {isCardAddingInProgress ? 'Please wait...' : 'ADD CARD'}
                                    </button>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </div>
        </Modal>
    )
}

const Card = ({ image, defaultCard, data, setRefreshKey, redirectToBooking, fromBooking }) => {
    const [alert, setAlert] = useState(false)

    const handleDelete = async () => {
        try {
            const res = await bankServices.deleteCard(data.id)
            if (res.data.status) {
                toast.success(res.data.message)
                setRefreshKey((e) => e + 1)
            } else toast.error(res.data.message)
        } catch (error) {
            toast.error('Something went wrong!')
        }
    }

    const handlePrimaryCard = async () => {
        try {
            const res = await bankServices.makePrimary(data.id, fromBooking)
            if (res.data.status) {
                toast.success(res.data.message)
                if (res.data.status) {
                    redirectToBooking()
                }
                setRefreshKey((e) => e + 1)
            } else toast.error(res.data.message)
        } catch (error) {
            toast.error('Something went wrong!')
        }
    }

    return (
        <div className="flex lg:flex-row flex-col justify-start lg:gap-4 items-center">
            {alert && <MessageAlert message="Are you sure want to delete this card?" onProceed={handleDelete} onCancle={() => setAlert(false)} />}
            <div className="relative lg:w-[22.125rem] w-full overflow-hidden lg:mx-0 my-4 mx-4  flex items-center justify-start gap-5 p-2 shadow-md rounded-lg">
                {data.isExpired && <div className="bg-red-500 expire-label text-white">Expired</div>}

                {data.isExpiredNextMonth && <div className="bg-yellow-600 expire-label text-white">EXP. Soon</div>}
                <img src={`/assets/images/${getCreditCardImage(data.brand)}.png`} className="h-14 w-24 rounded-md" alt="apple phone" />
                <div className="text-base text-gray-500">
                    <p>XXXX-XXXX-XXXX-{data.last4}</p>
                    <p>{`${data.exp_year}-${String(data.exp_month).padStart(2, '0')}`}</p>
                </div>
            </div>
            <div className="flex-center md:justify-between justify-start items-stretch">
                {!data.isPrimary && (
                    <button
                        onClick={() => setAlert(true)}
                        className="flex-center mr-3 p-3 px-5 ring-1 ring-primary-blue text-primary-blue text-xs rounded-md "
                    >
                        Delete <IoMdTrash size={18} className="text-primary-blue ml-4" />
                    </button>
                )}
                <button
                    onClick={data.isPrimary ? null : handlePrimaryCard}
                    className={`w-32 h-11  p-3 mr-2 px-5  text-primary-blue text-xs rounded-md ${
                        data.isPrimary ? 'bg-parrot text-white' : 'ring-1 ring-primary-blue'
                    }`}
                >
                    {data.isPrimary ? 'Default' : 'Make Default'}
                </button>
            </div>
        </div>
    )
}

function SavedCards() {
    const [addCard, setAddCard] = useState(false)
    const [refreshKey, setRefreshKey] = useState(0)
    const [list, setList] = useState([])
    const [fetching, setFetching] = useState(false)

    const history = useHistory()
    const { state } = useLocation()
    const loc = useLocation()

    useEffect(() => {
        const fetch = async () => {
            try {
                setFetching(true)
                const res = await bankServices.getCardList()
                if (res.data.status) {
                    setList(res.data.data.data)
                } else toast.error(res.data.message)
            } catch (error) {
                toast.error('Something went wrong!')
            } finally {
                setFetching(false)
            }
        }
        fetch()
    }, [refreshKey])

    const redirectToBooking = () => {
        if (loc && loc.search === '?from=payment-confirmation') {
            history.goBack()
            return
        }
        if (state && state.data) {
            history.push('/dashboard/my-booking')
        }
    }

    return (
        <div>
            {addCard && <AddBankCardModal setRefreshKey={setRefreshKey} onClose={() => setAddCard(false)} />}
            {fetching && <p className="text-sm text-gray-600">Please wait...</p>}
            {list.map((curr) => {
                return <Card key={curr.id} fromBooking={state} redirectToBooking={redirectToBooking} setRefreshKey={setRefreshKey} data={curr} />
            })}

            <button
                onClick={() => {
                    if (list.length >= 10) {
                        toast.warn('You can only add up to 10 cards at a time. Please remove a card and try again.')

                    } else {
                        setAddCard(true)
                    }
                }}
                className="rounded-md mt-10 p-3 px-8 bg-primary-blue text-white text-sm "
            >
                ADD NEW CARD
            </button>
        </div>
    )
}

export default SavedCards
