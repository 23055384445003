import React from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { processMediaUrl } from '../../../_helper/utils'

const ConversationHeader = ({ channelDetails, setViewList }) => {
    const userStatusForChannel = channelDetails?.users?.displayState || false;
    return (
        <div>
            <div className="flex justify-between cursor-pointer p-4 space-x-2 border-b border-[#E4ECF8]">
                <div className="flex items-center justify-start space-x-2">
                    <span className="md:hidden" onClick={() => setViewList((e) => !e)}>
                        <IoIosArrowBack size={24} />
                    </span>
                    <span className="relative flex-shrink-0">
                        <img
                            alt=""
                            src={channelDetails?.property.picture ? processMediaUrl(channelDetails?.property.picture) : 'user.png'}
                            className="h-11 w-11 rounded-full object-cover flex-shrink-0"
                        />
                    </span>
                    <div className="truncate text-primary-blue">
                        <div className="flex items-center">
                            <p className="text-base font-medium ml-2 block truncate">{channelDetails?.property.name}</p>
                            {userStatusForChannel ? (
                                <span className="h-2 w-2 block bg-[#06d6a0] mb-1 ml-3 rounded-full"></span>
                            ) : (
                                <span className="h-2 w-2 block bg-[#ff3434] mb-1 ml-3 rounded-full"></span>
                            )}
                        </div>
                        <div>
                            <p className="text-base font-medium mb-1 ml-2 block truncate">{channelDetails?.property.host}</p>
                            <p className="text-sm font-normal mb-1 ml-2 block truncate">{channelDetails?.property.reservationId}</p>
                        </div>
                    </div>
                </div>
            </div>
            <span className="flex items-center space-x-2 text-primary-blue-dim">{/* <AiFillHeart size={20} /> */}</span>
        </div>
    )
}

export default ConversationHeader
