import Header from "@front/Header";
import Footer from "@front/Footer";
import Section from "@layout/Section";
import Button from "@ui/Button";
import { Link } from "react-router-dom";
import BreadCrunb from "@components/BreadCrunb";

function Card({ icon, title, description }) {
  return (
    <div className="rounded-3xl md:mb-auto w-full p-5 hover:shadow-xl hover:bg-parrot-light hover:bg-opacity-10 cursor-pointer ">
      <img
        alt="Home icon"
        className="h-14 w-14 rounded-full mx-auto my-2 mb-10"
        src={icon}
      />
      <h4 className="text-center text-base mb-2 font-semibold  text-gray-900">
        {title}
      </h4>
      <p className="text-center text-sm text-gray-500">{description}</p>
    </div>
  );
}

export default function BecomeAHost() {
  return (
    <div>
      <Header />
      <Section className="px-4 mt-28">
        <BreadCrunb
          className="mb-10 mt-5"
          title="Become a Host"
          bread={[
            { name: "Home", path: "/" },
            { name: "Become a Host", path: "/become-host" },
          ]}
        />

        <div className="mb-20 mt-5">
          <h4 className="capitalize text-center text-gray-900  text-lg">
            BECOME A CREWMATES HOST
          </h4>
          <h2 className="primary__heading text-center text-primary-blue">
          Want to List Your Property?
          </h2>
          <img
            src="/assets/resources/hand.png"
            alt="promise"
            className="w-full rounded-3xl my-10"
          />
        </div>
        <h4 className="primary__heading__caption text-center">HOW TO BECOME A CREWMATES HOST</h4>
        <h2 type="heading" className="primary__heading text-center">
        Advertise In a Few Simple Steps
        </h2>
        <div className="w-full md:w-10/12 mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 mt-20 gap-y-10 md:gap-x-12">
            <div className="flex justify-end ">
              <img
                className="w-full object-cover h-64 lg:h-80  inline-block rounded-3xl"
                src="/assets/resources/comp.png"
                alt="3245723945728345"
              ></img>
            </div>
            <div className="text-gray-600 ">
              <Button btnType="filled" className="cursor-auto">
                STEP 1
              </Button>
              <h2 className="font-semibold  text-base  lg:text-2xl my-4 text-gray-800">
                Setup your Account
              </h2>
              <p className="text-sm md:text-base font-light">
                {/* Create an account with Crewmates using your email address or
                social login. */}
                Create an account with Crewmates using your email address.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 mt-20 gap-y-10 md:gap-x-12">
            <div className="text-gray-600 ">
              <Button btnType="filled" className="cursor-auto">
                STEP 2
              </Button>
              <h2 className="font-semibold  text-base  lg:text-2xl my-4 text-gray-800">
                Create your Listing
              </h2>
              <p className="text-sm md:text-base font-light">
                Advertise your space to a passionate industry of aviation
                professionals.
              </p>
            </div>
            <div className="flex justify-start row-start-1 md:row-start-auto ">
              <img
                className="h-64 lg:h-80 inline-block object-cover rounded-3xl"
                src="/assets/resources/clap.png"
                alt="3245723945728345"
              ></img>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 mt-20 gap-y-10 md:gap-x-12">
            <div className="flex justify-end ">
              <img
                className="w-full object-cover h-64 lg:h-80  rounded-3xl inline-block"
                src="/assets/resources/comp.png"
                alt="3245723945728345"
              ></img>
            </div>
            <div className="text-gray-600">
              <Button btnType="filled" className="cursor-auto">
                STEP 3
              </Button>
              <h2 className="font-semibold text-base  lg:text-2xl my-4 text-gray-800">
                Start Earning
              </h2>
              <p className="text-sm md:text-base font-light">
                From saving on home renovations to taking a dream vacation,
                hosts can use the extra cash to fund other pursuits.
              </p>
            </div>
          </div>
        </div>
        <div className="md:my-20 my-10">
          <h4 className="primary__heading__caption text-center">
            Hosting benefits
          </h4>
          <h2 type="heading" className="text-center    primary__heading">
            Benefits With Us
          </h2>
        </div>
        <div className="md:my-20 my-10 sm:w-[345px] md:w-full 2xl:w-10/12 mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 md:gap-10">
          <Card
            icon="/assets/resources/wifi.png"
            title="Crashpad Management"
            description="Our live booking system will collect payments and track bed availability in real time."
          />
          <Card
            icon="/assets/resources/pot.png"
            title="Marketing Platform"
            description="Leverage the Crewmates network to obtain reservation requests directly to your inbox."
          />
          <Card
            icon="/assets/resources/boy.png"
            title="Serve Flight Crew"
            description="Make a positive impact on the airline community by hosting airline crewmembers."
          />
          <Card
            icon="/assets/resources/hand-globe.png"
            title="Earn Extra Income"
            description="Collecting rent has been simplified with our automated payment feature."
          />
        </div>
        <div className="mx-auto w-11/12 lg:w-6/12">
          <h2 type="heading" className="text-center    primary__heading">
          We Welcome You to Join The Crewmates Family
          </h2>
          <div className="bg-primary-blue rounded-2xl  cursor-pointer  w-max mx-auto md:my-20 my-10">
            <p className="text-xl  lg:text-2xl font-semibold text-center px-16 lg:px-20 py-5  text-white">
              <Link to="/register">REGISTER NOW</Link>
            </p>
          </div>
        </div>
      </Section>

      <Footer />
    </div>
  );
}
