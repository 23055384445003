import React, { useRef, useState } from 'react'
import * as utility from '@utils/utility'
import useQuery from '@hooks/useQuery'
import { HiOutlineChevronLeft } from 'react-icons/hi'
import { useHistory, Link, useParams } from 'react-router-dom'
import { FaBed } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { DateRange, Calendar } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { useEffect } from 'react'
import moment from 'moment'
import { addDays } from 'date-fns'
import { getFormattedDate, getNextMonth } from '@utils/utility'

const HOT = 'Hot bed'
const COLD = 'Cold bed'
const FLEXIBLE = 'Flexible'
const ENTIREPLACE = 'Entire Place'
const CRASHPAD = 'Crashpad'

const LINEHOLDER = 'LINEHOLDER'
const RESERVE = 'RESERVE'

const today = new Date()

function getDaysInCurrentMonth(dd) {
    const date = new Date(dd)
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
}

function checkInitialDate(dd) {
    if (moment().isAfter(moment(dd))) {
        return moment(new Date()).toDate()
    } else return dd
}

const BookingCard = React.forwardRef(
    (
        { propertyData, user, price, suffix, setSleepingArrangemntRefreshKey, setShowStayLimit, fetchingSleepingArrangement, sleepingArrangements },
        ref
    ) => {
        const query = useQuery()
        const history = useHistory()
        const [extraGuestCount, setExtraGuestCount] = useState('')
        const [extraGuestCharge, setExtraGuestCharge] = useState(0)
        const [discountType, setDiscountType] = useState(RESERVE)
        const [lineHolderDiscount, setLineholderDiscount] = useState(0)
        const [checkIn, setCheckIn] = useState(checkInitialDate(moment(query.get('checkIn') || new Date()).toDate()))
        const [dependencyKey, setDependencyKey] = useState(0)
        const [discountSwitchRefreshKey, setDiscountSwitchRefreshKey] = useState(0)
        const [perMonthPrice, setPerMonthPrice] = useState(0)
        const [bookingPrice, setBookingPrice] = useState(0)
        const [bookingDiscountValue, setbookingDiscount] = useState(0)
        const [bookingDiscountType, setbookingDiscountType] = useState('')
        const [discountedBedPrice, setDiscountedBedPrice] = useState(0)
        const [totalPrice, setTotalPrice] = useState(propertyData.bookingType === COLD ? propertyData.perMonthPrice : 0)
        const [bookingType, setBookingType] = useState('Flexible')
        const [selectedBedIndex, setSelectedBedIndex] = useState(null)

        const [visitDate, setVisitDate] = useState([
            {
                startDate: checkInitialDate(moment(query.get('checkIn') || new Date()).toDate()),
                endDate: checkInitialDate(moment(query.get('checkOut') || addDays(new Date(query.get('checkIn') || new Date()), 1)).toDate()),

                key: 'selection',
            },
        ])

        const [selectedRoom, setSelectedRoom] = useState({
            index: 0,
            isAvailable: sleepingArrangements[0].isAvailable,
        })

        const [selectedBed, setSelectedBed] = useState(null)
        const [selectedBedName, setSelectedBedName] = useState(null)
        const [requesting, setRequesting] = useState(false)

        const DAY_IN_MONTH = getDaysInCurrentMonth(checkIn)

        function reset() {
            setTotalPrice(0)
            setSelectedBed(null)
            setSelectedBedName(null)
            setSelectedBedIndex(null)
            setSelectedRoom({
                index: 0,
                isAvailable: sleepingArrangements[0].isAvailable,
            })
        }

        const handleNumberOfDays = () => {
            let diffInDays = 0
            if (propertyData.bookingType === COLD || bookingType === COLD) {
                diffInDays = utility.getNumberOfDays(checkIn)
            } else {
                diffInDays = utility.getNumberOfDays(visitDate[0].startDate, visitDate[0].endDate)
                diffInDays--
            }
            return diffInDays
        }

        async function handleReservation() {
            try {
                if (!selectedRoom.isAvailable) {
                    return toast.error('Room is already reserved!', { toastId: 82 })
                }
                const days = handleNumberOfDays()

                if (days === 0) {
                    toast.warn('Please choose your visit days!', { toastId: 83 })
                    return
                }

                const data = {}
                console.clear()

                data['propertyId'] = propertyData._id
                data['timezone'] = propertyData.timezone

                data['securityDeposite'] = propertyData.securityDeposite.toFixed(2) * 1
                data['numberOfDay'] = handleNumberOfDays()
                data['selectedRoom'] = selectedRoom.index + 1
                data['roomId'] = sleepingArrangements[selectedRoom.index]?._id
                data['roomName'] = sleepingArrangements[selectedRoom.index]?.roomName
                data['propertyType'] = propertyData.rentalType
                data['bookingPrice'] = bookingPrice
                data['perMonthPrice'] = perMonthPrice
                data['extraGuestCount'] = extraGuestCount
                data['bookingDiscountType'] = bookingDiscountType
                data['cancelationPolicy'] = propertyData.cancelationPolicy
                console.log('propertyData.cancelationPolicy ..............', propertyData.cancelationPolicy)
                console.log('data ==============>', data)

                if (discountType === LINEHOLDER) {
                    data['discount'] = lineHolderDiscount.toFixed(2) * 1
                    data['bookingDiscountValue'] = bookingDiscountValue
                } else {
                    data['discount'] = 0
                    data['bookingDiscountValue'] = 0
                }

                if (discountType === LINEHOLDER) {
                    data['discountedBedPrice'] = discountedBedPrice
                } else data['discountedBedPrice'] = bookingPrice

                if (propertyData.bookingType !== HOT) {
                    if (propertyData.lineholderDiscountType === 'none') {
                        data['discountType'] = 'None'
                    } else {
                        data['discountType'] = propertyData.lineholderDiscountType
                    }
                }

                if (propertyData.bookingType === HOT || bookingType === HOT) {
                    if (new Date(visitDate[0].startDate + '') > new Date(visitDate[0].endDate + '')) {
                        return toast.error('Invalid visit dates', { toastId: 84 })
                    }
                    data['checkInDate'] = moment(visitDate[0].startDate + '').format('L')
                    data['checkOutDate'] = moment(visitDate[0].endDate + '').format('L')
                    data['bookingType'] = HOT
                    data['totalPrice'] = (totalPrice + propertyData.cleaningFee + extraGuestCharge).toFixed(2) * 1
                    data['cleaningFee'] = propertyData.cleaningFee.toFixed(2) * 1
                } else {
                    data['checkInDate'] = moment(checkIn).format('L')
                    data['bookingType'] = COLD
                    data['totalPrice'] =
                        (totalPrice + extraGuestCharge + (propertyData.cleaningFee / DAY_IN_MONTH) * handleNumberOfDays()).toFixed(2) * 1
                    data['cleaningFee'] = ((propertyData.cleaningFee / DAY_IN_MONTH) * handleNumberOfDays()).toFixed(2) * 1
                }

                if (new Date(data.checkInDate) < new Date(moment(new Date()).format('L'))) {
                    toast.warn('Invalid check in date.', { toastId: 85 })
                    return
                }

                if (propertyData.rentalType === CRASHPAD) {
                    if (!selectedBed) {
                        return toast.error('Please choose your bed.', { toastId: 86 })
                    }
                    data['bedId'] = selectedBed
                    data['bedName'] = selectedBedName
                    data['selectedBed'] = selectedBed
                }

                if (propertyData.rentalType === ENTIREPLACE) {
                    data['extraGuestCharge'] = extraGuestCharge
                }
                if (!user.authStatus) {
                    return toast.error(
                        <>
                            Please{' '}
                            <Link to="/register" className="text-primary-blue ">
                                register
                            </Link>{' '}
                            or{' '}
                            <Link to="/login" className="text-primary-blue ">
                                log in
                            </Link>{' '}
                            to book this property
                        </>
                    )
                }

                if (user.id === propertyData.hostId.id) {
                    return toast.error('You cannot book your own property.', { toastId: 90 })
                }

                setRequesting(true)
                history.push({
                    pathname: `/booking-preview`,
                    state: {
                        data,
                        totalPrice: totalPrice,
                        cleaningFee: (propertyData.cleaningFee / DAY_IN_MONTH) * handleNumberOfDays(),
                        property: propertyData,
                        location: query.get('distance'),
                    },
                })
            } catch (error) {
                history.push('/login')
            } finally {
                setRequesting(false)
            }
        }

        function handleExtraGuestCount(count) {
            let value = parseInt(count)
            if (!isNaN(value)) {
                // Changes made for github issues #3, #4 & #5
                // https://github.com/Crewmatesapp/CrewmatesBackend/issues/3
                // https://github.com/Crewmatesapp/CrewmatesBackend/issues/4
                // https://github.com/Crewmatesapp/CrewmatesBackend/issues/5

                // propertyData.bookingType !== "Flexible"

                if (bookingType === COLD) {
                    // handles bookingType === COLD
                    setExtraGuestCharge(
                        parseFloat((parseFloat(propertyData.extraChargePerGuest / DAY_IN_MONTH) * handleNumberOfDays() * value).toFixed(2))
                    )
                } else {
                    // setExtraGuestCharge
                    setExtraGuestCharge(parseFloat((propertyData.extraChargePerGuest * handleNumberOfDays() * value).toFixed(2)))
                }
            } else setExtraGuestCharge(0)
            setExtraGuestCount(value)
        }

        //changing selected room here
        const handleRoomSelection = (type) => {
            if (type === 'next') {
                setSelectedRoom((e) => {
                    let ind = Math.min(e.index + 1, sleepingArrangements.length - 1)
                    return {
                        index: ind,
                        isAvailable: sleepingArrangements[ind].isAvailable,
                    }
                })
            } else {
                setSelectedRoom((e) => {
                    let ind = Math.max(e.index - 1, 0)
                    return {
                        index: ind,
                        isAvailable: sleepingArrangements[ind].isAvailable,
                    }
                })
            }

            setSelectedBed(null)
            setSelectedBedIndex(null)
        }

        function BedCard({ data, selected, onClick, disabled, unSelectable }) {
            const renderPrice = () => {
                if (bookingType === FLEXIBLE) {
                    if (data.perMonthPrice) return `$${data.perMonthPrice}/month`
                    if (data.perNightPrice) return `$${data.perNightPrice}/night`
                } else {
                    if (bookingType === HOT && data.perNightPrice) return `$${data.perNightPrice}/night`
                    if (bookingType === COLD && data.perMonthPrice) return `$${data.perMonthPrice}/month`
                }
            }
            return (
                <span
                    onClick={disabled || unSelectable ? null : onClick}
                    className={`relative ${
                        disabled
                            ? `cursor-not-allowed  p-4 bg-gray-200  text-sm shadow-md rounded  flex flex-col items-center justify-center w-[100px] flex-shrink-0   `
                            : `p-4 ${
                                  unSelectable ? '' : 'hover:bg-parrot-light hover:text-white'
                              }   text-sm shadow-md rounded cursor-pointer flex flex-col items-center justify-center w-[100px] flex-shrink-0  ${
                                  selected ? 'bg-parrot-light text-white' : ''
                              } `
                    }`}
                >
                    <p>{data.bedNumber}</p>
                    <p className="text-xs my-2">{renderPrice()}</p>
                    <FaBed size={24} />
                    <p className="text-xs whitespace-normal">{data.bedType.split(' ')[0]}</p>
                    {disabled && (
                        <span className="absolute bg-red-400 bg-opacity-80 top-0 left-0 right-0 bottom-0 text-white font-medium grid place-content-center rounded">
                            <p className="transform -rotate-45  ">RESERVED</p>
                        </span>
                    )}
                </span>
            )
        }

        const handleSleepingArrangementCheck = (startDate, endDate = '') => {
            const searchParam = new URLSearchParams(history.location.search)
            if (startDate) {
                searchParam.set('checkIn', getFormattedDate(startDate))
            }

            if (endDate) {
                searchParam.set('checkOut', getFormattedDate(endDate))
            } else searchParam.delete('checkOut')

            history.replace(`/property-details/${propertyData._id}?${searchParam.toString()}`)
        }

        useEffect(() => {
            setSelectedRoom({
                index: 0,
                isAvailable: sleepingArrangements[0].isAvailable,
            })
        }, [sleepingArrangements])

        useEffect(() => {
            // if (propertyData.bookingType !== "Flexible") {
            //   setBookingType("Flexible");
            //   return;
            // }
            // if (query.get("bookingType")) {
            //   setBookingType(query.get("bookingType"));
            //   if (query.get("bookingType") === COLD) {
            //     setShowStayLimit(true);
            //   }
            //   if (query.get("bookingType") === HOT) {
            //     setShowStayLimit(false);
            //   }
            // } else {
            //   if (propertyData.bookingType === "Flexible") {
            //     setBookingType(COLD);
            //     setShowStayLimit(true);
            //   } else {
            //     setBookingType("Flexible");
            //   }
            // }

            const type = query.get('bookingType') || propertyData.bookingType
            setBookingType(type)
            switch (type) {
                case COLD:
                    setShowStayLimit(true)
                    break
                case HOT:
                    setShowStayLimit(false)
                    break
                default:
                    break
            }
        }, [propertyData])

        useEffect(() => {
            const totalDays = handleNumberOfDays()
            if (totalDays < 0) {
                return
            }
            let total = 0
            console.log('propertyData---->>>>>>>>> in booking card')
            //If property is hotbed or Flexible(Hotbed)
            if (propertyData.bookingType === HOT || bookingType === HOT) {
                //If Rental type is Entireplace
                if (propertyData.rentalType === ENTIREPLACE) {
                    let total = totalDays * propertyData.perNightPrice
                    setBookingPrice(propertyData.perNightPrice)
                    setTotalPrice(parseFloat(total.toFixed(2)))
                }
                //If Rental type is Crashpad
                if (propertyData.rentalType === CRASHPAD) {
                    let total = sleepingArrangements[selectedRoom.index]?.beds[selectedBedIndex]?.perNightPrice * totalDays
                    setBookingPrice(sleepingArrangements[selectedRoom.index]?.beds[selectedBedIndex]?.perNightPrice || 0)
                    setTotalPrice(parseFloat(total.toFixed(2)))
                }
            }
            //If property is coldbed or Flexible(Coldbed)
            if (propertyData.bookingType === COLD || bookingType === COLD) {
                //If Rental type is Entireplace
                if (propertyData.rentalType === ENTIREPLACE) {
                    let tempTotal1 = totalDays * (propertyData.perMonthPrice / DAY_IN_MONTH)

                    let count = extraGuestCount
                    handleExtraGuestCount(count)
                    total = parseFloat(tempTotal1.toFixed(2))
                    setBookingPrice(propertyData.perMonthPrice)
                    setPerMonthPrice(propertyData.perMonthPrice)
                    setTotalPrice(total)
                }
                //If Rental type is Crashpad
                if (propertyData.rentalType === CRASHPAD) {
                    let tempTotal1 = (sleepingArrangements[selectedRoom.index]?.beds[selectedBedIndex]?.perMonthPrice / DAY_IN_MONTH) * totalDays

                    total = parseFloat(tempTotal1.toFixed(2))
                    setBookingPrice(propertyData.perMonthPrice)
                    setPerMonthPrice(propertyData.perMonthPrice)
                    setTotalPrice(total)
                }
            }
            //discount calculation
            let discount = 0

            if ((propertyData.bookingType === COLD || bookingType === COLD) && propertyData.lineholderDiscountType) {
                let discountValue = parseFloat(propertyData.lineholderDiscount)
                setbookingDiscount(parseFloat(discountValue.toFixed(2)))
                //for crashpad only we will give discount on bed level
                let bedPrice = 0
                if (propertyData.rentalType === CRASHPAD) {
                    bedPrice = sleepingArrangements[selectedRoom.index]?.beds[selectedBedIndex]?.perMonthPrice
                } else {
                    bedPrice = propertyData.perMonthPrice
                }

                if (propertyData.lineholderDiscountType === 'Fixed') {
                    setbookingDiscountType('Fixed')
                    setDiscountedBedPrice(bedPrice - discountValue)
                    bedPrice = (bedPrice - discountValue) / DAY_IN_MONTH
                    discount = (discountValue / DAY_IN_MONTH) * handleNumberOfDays()
                    // discount = total - bedPrice * handleNumberOfDays()
                } else if (propertyData.lineholderDiscountType === 'Percentage') {
                    setbookingDiscountType('Percentage')
                    bedPrice -= (bedPrice / 100) * discountValue
                    setDiscountedBedPrice(bedPrice)
                    bedPrice = bedPrice / DAY_IN_MONTH
                    discount = total - bedPrice * handleNumberOfDays()
                }
            }
            console.log('00000000000000000000000 discount --------------', discount)
            setLineholderDiscount(parseFloat(discount.toFixed(2)))
            handleExtraGuestCount(extraGuestCount)
        }, [dependencyKey, propertyData, sleepingArrangements, discountSwitchRefreshKey, bookingType, checkIn, visitDate, selectedRoom])
        const bedsContainer = useRef()

        const scrollLeftHandlerForBed = () => {
            bedsContainer?.current.scrollTo({
                left: bedsContainer?.current?.scrollLeft - 88,
                behavior: 'smooth',
            })
        }

        const scrollRightHandlerForBed = () => {
            bedsContainer?.current.scrollTo({
                left: bedsContainer?.current?.scrollLeft + 88,
                behavior: 'smooth',
            })
        }

        return (
            <div ref={ref} className="shadow-lg mx-2 rounded-lg p-4 lg:p-2 xl:p-4 relative pt-16 ">
                {fetchingSleepingArrangement && (
                    <div className="absolute top-0 bottom-0 left-0 right-0 grid place-content-center bg-gray-400 bg-opacity-40 rounded-lg z-30 ">
                        <div className="">
                            <div className="bg-white rounded shadow-md p-4">
                                <div className="loader"></div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="grid z-40 grid-cols-2 p-2 px-4 rounded-xl absolute left-2 right-2 -top-5 xl:top-[-3.30rem] lg:top-[-3.80rem] bg-primary-blue shadow-xl text-white">
                    <div>
                        {/* <h4 className="font-medium">Superior Room</h4>
          <h4 className="font-medium text-sm">Lorem ipsum lorem</h4> */}
                    </div>
                    <div>
                        <p className="text-right font-medium text-xl">
                            {typeof parseInt(price) === 'number'
                                ? parseInt(price).toLocaleString('en-US', {
                                      style: 'currency',
                                      currency: 'USD',
                                  })
                                : ''}
                        </p>
                        <p className="text-right text-xs font-normal ">{suffix}</p>
                    </div>
                </div>
                <div className="flex justify-between items-center">
                    <p className="text-gray-600 text-sm font-medium ">Booking Type</p>
                    <span className="p-2 px-4 rounded text-sm  bg-blue-200">
                        {propertyData.bookingType !== 'Flexible' ? (
                            propertyData.bookingType
                        ) : (
                            <select
                                defaultValue={bookingType}
                                value={bookingType}
                                onChange={(e) => {
                                    setBookingType(e.target.value)
                                    setSleepingArrangemntRefreshKey((e) => e + 1)
                                    if (e.target.value === COLD) {
                                        setShowStayLimit(true)
                                    } else {
                                        setShowStayLimit(false)
                                    }
                                }}
                                className="bg-transparent text-sm outline-none ring-0 py-0 border-0"
                            >
                                <option value={COLD}>Cold bed</option>
                                <option value={HOT}>Hot bed</option>
                            </select>
                        )}
                    </span>
                </div>
                {propertyData.rentalType === ENTIREPLACE && (
                    <div className="flex justify-between md:flex-row lg:flex-col xl:flex-row flex-col items-start my-4">
                        <p className="text-gray-600 text-sm font-medium lg:mt-2 ">Extra Guest</p>
                        <div className="flex flex-col items-end ">
                            <select
                                onChange={(e) => {
                                    handleExtraGuestCount(e.target.value)
                                }}
                                placeholder="Extra guest count"
                                className="p-2 w-[180px] rounded ring-1 text-xs ring-gray-300"
                            >
                                <option value={0}>{0}</option>
                                {new Array(parseInt(propertyData.accomodationCapacity || 0))
                                    .fill(0)
                                    .map((e, i) => i + 1)
                                    .map((e) => (
                                        <option value={e}>{e}</option>
                                    ))}
                            </select>

                            <p className="text-xs text-right my-2 text-gray-600">* Allowed number of guest {propertyData.accomodationCapacity}</p>
                        </div>
                    </div>
                )}
                {(propertyData.bookingType === HOT || bookingType === HOT) && (
                    <div className="my-4 ">
                        <p className="font-medium text-gray-600 text-sm flex justify-between items-center mb-1 ">
                            <span>Check In</span>
                            <span>Check Out</span>
                        </p>
                        <div className="sm:mx-auto sm:w-max lg:w-full xl:w-max ">
                            <DateRange
                                rangeColors={['#23426E']}
                                minDate={new Date()}
                                maxDate={new Date(today.getFullYear(), today.getMonth() + 2, 0)}
                                className="w-full"
                                editableDateInputs={true}
                                onChange={(item) => {
                                    setVisitDate([item.selection])
                                    setDependencyKey((e) => e + 1)
                                    setSleepingArrangemntRefreshKey((e) => e + 1)
                                    setSelectedBed('')
                                    setSelectedBedName('')
                                    setSelectedBedIndex('')
                                    handleSleepingArrangementCheck(item.selection.startDate, item.selection.endDate)
                                }}
                                moveRangeOnFirstSelection={false}
                                ranges={visitDate}
                            />
                        </div>
                    </div>
                )}
                {(propertyData.bookingType === COLD || bookingType === COLD) && (
                    <div className="my-4 ">
                        <p className="font-medium  text-gray-600 text-sm ">Check In</p>
                        <div className="mx-auto w-max">
                            <Calendar
                                className="w-full"
                                minDate={new Date()}
                                maxDate={new Date(today.getFullYear(), today.getMonth() + 2, 0)}
                                date={checkIn}
                                onChange={(e) => {
                                    setCheckIn(e)
                                    setSelectedBed('')
                                    setSelectedBedName('')
                                    setSelectedBedIndex('')
                                    handleSleepingArrangementCheck(e)

                                    setDependencyKey((e) => e + 1)
                                    setSleepingArrangemntRefreshKey((e) => e + 1)
                                }}
                            />
                        </div>
                    </div>
                )}
                <p className="text-gray-600 text-sm font-medium">Bed Selection</p>
                <div className="flex justify-between items-center py-8 ">
                    <span
                        onClick={() => handleRoomSelection('prev')}
                        className="flex-shrink-0 mr-2  hover:bg-gray-100 h-8 w-8 rounded-full grid place-content-center "
                    >
                        <HiOutlineChevronLeft size={24} className="cursor-pointer " />
                    </span>
                    <hr className="border-dashed border-gray-400 mx-auto w-full" />
                    <p className="text-center w-max mx-2 whitespace-nowrap ">Bedroom {sleepingArrangements[selectedRoom.index]?.roomNumber}</p>
                    <hr className="border-dashed border-gray-400 mx-auto w-full" />
                    <span
                        onClick={() => handleRoomSelection('next')}
                        className="flex-shrink-0 ml-2  hover:bg-gray-100 h-8 w-8 rounded-full grid place-content-center"
                    >
                        <HiOutlineChevronLeft size={24} className="transform rotate-180 cursor-pointer " />
                    </span>
                </div>
                <div className="relative">
                    {!selectedRoom.isAvailable && (
                        <div className="absolute top-0 bottom-0 left-0 right-0 z-10 rounded bg-red-400 bg-opacity-80 text-white grid place-content-center">
                            <p className="text-xl uppercase  transform -rotate-45">Reserved</p>
                        </div>
                    )}
                    <div className="grid grid-cols-4 gap-2  text-xs text-gray-500">
                        {propertyData.rentalType === 'Crashpad' && (
                            <>
                                <p className="col-span-2">
                                    <span>Room Type </span> <br />
                                    <span className="font-medium text-primary-blue ">
                                        {sleepingArrangements[selectedRoom.index]?.roomType || '--'}
                                    </span>
                                </p>
                                <p className="col-span-2 ">
                                    <span>Bathroom </span> <br />
                                    <span className="font-medium text-primary-blue ">
                                        {sleepingArrangements[selectedRoom.index]?.numberOfBathroom || '--'}
                                    </span>
                                </p>
                                <p className="col-span-2">
                                    <span>Gender </span> <br />
                                    <span className="font-medium text-primary-blue ">
                                        {sleepingArrangements[selectedRoom.index]?.genderType || '--'}
                                    </span>
                                </p>
                                <p className="col-span-2">
                                    <span> Profession</span>
                                    <br />
                                    <span className="font-medium text-primary-blue ">
                                        {sleepingArrangements[selectedRoom.index]?.crewType || '--'}
                                    </span>
                                </p>
                            </>
                        )}
                    </div>
                    <div className="">
                        <div className="flex justify-between mt-3 items-center">
                            <span
                                onClick={scrollLeftHandlerForBed}
                                className="flex-shrink-0 mr-2  hover:bg-gray-100 h-8 w-8 rounded-full grid place-content-center "
                            >
                                <HiOutlineChevronLeft size={24} className="cursor-pointer " />
                            </span>
                            <div
                                ref={bedsContainer}
                                className="flex justify-start items-center gap-2 flex-nowrap p-4 px-2 overflow-scroll scrollbar-hide"
                            >
                                {sleepingArrangements[selectedRoom.index]?.beds.map((el, ind) => {
                                    if (el.isAvailable) {
                                        return (
                                            <React.Fragment key={ind}>
                                                <BedCard
                                                    unSelectable={propertyData.rentalType === ENTIREPLACE}
                                                    data={el}
                                                    selected={selectedBed === el._id}
                                                    onClick={() => {
                                                        setSelectedBed(el._id || null)
                                                        setSelectedBedName(el.bedNumber)
                                                        setSelectedBedIndex(ind)
                                                        setDependencyKey((e) => e + 1)
                                                    }}
                                                />
                                            </React.Fragment>
                                        )
                                    } else
                                        return (
                                            <React.Fragment key={ind}>
                                                <BedCard disabled data={el} selected={selectedBed === el._id} onClick={() => {}} />
                                            </React.Fragment>
                                        )
                                })}
                            </div>
                            <span
                                onClick={scrollRightHandlerForBed}
                                className="flex-shrink-0 ml-2  hover:bg-gray-100 h-8 w-8 rounded-full grid place-content-center"
                            >
                                <HiOutlineChevronLeft size={24} className="transform rotate-180 cursor-pointer " />
                            </span>
                        </div>
                    </div>
                </div>
                {propertyData.bookingType === COLD || bookingType === COLD ? (
                    <>
                        <div className=" text-white grid grid-cols-2 my-2 text-sm">
                            <span
                                onClick={() => {
                                    setDiscountType(RESERVE)
                                    // setDiscountSwitchRefreshKey((e) => e + 1);
                                }}
                                className={`inline-block p-2 text-center cursor-pointer    ${
                                    discountType === RESERVE ? 'bg-primary-blue text-white' : 'bg-blue-200 text-gray-700'
                                }`}
                            >
                                Reserve
                            </span>
                            <span
                                onClick={() => {
                                    setDiscountType(LINEHOLDER)
                                    // setDiscountSwitchRefreshKey((e) => e + 1);
                                }}
                                className={`inline-block p-2 text-center cursor-pointer  ${
                                    discountType === LINEHOLDER ? 'bg-primary-blue text-white' : 'bg-blue-200 text-gray-700'
                                }  `}
                            >
                                Line Holder
                            </span>
                        </div>
                        {(propertyData.bookingType === COLD || bookingType === COLD) && (
                            <div className="my-2 flex justify-between items-center  text-sm ">
                                <p className="text-gray-500">Stay Limits</p>
                                <p>{discountType === LINEHOLDER ? propertyData.lineholderDays : propertyData.reservedDays} Nights</p>
                            </div>
                        )}
                        <div className="grid grid-cols-2 text-sm border-t border-b border-gray-500 py-3 space-y-1 my-2 text-gray-900">
                            <p>
                                Prorated {utility.getMonthFromIndex(new Date(checkIn).getMonth())}({handleNumberOfDays()})
                            </p>
                            <p className="text-right">
                                {(isNaN(totalPrice) ? 0 : totalPrice).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                })}
                            </p>
                            {propertyData.cleaningFee > 0 && (
                                <>
                                    <p>Cleaning Fee</p>
                                    <p className="text-right">
                                        {((propertyData.cleaningFee / DAY_IN_MONTH) * handleNumberOfDays()).toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        })}
                                    </p>
                                </>
                            )}

                            <p>Security Deposit</p>
                            <p className="text-right">
                                {propertyData.securityDeposite.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                })}
                            </p>

                            {discountType === LINEHOLDER && propertyData.lineholderDiscountType && lineHolderDiscount > 0 && (
                                <>
                                    <p>Line holder Discount</p>
                                    <p className="text-right">
                                        {(isNaN(lineHolderDiscount) ? 0 : lineHolderDiscount).toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        })}
                                    </p>
                                </>
                            )}

                            {propertyData.rentalType === ENTIREPLACE && extraGuestCharge > 0  && (
                                <>
                                    <p>Extra Guest Charge</p>
                                    <p className="text-right">
                                        {(isNaN(extraGuestCharge) ? 0 : extraGuestCharge).toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        })}
                                    </p>
                                </>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <div className="grid grid-cols-2 text-sm border-t border-b border-gray-500 py-3 space-y-1 my-2 text-gray-900">
                            <p>({handleNumberOfDays()}) Night(s)</p>
                            <p className="text-right">
                                {(isNaN(totalPrice) ? 0 : totalPrice).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                })}
                            </p>
                            {propertyData.cleaningFee > 0 && (
                                <>
                                    <p>Cleaning Fee</p>
                                    <p className="text-right">
                                        {propertyData.cleaningFee.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        })}
                                    </p>
                                </>
                            )}

                            <p>Security Deposit</p>
                            <p className="text-right">
                                {propertyData.securityDeposite.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                })}
                            </p>
                            {propertyData.rentalType === ENTIREPLACE && extraGuestCharge > 0 && (
                                <>
                                    <p>Extra Guest Charge</p>
                                    <p className="text-right">
                                        {(isNaN(extraGuestCharge) ? 0 : extraGuestCharge).toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        })}
                                    </p>
                                </>
                            )}
                        </div>
                    </>
                )}
                <div className="flex items-center justify-between text-lg text-primary-blue font-medium  my-2">
                    <h4>Total</h4>
                    <p>
                        {propertyData.bookingType === COLD || bookingType === COLD ? (
                            <>
                                {discountType === LINEHOLDER
                                    ? (isNaN(
                                          totalPrice -
                                              lineHolderDiscount +
                                              extraGuestCharge +
                                              (propertyData.cleaningFee / DAY_IN_MONTH) * handleNumberOfDays()
                                      )
                                          ? '$0.00'
                                          : totalPrice -
                                            lineHolderDiscount +
                                            extraGuestCharge +
                                            (propertyData.cleaningFee / DAY_IN_MONTH) * handleNumberOfDays()
                                      ).toLocaleString('en-US', {
                                          style: 'currency',
                                          currency: 'USD',
                                      })
                                    : (isNaN(totalPrice + extraGuestCharge + (propertyData.cleaningFee / DAY_IN_MONTH) * handleNumberOfDays())
                                          ? '$0.00'
                                          : totalPrice + extraGuestCharge + (propertyData.cleaningFee / DAY_IN_MONTH) * handleNumberOfDays()
                                      ).toLocaleString('en-US', {
                                          style: 'currency',
                                          currency: 'USD',
                                      })}
                            </>
                        ) : (
                            <>
                                {isNaN(totalPrice + propertyData.cleaningFee)
                                    ? '$0.00'
                                    : (totalPrice + propertyData.cleaningFee + extraGuestCharge).toLocaleString('en-US', {
                                          style: 'currency',
                                          currency: 'USD',
                                      })}
                            </>
                        )}
                    </p>
                </div>
                <button onClick={handleReservation} className="p-4 text-center bg-primary-blue text-white rounded-md w-full text-lg font-medium  ">
                    {requesting ? 'Please wait' : 'Request to Book'}
                </button>
            </div>
        )
    }
)
export default BookingCard
