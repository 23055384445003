import React from 'react';

function Button({ btnType, children, className, ...props }) {
  const btn = 'md:p-2 px-4 md:px-4 p-1.5 rounded';

  switch (btnType) {
    case 'filled': {
      return (
        <button
          {...props}
          className={`${btn} ${className} bg-primary-blue text-white text-sm `}
        >
          {children}
        </button>
      );
    }
    case 'small': {
      return (
        <button {...props} className={`rounded p-px px-3 ${className} `}>
          {children}
        </button>
      );
    }
    case 'outline': {
      return (
        <button {...props} className={`${btn} ${className}  border-gray-800`}>
          {children}
        </button>
      );
    }

    case 'rounded': {
      return (
        <button
          {...props}
          className={`${btn} ${className} rounded-full bg-primary-blue text-white  `}
        >
          {children}
        </button>
      );
    }

    default: {
      return (
        <button {...props} className={`${btn} ${className}`}>
          {children}
        </button>
      );
    }
  }
}

export default Button;
