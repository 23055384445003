import React, { useState, useEffect, useContext, useCallback } from 'react'
import { throttle } from 'lodash'
import { useHistory } from 'react-router-dom'
import { getAirportList, getIataInfo } from '@services/airport'
import moment from 'moment'
import useQuery from '@hooks/useQuery'
import { getPropertyList } from '../_services/property'
import 'tippy.js/dist/tippy.css'
import Section from '@layout/Section'
import { GoSettings } from 'react-icons/go'
import { GrPowerReset } from 'react-icons/gr'
import FilterModal from '@components/FilterModal'
import ProductCard, { calculateRoomAvgPrice } from '@components/RoomCard'
import { DropdownRange, DropdownList, DropdownListUnique } from '@components/DropDown'
import PropertyListingMap from '../components/PropertyListingMap'
import Header from '@front/Header'
import Footer from '@front/Footer'
import { getCoords } from '@utils/utility'
import { useAuth } from '@context/authContext'
import { usePropertyFilter } from '@context/propertyFilterContext'
import { toast } from 'react-toastify'
import { getFormattedDate } from '../utils/utility'
import { capitalizeEachWord } from '../_helper/header'

const bookingTypeList = ['Cold bed', 'Hot bed']
const today = new Date()

const HOT = 'Hot bed'
const COLD = 'Cold bed'
const FLEXIBLE = 'Flexible'

const ENTIREPLACE = 'Entire Place'
const CRASHPAD = 'Crashpad'

function checkInitialDate(dd) {
    if (dd < new Date()) {
        return new Date()
    } else return dd
}

export function SearchTopBar({ setGlobalBookingType }) {
    const query = useQuery()
    const history = useHistory()
    const [iataRefreshKey, setIataRefreshKey] = useState(0)
    const [airportDetails, setAirportDetails] = useState({
        name: '',
        iata: '',
    })
    const [checkin, setCheckin] = useState(query.get('checkIn') || new Date().toISOString().split('T')[0])
    const [checkout, setCheckout] = useState(query.get('checkOut') || checkin)
    const [iataCode, setIataCode] = useState(query.get('iata'))
    const [airportlist, setAirportList] = useState([])
    const [propertyOption, setPropertyOption] = useState(false)
    const [bookingType, setBookingType] = useState(query.get('bookingType') || 'Cold bed')

    const fetchAirport = async (term) => {
        try {
            setAirportList([])
            getAirportList(
                term,
                (data) => {
                    setAirportList(data.airports)
                },
                (data) => {
                    setAirportList([])
                    setAirportDetails({ name: '' })
                }
            )
        } catch (error) {
            console.log('AirportCodeError', error)
            // toast.error(error.message,{toastId:100})
        }
    }

    const throtteledFetchAirport = useCallback(throttle(fetchAirport, 500), [])

    function handleAirportFetch(e) {
        setIataCode(e.target.value)
        throtteledFetchAirport(e.target.value)
    }

    function handleSearchProperty() {
        let params
        if (iataCode) {
            params = new URLSearchParams({
                iata: iataCode,
                airportName: airportDetails.name,
                bookingType,
                checkIn: checkin,
                checkOut: checkout,
            })
        } else
            params = new URLSearchParams({
                bookingType,
                checkIn: checkin,
                checkOut: checkout,
            })

        // history.push({
        //   pathname: `/property-listing?${params.toString()}`,
        //   state: {
        //     airportDetails,
        //   },
        // });

        history.replace(`/property-listing?${params.toString()}`)
    }

    useEffect(() => {
        setCheckin(query.get('checkIn') || new Date().toISOString().split('T')[0])
        if (query.get('checkOut')) {
            setCheckout(query.get('checkOut'))
        }
    }, [query])

    useEffect(() => {
        const tommorow = new Date(checkin)
        tommorow.setDate(new Date(checkin).getDate() + 1)
        if (!query.get('checkOut') || new Date(checkout) - new Date(checkin) <= 0) {
            setCheckout(tommorow.toISOString().split('T')[0])
        }
    }, [checkin])

    return (
        <div className=" justify-start gap-y-1.5 items-stretch gap-x-2.5  flex-wrap my-5 hidden md:flex">
            <div className="p-2 px-4 shadow-md flex-shrink-0 rounded-full rounded-br-none pr-5 w-52  text-white bg-primary-blue flex flex-col justify-end relative">
                <p className="text-xs font-medium ">Airport Code</p>
                <input
                    value={iataCode}
                    onChange={handleAirportFetch}
                    placeholder="Airport Code"
                    // maxLength={4}
                    className="cursor-pointer  placeholder-gray-50 pr-2 flex-grow text-xs  bg-transparent outline-none"
                />
                {Boolean(airportlist.length) && (
                    <div className="absolute top-14 py-2  text-white left-0 w-max z-50  rounded bg-primary-blue">
                        {airportlist.map((curr, ind) => (
                            <p
                                key={ind}
                                onClick={() => {
                                    setIataCode(curr.iata)
                                    setIataRefreshKey((e) => e + 1)
                                    setAirportList([])
                                    setAirportDetails({ ...curr })
                                }}
                                className="p-2 text-xs cursor-pointer hover:bg-opacity-40 hover:bg-gray-900"
                            >
                                {curr.iata}, {curr.name}
                            </p>
                        ))}
                    </div>
                )}
            </div>
            <div
                className="p-2 px-4 shadow-md flex-shrink-0 rounded-full rounded-br-none pr-10 w-52  text-white bg-primary-blue flex flex-col justify-end relative"
                onClick={() => setPropertyOption(!propertyOption)}
            >
                <p className="text-xs font-medium ">Booking Type</p>

                <div
                    className="cursor-pointer pr-2 flex-grow text-xs  bg-transparent outline-none"
                    onClick={() => setPropertyOption(!propertyOption)}
                >
                    {bookingType === '' ? 'Booking Type' : capitalizeEachWord(bookingType)}
                </div>
                {propertyOption && (
                    <div className="absolute top-[3.125rem] py-2 z-100 text-white left-0 right-0 rounded bg-primary-blue">
                        {bookingTypeList.map((list, index) => {
                            return (
                                <p
                                    onClick={() => {
                                        setBookingType(list)
                                        if (setGlobalBookingType) {
                                            setGlobalBookingType(list)
                                        }
                                        setPropertyOption(!propertyOption)
                                    }}
                                    className="p-2 text-xs cursor-pointer hover:bg-opacity-40 hover:bg-gray-900"
                                    value={list}
                                    key={index}
                                >
                                    {capitalizeEachWord(list)}
                                </p>
                            )
                        })}
                    </div>
                )}
            </div>
            <div className="p-2 px-4 cursor-pointer shadow-md flex-shrink-0 mr-2 rounded-full rounded-br-none pr-10  text-gray-900  flex flex-col justify-end ">
                <p className="text-xs font-medium">Check-In</p>
                <input
                    value={checkin}
                    onChange={(e) => {
                        setCheckin(e.target.value)
                    }}
                    min={new Date().toISOString().split('T')[0]}
                    max={getFormattedDate(new Date(today.getFullYear(), today.getMonth() + 2, 0))}
                    type="date"
                    className="outline-none bg-transparent text-xs border-0 p-0 focus:ring-0 focus:border-0 "
                />
                {/* <p className="text-sm font-medium">TUE, 14 Sep 2021</p> */}
            </div>
            <div className="p-2 px-4 cursor-pointer  shadow-md flex-shrink-0 mr-2 rounded-full rounded-br-none pr-10  text-gray-900  flex flex-col justify-end ">
                <p className="text-xs font-medium">Check-Out</p>
                <input
                    value={checkout}
                    onChange={(e) => setCheckout(e.target.value)}
                    min={new Date(moment(new Date(checkin)).add(1, 'day')).toISOString().split('T')[0]}
                    max={getFormattedDate(new Date(today.getFullYear(), today.getMonth() + 2, 0))}
                    type="date"
                    className="outline-none bg-transparent text-xs border-0 p-0 focus:ring-0 focus:border-0 "
                />
            </div>
            <button
                onClick={handleSearchProperty}
                className="px-5 lg:my-auto my-2 w-44 p-2 rounded-full bg-primary-blue text-white font-medium   text-sm  "
            >
                SEARCH
            </button>
        </div>
    )
}

export function MobileNavOptions({ setGlobalBookingType }) {
    const query = useQuery()
    const history = useHistory()
    const [airportCode, setAirportCode] = useState('')
    const [airportDetails, setAirportDetails] = useState({
        name: '',
        iata: '',
    })
    const [checkin, setCheckin] = useState(query.get('checkIn') || new Date().toISOString().split('T')[0])
    const [checkout, setCheckout] = useState(query.get('checkOut') || checkin)
    const [iataCode, setIataCode] = useState(query.get('iata') || '')
    const [airportlist, setAirportList] = useState([])
    const [propertyOption, setPropertyOption] = useState(false)
    const [bookingType, setBookingType] = useState(query.get('bookingType') || 'Cold bed')

    const fetchAirport = async (term) => {
        try {
            setAirportList([])
            getAirportList(
                term,
                (data) => {
                    setAirportList(data.airports)
                    setAirportDetails(data)
                },
                (data) => {
                    setAirportList([])
                    setAirportDetails({ name: '' })
                }
            )
        } catch (error) {}
    }

    const throtteledFetchAirport = useCallback(throttle(fetchAirport, 500), [])

    function handleAirportFetch(e) {
        setIataCode(e.target.value.toUpperCase())
        throtteledFetchAirport(e.target.value.toUpperCase())
    }

    function handleSearchProperty() {
        let params
        if (iataCode) {
            params = new URLSearchParams({
                iata: iataCode,
                bookingType,
                airportName: airportDetails.name,
                checkIn: checkin,
                checkOut: checkout,
            })
        } else
            params = new URLSearchParams({
                bookingType,
                checkIn: checkin,
                checkOut: checkout,
            })

        // history.push({
        //   pathname: `/property-listing?${params.toString()}`,
        //   state: {
        //     airportDetails,
        //   },
        // });

        history.replace(`/property-listing?${params.toString()}`)
    }

    useEffect(() => {
        setCheckin(query.get('checkIn') || new Date().toISOString().split('T')[0])
        if (query.get('checkOut')) {
            setCheckout(query.get('checkOut'))
        }

        // if (!query.get("iata")) {
        //   setIataCode("");
        //   setAirportDetails({ name: "", iata: "" });
        // }

        // if (query.get("bookingType")) {
        //   setBookingType(query.get("bookingType"));
        // } else {
        //   setBookingType("Hot bed");
        // }
    }, [query])

    useEffect(() => {
        const tommorow = new Date(checkin)
        tommorow.setDate(new Date(checkin).getDate() + 1)
        if (!query.get('checkOut') || new Date(checkout) - new Date(checkin) <= 0) {
            setCheckout(tommorow.toISOString().split('T')[0])
        }
    }, [checkin])

    return (
        <div className="md:hidden block">
            <div className=" md:hidden grid grid-cols-2 gap-x-4 rounded-md ring-0 ring-primary-blue ring-opacity-40 p-3 text-primary-blue">
                <div>
                    <p className="text-sm font-bold">Airport Code</p>
                    <input
                        value={iataCode}
                        onChange={handleAirportFetch}
                        placeholder="Airport Code"
                        // maxLength={4}
                        className="cursor-pointer   placeholder-gray-500 pr-2 flex-grow text-xs  bg-transparent outline-none"
                    />
                    <div className="relative ">
                        {Boolean(airportlist.length) && (
                            <div className="absolute top-0 py-2  text-white left-0 right-0 z-50  rounded bg-primary-blue">
                                {airportlist.map((curr, ind) => (
                                    <p
                                        key={ind}
                                        onClick={() => {
                                            setIataCode(curr.iata)
                                            setAirportList([])
                                            setAirportDetails({ ...curr })
                                        }}
                                        className="p-2 text-xs cursor-pointer hover:bg-opacity-40 hover:bg-gray-900"
                                    >
                                        {curr.iata}, {curr.name}
                                    </p>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div className=" relative" onClick={() => setPropertyOption(!propertyOption)}>
                    <p className="text-sm font-bold">Booking type</p>
                    <select
                        value={bookingType}
                        onChange={(e) => {
                            setBookingType(e.target.value)

                            if (setGlobalBookingType) {
                                setGlobalBookingType(e.target.value)
                            }
                        }}
                        className="text-xs cursor-pointer pr-2 w-full flex-grow border-0  bg-transparent outline-none rounded ring-0 focus:ring-0 focus:outline-none"
                    >
                        <option value="" disabled>
                            Select Booking type
                        </option>
                        {bookingTypeList.map((curr, ind) => (
                            <option key={ind} value={curr}>
                                {curr}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <p className="text-sm font-bold">Check In</p>
                    <input
                        value={checkin}
                        onChange={(e) => setCheckin(e.target.value)}
                        min={new Date().toISOString().split('T')[0]}
                        type="date"
                        className="outline-none text-xs ring-0 border-0 p-0 focus:ring-0 focus:border-0"
                    />
                </div>
                <div>
                    <p className="text-sm font-bold">Check Out</p>
                    <input
                        value={checkout}
                        onChange={(e) => setCheckout(e.target.value)}
                        min={new Date(moment(new Date(checkin)).add(1, 'day')).toISOString().split('T')[0]}
                        type="date"
                        className="outline-none text-xs ring-0 border-0 p-0 focus:ring-0 focus:border-0"
                    />
                </div>
            </div>
            <button onClick={handleSearchProperty} className="px-5 w-44 p-2 rounded-full bg-primary-blue text-white font-medium   text-sm  ">
                SEARCH
            </button>
        </div>
    )
}

function PropertyListing() {
    const { user } = useAuth()
    const { updateFilter } = usePropertyFilter()
    const query = useQuery()
    const [sorting, setSorting] = useState('')
    const [globalBookingType, setGlobalBookingType] = useState(query.get('bookingType') || bookingTypeList[0])
    const [airportName, setAirportName] = useState(query.get('airportName') || '')
    const [fetchingProperty, setFetchingProperty] = useState(true)
    const [fetchingLocation, setFetchingLocation] = useState(true)
    const [filterOptions, setFilterOptions] = useState({
        listing_type: [],
        property_type: [],
        profession: [],
        amenities: [],
        latlng: [],
        gender: [],
        price: [0, 1001],
        stayLimit: [1, 30],
        radiusToAirport: [0, 50],
    })

    const [propertyList, setPropertyList] = useState([])
    const [filterModal, setFilterModal] = useState(false)
    const [propertyMap, setPropertyMap] = useState(false)
    const history = useHistory()

    async function fetchProperty(filter, fetchingLocation) {
        setFetchingProperty(true)
        try {
            if (!fetchingLocation && filter.hasOwnProperty('airportLocation')) {
                const res = await getPropertyList(filter)
                if (res.data.status) {
                    setPropertyList(res.data.data)
                    updateFilter(filter)
                } else {
                    toast.error(res.data.message)
                }
                setFetchingProperty(false)
            }
        } catch (error) {
        } finally {
            setFetchingProperty(false)
        }
    }

    function handleFilterReset() {
        window.location = 'property-listing?bookingType=Hot+bed'
    }

    const throtteledFetchProperty = useCallback(throttle(fetchProperty, 1000), [])

    function filterPrice(property) {
        let searchBookingType = query.get('bookingType')
        if (property.rentalType === ENTIREPLACE) {
            if (property.bookingType === COLD || (property.bookingType === FLEXIBLE && searchBookingType === COLD)) {
                return {
                    str: `${property.perMonthPrice}/ month`,
                    price: property.perMonthPrice,
                    suffix: 'Per month',
                }
            } else if (property.bookingType === HOT || (property.bookingType === FLEXIBLE && searchBookingType === HOT)) {
                return {
                    str: `${property.perNightPrice}/ night`,
                    price: property.perNightPrice,
                    suffix: 'Per night',
                }
            }
        } else {
            return calculateRoomAvgPrice(property?.sleepingArrangements || [], searchBookingType, property.bookingType, property.rentalType, property)
        }
    }

    useEffect(() => {
        setFetchingProperty(true)
        const newSearchParam = new URLSearchParams(history.location.search)

        newSearchParam.set('bookingType', globalBookingType)

        history.push({
            pathname: '/property-listing',
            search: newSearchParam.toString(),
        })
    }, [globalBookingType])

    useEffect(() => {
        const arr = propertyList.sort(function (a, b) {
            var nameA = a.listingName.toLowerCase()
            var nameB = b.listingName.toLowerCase()
            if (sorting === 'Z-A') {
                if (nameA < nameB) return 1
                if (nameA > nameB) return -1
            } else if (sorting === 'A-Z') {
                if (nameA > nameB) return 1
                if (nameA < nameB) return -1
            } else if (sorting === 'Reviews') {
                if (a.totalReview < b.totalReview) return 1
                if (a.totalReview >= b.totalReview) return -1
            } else if (sorting === 'TopRated') {
                if (a.rating < b.rating) return 1
                if (a.rating >= b.rating) return -1
            } else if (sorting === 'AirportClosest') {
                if (a.mile < b.mile) return -1
                if (a.mile >= b.mile) return 1
            } else if (sorting === 'LowHigh') {
                if (filterPrice(a).price < filterPrice(b).price) return -1
                if (filterPrice(a).price >= filterPrice(b).price) return 1
            } else if (sorting === 'HighLow') {
                if (filterPrice(a).price > filterPrice(b).price) return -1
                if (filterPrice(a).price <= filterPrice(b).price) return 1
            }
            return 0
        })

        setPropertyList([...arr])
    }, [sorting])

    useEffect(() => {
        const tommorow = new Date()
        tommorow.setDate(new Date().getDate() + 1)

        let filterRequest = {
            ...filterOptions,
            price: [filterOptions['price'][0], filterOptions['price'][1] > 1000 ? '1000+' : filterOptions['price'][1]],
            radiusToAirport: [
                filterOptions['radiusToAirport'][0],
                filterOptions['radiusToAirport'][1] > 100 ? '100+' : filterOptions['radiusToAirport'][1],
            ],
            userId: user.id,
            stayLimit: query.get('bookingType') === 'Cold bed' || query.get('bookingType') === 'Flexible' ? filterOptions['stayLimit'] : [],
            checkIn: moment(query.get('checkIn') || new Date() + '').format('L'),
            checkOut: moment(query.get('checkOut') || tommorow + '').format('L'),
            booking_type: query.get('bookingType') || bookingTypeList[0],
        }
        console.log('testing throttle call')
        throtteledFetchProperty(filterRequest, fetchingLocation)
    }, [filterOptions, fetchingLocation, user])

    useEffect(() => {
        const locationQuery = async () => {
            try {
                setFetchingLocation(true)
                const iata = query.get('iata')
                if (iata) {
                    getIataInfo(iata, (data) => {
                        const coords = {
                            lat: data.airport.latitude,
                            lng: data.airport.longitude,
                        }

                        setFilterOptions((e) => ({
                            ...e,
                            airportLocation: true,
                            airportName: query.get('iata'),
                            latlng: [coords.lng, coords.lat],
                        }))
                    })
                } else {
                    console.log('error', 'bsdk')
                    const pos = await getCoords()

                    const coords = {
                        lat: pos.coords.latitude,
                        lng: pos.coords.longitude,
                    }

                    setFilterOptions((e) => ({
                        ...e,
                        airportLocation: false,
                        airportName: null,
                        latlng: [coords.lng, coords.lat],
                    }))
                }
            } catch (error) {
                toast.error('Please allow location sharing to show properties nearby.', { toastId: 'pleas_allow_location_error' })
                navigator.permissions
                    .query({
                        name: 'geolocation',
                    })
                    .then(function (result) {})
                setFilterOptions((e) => ({
                    ...e,
                    airportLocation: false,
                    airportName: null,
                    latlng: [],
                }))
            } finally {
                setFetchingLocation(false)
            }
        }

        if (query.get('airportName')) {
            setAirportName(query.get('airportName'))
        } else {
            setAirportName('')
        }

        locationQuery()
    }, [query])

    return (
        <>
            <Header />
            {filterModal && (
                <FilterModal
                    title="Filters"
                    formData={filterOptions}
                    onClose={() => setFilterModal(false)}
                    onSubmit={(e) => {
                        setFilterModal(false)
                        setFilterOptions({ ...e })
                    }}
                />
            )}
            <Section className="px-4 mt-16 lg:mt-24">
                <MobileNavOptions setGlobalBookingType={setGlobalBookingType} />
                <SearchTopBar setGlobalBookingType={setGlobalBookingType} />
                <div className="my-4 hidden md:flex flex-wrap gap-y-3 gap-x-2 items-center justify-start  space-x-2  w-full scrollbar-hide p-2 ">
                    <button
                        onClick={() => setFilterModal(!filterModal)}
                        className="px-4  p-2 shadow-md rounded-full flex text-gray-600 justify-between text-xs  items-center ring-1 ring-gray-200"
                    >
                        <GoSettings size={18} className="mr-2 text-gray-600 " /> Filter
                    </button>
                    <button
                        onClick={handleFilterReset}
                        className="px-4  p-2 shadow-md rounded-full flex text-gray-600 justify-between text-xs  items-center ring-1 ring-gray-200"
                    >
                        <GrPowerReset size={16} className="mr-2 text-gray-600 " /> Reset
                    </button>
                    <div className="hidden md:flex items-center flex-wrap justify-start gap-2.5 ">
                        <DropdownList
                            data={[
                                { key: 'Crashpad', value: 'Crashpad' },
                                { key: 'Entire Place', value: 'Entire Place' },
                            ]}
                            title={`Listing Type (${filterOptions['listing_type'].length})`}
                            id="listing_type"
                            value={filterOptions['listing_type']}
                            setValue={setFilterOptions}
                        />
                        <DropdownList
                            data={[
                                { key: 'House', value: 'House' },
                                { key: 'Apartment', value: 'Apartment' },
                                { key: 'Hotel', value: 'Hotel' },
                            ]}
                            title={`Property Type  (${filterOptions['property_type'].length})`}
                            id="property_type"
                            value={filterOptions['property_type']}
                            setValue={setFilterOptions}
                        />

                        <DropdownList
                            data={[
                                {
                                    key: 'Flight Attendant Only',
                                    value: 'Flight Attendant Only',
                                },
                                { key: 'Pilot Only', value: 'Pilot Only' },
                                {
                                    key: 'Flight Attendant and Pilot',
                                    value: 'Flight Attendant and Pilot',
                                },
                                {
                                    key: 'All Airline Professionals',
                                    value: 'All Airline Professionals',
                                },
                            ]}
                            title={`Profession (${filterOptions['profession'].length})`}
                            id="profession"
                            value={filterOptions['profession']}
                            setValue={setFilterOptions}
                        />
                        <DropdownRange
                            onChange={(val) => setFilterOptions((e) => ({ ...e, price: val }))}
                            value={filterOptions['price']}
                            title={`$${filterOptions['price'][0]}-$${filterOptions['price'][1] > 1000 ? `1000+ ` : filterOptions['price'][1]}`}
                            prefix={'$'}
                            min={0}
                            max={1001}
                        />
                        <DropdownRange
                            onChange={(val) => setFilterOptions((e) => ({ ...e, radiusToAirport: val }))}
                            value={filterOptions['radiusToAirport']}
                            title={`${filterOptions['radiusToAirport'][0]}mi-${
                                filterOptions['radiusToAirport'][1] > 100 ? '100+ ' : filterOptions['radiusToAirport'][1]
                            }mi`}
                            subHeading="Radius"
                            suffix={'mi'}
                            min={0}
                            max={101}
                        />
                        <div className="hidden lg:block">
                            <DropdownListUnique
                                data={[
                                    // {
                                    //   key: "A-Z",
                                    //   value: "A-Z",
                                    // },
                                    // { key: "Z-A", value: "Z-A" },
                                    { key: 'Number of Reviews', value: 'Reviews' },
                                    { key: 'Closest to Airport', value: 'AirportClosest' },
                                    { key: 'Top Rated', value: 'TopRated' },
                                    {
                                        key: 'Low-High',
                                        value: 'LowHigh',
                                    },
                                    {
                                        key: 'High-Low',
                                        value: 'HighLow',
                                    },
                                ]}
                                value={sorting}
                                unique
                                setValue={setSorting}
                                title="Sort"
                            />
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-4 mt-4 ">
                    <div className="flex flex-col  md:flex-row  md:justify-between mb-3 md:items-center col-span-full  lg:col-span-8">
                        <div className="flex justify-center gap-1.5 md:justify-start items-center">
                            <div className="md:hidden ">
                                <DropdownListUnique
                                    data={[
                                        // {
                                        //   key: "A-Z",
                                        //   value: "A-Z",
                                        // },
                                        // { key: "Z-A", value: "Z-A" },
                                        { key: 'Number of Reviews', value: 'Reviews' },
                                        { key: 'Closest to Airport', value: 'AirportClosest' },
                                        { key: 'Top Rated', value: 'TopRated' },
                                        {
                                            key: 'Low-High',
                                            value: 'LowHigh',
                                        },
                                        {
                                            key: 'High-Low',
                                            value: 'HighLow',
                                        },
                                    ]}
                                    value={sorting}
                                    unique
                                    setValue={setSorting}
                                    title="Sort"
                                />
                            </div>
                            <button
                                className="px-3.5 my-2 p-2 space-x-0 md:hidden shadow-md rounded-full flex text-gray-600 justify-between text-xs  items-center ring-1 ring-gray-400 "
                                onClick={() => setPropertyMap((e) => !e)}
                            >
                                Toggle Map
                            </button>
                            <button
                                onClick={() => setFilterModal(!filterModal)}
                                className="px-3.5 my-2 p-2  md:hidden shadow-md rounded-full flex text-gray-600 justify-between text-xs  items-center ring-1 ring-gray-400 "
                            >
                                <GoSettings size={18} className="mr-2 text-gray-600 " /> Filter
                            </button>
                        </div>
                    </div>
                </div>
                {airportName && !fetchingProperty && (
                    <h1 className="text-2xl mb-4 text-primary-blue font-medium">
                        {airportName}, {propertyList.length}{' '}
                        {propertyList.length === 0 ? 'properties' : propertyList.length === 1 ? 'property' : 'properties'} found
                    </h1>
                )}
                <div className="grid grid-cols-12 relative gap-4  overflow-y-auto   lg:h-80-screen">
                    <div className="col-span-full  md:col-span-8  overflow-y-scroll scrollbar-hide ">
                        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3  auto-rows-max gap-4 px-2 pb-4  min-h-[60vh] ">
                            {fetchingProperty ? (
                                <div className="bg-gray-100 flex-center h-[645px] col-span-full ">
                                    <div className="bg-white rounded shadow-md p-4">
                                        <div className="loader"></div>
                                    </div>
                                </div>
                            ) : Boolean(propertyList.length) ? (
                                propertyList.map((el, ind) => {
                                    return (
                                        <React.Fragment key={ind}>
                                            <ProductCard
                                                searchBookingType={query.get('bookingType')}
                                                lat={filterOptions['latlng'][1]}
                                                lng={filterOptions['latlng'][0]}
                                                airportName={query.get('iata')}
                                                property={el}
                                            />
                                        </React.Fragment>
                                    )
                                })
                            ) : (
                                <>
                                    {fetchingProperty === false && propertyList.length === 0 && (
                                        <p className="text-xl text-primary-blue text-center my-44  col-span-full">
                                            Crewmates will soon be arriving at your airport domicile!{' '}
                                            <a
                                                className="underline cursor-pointer font-bold"
                                                href="https://www.facebook.com/groups/crewmatescrashpads/"
                                            >
                                                Join our Facebook group
                                            </a>{' '}
                                            to connect with hosts.
                                        </p>
                                    )}

                                    {/* <p className="text-xl text-primary-blue text-center my-44  col-span-full">No property found</p> */}
                                </>
                            )}
                        </div>
                    </div>

                    <div
                        className={`row-start-1 md:row-start-auto scrollbar-hide overflow-scroll  col-span-full  md:col-span-4 h-full ${
                            propertyMap ? ' absolute h-full w-full z-[200]' : ' '
                        }`}
                    >
                        <div className="h-full w-full relative">
                            <PropertyListingMap
                                propertyList={propertyList}
                                filterOptions={filterOptions}
                                lat={filterOptions['latlng'][1]}
                                lng={filterOptions['latlng'][0]}
                                mapkey={propertyList.length > 0 ? propertyList[0].id : 'ee'}
                            />
                        </div>
                    </div>
                </div>
            </Section>
            <Footer />
        </>
    )
}

export default PropertyListing
