import React, { useState } from "react";
import Section from "@layout/Section";
import Header from "@front/Header";
import Footer from "@front/Footer";
import BreadCrunb from "@components/BreadCrunb";

function BankingDetails() {
  const [previewDetails, setPreviewDetails] = useState(true);

  return (
    <div>
      <Header />
      <Section className="px-4 mt-24  ">
        <BreadCrunb
          className="mb-10 mt-5"
          title="Booking Details"
          bread={[
            { name: "Home", path: "/" },
            { name: "Banking Details", path: "/Booking" },
          ]}
        />
        <div className="grid grid-cols-12 my-10">
          <div className="col-span-full  md:col-span-8 xl:col-span-7 ">
            <div className="rounded-xl ring-1 ring-gray-100 bg-white p-4 shadow-xl">
              <h2 className="text-lg font-medium flex justify-between items-center ">
                Property Information{" "}
                <img
                  alt=""
                  onClick={() => setPreviewDetails(!previewDetails)}
                  src="/assets/resources/blue-dropdown.png"
                  className={`cursor-pointer ${
                    previewDetails ? "" : "transform rotate-180 "
                  } `}
                />
              </h2>

              {previewDetails && (
                <>
                  <p className="rounded flex items-center justify-start my-4 p-2 bg-primary-blue text-white">
                    <span className="mr-2 font-semibold">$</span>
                    <span className="text-xs font-light">
                      Best in budget:Skylane value stay is a great choice
                    </span>
                  </p>
                  <div className="flex flex-col sm:flex-row  items-start justify-between ">
                    <div className="space-y-2 ">
                      <p className="text-xl font-semibold">
                        Skylane Apartment Chicago Loop - Theatre District Hotel
                      </p>
                      <p className="text-sm font-light text-gray-400">
                        2 Miles away from Airport
                      </p>
                      <span className=" flex items-center justify-start mb-1">
                        <img
                          src="/assets/resources/star.svg"
                          alt="review star"
                          className="w-4 mr-1"
                        />
                        <img
                          src="/assets/resources/star.svg"
                          alt="review star"
                          className="w-4 mr-1"
                        />
                        <img
                          src="/assets/resources/star.svg"
                          alt="review star"
                          className="w-4 mr-1"
                        />
                        <img
                          src="/assets/resources/star.svg"
                          alt="review star"
                          className="w-4 "
                        />
                      </span>
                    </div>
                    <img
                      alt=""
                      className="my-2 sm:my-0 w-full sm:w-auto object-cover h-32 rounded-md"
                      src="/assets/resources/room1.png"
                    />
                  </div>
                  <div className="bg-gray-light ring-1 ring-gray-300 my-10 ">
                    <div className="grid grid-cols-3 py-2 px-4 xl:px-10">
                      <div className="p-2 space-y-2">
                        <p className="text-xs text-gray-400">check in</p>
                        <p className="text-lg md:text-2xl font-medium  ">
                          14th oct, 2021
                        </p>
                        <p className="text-xs text-gray-400">Thursday 1PM</p>
                      </div>
                      <div className="p-2 flex-center">
                        <hr className="border-dashed w-10" />
                        <span className="bg-white ring-1 ring-gray-300 rounded-md p-2 text-center text-sm text-gray-400 font-light ">
                          2 Nights
                        </span>
                        <hr className="border-dashed w-10" />
                      </div>
                      <div className="p-2 space-y-2">
                        <p className="text-xs text-gray-400">check in</p>
                        <p className="text-lg md:text-2xl font-medium  ">
                          24th oct, 2021
                        </p>
                        <p className="text-xs text-gray-400">Thursday 1PM</p>
                      </div>
                    </div>
                    <div className="grid grid-cols-3">
                      <div className="p-4 border-t flex-center flex-col border-l border-gray-200">
                        <p className="text-xs text-gray-400">Bedrooms</p>
                        <p className="text-xl font-semibold">2</p>
                      </div>
                      <div className="p-4 border-t flex-center flex-col border-l border-gray-200">
                        <p className="text-xs text-gray-400">Bed</p>
                        <p className="text-xl font-semibold">B1,B2</p>
                      </div>
                      <div className="p-4 border-t flex-center flex-col border-l border-gray-200">
                        <p className="text-lg md:text-xl font-semibold">
                          2 Guests
                        </p>
                        <p className="text-lg md:text-xl font-semibold">
                          1 Room
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h4 className="text-xl font-medium mb-5">
                      Booking Details
                    </h4>
                    <div className="text-base space-y-4 pb-5 text-gray-500 pr-5">
                      <p className="flex justify-between items-center">
                        <span className="font-light">Booking Type</span>
                        <span className="font-medium text-primary-blue-dim">
                          Hot Bed
                        </span>
                      </p>
                      <p className="flex justify-between items-center">
                        <span className="font-light">Bathroom</span>
                        <span className="font-medium text-primary-blue-dim">
                          Two (2)
                        </span>
                      </p>
                      <p className="flex justify-between items-center">
                        <span className="font-light">Profession</span>
                        <span className="font-medium text-primary-blue-dim">
                          Pilot
                        </span>
                      </p>
                      <p className="flex justify-between items-center">
                        <span className="font-light">Gender</span>
                        <span className="font-medium text-primary-blue-dim">
                          Male
                        </span>
                      </p>
                      <p className="flex justify-between items-center">
                        <span className="font-light">Stay Limits</span>
                        <span className="font-medium text-primary-blue-dim">
                          30 Nights
                        </span>
                      </p>
                      <p className="flex justify-between items-center">
                        <span className="font-light">Cancellation Policy</span>
                        <span className="font-medium underline text-primary-blue-dim">
                          Flexible
                        </span>
                      </p>
                      <p className="flex justify-between items-center">
                        <span className="font-light">Bed</span>
                        <span className="font-medium  text-primary-blue-dim">
                          Bottom & Top Bunk
                        </span>
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="my-10 shadow-xl rounded-xl ">
              <h4 className="p-4 bg-parrot text-white text-lg font-semibold rounded-xl">
                Important Information
              </h4>
              <div className="p-4">
                <h4 className="text-xl font-medium mb-5">Hotel Rules</h4>
                <div className="font-light text-sm space-y-2 text-gray-500 ">
                  <p>
                    ~ Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                  <p>
                    ~ Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                  <p>
                    ~ Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                  <p>
                    ~ Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                  <p>
                    ~ Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="md:col-start-9   col-span-full  md:col-span-3 ">
            <div className="text-sm md:text-xs ring-1 ring-gray-100 font-light shadow-xl rounded-xl bg-white">
              <h2 className="text-lg uppercase font-medium p-4 border-b border-gray-400">
                Price Break up
              </h2>
              <div className=" px-4 pb-4 ">
                <p className="flex justify-between items-center py-4 border-b border-gray-200">
                  <span>
                    $300 X 2 Nights <br />{" "}
                    <span className="text-gray-400">Base Price</span>
                  </span>
                  <span>$600</span>
                </p>
                <p className="flex justify-between items-center py-4 border-b border-gray-200">
                  <span>Line Holder Price</span>
                  <span>$50</span>
                </p>
                <p className="flex justify-between items-center py-4 border-b border-gray-200">
                  <span>Service Fee</span>
                  <span>$100</span>
                </p>
                <p className="flex justify-between items-center py-4 border-b border-gray-200">
                  <span>Taxes & Service Fee</span>
                  <span>$36</span>
                </p>
                <p className="flex justify-between items-center py-4 ">
                  <span className="font-semibold">Total Amount</span>
                  <span className="font-semibold text-primary-blue-dim">
                    $710
                  </span>
                </p>
              </div>
            </div>
            <div className="my-10 text-xs font-light shadow-xl rounded-xl overflow-hidden bg-white">
              <div className="font-medium  flex justify-between items-center rounded-xl p-4 bg-primary-blue text-white">
                <span className="text-base">Amount Paid </span>
                <span className="text-base ">$710 </span>
              </div>
              <div className="flex justify-between items-center p-4 py-5">
                <p className="flex flex-col items-start justify-center text-gray-500">
                  <span className="text-sm font-medium text-gray-700">
                    American Express Card
                  </span>
                  <span className="text-base">XXXX XXXX XXXX 1234</span>
                </p>
                <img
                  src="/assets/images/american-card.png"
                  className="h-8"
                  alt="apple phone"
                />
              </div>
            </div>
            <div className="cursor-pointer  rounded-md text-white bg-primary-blue flex-center p-4">
              <img
                src="/assets/resources/download-icon.png"
                className="h-4 mr-2"
                alt="download"
              />
              <p className="font-medium">Download</p>
            </div>
          </div>
        </div>
      </Section>
      <Footer />
    </div>
  );
}

export default BankingDetails;
