import React, { useContext } from "react";
import Navbar from "@dashboard/Navbar";
import { NavLink, Switch, Route } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { BsFacebook } from "react-icons/bs";
import { AiFillApple } from "react-icons/ai";
import UpdateProfile from "./UpdateProfile";
import VerifyDetails from "./VerifyDetails";
import { Context as AuthContext } from "@context/authContext";

function MyProfile() {
  const { user } = useContext(AuthContext);
  return (
    <div>
      <Navbar user={user.name} title="My Profile" />
      <div className="my-10 flex justify-between items-center">
        {/* <div className="w-96 grid grid-cols-2 ring-1 ring-primary-blue-dark rounded-md overflow-hidden">
          <NavLink
            exact
            to="/dashboard/profile"
            className="text-sm p-4 text-center"
            activeClassName="bg-primary-blue text-white "
          >
            Update Profile
          </NavLink>
          <NavLink
            exact
            to="/dashboard/profile/verify"
            className="text-sm p-4 text-center"
            activeClassName="bg-primary-blue text-white "
          >
            Verify Details
          </NavLink>
        </div> */}
        <div className="hidden w-44 flex-center flex-col">
          <p className="text-lg font-medium">Link Accounts</p>

          <div className="flex justify-center items-center my-2 ">
            <FcGoogle size={39} className="mx-2" />
            <BsFacebook size={36} className="mx-2 text-blue-500" />
            <AiFillApple size={46} className="mx-2" />
          </div>
        </div>
      </div>
      <Switch>
        <Route exact component={UpdateProfile} path="/dashboard/profile" />
        <Route
          exact
          component={VerifyDetails}
          path="/dashboard/profile/verify"
        />
      </Switch>
    </div>
  );
}

export default MyProfile;
