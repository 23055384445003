import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import useQuery from '@hooks/useQuery'
import Modal from '@components/Modal'
import moment from 'moment'
import * as utility from '@utils/utility'
import { constant } from '../_config/constant'
import { makePayment, bookProperty } from '../_services/property'
import { toast } from 'react-toastify'
import { processMediaUrl } from '../_helper/utils'

const HOT = 'Hot bed'
const COLD = 'Cold bed'
const FLEXIBLE = 'Flexible'
const ENTIREPLACE = 'Entire Place'
const CRASHPAD = 'Crashpad'

const LINEHOLDER = 'LINEHOLDER'
const RESERVE = 'RESERVE'

const today = new Date()

function getDaysInCurrentMonth(dd) {
    const date = new Date(dd)
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
}

// const DAY_IN_MONTH = 28;

function BillingConfirmation({ data, property, onSubmit, requesting }) {
    const history = useHistory()
    const [bedInfo, setBedInfo] = useState({})
    const [roomInfo, setroomInfo] = useState({})

    useEffect(() => {
        const room = property.sleepingArrangements[parseInt(data.selectedRoom) - 1]
        setroomInfo(room)
        if (data.propertyType === CRASHPAD) {
            const bed = property.sleepingArrangements[parseInt(data.selectedRoom) - 1].beds.filter((c) => c._id === data.selectedBed)
            setBedInfo(bed)
        }
    }, [data])
    // console.log("data to check monthly checkout===>", data?.bedId);
    console.log('History===================>>>>', history)
    console.log('Property==================>>>>', property)
    console.log('data to check monthly checkout===>', data)
    var propertyRatePerMonth = 0
    var percentageOfLineHolder = 0
    var fixedOfLineHolder = 0
    let totalDiscountDeductionAmount = 0
    // var propertyBedNo ="";
    if (history.location?.state?.property?.rentalType == 'Crashpad') {
        history.location?.state?.property?.sleepingArrangements?.map((data1, index) => {
            data1.beds.map((data2, index2) => {
                // console.log("data---------------->>>>>>>>>>>>>>>>", data2?.perMonthPrice);
                // console.log("data2.historyBedID->>>>>>>>>>>>>>>>>>>", data2?._id);
                // console.log("Baher data2?._id->>>>>>>>>>>>>>>>>>>", data?.bedId)
                if (data?.bedId == data2?._id) {
                    propertyRatePerMonth = data2?.perMonthPrice
                }
            })
        })
    } else {
        propertyRatePerMonth = history.location?.state?.property?.perMonthPrice
    }
    percentageOfLineHolder = history.location?.state?.property?.lineholderDiscount

    // let totalDiscountDeductionAmount = (propertyRatePerMonth * percentageOfLineHolder ) / 100 ;
    // if(history.location?.state?.data?.discountedBedPrice > 0
    //   ){
    //     totalDiscountDeductionAmount = (propertyRatePerMonth * percentageOfLineHolder ) / 100 ;
    //   }
    if (history.location?.state?.data?.discount > 0) {
        if (history.location?.state?.property?.lineholderDiscountType == 'Percentage') {
            totalDiscountDeductionAmount = (propertyRatePerMonth * percentageOfLineHolder) / 100
        }
        if (history.location?.state?.property?.lineholderDiscountType == 'Fixed') {
            totalDiscountDeductionAmount = history.location?.state?.property?.lineholderDiscount
        }
    }

    // property?.sleepingArrangements.map((i,j)=>{
    //   i?.beds.map((i2,j2)=>{
    //     console.log("ii2222222222----->>>", i2.bedNumber);
    //     (propertyBedNo=i2.bedNumber)
    //   })
    // })
    // console.log("historyyyyyyyyyyybeeeddddddddddd--->",historyBedNo);
    // console.log("propertyBedNoooooooooooooooooooo--->",propertyBedNo);
    // if(historyBedNo === propertyBedNo){
    //   property?.sleepingArrangements.map((i,j)=>{
    //     i?.beds.map((i2,j2)=>{
    //       console.log("ii2222222222----->>>", i2?.perMonthPrice);
    //     })
    //   })
    // }
    // console.log("data?.bedId------->>>>>>>>>>", data?.bedId)
    // console.log("historyBedNo------->>>>>>>>>>", historyBedNo)
    // if(data?.bedId == historyBedNo){
    //   property?.sleepingArrangements.map((i,j)=>{
    //     i?.beds.map((i2,j2)=>{
    //       console.log("ii2222222222----->>>", i2?.perMonthPrice);
    //     })
    //   })
    // }
    // property?.sleepingArrangements.map((i, j) => {
    //   i?.beds.map((i2, j2) => {
    //     // console.log("ii2222222222----->>>", i2?.perMonthPrice);
    //   })
    // })

    return (
        <div className="shadow-lg mx-2 rounded-md mb-4 overflow-hidden ">
            {property.images && <img src={processMediaUrl(property?.images[0])} alt="room pic" className="w-full h-56 object-cover rounded-t-md" />}

            <div className="p-4">
                <div className="text-gray-800">
                    <h2 className="text-base font-medium ">{property.listingName}</h2>
                    <p className="text-xs">{history?.location?.state.location}</p>
                </div>
                <hr className="border-dashed border-gray-400 my-4" />
                <p className="flex items-center gap-4 text-sm   ">
                    {property.sleepingArrangements && (
                        <span>
                            Bedroom: <span className="font-medium">{property.sleepingArrangements[parseInt(data.selectedRoom - 1)].roomNumber}</span>
                        </span>
                    )}
                    {data.selectedBed && (
                        <span>
                            Bed:
                            <span className="font-medium"> {bedInfo[0]?.bedNumber}</span>
                        </span>
                    )}

                    <span>
                        Property:
                        <span className="font-medium"> {data.propertyType}</span>
                    </span>
                </p>
                <div className="flex justify-between  my-5 text-gray-800">
                    <div>
                        <span className="text-sm">Check In</span>
                        <p className="font-medium text-sm">{moment(data.checkInDate).format('MMMM Do, YYYY')}</p>
                    </div>
                    {data.bookingType === HOT && (
                        <div>
                            <span className="text-sm">Check Out</span>
                            <p className="font-medium text-sm">{moment(data.checkOutDate).format('MMMM Do, YYYY')}</p>
                        </div>
                    )}
                </div>
                <div className="grid grid-cols-2 text-sm border-t border-gray-700 py-3 space-y-1 my-2 text-black ">
                    <p>Booking Type</p>
                    <p className="text-right">{data?.bookingType}</p>
                    <p>Bathrooms</p>
                    <p className="text-right">{roomInfo?.numberOfBathroom || '--'}</p>
                    <p>Room Type</p>
                    <p className="text-right">{roomInfo?.roomType || '--'}</p>
                    <p>Profession</p>
                    <p className="text-right">{roomInfo?.crewType || '--'}</p>
                    <p>Gender</p>
                    <p className="text-right">{roomInfo?.genderType || '--'}</p>
                    {/* <p>Stay Limit (to ask)</p>
          <p className="text-right">30 Nights</p> */}
                    <p>Cancellation Policy</p>
                    <p className="text-right">{property.cancelationPolicy || '--'}</p>
                </div>
                {data.bookingType === COLD && (
                    <div className="grid grid-cols-2 text-sm border-t  border-gray-700 pt-3 space-y-1 mt-2 text-black ">
                        <h4 className="col-span-2 text-black text-base font-medium">Prorated Amount</h4>
                        <p>
                            Prorated {utility.getMonthFromIndex(new Date(data.checkInDate).getMonth())}({data.numberOfDay})
                        </p>
                        <p className="text-right">
                            {history.location?.state?.totalPrice?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            }) || '-'}
                        </p>

                        {history.location?.state?.cleaningFee > 0 && (
                            <>
                                <p>Cleaning Fee</p>
                                <p className="text-right">
                                    {' '}
                                    {history.location?.state?.cleaningFee?.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    }) || '-'}
                                </p>
                            </>
                        )}

                        {Boolean(data.discount) && (
                            <>
                                <p>Lineholder Discount</p>
                                <p className="text-right">
                                    {data.discount.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    })}
                                </p>
                            </>
                        )}
                        {property.rentalType === ENTIREPLACE && (
                            <>
                                <p>Extra Guest Charge</p>
                                <p className="text-right">
                                    {data.extraGuestCharge.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    }) || '-'}
                                </p>
                            </>
                        )}
                    </div>
                )}

                {data.bookingType === HOT && (
                    <div className="grid grid-cols-2 text-sm border-t border-gray-700 pt-3 space-y-1 mt-2 text-black ">
                        <p>({data.numberOfDay}) Night(s)</p>
                        <p className="text-right">
                            {history.location?.state?.totalPrice?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            }) || '-'}
                        </p>

                        {data.cleaningFee > 0 && (
                            <>
                                <p>Cleaning Fee</p>
                                <p className="text-right">
                                    {' '}
                                    {data.cleaningFee?.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    }) || '-'}
                                </p>
                            </>
                        )}

                        {property.rentalType === ENTIREPLACE && (
                            <>
                                <p>Extra Guest Charge</p>
                                <p className="text-right">
                                    {data.extraGuestCharge.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    }) || '-'}
                                </p>
                            </>
                        )}
                    </div>
                )}
                <div className="grid grid-cols-2 text-sm border-b border-gray-700 pb-3  space-y-1 mb-2 text-black ">
                    <p>Security Deposit</p>
                    <p className="text-right">
                        {property.securityDeposite.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        }) || '-'}
                    </p>
                </div>

                <div className="flex items-center justify-between text-lg text-primary-blue font-medium my-2">
                    <h4>Total</h4>
                    <p>
                        {(data.totalPrice - data.discount).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                    </p>
                </div>
                <button onClick={onSubmit} className="p-4 text-center bg-primary-blue text-white rounded-md w-full text-lg font-medium ">
                    {requesting ? 'Please wait...' : 'Request to Book'}
                </button>
                {data.bookingType === COLD && data.isMonthlyCheckout === 2 && data.propertyType != 'Entire Place' && (
                    <div className="py-5 flex justify-between items-center">
                        <span className="p-2 px-4 bg-parrot-light  bg-opacity-20 rounded-lg text-gray-700   text-sm">
                            Due on {utility.month[utility.getNextMonth(data.checkInDate).getMonth()]} 1st{' '}
                        </span>
                        <span className="font-medium text-primary-blue">
                            {(propertyRatePerMonth + history.location.state.property.cleaningFee - totalDiscountDeductionAmount).toLocaleString(
                                'en-US',
                                {
                                    style: 'currency',
                                    currency: 'USD',
                                }
                            )}
                        </span>
                    </div>
                )}
                {data.bookingType === COLD && data.isMonthlyCheckout === 2 && data.propertyType == 'Entire Place' && (
                    <div className="py-5 flex justify-between items-center">
                        <span className="p-2 px-4 bg-parrot-light  bg-opacity-20 rounded-lg text-gray-700   text-sm">
                            Due on {utility.month[utility.getNextMonth(data.checkInDate).getMonth()]} 1st{' '}
                        </span>
                        <span className="font-medium text-primary-blue">
                            {(
                                propertyRatePerMonth +
                                history.location.state.property.cleaningFee -
                                totalDiscountDeductionAmount +
                                parseFloat(history.location.state.property?.extraChargePerGuest) *
                                    (isNaN(history.location.state?.data?.extraGuestCount) ? 0 : history.location.state?.data?.extraGuestCount)
                            ).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })}
                        </span>
                    </div>
                )}
            </div>
        </div>
    )
}

export default BillingConfirmation
