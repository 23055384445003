import axios from 'axios'
import { constant } from '@config/constant'
import { getAuthHeader } from '@helper/header'
import { handleResponse, handleErrorResponse } from '../_helper/utils'

const api = axios.create({ baseURL: constant.apiUrl })

export const getCardList = async () => {
    try {
        const response = await api.get('/user/card', getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export const addNewCard = async (data) => {
    try {
        const response = await api.post('/user/card', data, getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export const deleteCard = async (id) => {
    try {
        const response = await api.delete(`/user/card/${id}`, getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export const makePrimary = async (id, fromBooking) => {
    try {
        const response = await api.post(`/user/card/primary`, { cardId: id, fromBooking }, getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}
