import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Section from '@layout/Section'
import Header from '@front/Header'
import Footer from '@front/Footer'
import Modal from '@components/Modal'
import BreadCrunb from '@components/BreadCrunb'
import { getPropertyDetails, getBookingDetails } from '@services/property'
import BillingConfirmation from '@components/BillingConfirmation'
import { FiChevronRight } from 'react-icons/fi'
import { toast } from 'react-toastify'

const COLD = 'Cold bed'

function Booking() {
    const history = useHistory()
    const [resolveState, setResolveState] = useState(false)
    const [paymentSchedule, setPaymentSchedule] = useState(0)
    const [bookingData, setbookingData] = useState({})
    const [fetchingProperty, setfetchingProperty] = useState(true)
    const [requesting, setRequesting] = useState(false)

    async function goToPaymentConfirmation() {
        try {
            const state = history.location.state
            if (paymentSchedule == 0 && state.data.bookingType === COLD) {
                toast.warn('Please choose one of the payout method!',{toastId:93})
                return
            }

            history.push({
                pathname: '/payment-confirmation',
                state: {
                    data: { ...state.data, isMonthlyCheckout: paymentSchedule },
                    totalPrice: state.totalPrice,
                    cleaningFee: state.cleaningFee,
                    property: { ...state.property, extraGuestCount: history.location.state.extraGuestCount },
                    location: state.location,
                },
            })
        } catch (error) {
            toast.error('Something went wrong',{toastId:94})
        } finally {
            setRequesting(false)
        }
    }

    useEffect(() => {
        if (!history.location.state) {
            history.goBack()
            return
        } else {
            console.log(history.location.state)
            setbookingData(history.location.state.property)
            setfetchingProperty(false)
            setResolveState(true)
        }
    }, [])

    return (
        <div>
            {resolveState && (
                <>
                    <Header type="fixed" />
                    {fetchingProperty && (
                        <Modal>
                            <div className="bg-white rounded shadow-md p-4">
                                <div className="loader"></div>
                            </div>
                        </Modal>
                    )}
                    <Section className="px-4">
                        <BreadCrunb
                            className="my-10 overflow-scroll scrollbar-hide"
                            bread={[
                                { name: 'Home', path: '/' },
                                { name: 'Search result', path: '/property-listing' },
                                {
                                    name: bookingData.listingName,
                                    path: `/property-details/${bookingData._id}`,
                                },
                            ]}
                        />
                    </Section>
                    <Section className="px-4">
                        <div className="grid grid-cols-6 gap-10 ">
                            <div className="col-span-6 lg:col-span-3  xl:col-span-4 text-primary-blue text-base">
                                <div>
                                    <h2 className="text-2xl font-medium mb-10">Booking Preview</h2>
                                    {/* <h4 className="text-2xl font-medium my-4">
                    Tell Crewmate About Your Stay
                  </h4>

                  <p className="my-2">Some helpful tips on what to write:</p>
                  <ul className="list-disc space-y-1 text-sm pl-5">
                    <li>What bring you to crewmate ?</li>
                    <li>Coordinates check-in plans and key exchange</li>
                    <li>Ask for recommendation in their neighbourhood</li>
                  </ul> */}
                                </div>

                                <div>
                                    <div className="flex justify-between items-center pt-4 ">
                                        <h2 className="text-2xl font-medium">House Rules</h2>
                                        {/* <button
                      onClick={() => {
                        setToggleHouseRule((e) => !e);
                      }}
                      className="ring-1 hover:bg-primary-blue hover:text-white ring-gray-500 text-center p-1 px-4 rounded-full outline-none text-sm text-gray-500"
                    >
                      View House Rules
                    </button> */}
                                    </div>

                                    <p className="my-4 text-sm font-medium">By booking this space you're agreeing to follow the House Rules.</p>
                                    {Boolean(bookingData.houseRules?.length) && (
                                        <div>
                                            {bookingData.houseRules.map((curr, ind) => (
                                                <p className="text-sm mb-2" key={ind}>
                                                    {ind + 1}. {curr}
                                                </p>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                {history.location.state.data.bookingType === COLD && (
                                    <>
                                        <form>
                                            <h2 className="text-2xl font-medium mb-4">Which sounds like you?</h2>
                                            <div className="mb-8 flex justify-start items-start">
                                                <input
                                                    className="h-6 w-6 focus:ring-0"
                                                    id="type-1-booking"
                                                    type="checkbox"
                                                    checked={paymentSchedule === 1}
                                                    name="payment_schedule_type1"
                                                    onChange={(e) => {
                                                        setPaymentSchedule((e) => {
                                                            if (e == 0) return 1
                                                            return e === 1 ? 0 : 1
                                                        })
                                                    }}
                                                />
                                                <label className="text-sm ml-2 text-gray-600" htmlFor="type-1-booking">
                                                    I have been awarded a base transfer or have other plans for next month. I am staying only until
                                                    the first day of the next month. I understand that other guests can check in starting the 1st and
                                                    I will have to move out or rebook contingent upon availability. Checkout will be required on or
                                                    before 11am on the 1st.
                                                </label>
                                            </div>
                                            <div className="flex justify-start items-start">
                                                <input
                                                    className="h-6 w-6 focus:ring-0"
                                                    id="type-2-booking"
                                                    type="checkbox"
                                                    name="payment_schedule_type2"
                                                    checked={paymentSchedule === 2}
                                                    onChange={(e) => {
                                                        setPaymentSchedule((e) => {
                                                            if (e == 0) return 2
                                                            return e === 2 ? 0 : 2
                                                        })
                                                    }}
                                                />
                                                <label className="text-sm ml-2 text-gray-600" htmlFor="type-2-booking">
                                                    I will be based near this airport domicile for over a month. I understand that my card on file
                                                    will be charged the 1st of next month for a full month rent plus applicable fees. My bed will be
                                                    reserved exclusively for me until I submit my 30 night notice to leave.
                                                </label>
                                            </div>
                                        </form>
                                    </>
                                )}
                            </div>
                            <div className="col-span-6 lg:col-span-3  xl:col-span-2">
                                <div className="lg:w-96 w-full mx-auto pb-4">
                                    {bookingData && (
                                        <BillingConfirmation
                                            data={history.location.state.data}
                                            property={bookingData}
                                            isMonthlyCheckout={paymentSchedule === 1}
                                            requesting={false}
                                            onSubmit={goToPaymentConfirmation}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </Section>
                    <Footer />
                </>
            )}
        </div>
    )
}

export default Booking
