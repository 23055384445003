import React, { useState, useEffect, forwardRef } from 'react'
import Header from '@front/Header'
import Footer from '@front/Footer'
import Section from '@layout/Section'
import Modal from '@components/Modal'
import ErrorWrapper from '@components/ErrorWrapper'
import { Link, useHistory } from 'react-router-dom'
import { VscAdd } from 'react-icons/vsc'
import { AnimatePresence, motion } from 'framer-motion'
import BillingConfirmation from '@components/BillingConfirmation'
import BreadCrunb from '@components/BreadCrunb'
import { bank as bankApi, property as propertyApi } from '@services/index'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import { BsCreditCard2Back } from 'react-icons/bs'
import { FiChevronDown } from 'react-icons/fi'
import getCreditCardImage from '@utils/getCreditCardImage'
import { toast } from 'react-toastify'

import moment from 'moment'
import { isWithinSevenDays } from '../utils/utility'
import '../styles/payment-confirmation-style.css'
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'

const COLD = 'Cold bed'

function CardComponent({ data, setPrimary, primary, updateDefaultCardRequired }) {
    console.log({ updateDefaultCardRequired })
    const history = useHistory()
    return (
        <div
            className={`lg:w-auto my-4 lg:mx-4 grid items-center sm:gap-2.5 md:gap-4 gap-2  justify-between p-2 rounded-lg ${
                data.isExpired ? 'opacity-50' : ''
            }
            grid-cols-1 md:grid-cols-2 xl:grid-cols-3
        `}
        >
            <div
                className={`w-full hidden md:col-span-full xl:col-span-2 sm:flex flex-col sm:flex-row  items-start sm:items-center justify-start gap-2 sm:gap-5 md:gap-8 p-2 rounded-lg ${
                    updateDefaultCardRequired && data.isPrimary && 'border border-red-500 shadow-md temp-class'
                }  lg:w-auto justify-self-start`}
            >
                <input
                    checked={primary === data.id}
                    onChange={(e) => {
                        if (e.target.checked) {
                            setPrimary(data.id)
                        } else setPrimary('')
                    }}
                    type="checkbox"
                    className="h-8 w-8 rounded"
                    disabled={data.isExpired || (updateDefaultCardRequired && data.isPrimary)}
                />
                <img src={`/assets/images/${getCreditCardImage(data.brand)}.png`} className="h-12  rounded-md" alt="apple phone" />

                <div className="text-base text-gray-500">
                    <p>XXXX-XXXX-XXXX-{data.last4}</p>
                    <p>{`${data.exp_year}-${String(data.exp_month).padStart(2, '0')}`}</p>
                </div>

                {data.isExpired && (
                    <div
                        className="bg-red-500 text-white p-1"
                        style={{
                            width: '70px',
                            textAlign: 'center',
                            fontSize: '13px',
                            borderRadius: '3px',
                        }}
                    >
                        Expired
                    </div>
                )}
                {data.isExpiredNextMonth && (
                    <div
                        className="bg-yellow-600 text-white p-1"
                        style={{
                            width: '70px',
                            textAlign: 'center',
                            fontSize: '13px',
                            borderRadius: '3px',
                        }}
                    >
                        EXP. Soon
                    </div>
                )}
                {data.isPrimary && (
                    <>
                        <div
                            className="bg-blue-600 text-white p-1"
                            style={{
                                width: '70px',
                                textAlign: 'center',
                                fontSize: '13px',
                                borderRadius: '3px',
                            }}
                        >
                            Default
                        </div>
                    </>
                )}
            </div>

            <div
                className={`w-full  md:col-span-full xl:col-span-2 flex flex-wrap sm:hidden sm:flex-row  items-start sm:items-center justify-start gap-2 sm:gap-5 md:gap-8 p-2 rounded-lg ${
                    updateDefaultCardRequired && data.isPrimary && 'border border-red-500 shadow-md temp-class'
                }  lg:w-auto justify-self-start`}
            >
                <div className="flex items-center gap-3">
                    <input
                        checked={primary === data.id}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setPrimary(data.id)
                            } else setPrimary('')
                        }}
                        type="checkbox"
                        className="h-8 w-8 rounded"
                        disabled={data.isExpired || (updateDefaultCardRequired && data.isPrimary)}
                    />
                    <img src={`/assets/images/${getCreditCardImage(data.brand)}.png`} className="h-12  rounded-md" alt="apple phone" />
                </div>
                <div className="flex items-center flex-wrap gap-4">
                    <div className="text-base text-gray-500">
                        <p>XXXX-XXXX-XXXX-{data.last4}</p>
                        <p>{`${data.exp_year}-${String(data.exp_month).padStart(2, '0')}`}</p>
                    </div>
                    {data.isExpired && (
                        <div
                            className="bg-red-500 text-white p-1"
                            style={{
                                width: '70px',
                                textAlign: 'center',
                                fontSize: '13px',
                                borderRadius: '3px',
                            }}
                        >
                            Expired
                        </div>
                    )}
                    {data.isExpiredNextMonth && (
                        <div
                            className="bg-yellow-600 text-white p-1"
                            style={{
                                width: '70px',
                                textAlign: 'center',
                                fontSize: '13px',
                                borderRadius: '3px',
                            }}
                        >
                            EXP. Soon
                        </div>
                    )}
                    {data.isPrimary && (
                        <>
                            <div
                                className="bg-blue-600 text-white p-1"
                                style={{
                                    width: '70px',
                                    textAlign: 'center',
                                    fontSize: '13px',
                                    borderRadius: '3px',
                                }}
                            >
                                Default
                            </div>
                        </>
                    )}
                </div>
            </div>

            {data.isPrimary && updateDefaultCardRequired && (
                <div
                    onClick={() => {
                        history.push('/dashboard/setting/?from=payment-confirmation')
                    }}
                    className="bg-red-400 p-2 text-white mt-1"
                    style={{
                        width: '160px',
                        textAlign: 'center',
                        fontSize: '13px',
                        borderRadius: '3px',
                    }}
                >
                    Update Default Card
                </div>
            )}
        </div>
    )
}

function AddBillingAddressModal({ onClose }) {
    return (
        <Modal className="w-full md:w-3/12 lg:w-4/12 relative">
            <div className="bg-white rounded-xl ">
                <div className="bg-primary-blue p-4  text-white rounded-xl">
                    <h4 className="text-lg font-semibold ">Add Billing Address</h4>
                    <p className="text-xs font-light">Please fill your details below</p>
                </div>
                <div onClick={onClose} className="h-8 w-8 bg-white absolute -right-3 -top-3 flex-center rounded-full ring-2 ring-primary-blue ">
                    <VscAdd size={20} className="text-primary-blue cursor-pointer  transform rotate-45 " />
                </div>
                <div className="p-4 bg-white rounded-b-xl">
                    <form className="grid grid-cols-2 gap-2 space-y-2">
                        <div className="col-span-2 ">
                            <label className="block text-sm mb-1 text-primary-blue-dim " htmlFor="address-1">
                                Address Line 1
                            </label>
                            <input
                                type="text"
                                placeholder="Flat no. 2435"
                                className="rounded-md bg-white text-primary-blue-dim ring-1 ring-gray-600  p-3 text-xs w-full outline-none placeholder-primary-blue-dim "
                            />
                        </div>
                        <div className="col-span-2 ">
                            <label className="block text-sm mb-1 text-primary-blue-dim " htmlFor="address-1">
                                Address Line 2
                            </label>
                            <input
                                type="text"
                                placeholder="Letix Road"
                                className="rounded-md bg-white text-primary-blue-dim ring-1 ring-gray-600  p-3 text-xs w-full outline-none placeholder-primary-blue-dim "
                            />
                        </div>
                        <div>
                            <label className="block text-sm mb-1 text-primary-blue-dim " htmlFor="address-1">
                                City
                            </label>
                            <input
                                type="text"
                                placeholder="Singrauli"
                                className="rounded-md bg-white text-primary-blue-dim ring-1 ring-gray-600  p-3 text-xs w-full outline-none placeholder-primary-blue-dim "
                            />
                        </div>
                        <div>
                            <label className="block text-sm mb-1 text-primary-blue-dim " htmlFor="address-1">
                                State
                            </label>
                            <input
                                type="text"
                                placeholder="Punjab"
                                className="rounded-md bg-white text-primary-blue-dim ring-1 ring-gray-600  p-3 text-xs w-full outline-none placeholder-primary-blue-dim "
                            />
                        </div>
                        <div>
                            <label className="block text-sm mb-1 text-primary-blue-dim " htmlFor="address-1">
                                Country
                            </label>
                            <input
                                type="text"
                                placeholder="India"
                                className="rounded-md bg-white text-primary-blue-dim ring-1 ring-gray-600  p-3 text-xs w-full outline-none placeholder-primary-blue-dim "
                            />
                        </div>
                        <div>
                            <label className="block text-sm mb-1 text-primary-blue-dim " htmlFor="address-1">
                                Zip Code
                            </label>
                            <input
                                type="text"
                                placeholder="486886"
                                className="rounded-md bg-white text-primary-blue-dim ring-1 ring-gray-600  p-3 text-xs w-full outline-none placeholder-primary-blue-dim "
                            />
                        </div>
                        <p className="flex items-center justify-start text-xs ">
                            <IoIosCheckmarkCircle size={14} className="text-blue-500 mr-1 flex-shrink-0" />
                            Save card Details
                        </p>
                        <button className="rounded-xl  col-span-2 p-4 text-center font-medium text-white bg-primary-blue ">ADD ADDRESS</button>
                    </form>
                </div>
            </div>
        </Modal>
    )
}

function PaymentConfirmationModal({ onClose, isUnder7 }) {
    console.log({ isUnder7 })
    return (
        <div className="p-10">
            <Modal className="w-11/12 md:w-3/12  relative">
                <div className="bg-white rounded-xl ">
                    <div className="bg-primary-blue p-4  text-white rounded-xl">
                        <img src="/assets/resources/group-727.png" alt="check" className="h-20 mx-auto" />
                        <p className="text-base text-center my-2 font-light">Booking Request Sent</p>
                        <p className="text-xs text-center font-light"></p>
                    </div>

                    <div className="p-4 bg-white rounded-b-xl">
                        <img src="/assets/resources/payment-success.png" alt="girl" className=" mx-auto" />
                        <p className="text-sm text-center">
                            {isUnder7 ? (
                                <>
                                    Your booking request has been submitted. There will be a hold on your default card, but will only turn into a real
                                    charge once the host accepts the reservation. If you look at your bank account online it may show as a pending
                                    transaction. If the host declines the request, the hold will be released from your card within 3-8 business days.
                                </>
                            ) : (
                                <>
                                    Your booking request has been submitted. If the host accepts the reservation, there will be a hold on your default card
                                    approximately 7 days prior to check in. The authorization will turn into a real charge approximately 1 day before
                                    check in. If you look at your bank account online it may show as a pending transaction. If the reservation is
                                    canceled during your cancellation period, the hold will be released from your card within 3-8 business days.
                                </>
                            )}
                        </p>
                        <Link
                            to="/dashboard/my-booking"
                            className="mt-5 rounded-xl w-max font-light mx-auto p-4 px-6 text-center text-xs  text-white bg-primary-blue block"
                        >
                            Go to my booking
                        </Link>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
function PaymentConfirmation() {
    const history = useHistory()
    const state = history.location.state

    const [resolveState, setResolveState] = useState(false)
    const [refreshKey, setRefreshKey] = useState(0)
    const [requesting, setRequesting] = useState(false)
    const [reservationRequest, setReservationRequest] = useState(false)
    const [primaryCard, setPrimaryCard] = useState('')
    const [fetching, setfetching] = useState(true)
    const [addedCard, setAddedCard] = useState([])
    const [addCardForm, setAddCardForm] = useState(false)
    const [addAddressModal, setAddAddressModal] = useState(false)
    const [paymentSuccess, setPaymentSuccess] = useState(false)
    const [isUnder7Day, setIsUnder7Day] = useState(false)
    const [updateDefaultCardRequired, setUpdateDefaultCardRequired] = useState(false)
    const [name, setName] = useState('')
    const stripe = useStripe()
    const elements = useElements()
    const [errors, setErrors] = useState({})

    useEffect(() => {
        if (state && state.data) {
            const checkInDateStr = state.data.checkInDate
            const checkinDate = new Date(checkInDateStr)
            const check = isWithinSevenDays(checkinDate)
            setIsUnder7Day(check)
        }
    }, [])

    const handleAddnewCard = async (e) => {
        try {
            e.preventDefault()
            if (addedCard.length >= 10) {
                toast.warn('You can only add up to 10 cards at a time. Please remove a card and try again.');
                return
            } 

            if (!stripe || !elements) {
                return
            }

            setRequesting(true)
            const cardElement = elements.getElement(CardNumberElement)
            const { error } = await stripe.createToken(cardElement)
            if (name.trim().length === 0) {
                setErrors((prev) => ({ ...prev, name: 'Card holder name is required.' }))
            }
            if (error) {
                let er = {}

                if (error.type === 'validation_error') {
                    if (error.code === 'incomplete_number') {
                        er['cardNumber'] = error.message
                    }
                    if (error.code === 'invalid_expiry_year_past' || error.code === 'exp_year') {
                        er['cardExpiry'] = error.message
                    }
                    if (error.code === 'incomplete_cvc') {
                        er['cardCvc'] = error.message
                    }
                    setErrors((prev) => ({ ...prev, ...er }))
                }

                setRequesting(false)
                return
            }

            if (!error && name.length > 0) {
                const { token } = await stripe.createToken(cardElement, { name })
                const res = await bankApi.addNewCard(token)
                if (res.data.status) {
                    toast.success(res.data.message, { toastId: 11 })
                } else toast.error(res.data.message)
                setRefreshKey((e) => e + 1)
                e.target.reset()
                setAddCardForm(false)
            }
        } catch (error) {
            console.log('errorerror ==>>> ', error)
            toast.error('Something went wrong!')
        } finally {
            setRequesting(false)
        }
    }

    const handleReservation = async () => {
        try {
            setReservationRequest(true)
            if (!primaryCard) {
                return toast.error('Please choose payment card')
            }

            if (state.data.bookingType == COLD && state.data.isMonthlyCheckout === 1) {
                const dd = new Date(state.data.checkInDate)
                state.data['checkOutDate'] = moment(new Date(dd.getFullYear(), dd.getMonth() + 1, 1)).format('L')
            }

            // state.data.totalPrice = state.data.totalPrice - state.data.discount;
            setUpdateDefaultCardRequired(false)
            const res = await propertyApi.bookProperty({
                ...state.data,
                totalPrice: (state.data.totalPrice - state.data.discount).toFixed(2) * 1,
                cardId: primaryCard,
            })
            console.log({ myres: res })
            if (res.data.status) {
                setPaymentSuccess(true)
            } else if (res.data.statusCode === '203') {
                history.push('/login')
            } else {
                toast.error(res.data.message)

                if (res.data && res.data.message === 'There was an error validating your payment method. Please update your default card.') {
                    setUpdateDefaultCardRequired(true)
                }
            }
        } catch (error) {
        } finally {
            setReservationRequest(false)
        }
    }

    useEffect(() => {
        const fetchCards = async () => {
            try {
                const { data } = await bankApi.getCardList()
                if (data.status) {
                    if(isWithinSevenDays(new Date(state.data.checkInDate))){
                        setAddedCard(data.data.data)
                    }else{
                        const defaultCard=data.data.data.filter(c=>c.isPrimary);
                        setAddedCard(defaultCard)
                    }
                    
                }
            } catch (error) {
            } finally {
                setfetching(false)
            }
        }
        if (!state) {
            history.goBack()
        } else {
            setResolveState(true)
        }
        fetchCards()
    }, [refreshKey])

    return (
        <>
            {resolveState ? (
                <div>
                    <Header />
                    {addAddressModal && <AddBillingAddressModal onClose={() => setAddAddressModal(false)} />}
                    {paymentSuccess && <PaymentConfirmationModal isUnder7={isUnder7Day} onClose={() => setPaymentSuccess(false)} />}

                    <Section className="px-4 mt-24  ">
                        <BreadCrunb
                            className="mb-10 mt-5"
                            title="Payment"
                            bread={[
                                { name: 'Home', path: '/' },
                                { name: 'Properties', path: '/property-listing' },
                                { name: 'Payment', path: '' },
                            ]}
                        />

                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
                            <div className="lg:col-span-2 ">
                                <h4 className="text-2xl font-medium text-primary-blue ">Choose your payment method below</h4>
                                <div className="flex items-center my-10"></div>
                                <div className="my-5 transition-all rounded-xl shadow-md  p-4 cursor-pointer">
                                    <div>
                                        <h4 className="flex items-center justify-between font-medium text-xl ">
                                            <span className="flex items-center">
                                                <BsCreditCard2Back size={24} className="mr-4 text-primary-blue" /> Add New Card
                                            </span>{' '}
                                            <span onClick={() => setAddCardForm(!addCardForm)}>
                                                {addCardForm ? (
                                                    <FiChevronDown className="transform rotate-180" size={30} />
                                                ) : (
                                                    <FiChevronDown size={30} />
                                                )}
                                            </span>
                                        </h4>
                                        <p className="flex flex-col sm:items-center sm:gap-2.5 sm:flex-row items-start gap-1.5 md:space-x-4 mt-2 ">
                                            <span>We accept</span>
                                            <div className="flex items-center gap-1">
                                                <img src="/assets/images/AMEX.png" className="w-14" alt="american pay" />
                                                <img src="/assets/images/MASTERCARD.png" className="w-14" alt="master card" />
                                                <img src="/assets/images/VISA.png" className="w-14" alt="visa" />
                                            </div>
                                        </p>
                                    </div>
                                    <AnimatePresence>
                                        {addCardForm && (
                                            <motion.form
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                className="md:mt-10 mt-2.5 sm:mt-5 space-y-4 text-sm"
                                                onSubmit={handleAddnewCard}
                                            >
                                                <span className="grid md:grid-cols-3 grid-cols-1 gap-2 md:gap-4">
                                                    <h4 className="text-sm text-primary-blue-dim">Card Number</h4>
                                                    <CardNumberElement
                                                        onChange={(e) => {
                                                            if (e) {
                                                                if (e.empty) {
                                                                    setErrors((prev) => ({ ...prev, [e.elementType]: 'Card number is required.' }))
                                                                } else if (e.error) {
                                                                    setErrors((prev) => ({ ...prev, [e.elementType]: e.error.message }))
                                                                } else if (e.complete) {
                                                                    setErrors((prev) => ({ ...prev, [e.elementType]: null }))
                                                                }
                                                            }
                                                        }}
                                                        className="outline-none col-span-full  ring-1 ring-gray-200  p-2 rounded-md text-xs"
                                                    />
                                                    {errors && errors.cardNumber && <ErrorWrapper>{errors.cardNumber}</ErrorWrapper>}
                                                </span>
                                                <span className="grid md:grid-cols-3 grid-cols-1 gap-2 md:gap-4">
                                                    <h4 className="text-sm text-primary-blue-dim">Expiration Date</h4>
                                                    <span className="grid-cols-6 col-span-full grid gap-4">
                                                        <div className="col-span-4">
                                                            <div className="ring-1 ring-[#6B7280] rounded-md flex items-center">
                                                                <div className="flex items-center w-full gap-3">
                                                                    <CardExpiryElement
                                                                        onChange={(e) => {
                                                                            if (e) {
                                                                                if (e.empty) {
                                                                                    setErrors((prev) => ({
                                                                                        ...prev,
                                                                                        [e.elementType]: 'Card expiration date is required.',
                                                                                    }))
                                                                                } else if (e.error) {
                                                                                    setErrors((prev) => ({
                                                                                        ...prev,
                                                                                        [e.elementType]: e.error.message,
                                                                                    }))
                                                                                } else if (e.complete) {
                                                                                    setErrors((prev) => ({ ...prev, [e.elementType]: null }))
                                                                                }
                                                                            }
                                                                        }}
                                                                        className="rounded-md border-0  bg-white text-primary-blue-dim ring-1 ring-gray-200  p-2 text-xs w-full outline-none placeholder-primary-blue-dim "
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="p-3">
                                                                {errors && errors.cardExpiry && <ErrorWrapper>{errors.cardExpiry}</ErrorWrapper>}
                                                            </div>
                                                        </div>
                                                        <div className="col-span-2">
                                                            <CardCvcElement
                                                                name="cvv"
                                                                placeholder="cvv"
                                                                options={{ placeholder: 'CVV' }}
                                                                className="outline-none  ring-1 ring-gray-200  p-2 rounded-md"
                                                                onChange={(e) => {
                                                                    if (e) {
                                                                        if (e.empty) {
                                                                            setErrors((prev) => ({
                                                                                ...prev,
                                                                                [e.elementType]: 'Card CVV is required.',
                                                                            }))
                                                                        } else if (e.error) {
                                                                            setErrors((prev) => ({ ...prev, [e.elementType]: e.error.message }))
                                                                        } else if (e.complete) {
                                                                            setErrors((prev) => ({ ...prev, [e.elementType]: null }))
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                            <div className="p-3">
                                                                {errors && errors.cardCvc && <ErrorWrapper>{errors.cardCvc}</ErrorWrapper>}
                                                            </div>
                                                        </div>
                                                    </span>
                                                </span>
                                                <span className="md:grid-cols-3 grid grid-cols-1 gap-2 md:gap-4">
                                                    <h4 className="text-sm text-primary-blue-dim">Name on card</h4>
                                                    <input
                                                        name="holderName"
                                                        placeholder="Exact name on credit card"
                                                        className=" outline-none col-span-full ring-1 ring-gray-200  p-2 rounded-md text-xs"
                                                        onChange={(e) => {
                                                            setName(e.target.value)
                                                            if (name.trim().length > 0) {
                                                                errors.name = ''
                                                            }
                                                        }}
                                                    />
                                                    {errors && errors.name && <ErrorWrapper>{errors.name}</ErrorWrapper>}
                                                </span>

                                                <button className="block w-full font-medium text-center bg-primary-blue text-white rounded-md p-4 mt-8 ">
                                                    {requesting ? 'Please wait...' : 'ADD CARD'}
                                                </button>
                                            </motion.form>
                                        )}
                                    </AnimatePresence>
                                </div>

                                <div className="my-5 rounded-xl shadow-md p-4 cursor-pointer">
                                    <div>
                                        <h4 className="flex items-center justify-between font-medium text-xl ">
                                            <span className="flex items-center">
                                                <BsCreditCard2Back size={24} className="mr-4 text-primary-blue" /> Saved Cards
                                            </span>{' '}
                                        </h4>
                                        {!fetching &&
                                            addedCard.map((curr) => {
                                                return (
                                                    <CardComponent
                                                        primary={primaryCard}
                                                        updateDefaultCardRequired={updateDefaultCardRequired}
                                                        setPrimary={setPrimaryCard}
                                                        data={curr}
                                                        key={curr.id}
                                                    />
                                                )
                                            })}
                                        {fetching && <p className="m-4">Loading...</p>}
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="mx-auto">
                                    <BillingConfirmation
                                        data={history.location.state.data}
                                        property={history.location.state.property}
                                        requesting={reservationRequest}
                                        onSubmit={handleReservation}
                                    />
                                </div>
                            </div>
                        </div>
                    </Section>
                    <Footer />
                </div>
            ) : (
                <></>
            )}
        </>
    )
}

export default PaymentConfirmation
