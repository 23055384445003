import React from 'react'
import { processMediaUrl } from '../../../_helper/utils'

const ChatCard = ({ onSelectChannel, data, activeChannel, setViewList, updateUnreadCount }) => {
    // Ensure data is defined before proceeding
    if (!data) {
        return null
    }

    const handleClick = () => {
        // Ensure channelId is defined before proceeding
        if (data.channelId) {
            updateUnreadCount(data.channelId, 0) // Reset unread count
            onSelectChannel({
                channelId: data.channelId,
                name: data.name,
                property: data.property,
                lastMessageDate: data.lastMessageDate,
                unreadMessages: data.unreadMessages,
                lastMessageText: data.lastMessageText,
                users: data.users,
            })
            setViewList(true)
        }
    }

    const userStatusForChannel = data?.users?.displayState || false

    return (
        <div
            onClick={handleClick}
            className={`flex justify-between rounded-md cursor-pointer chat-spacing text-primary-blue p-4 hover:bg-[#e6ebf5] ${
                activeChannel?.channelId === data?.channelId ? 'bg-[#e6ebf5] text-black' : ''
            } space-x-2`}
        >
            <div className="flex items-center justify-start space-x-2 w-full">
                <div>
                    <span className="relative flex-shrink-0 w-11 block">
                        <img alt="" src={processMediaUrl(data?.property?.picture || '')} className="h-11 w-11 rounded-full object-cover" />
                        {userStatusForChannel ? (
                            <span
                                className="h-2 w-2 block absolute border border-white bg-[#06d6a0] rounded"
                                style={{ right: '3px', bottom: '3px' }}
                            ></span>
                        ) : (
                            <span
                                className="h-2 w-2 block absolute border border-white bg-[#ff3434] rounded"
                                style={{ right: '3px', bottom: '3px' }}
                            ></span>
                        )}
                    </span>
                </div>
                <div className="truncate flex flex-row justify-between items-center w-full relative">
                    <div className="w-full">
                        <p className="text-sm font-medium mb-1 truncate flex justify-between">
                            {data?.property?.name || 'Unknown'}
                            {data?.lastMessageDate && (
                                <p className="text-xs text-gray-500">
                                    {new Date(data.lastMessageDate).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                                </p>
                            )}
                        </p>
                        <p className="text-xs font-medium mb-1 truncate">{data?.property?.host || ''}</p>
                        <p className="text-xs font-medium mb-1">{data?.property?.reservationId || ''}</p>
                        {data?.lastMessageText && (
                            <p className="text-xs text-gray-500 truncate" style={{ maxWidth: '250px' }}>
                                {data.lastMessageText}
                            </p>
                        )}
                    </div>
                    {Boolean(data?.unreadMessages) && (
                        <div className="h-6 w-6 rounded-full bg-[#c6d9f5] text-xs grid place-content-center text-[#23426e] font-semibold absolute right-0 top-5">
                            {data.unreadMessages}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ChatCard
