import React, { useState } from "react";
import { PHONE, EMAIL } from "@utils/Regex";

function TextInput({
  value,
  setValue,
  label,
  field = "text",
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  datalist = [],
  listId,
  className,
  validateField = true,
  ...props
}) {
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState(false);

  const validate = (input) => {
    switch (field) {
      case "email": {
        const test = EMAIL.test(String(input).toLowerCase());

        if (test) {
          {
            setError(false);
          }
        } else {
          setError(true);
        }

        return;
      }
      case "mobile": {
        const test = PHONE.test(input);
        if (test) {
          setError(false);
        } else setError(true);

        return;
      }

      case "password": {
        const test = input.length >= 8;
        if (test) {
          setError(false);
        } else setError(true);

        return;
      }

      default: {
        if (input.length === 0) {
          setError(true);
        } else setError(false);
      }
    }
  };
  const handleValidation = (e) => {
    const userInput = e.target.value;
    setValue(userInput);

    if (touched) {
      validate(userInput);
    }
  };
  return (
    <div
      className={`flex flex-col space-y-1 justify-start items-start ${className}`}
    >
      {label && <label className="text-gray-600 text-sm">{label}</label>}
      <div
        className={`flex items-center ring-1 ${
          error ? "ring-red-400 text-red-600" : "ring-gray-200 text-gray-600"
        } rounded w-full bg-gray-100`}
      >
        {LeftIcon && (
          <span className="border-r p-2   border-gray-300">{LeftIcon}</span>
        )}
        <input
          onFocus={() => setTouched(true)}
          {...props}
          className="p-2 flex-grow text-sm  outline-none bg-transparent ring-0 border-0 focus:ring-0 focus:border-0"
          value={value}
          list={listId}
          onChange={
            validateField ? handleValidation : (e) => setValue(e.target.value)
          }
        />
        {RightIcon && <span className="p-2">{RightIcon}</span>}
        {/* {touched && !error && value.length > 2 && (
          <span className="p-2">
            {' '}
            <FiCheckCircle size={24} className="text-green-500" />
          </span>
        )} */}
        <datalist id={listId}>
          {datalist.map((key, i) => {
            return <option value={key} key={i} />;
          })}
        </datalist>
      </div>
    </div>
  );
}

export default TextInput;
