import React, { useState } from "react";
import { RiMessage2Fill } from "react-icons/ri";
import TextInput from "@ui/TextInput";
import Button from "@ui/Button";
import Header from "@front/Header";
import Footer from "@front/Footer";
import Section from "@layout/Section";
import { toast, ToastContainer } from "react-toastify";
import { forgetPassword } from "@services/user";
import { setResetToken } from "@helper/header";
import { verifyPhone, resetPassword } from "@services/user";
import { useHistory } from "react-router-dom";
import { constant } from "../_config/constant";
import { logout } from "../_services/user";

function OTPScreen({ onSubmit }) {
  const [otp, setOtp] = useState("");
  const [isRequestion, setRequesting] = useState(false);

  const handleResendOtp = async () => {
    const request = JSON.parse(
      localStorage.getItem(constant.RESET_REQUEST_PHONE)
    );
    const res = await forgetPassword(request);
    if (res.data.status) {
      setResetToken(res.data.data.token);
      toast.success("OTP sent",{toastId:14});
    } else toast.error("Something wrong happened!");
  };
  return (
    <div className="mt-8 w-full md:w-8/12 lg:w-5/12 mx-auto ">
      <h4 className="mb-2 text-center text-primary-blue text-3xl font-bold ">
        Reset Password
      </h4>

      <div className="grid grid-cols-1 gap-y-2 mt-10 mb-5 ">
        <TextInput
          type="number"
          field="otp"
          label="Enter OTP"
          value={otp}
          setValue={setOtp}
          leftIcon={<RiMessage2Fill size={24} />}
        />
      </div>
      <Button
        className="block mx-auto my-4"
        btnType="filled"
        onClick={() => {
          setRequesting(true);
          onSubmit(otp, () => setRequesting(false));
        }}
        disabled={isRequestion}
      >
        {isRequestion ? "Please wait ..." : "Continue"}
      </Button>
      <p
        onClick={handleResendOtp}
        className="text-primary-blue text-center  cursor-pointer text-sm my-4"
      >
        Resend OTP
      </p>
    </div>
  );
}

function ResetPasswordPhone() {
  const history = useHistory();
  const [formStep, setFormStep] = useState(0);
  const [isRequestion, setRequesting] = useState(false);

  const [Password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleOtp = async (otp, callback) => {
    try {
      let res = await verifyPhone({ otp });

      if (res.data.status) {
        setFormStep(1);
      } else {
        toast.error(res.data.message);
        callback();
      }
    } catch {
      toast.error("Something went wrong");
      setTimeout(logout, 1000);
    }
  };

  const handleChangePassword = async () => {
    try {
      setRequesting(false);
      if (Password.length < 8) {
        toast.error("Password must have at least 8 characters.");
        return;
      }

      if (Password != confirmPassword) {
        toast.error("Password and confirm password must be same.");
        return;
      }
      const res = await resetPassword({
        newPassword: Password,
        confirmPassword: confirmPassword,
      });

      if (res.data.status) {
        history.push("/login");
      } else {
        toast.error(res.data.message);
        setRequesting(false);
      }
    } catch {
      toast.error("Something went wrong");
      setTimeout(logout, 1000);
    }
  };

  return (
    <>
      <Header type="fixed" />
      <ToastContainer />

      <Section className="mt-10 px-4 flex-center flex-col">
        {formStep === 0 ? (
          <OTPScreen onSubmit={handleOtp} />
        ) : (
          <div className="mt-8  w-full md:w-8/12 lg:w-5/12 mx-auto ">
            <h4 className="mb-2 text-center text-primary-blue text-3xl font-bold ">
              Reset Password
            </h4>

            <div className="grid grid-cols-1 gap-y-4 mb-5 ">
              <TextInput
                type="password"
                field="password"
                label="Enter New Password"
                value={Password}
                setValue={setPassword}
              />
              <TextInput
                type="password"
                field="password"
                label="Re-Enter New Password"
                value={confirmPassword}
                setValue={setConfirmPassword}
              />
            </div>
            <Button
              className="block mx-auto my-4"
              btnType="filled"
              onClick={() => {
                setRequesting(true);
                handleChangePassword();
              }}
              disabled={isRequestion}
            >
              {isRequestion ? "Please wait ..." : "Done"}
            </Button>
          </div>
        )}
      </Section>
      <Footer />
    </>
  );
}

export default ResetPasswordPhone;
