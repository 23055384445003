import axios from "axios";
import { constant } from "@config/constant";
import { getAuthHeader } from "@helper/header";
import { handleErrorResponse } from "../_helper/utils";

const api = axios.create({ baseURL: constant.apiUrl });

export const getTransactions = async ({ bookingId }) => {
  const response = await api.get(
    `/user/transaction/booking/${bookingId}`,
    getAuthHeader()
  );
  return response;
};

export const getTransactionDetails = async ({ transactionId }) => {
  const response = await api.get(
    `/user/transaction/${transactionId}/detail`,
    getAuthHeader()
  );
  return response;
};

export const getRefundDetails = async ({ bookingId }) => {
  const response = await api.get(`/user/refund/${bookingId}`, getAuthHeader());
  return response;
};
