import React, { useRef, useState } from 'react'
import { makePropertyFavourite } from '../_services/property'
import useWindowDimensions from '@hooks/useWindowDimension'
import { BsFillHeartFill, BsHeart } from 'react-icons/bs'
import { HiOutlineChevronLeft } from 'react-icons/hi'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Link, useHistory } from 'react-router-dom'
import { constant } from '../_config/constant'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { toast } from 'react-toastify'
import useQuery from '@hooks/useQuery'
import StarRatings from 'react-star-ratings'
import { processMediaUrl } from '../_helper/utils'
// // install Swiper modules
SwiperCore.use([Navigation, Pagination])

const HOT = 'Hot bed'
const COLD = 'Cold bed'
const FLEXIBLE = 'Flexible'

const ENTIREPLACE = 'Entire Place'
const CRASHPAD = 'Crashpad'

export const calculateRoomAvgPrice = (sleepingArrangements, searchBookingType = HOT, bookingType, rentalType) => {
    let price = 0
    let totalBed = 0

    if (rentalType === CRASHPAD) {
        if (bookingType === HOT || (bookingType === FLEXIBLE && searchBookingType === HOT)) {
            sleepingArrangements.map(({ beds }) => {
                totalBed += beds.length || 0
                beds.map((curr) => {
                    price += curr.perNightPrice
                })
            })
            totalBed = totalBed || 1
            // return `${Math.round(price / totalBed)} avg / night`;
            return {
                str: `${Math.round(price / totalBed)} avg / night`,
                price: Math.round(price / totalBed),
                suffix: 'avg per night',
            }
        } else if (bookingType === COLD || (bookingType === FLEXIBLE && searchBookingType === COLD)) {
            sleepingArrangements.map(({ beds }) => {
                totalBed += beds.length || 0
                beds.map((curr) => {
                    price += curr.perMonthPrice
                })
            })
            totalBed = totalBed || 1
            // return `${Math.round(price / totalBed)} avg / month`;

            return {
                str: `${Math.round(price / totalBed)} avg / month`,
                price: Math.round(price / totalBed),
                suffix: 'avg per month',
            }
        }
    }
}

const getTommorow = () => {
    const tommorow = new Date()
    tommorow.setDate(new Date().getDate() + 1)

    return tommorow.toISOString().split('T')[0]
}

function PropertyCard({ property, className, lat, lng, airportName, setRefreshKey, searchBookingType = HOT, navigationBookingType }) {
    const query = useQuery()
    const value = property.hasOwnProperty('rating') ? property['rating'] : 0
    const rating = new Array(Math.ceil(value)).fill(-1)
    const { width } = useWindowDimensions()
    const prevRef = useRef(null)
    const nextRef = useRef(null)
    const history = useHistory()
    const [fav, setFav] = useState(property.isFavourite)

    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return `<span class="${className} h-2 w-2 bg-white shadow-md rounded-full z-100"></span>`
        },
    }

    const handleFavouriteProperty = async (e) => {
        e.stopPropagation()
        try {
            setFav(!fav)
            const res = await makePropertyFavourite(property._id)

            if (res.data.status) {
                if (fav && setRefreshKey) {
                    setRefreshKey((e) => e + 1)
                } else {
                    setFav(!fav)
                }
            } else toast.error(res.data.message, { toastId: 92 })
        } catch (error) {
            if (!error.response.data.status) {
                setFav(false)

                toast.error(
                    <>
                        Please{' '}
                        <Link to="/register" className="text-primary-blue ">
                            {' '}
                            register
                        </Link>{' '}
                        or{' '}
                        <Link to="/login" className="text-primary-blue ">
                            log in{' '}
                        </Link>{' '}
                        to add properties to your favorites list.
                    </>
                )
            }
        }
    }

    function priceCalculator() {
        if (property.rentalType === ENTIREPLACE) {
            if (property.bookingType === COLD || (property.bookingType === FLEXIBLE && searchBookingType === COLD)) {
                return {
                    str: `${property.perMonthPrice}/ month`,
                    price: property.perMonthPrice,
                    suffix: 'Per month',
                }
            } else if (property.bookingType === HOT || (property.bookingType === FLEXIBLE && searchBookingType === HOT)) {
                return {
                    str: `${property.perNightPrice}/ night`,
                    price: property.perNightPrice,
                    suffix: 'Per night',
                }
            }
        } else {
            const calc = calculateRoomAvgPrice(
                property?.sleepingArrangements || [],
                searchBookingType,
                property.bookingType,
                property.rentalType,
                property
            )
            return calc
        }
    }

    function handleNavigation() {
        const newSearchParam = new URLSearchParams(history.location.search)
        if (lat && lng) {
            newSearchParam.set('lat', lat)
            newSearchParam.set('lng', lng)
            newSearchParam.set('airportName', airportName)
        }

        if (!query.get('bookingType') && !navigationBookingType) {
            if (property.bookingType === COLD || property.bookingType === FLEXIBLE) {
                newSearchParam.set('bookingType', COLD)
            } else {
                newSearchParam.set('bookingType', HOT)
            }
        }

        if (navigationBookingType) {
            newSearchParam.set('bookingType', navigationBookingType)
        }

        if (query.get('checkIn')) {
            newSearchParam.set('checkIn', new Date(query.get('checkIn') || new Date()).toISOString().split('T')[0])
        } else {
            newSearchParam.set('checkIn', new Date().toISOString().split('T')[0])
        }

        if (query.get('checkOut')) {
            newSearchParam.set('checkOut', new Date(query.get('checkOut') || new Date()).toISOString().split('T')[0])
        } else {
            newSearchParam.set('checkOut', getTommorow())
        }

        newSearchParam.set('distance', property.distance)
        newSearchParam.set('price', priceCalculator().price)
        newSearchParam.set('suffix', priceCalculator().suffix)

        history.push({
            pathname: `/property-details/${property._id}`,
            search: newSearchParam.toString(),
        })
    }

    return (
        <div
            className={`${className} rounded-3xl h-32 md:h-auto  shadow-lg  md:shadow-xl overflow-hidden grid grid-cols-6 grid-rows-2 md:grid-rows-1   text-gray-500`}
        >
            <div className="w-full h-full relative  md:h-56    col-span-2 md:row-start-1  md:col-span-full row-span-full  md:row-span-1">
                {width > 768 && (
                    <>
                        <div
                            ref={prevRef}
                            className="absolute opacity-0   hover:opacity-100  top-0 bottom-0 my-auto left-5 text-white z-20 h-10 w-10  cursor-pointer flex-center"
                        >
                            <HiOutlineChevronLeft size={20} className=" " />
                        </div>

                        <div
                            ref={nextRef}
                            className="opacity-0   hover:opacity-100  absolute top-0 bottom-0 my-auto right-5 text-white  h-10 w-10  z-20 cursor-pointer flex-center"
                        >
                            <HiOutlineChevronLeft size={20} className="transform rotate-180   " />
                        </div>
                        {Boolean(property.images.length) && (
                            <Swiper
                                navigation={{
                                    prevEl: prevRef.current,
                                    nextEl: nextRef.current,
                                }}
                                onSwiper={(swiper) => {
                                    // Delay execution for the refs to be defined
                                    swiper.params.navigation.prevEl = prevRef.current
                                    swiper.params.navigation.nextEl = nextRef.current

                                    // Re-init navigation
                                    swiper.navigation.destroy()
                                    swiper.navigation.init()
                                    swiper.navigation.update()
                                }}
                                pagination={pagination}
                                className="h-full w-full"
                            >
                                {property.images.slice(0, 5).map((image,index) => {
                                    return (
                                        <SwiperSlide key={image+''+index} className="h-full w-full">
                                            <div className=" h-full w-full rounded-t-3xl overflow-hidden">
                                                <img alt="axon" className="w-full h-full   object-cover" src={processMediaUrl(image)} />
                                            </div>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        )}
                    </>
                )}
                {width < 768 && <img alt="axon" className="w-full h-full rounded-l-3xl object-cover" src={processMediaUrl(property.images[0])} />}
                {Boolean(property.hostId.airlineEmailVerification) && (
                    <img src="/assets/images/cm-verified.png" alt="cm verified" className="absolute top-5 left-5 z-20 w-10" />
                )}
                {fav ? (
                    <BsFillHeartFill
                        onClick={handleFavouriteProperty}
                        size={24}
                        fill={fav ? '#FF5B38' : 'white'}
                        className="text-white cursor-pointer hidden md:inline-block absolute top-5 right-5 z-20"
                    />
                ) : (
                    <BsHeart
                        onClick={handleFavouriteProperty}
                        size={24}
                        className="text-white cursor-pointer hidden md:inline-block absolute top-5 right-5 z-20"
                    />
                )}
            </div>
            <div
                onClick={handleNavigation}
                className=" cursor-pointer pl-2 md:px-4  py-2 md:p-4 flex-col lg:flex-row  flex justify-between items-center  row-start-1 md:row-start-2 col-span-4   md:col-span-full"
            >
                <div className="w-full lg:w-7/12">
                    <h4 className="text-base font-medium truncate">{property.listingName}</h4>
                    <div className="flex-shrink-0 sm:gap-6 flex flex-col sm:flex-row lg:hidden items-start sm:items-center gap-0.5 justify-start">
                        <span className="flex-center text-yellow-500">
                            <span className=" text-yellow-500 mb-1">
                                <StarRatings rating={property.rating} starDimension="18px" starSpacing="0px" starRatedColor="#eab308" />
                            </span>
                        </span>
                        <p className="text-xs text-parrot-dark ">{property.totalReview || '0'} Reviews</p>
                    </div>
                    {/* <p className="text-xs font-light">${property.perMonthPrice}/ Month</p> */}
                    <p className="text-xs font-light mt-0.5">${priceCalculator()?.str}</p>
                    <p className="text-xs font-light hidden lg:block text-bold">{property.hostId.name}</p>
                    <div className="flex-grow flex md:hidden justify-between items-center py-1 md:p-4 row-start-2   md:row-start-3 col-span-4   md:col-span-full">
                        <div className="flex items-center gap-0.5 sm:gap-2">
                            {' '}
                            <img alt="gps" className="h-3 transform -rotate-45 mr-1" src="/assets/resources/tele.png" />
                            <button onClick={handleNavigation} className="flex-shrink-0 ml-2  text-parrot text-sm  font-semibold  ">
                                <span>View Now</span>
                                {/* {lat && lng ? (
            <Link
              to={`/property-details/${property._id}?distance=${
                property.distance
              }&price=${`${priceCalculator().price}`}&suffix=${
                priceCalculator().suffix
              }&lat=${lat}&lng=${lng}&airportName=${airportName}`}
            >
              <span>View Now</span>
            </Link>
          ) : (
            <Link
              to={`/property-details/${property._id}?distance=${
                property.distance
              }&price=${`${priceCalculator().price}`}&suffix=${
                priceCalculator().suffix
              }`}
            >
              <span>View Now</span>
            </Link>
          )} */}
                            </button>
                        </div>
                        <div className="md:hidden">
                            {fav ? (
                                <BsFillHeartFill
                                    onClick={handleFavouriteProperty}
                                    size={24}
                                    fill={fav ? '#FF5B38' : 'white'}
                                    className="text-white cursor-pointer md:hidden inline-block  top-5 right-5 z-20 mr-4"
                                />
                            ) : (
                                <BsHeart
                                    onClick={handleFavouriteProperty}
                                    size={24}
                                    className="text-[#B8B8B8] cursor-pointer md:hidden inline-block  top-5 right-5 z-20 mr-4"
                                />
                            )}
                        </div>

                        <div className="text-gray-400 hidden lg:block">
                            <p className="text-xs ">{property.distance}</p>
                        </div>
                    </div>
                </div>

                <div className="flex-shrink-0 hidden lg:flex items-end flex-col justify-center   ">
                    <span className="flex-center text-yellow-500">
                        <span className=" text-yellow-500 mb-1">
                            <StarRatings rating={property.rating} starDimension="18px" starSpacing="0px" starRatedColor="#eab308" />
                        </span>
                    </span>
                    <p className="text-xs text-parrot-dark ">{property.totalReview || '0'} Reviews</p>
                </div>
            </div>

            <div className="px-4 py-2 md:p-4 hidden md:flex justify-between items-center  row-start-2   md:row-start-3 col-span-4   md:col-span-full">
                <div className="flex-grow flex justify-start items-start ">
                    <img alt="gps" className="h-3 transform -rotate-45 mr-1" src="/assets/resources/tele.png" />
                    <div className="text-gray-400">
                        <p className="text-xs hidden md:block">{property.distance}</p>
                    </div>
                </div>
                <button onClick={handleNavigation} className="flex-shrink-0 ml-2  text-parrot text-sm  font-semibold  ">
                    <span>View Now</span>
                    {/* {lat && lng ? (
            <Link
              to={`/property-details/${property._id}?distance=${
                property.distance
              }&price=${`${priceCalculator().price}`}&suffix=${
                priceCalculator().suffix
              }&lat=${lat}&lng=${lng}&airportName=${airportName}`}
            >
              <span>View Now</span>
            </Link>
          ) : (
            <Link
              to={`/property-details/${property._id}?distance=${
                property.distance
              }&price=${`${priceCalculator().price}`}&suffix=${
                priceCalculator().suffix
              }`}
            >
              <span>View Now</span>
            </Link>
          )} */}
                </button>
            </div>
        </div>
    )
}

export default PropertyCard
