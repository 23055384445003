import React, { useState, useEffect } from 'react'
import Modal from '@components/Modal'
import Button from '@ui/Button'
import Section from '@layout/Section'
import Header from '@front/Header'
import Footer from '@front/Footer'
import BreadCrunb from '@components/BreadCrunb'

import { FiChevronDown } from 'react-icons/fi'
import { getFaqCategory, getFaqList } from '../_services/general'
import { toast } from 'react-toastify'

function Faqs() {
    const [activeSection, setActiveSection] = useState(0)
    const [open, setOpen] = useState(-1)
    const [category, setCategory] = useState(0)
    const [faqs, setFaqs] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [hostCategoryList, setHostCategoryList] = useState([])
    const [guestCategoryList, setGuestCategoryList] = useState([])
    const [activeFaqList, setActiveFaqList] = useState([])
    const [fetchingFaqs, setfetchingFaqs] = useState(true)

    const HandleAccordian = (index, func, curr) => {
        if (index === curr) {
            func(-1)
        } else {
            func(index)
        }
    }

    const renderAccordian = () => {
        return activeFaqList.map((curr, index) => {
            return (
                <div
                    key={index}
                    className={`my-b ${index !== faqs.length - 1 ? ' border-b' : ''} border-gray-300 py-5`}
                    onClick={() => HandleAccordian(index, setOpen, open)}
                >
                    <div className="flex items-center cursor-pointer justify-between">
                        <h4 className=" text-base lg:text-lg font-medium md:font-semibold">{curr.question}</h4>

                        <span className="text-white">
                            {open !== index ? (
                                <FiChevronDown size={30} className="ml-4  flex-shrink-0 text-black " />
                            ) : (
                                <FiChevronDown size={30} className="ml-4 transform rotate-180 flex-shrink-0 text-black " />
                            )}
                        </span>
                    </div>
                    <p
                        type="header-caption"
                        className={`text-sm md:text-base text-primary-blue-dim px-3 transition-all duration-300 ease-in-out overflow-hidden ${
                            index === open ? 'visible opacity-1 p-3' : 'h-0 opacity-0 invisible'
                        }`}
                    >
                        {`${curr.answer}`}
                    </p>
                </div>
            )
        })
    }

    function handleCategorySelection(id) {
        setCategory(id)
        setActiveFaqList(faqs.filter((el) => el.categoryId === id))
    }

    function handleActiveFaqList(active) {
        const type = active ? 'Host' : 'Crewmember'
        const list = faqs.filter((el) => el.userType === type)
        setActiveFaqList(list)
    }

    useEffect(() => {
        const fetchFaq = async () => {
            try {
                const faqList = await getFaqList()
                const categoryList = await getFaqCategory()
                if (faqList.data.status && categoryList.data.status) {
                    setfetchingFaqs(false)
                    setFaqs(faqList.data.data)
                    setCategoryList(categoryList.data.data)
                    const guestFilteredCategory = categoryList.data.data.filter((el) => el.userType === 'Crewmember')
                    const hostFilteredCategory = categoryList.data.data.filter((el) => el.userType === 'Host')
                    setHostCategoryList(hostFilteredCategory)
                    setGuestCategoryList(guestFilteredCategory)

                    if (guestFilteredCategory.length > 0) {
                        setCategory(guestFilteredCategory[0].id)
                    }
                } else {
                    toast.error('Check you internet connection and try again!')
                }
            } catch (error) {
                console.log(error)
                toast.error('Something went wrong!!')
            }
        }

        fetchFaq()
    }, [])

    useEffect(() => {
        let faqByUser = activeSection === 0 ? faqs.filter((f) => f.userType === 'Crewmember') : faqs.filter((f) => f.userType === 'Host')
        if (category) {
            faqByUser = faqByUser.filter((f) => f.categoryId === category)
        }
        setActiveFaqList(faqByUser)
    }, [activeSection, category])

    return (
        <div>
            {fetchingFaqs && (
                <Modal>
                    <div className="bg-white rounded shadow-md p-4">
                        <div className="loader"></div>
                    </div>
                </Modal>
            )}
            <Header />
            <Section className="px-4 mt-24  ">
                <BreadCrunb
                    className="mb-10 mt-5"
                    title="FAQ"
                    bread={[
                        { name: 'Home', path: '/' },
                        { name: 'FAQ', path: '/faq' },
                    ]}
                />
                <div>
                    <h3 className=" text-3xl text-center  md:text-5xl text-primary-blue font-bold lg:font-extrabold ">Hello, how can we help?</h3>

                    <div className="mx-auto py-8 lg:w-4/12 grid grid-cols-2 gap-10">
                        <button
                            onClick={() => {
                                setActiveSection(0)
                                if (guestCategoryList.length > 0) {
                                    handleCategorySelection(guestCategoryList[0].id)
                                }
                            }}
                            className={`text-sm lg:text-base p-4 font-medium  rounded-xl ring-1 ring-primary-blue  ${
                                !activeSection ? 'bg-primary-blue text-white ' : 'text-gray-700'
                            }`}
                        >
                            Crewmember
                        </button>
                        <button
                            onClick={() => {
                                setActiveSection(1)
                                if (hostCategoryList.length > 0) {
                                    handleCategorySelection(hostCategoryList[0].id)
                                }
                            }}
                            className={`text-sm lg:text-base p-4 font-medium  rounded-xl ring-1 ring-primary-blue  ${
                                activeSection ? 'bg-primary-blue text-white ' : 'text-gray-700'
                            }`}
                        >
                            Host
                        </button>
                    </div>
                </div>

                <div className="flex md:w-max mx-auto flex-nowrap overflow-x-scroll py-4 px-1 scrollbar-hide  items-center my-4  text-primary-blue">
                    {!activeSection
                        ? guestCategoryList.map((el) => (
                              <div
                                  key={el.id}
                                  onClick={() => handleCategorySelection(el.id)}
                                  className={`mr-2 lg:mr-4 flex-center flex-shrink-0 hover:bg-primary-blue h-32  w-36 ${
                                      category === el.id ? 'bg-primary-blue text-white ' : 'bg-white text-primary-blue'
                                  } hover:text-white  cursor-pointer flex-col  rounded-xl ring-1 ring-primary-blue-light`}
                              >
                                  <p className="text-sm text-center p-4  lg:text-base font-medium capitalize">{`${el.name}`}</p>
                              </div>
                          ))
                        : null}
                    {activeSection
                        ? hostCategoryList.map((el) => (
                              <div
                                  key={el.id}
                                  onClick={() => handleCategorySelection(el.id)}
                                  className={`mr-2 lg:mr-4 flex-center flex-shrink-0 hover:bg-primary-blue h-32  w-36 ${
                                      category === el.id ? 'bg-primary-blue text-white ' : 'bg-white text-primary-blue'
                                  } hover:text-white  cursor-pointer flex-col  rounded-xl ring-1 ring-primary-blue-light`}
                              >
                                  <p className="text-sm text-center p-4  lg:text-base font-medium capitalize">{`${el.name}`}</p>
                              </div>
                          ))
                        : null}
                </div>

                <div className="my-10 ">
                    <div className="  md:w-6/12 md:mx-auto">
                        <h4 className="text-xl lg:text-2xl  text-primary-blue font-medium mb-4 ">Frequently Asked Question</h4>
                        <div className="text-primary-blue-dark ">{renderAccordian()}</div>
                    </div>
                </div>
            </Section>
            <Footer />
        </div>
    )
}

export default Faqs

// import React, { useState } from "react";
// import Button from "@ui/Button";
// import Section from "@layout/Section";
// import Header from "@front/Header";
// import Footer from "@front/Footer";
// import BreadCrunb from "@components/BreadCrunb";
// import hostFaq from "@db/host-faq.json";
// import guestFaq from "@db/guest-faq.json";

// import { FaSearch } from "react-icons/fa";
// // import { FaHome } from "react-icons/fa";
// import { FiChevronDown } from "react-icons/fi";

// function Faqs() {
//   const [activeSection, setActiveSection] = useState(1);
//   const [open, setOpen] = useState(-1);
//   const [category, setCategory] = useState("general");
//   // const [subAccordion, setSubAccordion] = useState(-1);
//   const [faqs, setFaqs] = useState({ ...hostFaq });

//   const HandleAccordian = (index, func, curr) => {
//     if (index === curr) {
//       func(-1);
//     } else {
//       func(index);
//     }
//   };

//   const renderAccordian = () => {
//     return faqs[category].map((curr, index) => {
//       return (
//         <div
//           key={index}
//           className={`my-b ${
//             index !== faqs.length - 1 ? " border-b" : ""
//           } border-gray-300 py-5`}
//           onClick={() => HandleAccordian(index, setOpen, open)}
//         >
//           <div className="flex items-center cursor-pointer justify-between">
//             <h4 className=" text-base lg:text-lg font-medium md:font-semibold">
//               {curr.question}
//             </h4>

//             <span className="text-white">
//               {open !== index ? (
//                 <FiChevronDown
//                   size={30}
//                   className="ml-4  flex-shrink-0 text-black "
//                 />
//               ) : (
//                 <FiChevronDown
//                   size={30}
//                   className="ml-4 transform rotate-180 flex-shrink-0 text-black "
//                 />
//               )}
//             </span>
//           </div>
//           <p
//             type="header-caption"
//             className={`text-sm md:text-base text-primary-blue-dim px-3 transition-all duration-300 ease-in-out overflow-hidden ${
//               index === open
//                 ? "visible opacity-1 p-3"
//                 : "h-0 opacity-0 invisible"
//             }`}
//           >
//             {`${curr.answer}`}
//           </p>
//         </div>
//       );
//     });
//   };

//   // const renderSubCategory = () => {
//   //   return subFaqs.map((curr, index) => {
//   //     return (
//   //       <div
//   //         key={index}
//   //         className={`my-b ${
//   //           index !== subFaqs.length - 1 ? " border-b" : ""
//   //         } border-gray-300 py-5`}
//   //         onClick={() => HandleAccordian(index, setSubAccordion, subAccordion)}
//   //       >
//   //         <div className="flex items-center cursor-pointer justify-between">
//   //           <h4 className="text-base font-semibold">{curr.question}</h4>

//   //           <span className="text-white">
//   //             {subAccordion !== index ? (
//   //               <FiChevronDown
//   //                 size={30}
//   //                 className="ml-4  flex-shrink-0 text-black "
//   //               />
//   //             ) : (
//   //               <FiChevronDown
//   //                 size={30}
//   //                 className="ml-4 transform rotate-180 flex-shrink-0 text-black "
//   //               />
//   //             )}
//   //           </span>
//   //         </div>
//   //         <p
//   //           type="header-caption"
//   //           className={` text-sm text-primary-blue-dim px-3 transition-all duration-300 ease-in-out overflow-hidden ${
//   //             index === subAccordion
//   //               ? "visible opacity-1 p-3"
//   //               : "h-0 opacity-0 invisible"
//   //           }`}
//   //         >
//   //           {curr.answer}
//   //         </p>
//   //       </div>
//   //     );
//   //   });
//   // };

//   function handleActiveSection(i) {
//     if (i === 1) {
//       setFaqs(hostFaq);

//       setActiveSection(i);
//     } else {
//       setFaqs(guestFaq);
//       setActiveSection(i);
//     }
//     setCategory("general");
//   }

//   return (
//     <div>
//       <Header />
//       <Section className="px-4 mt-24  ">
//         <BreadCrunb
//           className="mb-10 mt-5"
//           title="FAQ"
//           bread={[
//             { name: "Home", path: "/" },
//             { name: "FAQ", path: "/faq" },
//           ]}
//         />
//         <div>
//           <h3 className=" text-3xl text-center  md:text-5xl text-primary-blue font-bold lg:font-extrabold ">
//             Hello, how can we help?
//           </h3>
//           {/* <form className="ring-1 items-center p-2  ring-gray-600 rounded-xl my-10 flex space-x-2 mx-auto w-full md:w-8/12 lg:w-6/12 justify-between ">
//             <FaSearch size={28} className="m-2" />
//             <input
//               size={10}
//               type="text"
//               placeholder="Ask Question"
//               className="h-full flex-grow text-sm outline-none ring-0 border-0 focus:ring-0 focus:border-0"
//             />
//             <Button btnType="filled" className="h-full">
//               Search
//             </Button>
//           </form> */}
//           <div className="mx-auto py-8 lg:w-4/12 grid grid-cols-2 gap-10">
//             <button
//               onClick={() => {
//                 handleActiveSection(0);
//               }}
//               className={`text-sm lg:text-base p-4 font-medium  rounded-xl ring-1 ring-primary-blue  ${
//                 !activeSection ? "bg-primary-blue text-white " : "text-gray-700"
//               }`}
//             >
//               Crewmember
//             </button>
//             <button
//               onClick={() => {
//                 handleActiveSection(1);
//               }}
//               className={`text-sm lg:text-base p-4 font-medium  rounded-xl ring-1 ring-primary-blue  ${
//                 activeSection ? "bg-primary-blue text-white " : "text-gray-700"
//               }`}
//             >
//               Host
//             </button>
//           </div>
//         </div>
//         {/* <div className="my-10">
//           <h4 className="text-2xl  md:text-3xl text-primary-blue font-medium text-center ">
//             Frequently Asked Question
//           </h4>
//           <p className=" text-sm lg:text-base text-gray-500 text-center my-2">
//             Lorem icon Ipsum Lorem Ipsum is simply dummy text of the printing
//             and typesetting industry.
//           </p>
//         </div> */}
//         <div className="flex md:w-max mx-auto flex-nowrap overflow-x-scroll py-4 px-1 scrollbar-hide  items-center my-4  text-primary-blue">
//           {Object.keys(faqs).map((item, index) => (
//             <div
//               key={index}
//               onClick={() => setCategory(item)}
//               className={`mr-2 lg:mr-4 flex-center flex-shrink-0 hover:bg-primary-blue h-32  w-36 ${
//                 category === item
//                   ? "bg-primary-blue text-white "
//                   : "bg-white text-primary-blue"
//               } hover:text-white  cursor-pointer flex-col  rounded-xl ring-1 ring-primary-blue-light`}
//             >
//               {/* <FaHome className="" size={44} /> */}
//               <p className="text-sm text-center p-4  lg:text-base font-medium capitalize">
//                 {`${item}`}
//               </p>
//             </div>
//           ))}
//         </div>

//         {/* <div className="my-10 grid grid-cols-1 md:grid-cols-4  xl:grid-cols-6 gap-8"> */}
//         <div className="my-10 ">
//           {/* <div className="col-span-full row-start-2 md:row-start-auto   md:col-span-2 lg:col-span-2">
//             <div className="lg:w-96 shadow-lg rounded-xl p-4">
//               <div className="text-lg lg:text-xl transform -translate-y-3 p-4 rounded-md shadow-xl bg-primary-blue text-white font-medium">
//                 Sub Category
//               </div>
//               {renderSubCategory()}
//             </div>
//           </div> */}
//           {/* <div className="col-span-full md:col-span-2 lg:col-span-4 mx-auto"> */}
//           <div className="  md:w-6/12 md:mx-auto">
//             <h4 className="text-xl lg:text-2xl  text-primary-blue font-medium mb-4 ">
//               Frequently Asked Question
//             </h4>
//             <div className="text-primary-blue-dark ">{renderAccordian()}</div>
//           </div>
//         </div>
//       </Section>
//       <Footer />
//     </div>
//   );
// }

// export default Faqs;
