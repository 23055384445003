import axios from "axios";
import { constant } from "@config/constant";
import { getBasicAuthHeader, getAuthHeader } from "@helper/header";
import { handleResponse, handleErrorResponse } from "../_helper/utils";

const api = axios.create({ baseURL: constant.apiUrl });

export const getDisputeDetails = async (id) => {
  try {
    const response = await api.get(
      `/user/dispute/detail/${id}`,
      getAuthHeader()
    );
    return handleResponse(response);
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getDisputeReceiptDetails =  async (id) => {
  try {
    const getHeaders = getAuthHeader();
    const response = await api.get(
      `/user/dispute/details/${id}`,
      getHeaders
    );
    return handleResponse(response);
  } catch (error) {
    handleErrorResponse(error);
  }
}

export const involveAdminInDispute = async (id) => {
  try {
    const response = await api.post(
      `/user/dispute/enableAdmin`,
      { disputeId: id },
      getAuthHeader()
    );
    return handleResponse(response);
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const sendDisputeReply = async ({
  message,
  amount,
  type,
  status,
  disputeId,
}) => {
  try {
    const response = await api.post(
      `/user/dispute/reply`,
      { message, amount, type, status, disputeId },
      getAuthHeader()
    );
    return handleResponse(response);
  } catch (error) {
    handleErrorResponse(error);
  }
};
