import React from "react";
import { Link } from "react-router-dom";
import Section from "@layout/Section";
import { FaFacebookF as Facebook } from "react-icons/fa";
// import { FaTwitter as Twitter } from "react-icons/fa";
import { FiInstagram as Instagram } from "react-icons/fi";

function Footer() {
  return (
    <div className="bg-gradient-2  pt-0 bg-white pb-10 relative">
      <Section>
        <footer className="w-full md:w-11/12 mx-auto relative px-4 sm:px-auto lg:pt-20 pt-5 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4  gap-10">
          <div className="col-span-full sm:col-auto space-y-5">
            <img
              className="h-14 lg:h-16"
              src="/assets/resources/logo-text.png"
              alt="logo"
            />
            <div className="space-y-1">
              <p className="text-sm text-gray-500 ">
                Safe Nights, Safe Flights
                <br />
                <span> Email : support@crewmatesapp.com</span>
              </p>
            </div>
          </div>
          <div className="space-y-4">
            <h4 className="text-base lg:text-lg text-gray-800 font-bold">
              Company
            </h4>
            <div className="space-y-2 text-gray-500 text-sm  ">
              {/* <p>
                <Link to="/about">About Us</Link>
              </p> */}
              <p>
                <Link to="/become-host">Become a Host</Link>
              </p>

              {/* <p>Why Crew Mate</p> */}
              <p>
                <Link to="/faq">FAQ</Link>
              </p>
            </div>
          </div>
          <div className="space-y-4">
            <h4 className="text-base lg:text-lg text-gray-800 font-bold">
              Contact
            </h4>
            <div className="space-y-2 text-gray-500 text-sm  ">
              {/* <p>Cancel Policy</p> */}
              <p>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </p>
              <p>
                <Link to="/terms-and-condition">Terms & Condition</Link>
              </p>
              {/* <p>Partner with us</p> */}
              <p>
                <Link to="/contact">Contact Us</Link>
              </p>
            </div>
          </div>
          {/* <div className="space-y-4">
            <h4 className="text-base lg:text-lg text-gray-800 font-bold">
              More
            </h4>
            <div className="space-y-2 text-gray-500 text-sm  ">
              <p>Get in Touch</p>
              <p>Feedback</p>
              <p>
                <Link to="/faq">FAQ's</Link>
              </p>
              <p>Features</p>
            </div>
          </div> */}
          <div className="space-y-4 lg:justify-self-end ">
            <div className="space-x-2 flex items-center">
              <a
                href="https://www.facebook.com/groups/crewmatescrashpads/"
                target="_blank"
              >
                <span className="inline-block p-3 rounded-full bg-white shadow-lg">
                  <Facebook className="text-xs" />
                </span>
              </a>
              <a href="https://www.instagram.com/crewmatesapp" target="_blank">
                <span className="inline-block p-3 rounded-full bg-white shadow-lg">
                  <Instagram className="text-xs" />
                </span>{" "}
              </a>
              <a href="https://linktr.ee/crewmatesapp" target="_blank">
                <span className="inline-block p-3 rounded-full bg-white shadow-lg">
                  <img
                    src="/assets/images/link-tree.png"
                    className="h-3"
                    alt=""
                  />
                </span>
              </a>
            </div>
            <h2 className="text-base text-gray-500 font-semibold">
              Coming Soon
            </h2>
            <img
              className="h-20"
              src="/assets/resources/store.png"
              alt="download options"
            ></img>
          </div>
          <div className="col-span-full text-center text-sm text-gray-600 font-medium">
            © {new Date().getFullYear()} Crewmates, LLC. All Rights Reserved.
          </div>
        </footer>
      </Section>
    </div>
  )
}

export default Footer;
