import React from 'react'
import moment from 'moment'
import { processMediaUrl } from '../../../_helper/utils'

function ConversationCard({ messageInstance, members }) {
    const member = members[messageInstance.author]
    switch (messageInstance.type) {
        case 'received': {
            return (
                <div className="m-4 mx-2 w-max max-w-md">
                    <div className="flex items-end">
                        <img
                            onError={({ currentTarget }) => {
                                currentTarget.src = '/user.png'
                            }}
                            alt=""
                            src={member.profile ? processMediaUrl(member.profile) : '/user.png'}
                            className="h-8 w-8 rounded-full object-cover mr-2 flex-shrink-0"
                        />
                        <div>
                            <p className="text-xs bg-[#e6ebf5] p-4 text-black rounded-lg rounded-bl-none relative">
                                {messageInstance.text}
                                <span className="flex gap-1 text-right text-[12px] mt-2 text-black items-center">
                                    <img src="/assets/resources/clock.svg" alt="Time" className="w-3 h-3" />
                                    {moment(messageInstance.timestamp).fromNow()}
                                </span>
                                <span
                                    className="w-0 h-0 border-l-0 border-r-8 border-t-8 absolute"
                                    style={{ borderColor: 'transparent', borderTopColor: '#e6ebf5', left: 0, bottom: '-8px' }}
                                ></span>
                            </p>
                            <span className="block mt-2 text-sm font-medium text-primary-blue" style={{ fontSize: '14px' }}>
                                {member.name || 'Unknown'}
                            </span>
                        </div>
                    </div>
                </div>
            )
        }

        case 'sent': {
            return (
                <div className="m-4 mx-2 w-max max-w-md ml-auto">
                    <div className="flex items-end">
                        <div>
                            <p className="text-xs bg-[#f5f7fb] p-4 text-black rounded-lg rounded-br-none relative">
                                {messageInstance.text}
                                <span className="flex gap-1 text-right text-[12px] mt-2 text-gray-500 items-center">
                                    <img src="/assets/resources/clock.svg" alt="Time" className="w-3 h-3" />
                                    {moment(messageInstance.timestamp).fromNow()}
                                </span>
                                <span
                                    className="w-0 h-0 border-r-0 border-l-8 border-t-8 absolute"
                                    style={{ borderColor: 'transparent', borderTopColor: '#f5f7fb', right: 0, bottom: '-8px' }}
                                ></span>
                            </p>
                            <span className="block mt-2 text-right text-sm font-medium text-primary-blue" style={{ fontSize: '14px' }}>
                                {member.name || 'Unknown'}
                            </span>
                        </div>
                        <img
                            onError={({ currentTarget }) => {
                                currentTarget.src = '/user.png'
                            }}
                            alt=""
                            src={member.profile ? processMediaUrl(member.profile) : '/user.png'}
                            className="h-8 w-8 rounded-full object-cover ml-2 flex-shrink-0"
                        />
                    </div>
                </div>
            )
        }

        default: {
            return <p>Specify chat type</p>
        }
    }
}

export default ConversationCard
