import axios from 'axios'
import { constant } from '@config/constant'
import { getBasicAuthHeader, getAuthHeader } from '@helper/header'
import { handleResponse, handleErrorResponse } from '../_helper/utils'

const api = axios.create({ baseURL: constant.apiUrl })

export const submitContactQuery = async (data) => {
    const response = await api.post('/user/contactus/add', data, getBasicAuthHeader())
    return response
}

export const getFaqList = async () => {
    const response = await api.get('/user/faq', getBasicAuthHeader())
    return response
}

export const getFaqCategory = async () => {
    const response = await api.get('/user/category/list', getBasicAuthHeader())
    return response
}

export const report = async (data) => {
    try {
        let fd = new FormData()
        fd.append('message', data.message)
        fd.append('bookingId', data.bookingId)

        data.photos.length &&
            data.photos.forEach((file) => {
                fd.append('files', file.current)
            })

        const response = await api.post('/user/report', fd, getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export async function siteSettings() {
    const response = await api.get('/user/site-settings', getBasicAuthHeader())
    return response
}
