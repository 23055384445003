import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import Header from "@front/Header";
import Footer from "@front/Footer";
import Section from "@layout/Section";
import TextInput from "@ui/TextInput";
import PhoneCode from "@ui/phoneCode";
import SelectInputText from "@ui/Select";
import Button from "@ui/Button";
import { forgetPassword } from "@services/user";
import { setResetToken } from "@helper/header";
import { IoIosSend } from "react-icons/io";
import PhoneInput from "react-phone-input-2";

import { AiOutlineMail } from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";
import { constant } from "../_config/constant";

function Message() {
  return (
    <div className="mt-8  w-full md:w-8/12 lg:w-5/12 mx-auto flex flex-col ">
      <div className="h-14 w-14 mx-auto rounded-full flex-center bg-primary-blue mb-10">
        <IoIosSend size={36} className="text-white" />
      </div>

      <p className="text-base text-center mb-10">
        A link to reset your Crewmate account's password has been sent to your
        Email ID johnxx@gmail.com & your mobile number
      </p>

      <Link to="/login" className="mx-auto">
        <Button btnType="filled" className="w-max mx-auto mb-4">
          Proceed To Login
        </Button>
      </Link>
      <p className="text-sm text-center text-primary-blue-light">
        Note in case you do not find the email in your inbox ,please check SPAM
        folder
      </p>
    </div>
  );
}

//

function Login({ onClose }) {
  const history = useHistory();
  const [formStep, setFormStep] = useState(0);
  const [email, setEmail] = useState("");
  const [resetType, setResetType] = useState("Email");
  const [isRequestion, setRequesting] = useState(false);
  const mobileRef = useRef();
  const [phone, setPhone] = useState("");
  const [countryCode, setcountryCode] = useState("");
  const [optionList, setOptionList] = useState(false)
  const handlePhone = (value, ev) => {
    const input = value.slice(ev.dialCode.length);
    setPhone(input);
    setcountryCode(ev.dialCode);
  };

  const handleForgetPassword = async () => {
    let res;

    if (resetType === "Email") {
      if (!email) return;
      setRequesting(true);
      const request = {
        authType: resetType.toLocaleLowerCase(),
        emailOrPhone: email,
      };
      localStorage.setItem(
        constant.RESET_REQUEST_EMAIL,
        JSON.stringify(request)
      );
      res = await forgetPassword(request);
    } else {
      if (!phone || !countryCode) return;
      setRequesting(true);
      const request = {
        authType: resetType.toLocaleLowerCase(),
        // emailOrPhone: mobileRef.current.tel.value,
        // countryCode: mobileRef.current.selectedCountryData.dialCode,
        emailOrPhone: phone,
        countryCode: countryCode,
      };
      localStorage.setItem(
        constant.RESET_REQUEST_PHONE,
        JSON.stringify(request)
      );
      res = await forgetPassword(request);
    }

    if (res.data.status) {
      setResetToken(res.data.data.token);
      if (resetType === "Email") {
        history.push("/reset-password-email");
      } else {
        history.push("/reset-password-phone");
      }
    }
    toast.success(res.data.message,{toastId:5});
    setRequesting(false);
  };

  return (
    <>
      <Header type="fixed" />
      <ToastContainer />
      <Section className="mt-10 px-4   flex justify-center items-center">
        {formStep === 0 ? (
          <div className="mt-8  w-full md:w-8/12 lg:w-5/12 mx-auto">
            <h4 className="mb-2 text-center text-primary-blue text-3xl font-bold ">
              Forgot Your Password?
            </h4>
            <p className="text-base text-gray-600 w-11/12 mx-auto text-center ">
              Simply enter your email or phone number below and we'll help you
              reset your password
            </p>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleForgetPassword();
              }}
              className="grid grid-cols-1 mt-20 mb-5 "
            >
              <div className="mb-5 z-100">
                <p className="hidden sm:block mb-1 text-sm text-gray-500">
                  Reset Password Using
                </p>
                <SelectInputText
                  label={
                    <p className="block sm:hidden">Reset Password Using</p>
                  }
                  defaultValue='Phone'
                  value={resetType}
                  setValue={setResetType}
                  listId="reset-type"
                  leftIcon={null}
                  datalist={[
                    { key: "Email", value: "Email" },
                    { key: "Phone", value: "Phone" },
                  ]}
                  setOptionList={setOptionList}
                  optionList={optionList}
                />
              </div>
              {resetType === "Email" ? (
                <TextInput
                  type="email"
                  field="email"
                  label="Email"
                  placeholder="Enter Email"
                  value={email}
                  setValue={setEmail}
                  leftIcon={<AiOutlineMail size={24} />}
                />
              ) : (
                // <PhoneCode ref={mobileRef} label="Mobile" />

                <div
                  className={`flex items-start flex-col justify-start w-full `}
                >
                  <label className="text-gray-600 text-sm mb-1">Mobile</label>
                  <PhoneInput
                    country={"us"}
                    autoFormat
                    value={`${countryCode}${phone}`}
                    onChange={handlePhone}
                    buttonClass="bg-gray-100 border-0"
                    inputClass="border-0 pl-12 focus:ring-0 tw-w-full focus:border-0 flex-grow text-sm w-full outline-none bg-gray-100 p-2 rounded ring-1 ring-gray-200"
                  />
                </div>
              )}

              <Button
                type="submit"
                className="block mx-auto my-4"
                btnType="filled"
                disabled={isRequestion}
              >
                {isRequestion ? "Please wait ..." : "Continue"}
              </Button>
            </form>
          </div>
        ) : (
          <Message />
        )}
      </Section>
      <Footer />
    </>
  );
}

export default Login;
