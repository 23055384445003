import { MdOutlineDashboard } from 'react-icons/md'
import { CgNotes } from 'react-icons/cg'
import { BiMessageDots } from 'react-icons/bi'
import { BsStar } from 'react-icons/bs'
import { AiOutlineUser, AiOutlineSetting } from 'react-icons/ai'
import { IoMdNotificationsOutline } from 'react-icons/io'
import { MdOutlineReportProblem } from 'react-icons/md'

const baseUrl = '/dashboard'
export const routes = [
    {
        title: 'Dashboard',
        path: `${baseUrl}/home`,
        icons: <MdOutlineDashboard size={22} className="mr-2" />,
    },
    {
        title: 'My Booking',
        path: `${baseUrl}/my-booking`,
        icons: <CgNotes size={22} className="mr-2" />,
    },
    
    {
        title: 'Messages',
        path: `${baseUrl}/messages`,
        icons: <BiMessageDots size={22} className="mr-2" />,
    },
    {
        title: 'Favorites',
        path: `${baseUrl}/favourites`,
        icons: <BsStar size={22} className="mr-2" />,
    },
    {
        title: 'My Profile',
        path: `${baseUrl}/profile`,
        icons: <AiOutlineUser size={22} className="mr-2" />,
    },
    {
        title: 'Setting',
        path: `${baseUrl}/setting/`,
        icons: <AiOutlineSetting size={22} className="mr-2" />,
    },
    {
        title: 'Notification',
        path: `${baseUrl}/notification`,
        icons: <IoMdNotificationsOutline size={22} className="mr-2" />,
    },
    // {
    //   title: "Report",
    //   path: `${baseUrl}/report`,
    //   icons: <MdOutlineReportProblem size={22} className="mr-2" />,
    // },
]
