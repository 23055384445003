import React, { useContext, useRef, useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Tooltip } from 'react-tippy'
import { throttle } from 'lodash'
import Navbar from '@dashboard/Navbar'
import ReactPaginate from 'react-paginate'
import { GoSearch } from 'react-icons/go'
import { addDays } from 'date-fns'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { HiOutlineChevronLeft } from 'react-icons/hi'
import * as utility from '@utils/utility'
import { Context as AuthContext, useAuth } from '@context/authContext'
import { getMyBooking, addReview, updateBookingNoticePeriod, cancleBooking } from '../../_services/property'
import { getTransactionDetails, getTransactions, getRefundDetails } from '../../_services/payout'
import { getDisputeDetails, sendDisputeReply, involveAdminInDispute, getDisputeReceiptDetails } from '../../_services/dispute'
import { toast } from 'react-toastify'
import { constant } from '../../_config/constant'
import Modal from '@components/Modal'
import StarRatings from 'react-star-ratings'
// import moment from 'moment-timezone'
import * as moment from 'moment-timezone'
import * as generalServices from '@services/general'
import { AiOutlinePlus } from 'react-icons/ai'
import { nanoid } from 'nanoid'
import { timezone } from '@services'
import { IoMdClose } from 'react-icons/io'
import { calculateDayDifference, calculateMinutesDifference, getHourDifference, processMediaUrl } from '../../_helper/utils'

SwiperCore.use([Navigation, Pagination])

const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

const COLD = 'Cold bed'
const HOT = 'Hot bed'

const ColdBedReceipt = ({ onClose, transactionId }) => {
    console.log('Inside coldbedreceipt function----->>>>>>>>>>>>>>>>>')
    const [fetching, setFetching] = useState(true)
    const [details, setDetails] = useState({})
    const [monthBookingPrice, setMonthBookingPrice] = useState(0)

    useEffect(() => {
        const fetchTransactionDetails = async () => {
            try {
                setFetching(true)
                const { data } = await getTransactionDetails({
                    transactionId,
                })
                const transactionDetails = data.data
                setDetails(transactionDetails)

                if (transactionDetails.bookingId) {
                    let totalDays =
                        moment(transactionDetails.endDate.split('T')[0]).diff(moment(transactionDetails.startDate.split('T')[0]), 'days') + 1
                    let numberOfDayInBookingMonth = moment(transactionDetails.bookingId.checkInDate.split('T')[0]).daysInMonth()

                    console.log({
                        numberOfDayInBookingMonth,
                        bookingPrice: transactionDetails.bookingId.bookingPrice,
                    })

                    let perDayPrice = transactionDetails.bookingId.bookingPrice / numberOfDayInBookingMonth
                    console.log(perDayPrice, totalDays)

                    // let tempPerDayPrice1 =
                    //   transactionDetails.bookingId.bookingPrice /
                    //   numberOfDayInBookingMonth;
                    // let tempPerDayPrice2 = tempPerDayPrice1.toString();
                    // let tempPerDayPrice3 = tempPerDayPrice2.slice(0, (tempPerDayPrice2.indexOf("."))+ 2 + 1);
                    // let perDayPrice = parseFloat(tempPerDayPrice3);

                    //setMonthBookingPrice(perDayPrice * totalDays);
                    if (transactionDetails) {
                        setMonthBookingPrice(monthBookingPrice) //transactionDetails.bookingId.totalPrice - transactionDetails.bookingId.cleaningFee);
                    }
                }
            } catch (error) {
                toast.error('Something went wrong!')
            } finally {
                setFetching(false)
            }
        }
        if (transactionId) fetchTransactionDetails()
    }, [transactionId])
    var discountOfLineholder = 0
    console.log('monthly booking price--->>>>>>', monthBookingPrice)
    console.log('details------------->>>>>>>>', details)
    if (Object.keys(details).length > 0) {
        // added 17/11/2022
        if (Object.keys(details.bookingId).length > 0) {
            if (details.discountOfLineholder) {
                discountOfLineholder = details.discountOfLineholder
            }
        }
    }

    const getReservationDates = () => {
        const datesForNormalBooking = `${moment(details.startDate.split('T')[0]).format('MMMM Do, YYYY')}
         - ${
             details.bookingId.isMonthlyCheckout == 1
                 ? moment(details.bookingId.checkOutDate.split('T')[0]).format('MMMM Do, YYYY')
                 : !details.bookingId.isNotice || details.endDate.split('T')[0] != details.bookingId.checkOutDate.split('T')[0]
                   ? moment(details.endDate.split('T')[0]).format('MMMM Do, YYYY')
                   : moment(details.bookingId.checkOutDate.split('T')[0]).format('MMMM Do, YYYY')
         }`
        if (details.bookingId.isMonthlyCheckout === 2) {
            if (details.startDate && details.endDate) {
                const startDate = moment(details.startDate.split('T')[0])
                const endDate = moment(details.endDate.split('T')[0])
                const isSameMonth = startDate.month() === endDate.month()
                return `${startDate.format('MMMM Do, YYYY')} - ${endDate.format('MMMM Do, YYYY')}`
            } else {
                return datesForNormalBooking
            }
        } else {
            return datesForNormalBooking
        }
    }
    const shouldAutopayShow = () => {
        if (details) {
            const startDate = moment(details.startDate)
            const bookingCheckInDate = moment(details.bookingId.checkInDate)
            if (startDate.month() === bookingCheckInDate.month()) {
                return false
            } else {
                return true
            }
        } else {
            return false
        }
    }

    return (
        <div className="w-[95%] text-sm lg:w-[65%]  mx-auto">
            {fetching ? (
                <div className="rounded-md p-8 grid place-content-center bg-white">Loading...</div>
            ) : (
                <div className="bg-white p-4 rounded-md relative ">
                    <span onClick={onClose} className="absolute right-6 top-6 cursor-pointer">
                        <IoMdClose size={32} />
                    </span>

                    <h2 className="text-3xl font-medium"></h2>
                    <p className="text-gray-500">
                        Receipt ID: {details.receiptId}{' '}
                        {details.transactionDate && `- ${moment(details.transactionDate).tz(details.bookingId.timezone).format('MMMM DD, YYYY')}`}
                    </p>
                    <h3 className="text-2xl font-medium mt-2">Reservation Details</h3>

                    <p className="my-4 text-gray-500">
                        {details.bookingId && getReservationDates()}
                        {/* {details.bookingId && (
                            <>
                                {moment(details.startDate.split('T')[0]).format('MMMM Do, YYYY')}
                                {` - ${
                                    details.bookingId.isMonthlyCheckout == 1
                                        ? moment(details.bookingId.checkOutDate.split('T')[0]).format('MMMM Do, YYYY')
                                        : !details.bookingId.isNotice || details.endDate.split('T')[0] != details.bookingId.checkOutDate.split('T')[0]
                                          ? moment(details.endDate.split('T')[0]).format('MMMM Do, YYYY')
                                          : moment(details.bookingId.checkOutDate.split('T')[0]).format('MMMM Do, YYYY')
                                }`}
                            </>
                        )} */}
                        <br />
                        Cold bed {shouldAutopayShow() ? 'Autopay' : ''} <br />
                        Room {details.bookingId && details.bookingId.roomName}, Bed {details.bookingId && details.bookingId.bedName} <br />
                        Security Deposit :{' '}
                        {details.bookingId &&
                            `$${parseFloat(details.bookingId.securityDeposite).toFixed(2).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })}`}{' '}
                        <br />
                        Confirmation code : {details.bookingId && details.bookingId.confirmationCode}
                    </p>

                    {!details.createdFromRenew && (
                        <>
                            <h4 className="text-xl font-medium">Cancellation Policy</h4>
                            {details.bookingId && details.bookingId.propertyId.cancelationPolicy === 'Relaxed' ? (
                                <p className="text-sm text-gray-500 my-2">
                                    <span className="text-gray-500">Relaxed: </span> Guests can receive a full refund up until 24 hours before
                                    check-in
                                </p>
                            ) : null}
                            {details.bookingId && details.bookingId.propertyId.cancelationPolicy === 'Moderate' ? (
                                <p className="text-sm text-gray-500 my-2">
                                    <span className="text-gray-500">Moderate: </span> Guests can receive a full refund up until 5 days before check-in
                                </p>
                            ) : null}
                            {details.bookingId && details.bookingId.propertyId.cancelationPolicy === 'Strict' ? (
                                <p className="text-sm text-gray-500 my-2">
                                    <span className="text-gray-500">Strict: </span> No refunds
                                </p>
                            ) : null}
                        </>
                    )}

                    {/* <p className="text-sm text-gray-500 my-2">
                        <span className="text-gray-500">Security Deposit: </span>
                        {details.bookingId &&
                            parseFloat(details.bookingId.securityDeposite).toFixed(2).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })}
                    </p> */}

                    <h4 className="text-xl font-medium">Price Breakdown</h4>
                    <div className="grid grid-cols-2 my-4 text-gray-500   ">
                        {/* <p>Prorated cold bed</p>
            <p className="text-right">
              {monthBookingPrice &&
                monthBookingPrice.toLocaleString("en-US", {   
                  style: "currency",
                  currency: "USD",
                })}
            </p> */}
                        {details.bookingId.isMonthlyCheckout == 2 ? (
                            <p className="tw-text-gray-500">Prorated Cold Bed</p>
                        ) : (
                            <p className="tw-text-gray-500">Cold Bed</p>
                        )}
                        {/*              <p className='tw-text-gray-500'>Prorated Cold Bed</p> */}

                        <p className="text-right text-gray-500">
                            {details.monthBookingPrice &&
                                `$${parseFloat(details.monthBookingPrice).toFixed(2).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                })}`}
                        </p>
                        {details.extraGuestCharge > 0 && (
                            <>
                                <p className="text-gray-500">Extra Guest Charge</p>
                                <p className="text-right text-gray-500">
                                    {details.bookingId &&
                                        `$${parseFloat(details.extraGuestCharge).toFixed(2).toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        })}`}
                                </p>
                            </>
                        )}
                        {details.cleaningFee > 0 && (
                            <>
                                <p className="text-gray-500">Cleaning Fee</p>
                                <p className="text-right text-gray-500 ">
                                    {details.cleaningFee &&
                                        `$${parseFloat(details.cleaningFee).toFixed(2).toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        })}`}
                                </p>
                            </>
                        )}

                        {details.discountOfLineholder && details.discountOfLineholder && details.discountOfLineholder > 0 ? (
                            <React.Fragment>
                                <p className="text-gray-500">Lineholder Discount</p>
                                <p className="text-right text-gray-500">
                                    {`$${parseFloat(details.discountOfLineholder).toFixed(2).toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    })}`}
                                </p>
                            </React.Fragment>
                        ) : null}
                        {/* <p>Security Deposit</p>
            <p className="text-right">
              {details.bookingId &&
                details.bookingId.securityDeposite.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
            </p> */}

                        <p className="font-medium font-medium">Total</p>
                        <p className="text-right">
                            {details.bookingId &&
                                `$${parseFloat(details.totalPrice).toFixed(2).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                })}`}
                        </p>

                        {/* <p className="font-medium">Amount Paid</p>
            <p className="font-medium text-right">
              {parseFloat(details.totalPrice).toFixed(2).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </p> */}
                    </div>
                    <hr className="tw-col-span-full" />
                    <div className="grid grid-cols-2 mb-3 mt-3 flex-center ">
                        <div className="flex-col flex">
                            <h4 className="text-xl font-medium">Payment</h4>
                            <p className="text-gray-500">{details.cardDetails}</p>
                            <p className="text-gray-500">
                                {moment(details.transactionDate).tz(details.bookingId.timezone).format('MMMM DD, YYYY h:mm:ss')}
                                {/* EST */}
                            </p>
                        </div>
                        <p className="text-right text-gray-500">
                            {details.bookingId &&
                                `$${parseFloat(details.totalPrice).toFixed(2).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                })}`}
                        </p>
                    </div>
                    <hr className="col-span-full" />

                    <div className="grid grid-cols-2 mt-3 ">
                        <p className="font-medium">Amount Paid</p>
                        <p className="font-medium text-right">
                            {details.bookingId &&
                                `$${parseFloat(details.totalPrice).toFixed(2).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                })}`}
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}

const HotBedReceipt = ({ onClose, transactionId }) => {
    const [fetching, setFetching] = useState(true)
    const [details, setDetails] = useState({})

    useEffect(() => {
        const fetchTransactionDetails = async () => {
            try {
                setFetching(true)
                const { data } = await getTransactionDetails({
                    transactionId,
                })
                const transactionDetails = data.data
                setDetails(transactionDetails)
            } catch (error) {
                toast.error('Something went wrong!')
            } finally {
                setFetching(false)
            }
        }
        if (transactionId) fetchTransactionDetails()
    }, [transactionId])

    return (
        <div className="w-[95%] text-sm lg:w-[65%]  mx-auto">
            {fetching ? (
                <div className="rounded-md p-8 grid place-content-center bg-white">Loading...</div>
            ) : (
                <div className="bg-white p-4 rounded-md relative ">
                    <span onClick={onClose} className="absolute right-6 top-6 cursor-pointer">
                        <IoMdClose size={32} />
                    </span>

                    <h2 className="text-3xl font-medium">Your Receipt from Crewmates</h2>
                    <p className="text-gray-500">
                        Receipt ID: {details.receiptId}{' '}
                        {details.transactionDate && `- ${moment(details.transactionDate).tz(details.bookingId.timezone).format('MMMM DD, YYYY')}`}
                    </p>
                    <h3 className="text-2xl font-medium mt-2">Reservation Details</h3>

                    <p className="my-4 text-gray-500">
                        {details.bookingId && (
                            <>
                                {moment(details.startDate.split('T')[0]).format('MMMM Do, YYYY')}
                                {` - ${moment(details.endDate.split('T')[0]).format('MMMM Do, YYYY')}`}
                            </>
                        )}
                        <br />
                        Hot bed <br />
                        Room {details.bookingId && details.bookingId.roomName}, Bed {details.bookingId && details.bookingId.bedName} <br />
                        Security Deposit :{' '}
                        {details.bookingId &&
                            `${details.bookingId.securityDeposite.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })}`}{' '}
                        <br />
                        Confirmation code : {details.bookingId && details.bookingId.confirmationCode}
                    </p>

                    {!details.createdFromRenew && (
                        <>
                            <h4 className="text-xl font-medium">Cancellation Policy</h4>
                            {details.bookingId && details.bookingId.propertyId.cancelationPolicy === 'Relaxed' ? (
                                <p className="text-sm text-gray-500 my-2">
                                    <span className="text-gray-500">Relaxed: </span> Guests can receive a full refund up until 24 hours before
                                    check-in
                                </p>
                            ) : null}
                            {details.bookingId && details.bookingId.propertyId.cancelationPolicy === 'Moderate' ? (
                                <p className="text-sm text-gray-500 my-2">
                                    <span className="text-gray-500">Moderate: </span> Guests can receive a full refund up until 5 days before check-in
                                </p>
                            ) : null}
                            {details.bookingId && details.bookingId.propertyId.cancelationPolicy === 'Strict' ? (
                                <p className="text-sm text-gray-500 my-2">
                                    <span className="text-gray-500">Strict: </span> No refunds
                                </p>
                            ) : null}
                        </>
                    )}

                    {/* <p className="text-sm text-gray-500 my-2">
                        <span className="text-gray-500">Security Deposit: </span>
                        {details.bookingId &&
                            details.bookingId.securityDeposite.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })}
                    </p> */}

                    <h4 className="text-xl font-medium">Price Breakdown</h4>
                    <div className="grid grid-cols-2 my-4 text-gray-500">
                        <p className="text-gray-500">
                            {details.bookingId.numberOfDay} {Number(details.bookingId.numberOfDay) > 1 ? 'Nights' : 'Night'}
                        </p>
                        <p className="text-right text-gray-500">
                            {details.bookingId &&
                                `$${parseFloat(details.bookingId.bookingPrice * details.bookingId.numberOfDay)
                                    .toFixed(2)
                                    .toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    })}`}
                        </p>
                        {details.bookingId.extraGuestCharge > 0 && (
                            <>
                                <p className="text-gray-500">Extra Guest Charge</p>
                                <p className="text-right text-gray-500">
                                    {details.bookingId &&
                                        `$${parseFloat(details.extraGuestCharge).toFixed(2).toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        })}`}
                                </p>
                            </>
                        )}
                        {details.cleaningFee > 0 && (
                            <>
                                <p className="text-gray-500">Cleaning Fee</p>
                                <p className="text-right text-gray-500">
                                    {details.bookingId &&
                                        `$${parseFloat(details.cleaningFee).toFixed(2).toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        })}`}
                                </p>
                            </>
                        )}

                        {/* <p>Security Deposit</p>
            <p className="text-right">
              {details.bookingId &&
                details.bookingId.securityDeposite.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
            </p> */}
                        {details.bookingId && details.discountOfLineholder && details.discountOfLineholder > 0 ? (
                            <React.Fragment>
                                <p className="text-gray-500">Lineholder Discount</p>
                                <p className="text-right text-gray-500">
                                    {`$${parseFloat(details.discountOfLineholder).toFixed(2).toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    })}`}
                                </p>
                            </React.Fragment>
                        ) : null}

                        <p className="font-medium font-medium text-gray-500">Total</p>
                        <p className="text-right text-gray-500">
                            {details.bookingId &&
                                `$${parseFloat(details.totalPrice).toFixed(2).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                })}`}
                        </p>
                    </div>

                    <hr className="tw-col-span-full" />
                    <div className="grid grid-cols-2 mb-3 mt-3 flex-center ">
                        <div className="flex-col flex">
                            <h4 className="text-xl font-medium mb-3">Payment</h4>
                            <p className="text-gray-500">{details.cardDetails}</p>
                            <p className="text-gray-500">
                                {moment(details.transactionDate).tz(details.bookingId.timezone).format('MMMM DD, YYYY h:mm:ss')}
                                {/* EST */}
                            </p>
                        </div>
                        <p className="text-right text-gray-500">
                            {details.bookingId &&
                                `$${parseFloat(details.totalPrice).toFixed(2).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                })}`}
                        </p>
                    </div>
                    <hr className="col-span-full" />
                    <div className="grid grid-cols-2 mt-3">
                        <p className="font-medium">Amount Paid</p>
                        <p className="font-medium text-right">
                            {details.bookingId &&
                                `$${parseFloat(details.totalPrice).toFixed(2).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                })}`}
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}

const RefundReceipt = ({ details, onClose }) => {
    console.log('refund details ==>>> ', details)
    const getReservationDates = () => {
        if (details.bookingId.isMonthlyCheckout === 2) {
            if (details.checkInDate && details.endDate) {
                const startDate = moment(details.checkInDate.split('T')[0])
                const endDate = moment(details.endDate.split('T')[0])
                const isSameMonth = startDate.month() === endDate.month()
                return `${startDate.format('MMMM Do, YYYY')} - ${endDate.format('MMMM Do, YYYY')}`
                // return isSameMonth?`${startDate.format("MMMM Do, YYYY")} - ${endDate.format("MMMM Do, YYYY")}`:`${startDate.format("MMMM Do, YYYY")} - ${endDate.subtract(1, 'days').format("MMMM Do, YYYY")}`
            } else {
                const startDate = moment(details.bookingId.checkInDate.split('T')[0])
                const endDate = moment(details.bookingId.checkOutDate.split('T')[0])
                return `${startDate.format('MMMM Do, YYYY')} - ${endDate.format('MMMM Do, YYYY')}`
            }
        } else {
            return `${moment(details.bookingId.checkInDate.split('T')[0]).format('MMMM Do, YYYY')}
           - ${moment(details.bookingId.checkOutDate.split('T')[0]).format('MMMM Do, YYYY')}`
        }
    }
    return (
        <div className="w-[95%] text-sm lg:w-[65%]  mx-auto">
            <div className="bg-white p-4 rounded-md relative ">
                <span onClick={onClose} className="absolute right-6 top-6 cursor-pointer">
                    <IoMdClose size={32} />
                </span>

                <h2 className="text-3xl font-medium">Your Receipt from Crewmates</h2>
                <p className="text-gray-500">
                    Receipt ID: {details.receiptId}{' '}
                    {details.bookingId.updatedAt && `- ${moment(details.updatedAt).tz(details.bookingId.timezone).format('MMMM DD, YYYY')}`}
                </p>
                <h3 className="text-2xl font-medium mt-2">Reservation Details</h3>

                <p className="my-4">
                    {/* {details.bookingId && details.bookingId.checkInDate && (
                        <>
                            {moment(details.bookingId.checkInDate.split('T')[0]).format('MMMM Do, YYYY')}
                            {` - ${moment(details.bookingId.checkOutDate.split('T')[0]).format('MMMM Do, YYYY')}`}
                        </>
                    )} */}
                    {details.bookingId && details.bookingId.checkInDate && getReservationDates()}
                    <br />
                    Room {details.bookingId && details.bookingId.roomName}, Bed {details.bookingId && details.bookingId.bedName} <br />
                    Security Deposit :{' '}
                    {details.bookingId &&
                        `$${parseFloat(details.bookingId.securityDeposite).toFixed(2).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}`}{' '}
                    <br />
                    Confirmation code : {details.bookingId && details.bookingId.confirmationCode}
                </p>

                {/* {!details.createdFromRenew && (
                    <>
                        <h4 className="text-xl font-medium">Cancellation Policy</h4>
                        {details.bookingId && details.bookingId.cancelationPolicy[0] === 'Relaxed' ? (
                            <p className="text-sm text-gray-500 my-2">
                                <span className="text-gray-500">Relaxed: </span> Guests can receive a full refund up until 24 hours before check-in
                            </p>
                        ) : null}
                        {details.bookingId && details.bookingId.cancelationPolicy[0] === 'Moderate' ? (
                            <p className="text-sm text-gray-500 my-2">
                                <span className="text-gray-500">Moderate: </span> Guests can receive a full refund up until 5 days before check-in
                            </p>
                        ) : null}
                        {details.bookingId && details.bookingId.cancelationPolicy[0] === 'Strict' ? (
                            <p className="text-sm text-gray-500 my-2">
                                <span className="text-gray-500">Strict: </span> No refunds
                            </p>
                        ) : null}
                    </>
                )} */}

                <h4 className="text-xl font-medium">Refund Details</h4>
                <div className="grid grid-cols-2 my-4 text-gray-500   ">
                    <p>Amount Refunded</p>
                    <p className="text-right">
                        {details.refundAmount &&
                            `$${parseFloat(details.refundAmount).toFixed(2).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })}`}
                    </p>

                    <hr className="col-span-full my-2" />
                    <p className="tw-font-medium font-medium text-black">Total</p>
                    <p className="tw-font-medium font-medium text-right text-black">
                        {details.refundAmount &&
                            `$${parseFloat(details.refundAmount).toFixed(2).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })}`}
                    </p>
                </div>
                <hr className="tw-col-span-full" />
                <div className="grid grid-cols-2 mt-3 d-flex align-items-center justify-content-between">
                    <div className="flex-col flex">
                        <h4 className="text-xl font-medium mb-3">Payment</h4>
                        <p className="text-gray-500">{details.cardDetail}</p>
                        <p className="text-gray-500">
                            {moment(details.createdAt).tz(details.bookingId.timezone).format(
                                // "MMMM DD, YYYY h:mm:ss zz"
                                'MMMM Do, YYYY h:mm:ss zz'
                            )}{' '}
                            {/* EST */}
                        </p>
                    </div>
                    <div className="flex-col flex justify-center">
                        <p className="text-right text-gray-500">
                            {details.refundAmount &&
                                `$${parseFloat(details.refundAmount).toFixed(2).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                })}`}
                        </p>
                    </div>
                </div>
                <hr className="tcol-span-full my-3" />
                <div className="grid grid-cols-2 text-gray-800 mt-3">
                    <p className="tw-font-medium font-medium">Amount Paid</p>
                    <p className="tw-font-medium text-right font-medium">
                        {details.refundAmount &&
                            `$${parseFloat(details.refundAmount).toFixed(2).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })}`}
                    </p>
                </div>
            </div>
        </div>
    )
}

const ViewDisputeReceipt = ({ onClose, disputeData }) => {
    console.log('Inside view dispute receipt function call in host website------------------------------', disputeData)
    return (
        <>
            {/* <div className="tw-bg-white p-4 tw-rounded-md tw-relative"> */}
            <div className="w-[95%] text-sm lg:w-[65%]  mx-auto">
                <div className="bg-white p-4 rounded-md relative ">
                    {/* <span
            onClick={onClose}
            className="tw-right-6 tw-top-6 tw-cursor-pointer align-right"
          >
            <IoMdClose size={32} />
          </span> */}
                    <span onClick={onClose} className="absolute right-6 top-6 cursor-pointer">
                        <IoMdClose size={32} />
                    </span>

                    <h2 className="text-3xl font-medium">Dispute receipt</h2>
                    <h3 className="text-2xl font-medium my-4">Your Dispute Receipt from Crewmates</h3>

                    {/* <p>Receipt ID: {disputeData.bookingId}</p>
                     */}
                    <p className="text-gray-500 mt-2">
                        Receipt ID: {disputeData.bookingId}{' '}
                        {disputeData.updatedAt && `- ${moment(disputeData.updatedAt).tz(disputeData.timezone).format('MMMM DD, YYYY')}`}
                    </p>
                    <p className="text-gray-500 tw-mt-2">Booking ID: {disputeData.originalBookingId} </p>
                    <br />
                    {/* <br /> */}
                    <h3 className="text-2xl font-medium mb-3">Dispute details</h3>
                    {/* <br /> */}
                    <p className="mb-1 text-gray-500">Dispute ID: {disputeData._id}</p>
                    <p className="mb-1 text-gray-500">
                        Amount Charged: $
                        {parseFloat(disputeData.amount).toFixed(2).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                    </p>

                    <hr className="col-span-full mt-3" />
                    <div className="grid grid-cols-2 mt-3 d-flex align-items-center justify-content-between">
                        <div className="flex-col flex">
                            <h4 className="text-xl font-medium mb-3">Payment</h4>
                            <p className="text-gray-500">{disputeData.cardDetail}</p>
                            <p className="text-gray-500">
                                {moment(disputeData.createdAt).tz(disputeData.timezone).format('MMMM DD, YYYY h:mm:ss zz')} {/* EST */}
                            </p>
                        </div>
                        <p className="text-right text-gray-500">
                            {`$${parseFloat(disputeData.amount).toFixed(2).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })}`}
                        </p>
                        <hr className="col-span-full mt-3" />
                        <div className="grid grid-cols-1 mt-3">
                            <p className="font-medium">Amount Paid</p>
                        </div>
                        <div className="grid grid-cols-1 mt-3 text-right">
                            <p className="font-medium text-right">
                                {`$${
                                    disputeData.amount &&
                                    parseFloat(disputeData.amount).toFixed(2).toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    })
                                }`}
                            </p>
                        </div>
                    </div>
                </div>
                {/* <p className="tw-mb-1">Reservation Name: {disputeData.userId.name}</p>
          <p className="tw-mb-1">Dispute Status: {disputeData.status}</p> */}
            </div>
        </>
    )
}

function BookingDetails({ data, checkIn, checkOut }) {
    const [transactionList, setTransactionList] = useState([])
    const [transactionId, setTransactionId] = useState(null)
    const [refundData, setRefundData] = useState({})
    const [refundDetails, setRefundDetails] = useState({
        reufndData: [],
        individualRefund: {},
    })

    const [disputeData, setDisputeData] = useState({ data: [], isOpen: false, selectedDispute: {} })

    const [showRefundReceipt, setShowRefundReceipt] = useState(false)
    const previousMonth = moment(data.checkInDate.split('T')[0]).subtract(1, 'M').format('MMMM')
    const currentMonth = moment(data.checkInDate.split('T')[0]).format('MMMM')
    const nextMonth = moment(data.checkInDate.split('T')[0]).add(1, 'M').format('MMMM')

    const toggleRefundReceipt = (refundInfo) => () => {
        setShowRefundReceipt((e) => !e)
        if (refundInfo) {
            setRefundDetails((state) => ({
                ...state,
                individualRefund: refundInfo,
            }))
        }
    }

    const toggleDisputeReceiptModal = (flag, selectedDispute) => () => {
        if (selectedDispute) {
            setDisputeData((state) => ({
                ...state,
                isOpen: flag,
                selectedDispute: selectedDispute,
            }))
        } else {
            setDisputeData((state) => ({
                ...state,
                isOpen: flag,
            }))
        }
        console.log('toggleDisputeReceiptModal ==>>> ', flag)
    }

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                const res = await getTransactions({
                    bookingId: data._id,
                })

                setTransactionList(res.data.data)
            } catch (error) {
                console.log(error)
                toast.error('Something went wrong!')
            } finally {
            }
        }
        if (data._id) fetchTransactions()
    }, [data])

    useEffect(() => {
        const fetchRefundData = async () => {
            try {
                const res = await getRefundDetails({
                    bookingId: data._id,
                })

                setRefundDetails((state) => ({
                    ...state,
                    reufndData: res?.data.data,
                }))
            } catch (error) {
                toast.error('Something went wrong!')
            } finally {
            }
        }
        if (data._id) fetchRefundData()
    }, [data])

    useEffect(() => {
        const fetchDispiuteReceipt = async () => {
            try {
                const res = await getDisputeReceiptDetails(data._id)
                console.log('dispute receipt ==>>> ', res)

                setDisputeData((state) => ({
                    ...state,
                    data: res.data.data,
                }))
            } catch (error) {
                toast.error('Something went wrong!')
            } finally {
            }
        }

        if (data._id) fetchDispiuteReceipt()
    }, [data])

    console.log('dispute data =>> ', disputeData)

    const showMonthsForTransactions = (transactionData) => {
        if (data.isMonthlyCheckout === 2) {
            return transactionData.dateForIdentifyMonth
                ? moment(transactionData.dateForIdentifyMonth).format('MMMM')
                : moment(transactionData.startDate.split('T')[0]).format('MMMM')
        } else {
            return moment(transactionData.startDate.split('T')[0]).format('MMMM')
        }
    }

    return (
        <div className="pb-4">
            {transactionId && (
                <Modal className="w-full  " onClose={() => setTransactionId(null)}>
                    {data.bookingType === COLD ? (
                        <ColdBedReceipt transactionId={transactionId} onClose={() => setTransactionId(null)} />
                    ) : (
                        <HotBedReceipt transactionId={transactionId} onClose={() => setTransactionId(null)} />
                    )}
                </Modal>
            )}

            {showRefundReceipt && (
                <Modal className="w-full  " onClose={toggleRefundReceipt(null)}>
                    <RefundReceipt details={refundDetails?.individualRefund} onClose={toggleRefundReceipt(null)} />
                </Modal>
            )}

            {}

            {disputeData.isOpen && (
                <Modal className="w-full  " onClose={toggleDisputeReceiptModal(false)}>
                    <ViewDisputeReceipt disputeData={disputeData.selectedDispute} onClose={toggleDisputeReceiptModal(false)} />
                </Modal>
            )}

            <div className="mb-4">
                <img alt="" className="w-full h-36 rounded-md object-cover " src={processMediaUrl(data.propertyId.images[0])} />
            </div>
            <div className="grid grid-cols-3 gap-2 text-sm">
                <div className="mb-4 col-span-full text-sm flex justify-between items-center">
                    <div>
                        <p className="text-xs">Check In</p>
                        <p className="text-sm text-gray-700 font-medium">{checkIn}</p>
                    </div>
                    <hr />
                    <div>
                        <p className="text-right text-xs">Check Out</p>
                        <p className="text-sm text-gray-700 font-medium">{data.checkOutDate ? checkOut : '-'}</p>
                    </div>
                </div>
                <>
                    <p className="font-medium ">Booking type</p>
                    <p className="col-span-2 ">{data.bookingType}</p>
                    <p className="font-medium ">Confirmation Code</p>
                    <p className="col-span-2 ">{data.confirmationCode || '--'}</p>
                    <p className="font-medium ">Property</p>
                    <p className="col-span-2 ">{data.propertyId.listingName}</p>
                    {data.checkOutDate && (
                        <>
                            <p className="font-medium ">Nights</p>
                            <p className="col-span-2 ">
                                {parseInt(utility.getNumberOfDays(new Date(data.checkInDate), new Date(data.checkOutDate))) - 1}
                            </p>
                        </>
                    )}
                    <p className="font-medium ">Created At</p>
                    <p className="col-span-2 ">{moment(data.createdAt.split('T')[0]).format('MMMM Do, YYYY')}</p>
                    <p className="font-medium ">Selected Room</p>
                    <p className="col-span-2 ">{data.roomName}</p>
                    {data.bedName && (
                        <>
                            <p className="font-medium ">Selected Bed</p>
                            <p className="col-span-2 ">{data.bedName}</p>
                        </>
                    )}

                    {/* {refundData && Boolean(Object.keys(refundData).length) && ( */}
                    {refundDetails.reufndData.length > 0 &&
                        refundDetails.reufndData &&
                        refundDetails.reufndData.map((refund, index) => {
                            return (
                                <>
                                    <p className="font-medium ">Refund Amount</p>
                                    <p className="col-span-2 flex justify-between items-center">
                                        {parseFloat(refund.refundAmount || 0)
                                            .toFixed(2)
                                            .toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                            })}
                                        <span
                                            onClick={toggleRefundReceipt(refund)}
                                            className="text-primary-blue ml-2 cursor-pointer underline text-sm block"
                                        >
                                            view receipt
                                        </span>
                                    </p>
                                </>
                            )
                        })}
                    {/* {disputeData.data != null && disputeData.data.length > 0 && disputeData.data.map((receipt,index)=>{ */}
                    {disputeData.data != null && disputeData.data.length > 0 && disputeData.data[0] && disputeData.data[0].status === 'Closed' && (
                        <>
                            <p className="font-medium ">Dispute Amount</p>
                            <p className="col-span-2 flex justify-between items-center">
                                {parseFloat(disputeData.data[0].amount || 0)
                                    .toFixed(2)
                                    .toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    })}

                                <span
                                    // onClick={() => {
                                    //     // setDisputeData(state=>({
                                    //     //     ...state,
                                    //     //     isOpen:true
                                    //     // }))
                                    //   }}
                                    onClick={toggleDisputeReceiptModal(true, disputeData.data[0])}
                                    // onClick={toggleRefundReceipt(refund)}
                                    className="text-primary-blue ml-2 cursor-pointer underline text-sm block"
                                >
                                    view receipt
                                </span>
                            </p>
                        </>
                    )}

                    {/* {data.bookingType === HOT ? ( */}
                    {data.bookingType === 'ss' ? (
                        <>
                            <p className="font-medium ">Price</p>
                            <p className="col-span-2 ">
                                {parseFloat(data.totalPrice || 0)
                                    .toFixed(2)
                                    .toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    })}
                            </p>
                        </>
                    ) : (
                        <>
                            {!Boolean(transactionList.length) && (
                                <div className="text-gray-500 p-4 col-span-full  grid place-content-center">No Transactions found</div>
                            )}
                            {transactionList.map((transactionData, ind) => (
                                <div className="col-span-full flex justify-between  space-x-3  ">
                                    {/* <p className="font-medium ">{moment(transactionData.startDate.split('T')[0]).format('MMMM')}</p> */}
                                    <p className="font-medium ">{showMonthsForTransactions(transactionData)}</p>

                                    <p className="col-span-2 flex justify-between ">
                                        {parseFloat(transactionData.totalPrice || 0)
                                            .toFixed(2)
                                            .toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                            })}
                                        <span
                                            onClick={() => {
                                                setTransactionId(transactionData.id)
                                            }}
                                            className="text-primary-blue ml-2 cursor-pointer underline text-sm block"
                                        >
                                            view receipt
                                        </span>
                                    </p>
                                </div>
                            ))}
                        </>
                    )}
                </>
            </div>
        </div>
    )
}

function DisputeModal({ data, setRefreshKey }) {
    const [fetching, setFetching] = useState(false)
    const [isRejected, setIsRejected] = useState(false)
    const [isRequestingSettelment, setSettlementRequest] = useState(false)
    const [settelmentApi, setSettlementApi] = useState(false)
    const [acceptingDisputeApi, setAcceptingDisputeApi] = useState(false)
    const [rejectingDisputeApi, setRejectingDisputeApi] = useState(false)
    const [disputeData, setDisputeData] = useState({})
    const [disputeRefreshKey, setDisputeRefreshKey] = useState(0)
    const [involvingAdmin, setinvolvingAdmin] = useState(false)
    const [reason, setReason] = useState('')
    const [amount, setAmount] = useState(0)
    const prevRef = useRef(null)
    const nextRef = useRef(null)

    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return `<span class="${className} h-2 w-2 bg-white shadow-md rounded-full z-100"></span>`
        },
    }

    const handleSettlementRequest = async () => {
        try {
            setSettlementApi(true)
            const res = await sendDisputeReply({
                message: reason,
                amount: amount,
                type: 'Guest',
                status: 'Settle',
                disputeId: disputeData?._id,
            })
            if (res.data.status) {
                setDisputeRefreshKey((e) => e + 1)
            }
        } catch (error) {
            toast.error('Something went wrong')
        } finally {
            setSettlementApi(false)
            setSettlementRequest(false)
        }
    }

    const acceptDisputeAmount = async () => {
        try {
            setAcceptingDisputeApi(true)
            const res = await sendDisputeReply({
                type: 'Guest',
                status: 'Accepted',
                disputeId: disputeData?._id,
            })
            if (res.data.status) {
                setDisputeRefreshKey((e) => e + 1)
            }
        } catch (error) {
            toast.error('Something went wrong')
        } finally {
            setAcceptingDisputeApi(false)
            setSettlementRequest(false)
        }
    }

    const rejectDispute = async (e) => {
        try {
            e.preventDefault()
            setRejectingDisputeApi(true)

            if (!e.target.rejectReason.value.length) {
                return toast.warn('Reject reason is required')
            }

            const res = await sendDisputeReply({
                message: e.target.rejectReason.value,
                type: 'Guest',
                status: 'Rejected',
                disputeId: disputeData?._id,
            })
            if (res.data.status) {
                setDisputeRefreshKey((e) => e + 1)
            }
        } catch (error) {
            toast.error('Something went wrong')
        } finally {
            setRejectingDisputeApi(false)
            setSettlementRequest(false)
        }
    }

    const involveAdmin = async (id) => {
        try {
            setinvolvingAdmin((e) => !e)
            await involveAdminInDispute(id)
        } catch (error) {
            toast.error('Something went wrong!')
        } finally {
            setinvolvingAdmin((e) => !e)
        }
    }

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                setFetching(true)
                const res = await getDisputeDetails(data._id)

                if (res.data.status) {
                    if (res.data.data === null) {
                        setRefreshKey((e) => e + 1)
                    }
                    setDisputeData(res.data.data)
                }
            } catch (error) {
                toast.error('Something went wrong')
            } finally {
                setFetching(false)
            }
        }

        fetchDetails()
    }, [disputeRefreshKey])

    return (
        <div className="max-h-[85vh] overflow-scroll scrollbar-hide">
            {fetching && (
                <div className="bg-gray-100 flex-center h-48">
                    <div className="bg-white rounded shadow-md p-4">
                        <div className="loader"></div>
                    </div>
                </div>
            )}
            {!fetching && (
                <>
                    {Boolean(disputeData?.images?.length) ? (
                        <div className="relative h-[350px] md:h-[250px]">
                            <div
                                ref={prevRef}
                                className="absolute opacity-100  top-0 bottom-0 my-auto left-5 text-white z-100 h-10 w-10  cursor-pointer flex-center"
                            >
                                <HiOutlineChevronLeft size={20} className=" " />
                            </div>

                            <div
                                ref={nextRef}
                                className="opacity-100  absolute top-0 bottom-0 my-auto right-5 text-white  h-10 w-10  z-100 cursor-pointer flex-center"
                            >
                                <HiOutlineChevronLeft size={20} className="transform rotate-180   " />
                            </div>
                            <Swiper
                                navigation={{
                                    prevEl: prevRef.current,
                                    nextEl: nextRef.current,
                                }}
                                onSwiper={(swiper) => {
                                    swiper.params.navigation.prevEl = prevRef.current
                                    swiper.params.navigation.nextEl = nextRef.current

                                    // Re-init navigation
                                    swiper.navigation.destroy()
                                    swiper.navigation.init()
                                    swiper.navigation.update()
                                }}
                                pagination={pagination}
                                className="h-full w-full"
                            >
                                {disputeData?.images?.map((curr, id) => {
                                    return (
                                        <SwiperSlide key={id} className="h-full w-full">
                                            <div className=" h-full w-full rounded overflow-hidden">
                                                <img src={processMediaUrl(curr)} alt="lady on call" className="w-full h-full object-cover  " />
                                            </div>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </div>
                    ) : (
                        <p className="text-center text-gray-600 my-2">No picture provided</p>
                    )}
                    <div className="my-4">
                        <p className="text-[12px] mb-2 bg-gray-100 p-2">
                            <span className="block font-medium text-gray-600">
                                You{' '}
                                {/* <span className="text-gray-500 font-normal ">
                  {moment(disputeData.createdAt).fromNow()}
                </span> */}
                            </span>

                            <span>
                                <span className="text-gray-600 ">Amount: </span>
                                {parseFloat(disputeData.amount).toFixed(2).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                })}
                            </span>
                            <span className="block">
                                <span className="text-gray-600  ">Reason: </span>
                                {disputeData?.message}
                            </span>
                        </p>
                        {disputeData?.conversation?.map((curr, id) => {
                            return (
                                <div className="text-[12px] mb-2 bg-gray-100 p-2" key={id}>
                                    <span className="block font-medium text-gray-600">
                                        {curr.type === 'Guest' ? 'You' : curr.type === 'Host' ? disputeData.hostId.name : 'Admin'}{' '}
                                        {/* <span className="text-gray-500 font-normal ">
                      {moment(curr.createdAt).fromNow()}
                    </span> */}
                                    </span>
                                    {curr.type !== 'Admin' && (
                                        <span>
                                            <span className="text-gray-600 ">Amount: </span>
                                            {parseFloat(curr.amount).toFixed(2).toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                            })}
                                        </span>
                                    )}
                                    <span className="block">
                                        <span className="text-gray-600  ">{curr.type === 'Admin' ? 'Message:' : 'Reason:'} </span>
                                        {curr.message}
                                    </span>
                                </div>
                            )
                        })}
                    </div>
                    {!isRejected && !isRequestingSettelment && (
                        <div className="flex gap-2  flex-wrap justify-end my-2">
                            <button onClick={acceptDisputeAmount} className="p-2 px-4 rounded text-xs bg-primary-blue text-white">
                                {!acceptingDisputeApi
                                    ? `Accept & Pay 
                ${parseFloat(disputeData?.amount).toFixed(2).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })}`
                                    : 'Please wait...'}
                            </button>

                            <button onClick={() => setSettlementRequest(true)} className="p-2 px-4 rounded text-xs bg-primary-blue text-white">
                                Settlement Request
                            </button>
                            {/* <button
                onClick={() => setIsRejected(true)}
                className="p-2 px-4 rounded text-xs bg-parrot  text-white"
              >
                Reject
              </button> */}
                            {!disputeData.isAdminInvolved && (
                                <button
                                    onClick={() => involveAdmin(disputeData._id)}
                                    className="p-2 px-4  rounded text-xs bg-primary-blue text-white"
                                >
                                    {involvingAdmin ? 'please wait...' : 'Involve Admin'}
                                </button>
                            )}
                            {disputeData.isAdminInvolved && (
                                <p className="p-2 mt-2 font-medium rounded text-xs text-primary-blue ">* Admin is involved in this dispute.</p>
                            )}
                        </div>
                    )}
                    {isRejected && (
                        <form onSubmit={rejectDispute} className=" my-2">
                            <div>
                                <textarea
                                    className="rounded w-full  p-2 px-4 text-sm text-gray-600"
                                    name="rejectReason"
                                    required
                                    placeholder="Please specify your reason for rejection"
                                    id=""
                                    rows="5"
                                ></textarea>
                            </div>
                            <button type="submit" className="p-2 px-4 ml-auto rounded text-xs bg-parrot  text-white">
                                {rejectingDisputeApi ? 'Please wait...' : 'Send'}
                            </button>
                        </form>
                    )}
                    {isRequestingSettelment && (
                        <div className=" my-2">
                            <div>
                                <input
                                    type="number"
                                    step="0.01"
                                    placeholder="Settlement Amount"
                                    value={amount}
                                    min={0}
                                    onChange={(e) => setAmount(Math.max(0, parseFloat(e.target.value)))}
                                    className="rounded p-2 px-4 text-sm mb-2 w-full"
                                />
                                <textarea
                                    className="rounded w-full  p-2 px-4 text-sm text-gray-600"
                                    name="reject-reason"
                                    placeholder="Reason for settlement"
                                    value={reason}
                                    onChange={(e) => setReason(e.target.value)}
                                    id=""
                                    rows="5"
                                ></textarea>
                            </div>
                            <div className="flex space-x-2">
                                <button onClick={handleSettlementRequest} className="p-2 px-4 ml-auto rounded text-xs bg-parrot  text-white">
                                    {settelmentApi ? 'Please wait...' : 'Send'}
                                </button>
                                <button
                                    onClick={() => setSettlementRequest(false)}
                                    className="p-2 px-4 ml-auto rounded text-xs bg-parrot  text-white"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

function NoticePeriodModal({ checkIn, onProceed, onCancle, bookingDetails }) {
    const [requesting, setRequesting] = useState(false)
    const [date, setDate] = useState(
        // new Date(addDays(new Date(checkIn), 30)).toISOString().split("T")[0]
        new Date(addDays(new Date(), 30)).toISOString().split('T')[0]
    )

    const getDateAfterThirtyDays = () => {
        const now = moment().tz(bookingDetails.timezone)
        const additionalDays = 30 // now.hour() < 10 ? 30 : 31
        let minDate = now.add(additionalDays, 'days').toDate()

        if (bookingDetails.latestBookingHistory.status === 'Hold') {
            const minuteDiffrence = calculateMinutesDifference(
                moment().tz(bookingDetails.timezone).toDate(),
                new Date(bookingDetails.latestBookingHistory.checkInDate)
            )
            console.log('latestBookingHistory 1', { minuteDiffrence })
            if (minuteDiffrence < 1450) {
                // if 10 minute left to capture
                const bookedTilDate = new Date(bookingDetails.latestBookingHistory.endDate)
                if (minDate < bookedTilDate) {
                    minDate = bookedTilDate
                }
            }
        } else if (bookingDetails.latestBookingHistory.status === 'Paid') {
            const bookedTilDate = new Date(bookingDetails.latestBookingHistory.endDate)

            if (minDate < bookedTilDate) {
                minDate = bookedTilDate
            }
        }

        minDate = moment(minDate).format('YYYY-MM-DD')
        return minDate
    }

    useEffect(() => {
        setDate(getDateAfterThirtyDays())
    }, [])

    return (
        <div className="pb-4">
            {/* {`${moment(date).format("MMMM Do YYYY")}`} */}
            <p className="text-sm my-4  flex items-center">
                Your checkout date will be on
                <input
                    className="text-sm border-0 inline-block border-b flex-shrink-0 p-0 pl-2"
                    value={date}
                    min={getDateAfterThirtyDays()}
                    defaultValue={new Date()}
                    type="date"
                    onChange={(e) => setDate(e.target.value)}
                />
            </p>
            <div className="flex justify-end items-center space-x-4 ">
                <button className="p-2 px-4 rounded-md text-sm text-white bg-parrot" onClick={onCancle}>
                    Cancel
                </button>
                <button className="p-2 px-4 rounded-md text-sm text-white bg-primary-blue " onClick={() => onProceed(date)}>
                    {requesting ? 'Please wait...' : 'Done'}
                </button>
            </div>
        </div>
    )
}

function ReviewModal({ handleReview, requesting }) {
    const [star, setStar] = useState(0)
    const [message, setMessage] = useState('')

    return (
        <div className="col-span-full flex flex-col py-4">
            <label htmlFor="" className="text-sm font-medium text-primary-blue mb-1">
                Review
            </label>
            <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows={6}
                placeholder="Write review"
                className="rounded outline-none focus:ring-0 border-1 border-gray-500 text-xs mb-2 p-2"
            />
            <label htmlFor="" className="text-sm font-medium text-primary-blue mb-1">
                Rating
            </label>
            <StarRatings rating={star} starDimension="28px" starSpacing="5px" starRatedColor="#eab308" changeRating={(e) => setStar(e)} />
            <button
                disabled={requesting}
                onClick={() => handleReview({ star, message })}
                className="mt-2  p-2 px-4 bg-primary-blue text-white text-sm rounded-md mr-2 font-light   "
            >
                {requesting ? 'Please wait...' : 'Submit'}
            </button>
        </div>
    )
}

function ReportModal({ id, toggleReportModal }) {
    const [message, setMessage] = useState('')
    const [photos, setPhotos] = useState([])
    const [requesting, setRequesting] = useState(false)

    const getImage = (file) => {
        const url = URL.createObjectURL(file.current)
        return url
    }

    const handleDelete = (id) => {
        const arr = photos.filter((curr) => curr.id !== id)
        setPhotos([...arr])
    }

    const handleReportSubmission = async () => {
        try {
            setRequesting(true)
            if (!message) {
                return toast.error('Please provide some details about the report.')
            }
            const { data } = await generalServices.report({
                message,
                bookingId: id,
                photos,
            })

            if (data.status) {
                toast.success(data.message, { toastId: 29 })
                setTimeout(() => {
                    toggleReportModal()
                }, 1000)
            } else toast.error(data.message)
        } catch (error) {
            toast.error('Something went wrong!', { toastId: 30 })
        } finally {
            setRequesting(false)
        }
    }

    return (
        <div className=" flex flex-col py-4 overflow-scroll max-h-[80%] ">
            <div className="">
                <p className="text-xs font-medium mb-4">
                    <span className="text-red-500 ">
                        EMERGENCY ASSISTANCE IS NOT AVAILABLE THROUGH CREWMATES. In an emergency, dial 911 or your local emergency number immediately.
                    </span>
                    <br />
                    Crewmates reports are for informational purposes only to track incidents or suspected fraudulent activity
                </p>
                <label className="name block  mb-2 font-medium">Add Photos</label>
                <div className="w-full">
                    <div className=" mb-2">
                        <div className="grid grid-cols-3 md:grid-cols-5 gap-2 items-start flex-wrap mb-4">
                            {photos.map((curr, i) => (
                                <div
                                    key={i}
                                    id={curr.id}
                                    className="w-full aspect-w-1 aspect-h-1 flex-shrink-0 grow-0 relative   object-cover rounded-md overflow-hidden"
                                >
                                    <span
                                        onClick={() => handleDelete(curr.id)}
                                        className="absolute z-10 left-2 top-2 h-6 w-6 flex items-center justify-center cursor-pointer rounded-full bg-gray-500 bg-opacity-50"
                                    >
                                        <AiOutlinePlus size={20} className="transform rotate-45 text-white" />
                                    </span>
                                    <img alt="" className="w-full h-full object-cover " src={curr.src} />
                                </div>
                            ))}{' '}
                        </div>
                        <div className="col-lg-6 mb-4">
                            <div className="mb-3">
                                <div className="flex items-center">
                                    <label htmlFor="add_photos" className="p-2 px-4 text-xs rounded bg-primary-blue text-white cursor-pointer">
                                        Choose
                                    </label>
                                    <p className="text-sm ml-4">{!photos.length ? 'No file choosen' : `${photos.length} File choosen`}</p>
                                </div>
                                <input
                                    max={5}
                                    id="add_photos"
                                    type="file"
                                    multiple
                                    className="form-control hidden"
                                    accept="image/*"
                                    onChange={(e) => {
                                        const curr = Array.from(e.target.files).map((file, i) => {
                                            return {
                                                current: file,
                                                id: nanoid(),
                                                src: getImage({ current: file }),
                                                order: photos.length + i + 1,
                                            }
                                        })

                                        const arr = [...photos, ...curr]
                                        if (arr.length > 6) {
                                            return toast.error('Maximum number of files allowed is 8.')
                                        }
                                        setPhotos(arr)
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <span className="block mb-2 font-medium  ">Write Message</span>
                    <textarea
                        type="text"
                        id="name"
                        rows={6}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        className="w-full rounded"
                    ></textarea>
                </div>
                <button disabled={requesting} onClick={handleReportSubmission} className="p-2 px-4 text-sm bg-primary-blue text-white rounded">
                    {requesting ? 'Please wait...' : 'Submit Report'}
                </button>
            </div>
        </div>
    )
}

function BookingCard({ data, className, setRefreshKey }) {
    const { user } = useAuth()
    const history = useHistory()
    const [cancleBookingStatus, setCancleBookingStatus] = useState(false)
    const [reviewWriteRequesting, setReviewWriteRequesting] = useState(false)
    const [isReviewGiven, setIsReviewGiven] = useState(data.isRated)
    const [writeReview, setwriteReview] = useState(false)
    const [disputeModal, setDisputeModal] = useState(false)
    const [reportModal, setReportModal] = useState(false)
    const [detailsModal, setDetailsModal] = useState(false)
    const [reportBookingId, setreportBookingId] = useState('')
    const [updateBookingRequest, setUpdateBookingRequest] = useState(false)
    const [cancelRequest, setCancelRequest] = useState(false)
    const [noticePeriodModal, setNoticePeriodModal] = useState(false)

    const toggleDispute = () => {
        setDisputeModal((e) => !e)
    }

    const toggleReview = () => {
        setwriteReview((e) => !e)
    }

    const toggleReport = () => {
        setReportModal((e) => !e)
    }

    const toggleDetailsModal = () => setDetailsModal(!detailsModal)
    const toggleNoticePeriodModal = () => setNoticePeriodModal(!noticePeriodModal)

    const checkIn = `${moment(data.checkInDate.split('T')[0]).format('MMMM Do, YYYY')}`
    const checkOut = data.checkOutDate ? `${moment(data.checkOutDate.split('T')[0]).format('MMMM Do, YYYY')}` : ``

    const handleReview = async ({ star, message }) => {
        try {
            setReviewWriteRequesting(true)
            if (!message) {
                return toast.error('Please write your message.')
            }
            const res = await addReview({
                rating: star,
                message,
                propertyId: data.propertyId._id,
                bookingId: data._id,
            })
            if (res.data.status) {
                toast.success(res.data.message, { toastId: 31 })
                setIsReviewGiven(true)
            } else toast.error(res.data.message)
        } catch (error) {
            toast.error('Something went wrong', { toastId: 32 })
        } finally {
            setwriteReview(false)
            setReviewWriteRequesting(false)
        }
    }

    function handleMessageNavigation() {
        if(data.bookingId) {
            const channelId = `${data.propertyId._id}-${user.id}-${data.propertyId.hostId}-${data.bookingId}`
            const friendlyName =`${data.propertyId._id}-${user.id}-${data.propertyId.hostId}-${data.bookingId}`
            console.log(channelId,friendlyName,'bk')
            history.push({
                pathname: '/dashboard/messages',
                state: {
                    channelId:channelId,
                    friendlyName: friendlyName,
                    user: {
                        id: user.id,
                        name: user.name,
                        profile: user.avatar,
                        email: user.personalEmail,
                    },
                    host: {
                        id: data.hostId.id,
                        name: data.hostId.name,
                        profile: data.hostId.avatar,
                        email: data.hostId.personalEmail,
                    },
                    property: {
                        name: data.propertyId.listingName,
                        id: data.propertyId._id,
                        host: data.hostId.name,
                        user: user.name,
                        picture: data.propertyId.images[0],
                        reservationId:data.bookingId
                    },
                    
                },
            })
            
        } else {
            const channelId = `${data.propertyId._id}-${user.id}-${data.propertyId.hostId}`
            const friendlyName=`${data.propertyId._id}-${user.id}-${data.propertyId.hostId}`
            console.log(channelId,friendlyName,'bk')
            history.push({
                pathname: '/dashboard/messages',
                state: {
                    channelId:channelId,
                    friendlyName: friendlyName,
                    user: {
                        id: user.id,
                        name: user.name,
                        profile: user.avatar,
                        email: user.personalEmail,
                    },
                    host: {
                        id: data.hostId.id,
                        name: data.hostId.name,
                        profile: data.hostId.avatar,
                        email: data.hostId.personalEmail,
                    },
                    property: {
                        name: data.propertyId.listingName,
                        id: data.propertyId._id,
                        host: data.hostId.name,
                        user: user.name,
                        picture: data.propertyId.images[0],
                        reservationId: data.bookingId
                    },
                    
                },
            })
        }
    }

    async function handleUpdateBooking(id, date) {
        try {
            setUpdateBookingRequest(true)
            const { data } = await updateBookingNoticePeriod({
                bookingId: id,
                checkOutDate: date,
            })

            if (data.status) {
                toast.success(data.message, { toastId: 33 })
            } else toast.error(data.message || 'Something went wrong', { toastId: 33 })
        } catch (error) {
        } finally {
            setRefreshKey((e) => e + 1)
            setUpdateBookingRequest(false)
        }
    }

    async function showCancleBooking() {
        if (data.status === 'Pending') return true
        if (data.status === 'Cancelled' || data.status === 'Completed' || data.status === 'Rejected') {
            return false
        }

        if (data.propertyId.cancelationPolicy === 'Strict') {
            return false
        }

        // const res = await timezone.fetchTimezone({
        //     lat: data.propertyId.location[1],
        //     lng: data.propertyId.location[0],
        // })
        //  console.log(res, 'resssssssssssssssssssssss')

        // let chkin = moment.tz(`${data.checkInDate.split('T')[0]} 11:00 am`, 'YYYY/M/D h:mm a', res.timeZoneId)

        //  let today = moment().tz(res.timeZoneId)

        //let hours = chkin.diff(today, 'hours')
        let hours = getHourDifference(moment().tz(data.timezone).toDate(), new Date(data.checkInDate))

        if (data.propertyId.cancelationPolicy === 'Relaxed') {
            if (hours >= 23.98) return true
            return false
        }

        if (data.propertyId.cancelationPolicy === 'Moderate') {
            if (hours >= 5 * 24) return true
            return false
        }
    }

    async function handleBookingCancelation(id) {
        try {
            setCancelRequest(true)
            const { data } = await cancleBooking({ bookingId: id })

            if (data.status) {
                toast.success(data.message, { toastId: 35 })
            } else {
                toast.error(data.message, { toastId: 36 })
            }
        } catch (error) {
            toast.error('Something went wrong')
        } finally {
            setRefreshKey((e) => e + 1)
            setCancelRequest(false)
        }
    }

    React.useEffect(() => {
        const checkStatus = async () => {
            let s = await showCancleBooking()
            setCancleBookingStatus(s)
        }
        checkStatus()
    }, [])

    const handleUpdatePayment = (data) => {
        history.push({
            pathname: '/update-payment-information',
            state: { data },
        })
    }

    const handleUpdatePrimaryPayment = (data) => {
        history.push({
            pathname: '/dashboard/setting',
            state: { data },
        })
    }

    const getNoticeText = () => {
        // (Notice Applied by hostname/Crewmates)
        if (data.hideNoticeApplyButton) {
            return ''
        }
        switch (data.noticeBy) {
            case 'Host':
                return `(Notice Applied by ${data.hostId.name})`
            case 'Guest':
                return `(Notice Applied by ${data.guestDetails.name})`
            case 'Admin':
                return `(Notice Applied by Crewmates)`
            default:
                return ''
        }
    }

    const showStausMessagesWithNotice = () => {
        if (data.isNotice) {
            switch (data.status) {
                case 'Accepted':
                case 'Paid':
                    return `Accepted ${getNoticeText()}`
                case 'Cancel_Scheduled':
                    return `Cancel Scheduled ${getNoticeText()}`
                default:
                    return data.status
            }
        } else {
            switch (data.status) {
                case 'Accepted':
                case 'Paid':
                    return `Accepted`
                case 'Cancel_Scheduled':
                    return `Cancel Scheduled`
                default:
                    return data.status
            }
            // return data.status === 'Paid' ? 'Accepted' : `${data.status}`
        }
    }

    return (
        <div className={`flex flex-col md:flex-row justify-start items-stretch py-5`}>
            <div className="md:w-7/12 flex flex-col md:flex-row">
                <img
                    alt=""
                    className="w-full h-36 md:h-auto md:w-44 rounded-md object-cover mb-2 md:mb-0"
                    src={processMediaUrl(data.propertyId.images[0])}
                />
                <div className="md:ml-4 flex flex-col items-stretch flex-grow">
                    <h3 className="text-primary-blue text-lg font-medium truncate w-8/12">{data.propertyId.listingName}</h3>
                    <p className="text-xs text-primary-blue my-2">
                        {checkIn} {checkOut ? `-${checkOut}` : ''}
                    </p>
                    <p className="text-[#94BA33] font-light text-sm my-2">
                        Reservation ID: <span className="font-medium"> {data.bookingId || 'NOT APPLICABLE'}</span>
                    </p>
                    {process.env.REACT_APP_APP_SHOW_EXTRA === 'true' && (
                        <>
                            <p className="text-[#94BA33] font-light text-sm my-2">
                                Current Time In {data.timezone}: <span className="font-medium">{moment().tz(data.timezone).toLocaleString()}</span>
                            </p>

                            {getHourDifference(moment().tz(data.timezone).toDate(), new Date(data.checkInDate)) > 0 && (
                                <p className="text-[#94BA33] font-light text-sm my-2">
                                    Hour Left from checkIn:{' '}
                                    <span className="font-medium">
                                        {getHourDifference(moment().tz(data.timezone).toDate(), new Date(data.checkInDate)).toFixed(2)} Hours
                                    </span>
                                </p>
                            )}

                            {calculateDayDifference(moment().tz(data.timezone).toDate(), new Date(data.checkInDate)) > 0 && (
                                <p className="text-[#94BA33] font-light text-sm my-2">
                                    Day Left from checkIn:{' '}
                                    <span className="font-medium">
                                        {calculateDayDifference(moment().tz(data.timezone).toDate(), new Date(data.checkInDate)).toFixed(2)} Days
                                    </span>
                                </p>
                            )}
                        </>
                    )}
                    {data.isNotice && !data.hideNoticeApplyButton && <p className="text-xs text-primary-blue">*Notice period applied</p>}
                    <p className="text-[#94BA33] font-light text-sm my-2">Confirmation Code: {data.confirmationCode || 'CVR45 W67'}</p>
                    {data.status === 'Accepted' && (
                        <p className="text-[#94BA33] font-light text-sm my-2">Address: {data.propertyId.address || '--'}</p>
                    )}
                    <p className="text-[#94BA33] font-light text-sm my-2">
                        <span className="font-medium ">Bedrooms</span>: {data.roomName || 0}{' '}
                        {data.bedName ? (
                            <>
                                | <span className="font-medium ">Bed </span>: {data.bedName}
                            </>
                        ) : (
                            ''
                        )}{' '}
                        | <span className="font-medium ">Rental Type</span>: {data.propertyId.rentalType}
                    </p>
                    <div className="mt-auto  flex flex-wrap gap-x-2 gap-y-2">
                        <button onClick={toggleDetailsModal} className="  p-2 px-4 bg-primary-blue text-white text-xs rounded-md mr-2 font-light   ">
                            View Details
                        </button>
                        <button
                            onClick={handleMessageNavigation}
                            className="p-2 px-4 bg-primary-blue text-white text-xs rounded-md mr-2 font-light   "
                        >
                            Message History
                        </button>
                        <button
                            onClick={() => {
                                setreportBookingId(data.id)
                                setReportModal(true)
                            }}
                            className="p-2 px-4 bg-primary-blue text-white text-xs rounded-md mr-2 font-light   "
                        >
                            Report
                        </button>
                        {data.status === 'Completed' && !isReviewGiven && (
                            <button onClick={toggleReview} className="  p-2 px-4 bg-primary-blue text-white text-xs rounded-md mr-2 font-light   ">
                                Write Review
                            </button>
                        )}
                        {cancleBookingStatus && (
                            <button
                                onClick={() => handleBookingCancelation(data._id)}
                                className="cancel booking  p-2 px-4 bg-primary-blue text-white text-xs rounded-md mr-2 font-light   "
                            >
                                {cancelRequest ? 'Please wait...' : 'Cancel Booking'}
                            </button>
                        )}

                        {/* {data?.updatePaymentMethodRequired && !data?.isPrimaryCardExpired && (
                            <button
                                onClick={() => handleUpdatePayment(data)}
                                className="cancel booking  p-2 px-4 bg-red-700 text-white text-xs rounded-md mr-2 font-light   "
                            >
                                Update Card Required
                            </button>
                        )} */}

                        {data?.updatePaymentMethodRequired && data?.isPrimaryCardExpired && (
                            <button
                                onClick={() => handleUpdatePrimaryPayment(data)}
                                className="cancel booking  p-2 px-4 bg-red-700 text-white text-xs rounded-md mr-2 font-light   "
                            >
                                Update Default Card Required
                            </button>
                        )}
                        {data.isDisputeCreated && (
                            <button onClick={toggleDispute} className="p-2 px-4 bg-primary-blue text-white text-xs rounded-md mr-2 font-light   ">
                                View Dispute
                            </button>
                        )}

                        {data.bookingType === COLD &&
                            new Date(data.checkInDate) - new Date() <= 0 &&
                            data.status === 'Accepted' &&
                            data.isMonthlyCheckout === 2 &&
                            !data.isNotice &&
                            !data.cancelRenew && (
                                <Tooltip
                                    arrow
                                    theme="light"
                                    html={<span className="text-xs">Give 30 days notice period for leaving the property</span>}
                                    position="bottom"
                                    trigger="mouseenter"
                                >
                                    <button
                                        onClick={toggleNoticePeriodModal}
                                        className="p-2 px-4 bg-primary-blue text-white text-xs rounded-md mr-2 font-light   "
                                    >
                                        {updateBookingRequest ? 'Please wait...' : ' Apply Notice'}
                                    </button>
                                </Tooltip>
                            )}
                    </div>
                </div>
            </div>
            <div className="mt-4 md:mt-auto">
                <h3 className="mb-2 text-sm text-primary-blue-dim">
                    Host Name:<span className="text-[#94BA33] "> {data.hostId.name}</span>
                </h3>
                <h3 className="mb-2 text-sm text-primary-blue-dim">
                    Amount :
                    <span className="text-[#94BA33] ">
                        {' '}
                        $
                        {parseFloat(data.wholeBookingTotal || 0)
                            .toFixed(2)
                            .toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })}
                        {/* {parseFloat(data.totalPrice || 0)
                            .toFixed(2)
                            .toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })} */}
                    </span>
                </h3>
                {data.status && (
                    <h3 className="mb-2 flex text-sm text-primary-blue-dim">
                        Status:
                        <span className="p-1 bg-primary-blue text-xs rounded inline ml-2 text-white ">
                            {' '}
                            {showStausMessagesWithNotice()}
                            {/* {data.status === 'Paid' ? 'Accepted' : `${data.status}`} */}
                        </span>
                    </h3>
                )}
            </div>
            {detailsModal && (
                <Modal title="Booking Details" onClose={toggleDetailsModal}>
                    <BookingDetails setRefreshKey={setRefreshKey} data={data} checkIn={checkIn} checkOut={checkOut} />
                </Modal>
            )}
            {noticePeriodModal && (
                <Modal title=" ">
                    <NoticePeriodModal
                        bookingDetails={data}
                        checkIn={data.checkInDate}
                        onCancle={toggleNoticePeriodModal}
                        onProceed={(date) => handleUpdateBooking(data._id, date)}
                    />
                </Modal>
            )}
            {disputeModal && (
                <Modal onClose={toggleDispute} title="Dispute">
                    <DisputeModal data={data} setRefreshKey={setRefreshKey} />
                </Modal>
            )}
            {writeReview && (
                <Modal onClose={toggleReview} title="Review">
                    <ReviewModal requesting={reviewWriteRequesting} handleReview={handleReview} />
                </Modal>
            )}
            {reportModal && (
                <Modal onClose={toggleReport} title="Report">
                    <ReportModal toggleReportModal={toggleReport} id={reportBookingId} />
                </Modal>
            )}
        </div>
    )
}

function MyBookings() {
    const { user } = useContext(AuthContext)
    const [fetching, setFetching] = useState(true)
    const [property, setProperty] = useState([])
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(0)
    const [status, setStatus] = useState('')
    const [search, setSearch] = useState('')
    const [refreshKey, setRefreshKey] = useState(0)

    const handlePageChange = (e) => {
        setPage(e.selected + 1)
    }

    const fetchBooking = async ({ page, status, search }) => {
        try {
            setFetching(true)
            const { data } = await getMyBooking({ page: page, status, search })
            if (data.status) {
                setProperty(data.data.docs)
                setTotalPage(data.data.pages)
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                })
            } else {
                toast.error(data.message)
            }
        } catch (error) {
            toast.error('Something went wrong')
        } finally {
            setFetching(false)
        }
    }

    const throtteledFetchBooking = useCallback(throttle(fetchBooking, 500), [])

    useEffect(() => {
        throtteledFetchBooking({ page, status, search })
    }, [page, search, status, refreshKey])

    return (
        <div>
            <Navbar title="My Bookings" user={user.name} />
            <div className="my-5 flex items-stretch w-11/12 space-x-4  justify-between">
                <div className=" w-8/12 p-1 bg-white  flex items-center">
                    {/* <GoSearch className="m-2 flex-shrink-0 " />
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            className="bg-transparent flex-grow text-sm p-0 outline-none ring-0 border-0 focus:ring-0 focus:border-0"
            placeholder="Search"
          /> */}
                </div>
                <div className="">
                    <select
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        className="text-xs md:w-44 ring-1 border-0 h-full ring-gray-700 rounded-full "
                    >
                        <option disabled value={''}>
                            Status
                        </option>
                        <option value={''}>All</option>
                        <option value={'Accepted'}>Accepted</option>
                        {/* <option value={'Upcoming'}>Upcoming</option> */}
                        <option value={'Pending'}>Pending</option>
                        <option value={'Completed'}>Completed</option>
                        <option value={'Rejected'}>Rejected</option>
                        <option value={'Cancelled'}>Cancelled</option>
                    </select>
                </div>
            </div>
            <div className="my-5">
                {!fetching ? (
                    <>
                        {/* <div className="md:w-8/12"> */}
                        <div>
                            {property.map((el) => (
                                <BookingCard setRefreshKey={setRefreshKey} data={el} key={el.id} poster="/assets/resources/my-booking.png" />
                            ))}
                        </div>
                        {property.length === 0 && (
                            <>
                                <div className="h-[65vh] bg-white mx-auto">
                                    <h4 className="text-center text-2xl text-primary-blue  font-medium my-10">No Booking Yet</h4>
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <div className="bg-gray-100 flex-center h-screen">
                        <div className="bg-white rounded shadow-md p-4">
                            <div className="loader"></div>
                        </div>
                    </div>
                )}
            </div>
            <div className="w-8/12 mx-auto">
                <ReactPaginate
                    nextClassName="bg-primary-blue  h-10 w-10 rounded-full flex-center"
                    disabledClassName="pagination-dissabled"
                    nextLabel={<img alt="" src="/assets/resources/arrow-left.png" className="w-4 transform rotate-180" />}
                    activeClassName="pagination-active"
                    previousClassName="bg-primary-blue  h-10 w-10 rounded-full flex-center"
                    previousLabel={<img alt="" src="/assets/resources/arrow-left.png" className="w-4 " />}
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={3}
                    pageCount={totalPage}
                    containerClassName="flex justify-center items-center flex-shrink-0 "
                    pageClassName="text-white rounded-full pagination-btn mx-2"
                    pageLinkClassName="flex-center  text-xs h-10 w-10 "
                />
            </div>
        </div>
    )
}

export default MyBookings
