import React, { useState, useContext, useRef, useCallback } from 'react'
import OTPModal from '@components/OTPModal'
import Modal from '@components/Modal'
import { useGoogleLogin } from '@react-oauth/google'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import OtpInput from 'react-otp-input'
import { toast, ToastContainer } from 'react-toastify'
import { Context as AuthContext } from '@context/authContext'
import Persona from 'persona'
import { constant } from '@config/constant'
import useClickOutside from '@hooks/useClickOutside'
import { FcGoogle } from 'react-icons/fc'
import { BsFacebook } from 'react-icons/bs'
import axios from 'axios'
import SocialCard from '@components/SocialCards'

import {
    verifyEmail,
    verifyPhone,
    forgetPassword,
    sendAirlineEmailVerificationLink,
    updateIdVerificationStatus,
    updateSocialAuthenticationStatus,
} from '@services/user'
import { setResetToken } from '@helper/header'

function VerifyDetails() {
    const { user, updateUser } = useContext(AuthContext)
    const { verificationType, setVerificationType } = useState('')
    const [requesting, setRequesting] = useState(false)
    const [otp, setOtp] = useState('')
    const personaRef = useRef()

    const onClose = () => {
        
    }

    const handleIdVerification = async ({ inquiryId, status, fields }) => {
        try {
            const { data } = await updateIdVerificationStatus()
            if (data.status) {
                toast.success(data.message)
                updateUser({ idVerificationStatus: 1 })
                onClose()
            } else {
                toast.error(data.message)
            }
        } catch (error) {
            toast.error('Something went wrong!')
        }
    }

    const handleSendAirlineEmailVerificationLink = async () => {
        try {
            if (!user.airlineEmail) {
                toast.info('Please update airline email in profile section.')
                return
            }
            setRequesting(true)
            const res = await sendAirlineEmailVerificationLink({
                emailType: 'airline',
                emailId: user.airlineEmail,
            })

            setRequesting(false)
            if (res.data.status) {
                toast.success(res.data.message)
            } else toast.error(res.data.message)
        } catch (error) {
            toast.error('Something went wrong!')
        }
    }

    const handleVerification = async () => {
        try {
            if (otp.length === 4) {
                setRequesting(true)
                if (verificationType === 'phone') {
                    const { data } = await verifyPhone({ otp })
                    if (data.status) {
                        toast.success(data.message)
                        onClose()
                        updateUser({ phoneVerification: 1 })
                    } else {
                        toast.error(data.message)
                    }
                } else {
                    const { data } = await verifyEmail({ otp })
                    if (data.status) {
                        toast.success(data.message)
                        updateUser({ personalEmailVerification: 1 })
                        onClose()
                    } else {
                        toast.error(data.message)
                    }
                }
                setRequesting(false)
            }
        } catch (error) {
            toast.error('something went wrong')
            setRequesting(false)
        }
    }

    const VerificationModal = ({ header, description }) => {
        return (
            <OTPModal title="&nbsp;" onClose={onClose}>
                <div className="grid place-content-center my-10">
                    <h2 className="text-base  text-center">{header}</h2>
                    <p className="text-sm text-center mt-2 w-10/12 mx-auto">{description}</p>
                    <OtpInput
                        value={otp}
                        onChange={(e) => {
                            setOtp(e)
                        }}
                        isInputNum
                        containerStyle="flex-center space-x-4 my-6"
                        inputStyle="h-10 w-10 bg-white rounded p-0"
                        separator={<span>{'  '}</span>}
                        numInputs={4}
                    />
                    <button onClick={handleVerification} className="bg-primary-blue p-2 px-4 mx-auto w-max block rounded text-sm text-white ">
                        {requesting ? 'Please wait...' : 'Verify'}
                    </button>
                    <p onClick={() => handleResendOtp()} className="text-primary-blue cursor-pointer  text-center text-sm my-4">
                        Resend OTP
                    </p>
                </div>
            </OTPModal>
        )
    }

    const AirlineVerificationModal = ({ onClose, header, description }) => {
        return (
            <Modal title="&nbsp;" onClose={onClose}>
                <div className="grid place-content-center  my-10">
                    <h2 className="text-base text-center">{header}</h2>
                    <p className="text-sm text-center mt-2 w-10/12 mx-auto">{description}</p>
                    <button
                        onClick={handleSendAirlineEmailVerificationLink}
                        className="bg-primary-blue p-2 px-4 my-10 mx-auto w-max block rounded text-sm text-white "
                    >
                        {requesting ? 'Please wait...' : 'Send Link'}
                    </button>
                </div>
            </Modal>
        )
    }

    const PersonaVerificationModal = ({ onClose, header, description, userId }) => {
        useClickOutside(personaRef, onClose)
        return (
            <Modal className="w-full  md:w-6/12  p-0">
                <div ref={personaRef} className="h-screen md:h-auto rounded-md overflow-hidden bg-white">
                    <Persona.Inquiry
                        referenceId={userId}
                        templateId={constant.persona_template_id}
                        environment={constant.persona_mode}
                        onLoad={() => {}}
                        onComplete={handleIdVerification}
                    />
                </div>
            </Modal>
        )
    }

    const connectUserSocialAccount = async (data) => {
        //check if user email is equal to social account email
        // if (user.personalEmail === data.email) {
        let obj = {
            socialType: data.authType,
            socialId: data.id,
        }
        if (data.authType === 'Google') {
            obj['googleEmail'] = data.email
        }
        if (data.authType === 'Facebook') {
            obj['facebookEmail'] = data.email
        }
        const res = await updateSocialAuthenticationStatus(obj)
        if (res.data.status) {
            toast.success(res.data.message)
            updateUser({ [data.dataKey]: 1 })
        } else {
            toast.error(res.data.message)
        }
    }

    const authenticateUsingGoogle = useGoogleLogin({
        onSuccess: async ({ access_token }) => {
            const { data } = await axios.get(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${access_token}`)
            connectUserSocialAccount({
                email: data.email,
                firstName: data.givenName,
                lastName: data.familyName,
                id: data.sub,
                authType: 'Google',
                dataKey: 'googleAuthentication',
            })
        },
        onError: (err) => {
            toast.error(err)
        },
        onNonOAuthError: (err) => {
            toast.error(err)
        },
    })

    const sentOTP = async (requestObj) => {
        try {
            const res = await forgetPassword(requestObj)
            if (res.data.status) {
                toast.success('OTP Sent Successfully')
                setResetToken(res.data.data.token)
            }
        } catch (error) {
            toast.error('Something went wrong!')
        }
    }

    const handleResendOtp = useCallback(
        async (verificationType) => {
            if (user.authStatus) {
                const requestObj = {}
                requestObj['authType'] = verificationType
                requestObj['emailOrPhone'] = verificationType === 'email' ? user.personalEmail : user.phone

                if (verificationType === 'phone') {
                    requestObj['countryCode'] = user.countryCode
                }
                sentOTP(requestObj)
            } else {
                toast.error('Something went wrong 33')
            }
        },
        [user.authStatus, user.countryCode, user.personalEmail, user.phone]
    )

    return (
        <div>
            <ToastContainer />
            <div className="flex items-center p-4 mb-4 bg-gray-light rounded">
                <BsFacebook size={32} className="text-blue-500" />
                <p className="text-sm text-primary-blue font-medium mx-4">Facebook</p>
                <p className="text-gray-400 text-sm mr-4 ml-auto">
                    {user.facebookAuthentication ? <span className="text-parrot">Connected</span> : 'Not Connected'}
                </p>
                {!user.facebookAuthentication && (
                    <FacebookLogin
                        appId={constant.FACEBOOK_APPID}
                        callback={(e) => {
                            if (e.email) {
                                connectUserSocialAccount({
                                    email: e.email,
                                    firstName: e.name.split(' ')[0],
                                    lastName: e.name.split(' ')[1],
                                    authType: 'Facebook',
                                    id: e.id,
                                    dataKey: 'facebookAuthentication',
                                })
                            } else {
                                toast.error('Something went very wrong.')
                            }
                        }}
                        fields="name,email,picture,gender,birthday"
                        render={(renderProps) => (
                            <button onClick={renderProps.onClick} className=" p-2 px-2 rounded-md text-white bg-primary-blue text-xs">
                                Connect Now
                            </button>
                        )}
                    />
                )}
            </div>
            <div className="flex items-center p-4 mb-4 bg-gray-light rounded">
                <FcGoogle size={32} className="text-blue-500" />
                <p className="text-sm text-primary-blue font-medium mx-4">Google</p>
                <p className="text-gray-400 text-sm mr-4 ml-auto">
                    {user.googleAuthentication ? <span className="text-parrot">Connected</span> : 'Not Connected'}
                </p>
                {!user.googleAuthentication && (
                    <button onClick={() => authenticateUsingGoogle()} className=" p-2 px-2 rounded-md text-white bg-primary-blue text-xs">
                        Connect Now
                    </button>
                )}
            </div>

            <div className="grid  md:w-auto sm:grid-cols-2 lg:grid-cols-4 gap-x-10">
                <SocialCard
                    icon="/assets/resources/material-email.png"
                    label="Personal Email Verification"
                    verified={user.personalEmailVerification === 1}
                    handleResendOtp={handleResendOtp}
                    verificationType={'email'}
                    Popup={function ({ onClose, popup }) {
                        return (
                            <VerificationModal
                                verificationType="email"
                                header="Email Address Verification"
                                description="Please check your email and enter the OTP. Check your spam folder if the
                first email doesn't reach your inbox."
                                onClose={onClose}
                                handleResendOtp={handleResendOtp}
                            />
                        )
                    }}
                />
                <SocialCard
                    icon="/assets/resources/material-flite.png"
                    label="Airline Email Verification"
                    verified={user.airlineEmailVerification === 1}
                    handleResendOtp={handleResendOtp}
                    verificationType={'email'}
                    Popup={function ({ onClose, popup }) {
                        return (
                            <AirlineVerificationModal
                                header="Verify your Airline Email."
                                description="Please check your email for verification link"
                                onClose={onClose}
                                handleResendOtp={handleResendOtp}
                            />
                        )
                    }}
                />
                <SocialCard
                    icon="/assets/resources/material-phone.png"
                    label="Mobile Number Verification"
                    verified={user.phoneVerification === 1}
                    handleResendOtp={handleResendOtp}
                    verificationType={'phone'}
                    Popup={function ({ onClose, popup }) {
                        return (
                            <VerificationModal
                                verificationType="phone"
                                header="Mobile Number Verification"
                                description="Crewmates has sent the OTP to your registered mobile number."
                                onClose={onClose}
                                handleResendOtp={handleResendOtp}
                            />
                        )
                    }}
                />
                <SocialCard
                    icon="/assets/resources/material-contact.png"
                    label="Selfie & Id Verification"
                    verified={user.idVerificationStatus === 1}
                    handleResendOtp={handleResendOtp}
                    Popup={function ({ onClose, popup }) {
                        return (
                            <PersonaVerificationModal
                                userId={user.id}
                                header="Selfie & ID Verification"
                                description=" Verify ID and Selfie with Persona"
                                onClose={onClose}
                                handleResendOtp={handleResendOtp}
                            />
                        )
                    }}
                />
            </div>
        </div>
    )
}

export default VerifyDetails
