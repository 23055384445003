import React from 'react'
import { BsHeartFill, BsHeart } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import moment from 'moment'
import StarRatings from 'react-star-ratings'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { processMediaUrl } from '../_helper/utils'

function HomeRoomCard({ featuredPropertiesList, handleFavouriteProperty, booking1 }) {
    const history = useHistory()
    const HOT = 'Hot bed'
    const COLD = 'Cold bed'
    const FLEXIBLE = 'Flexible'

    const ENTIREPLACE = 'Entire Place'
    const CRASHPAD = 'Crashpad'

    const calculateRoomAvgPrice = (sleepingArrangements, searchBookingType = HOT, bookingType, rentalType) => {
        let price = 0
        let totalBed = 0

        if (rentalType === CRASHPAD) {
            if (bookingType === HOT || (bookingType === FLEXIBLE && searchBookingType === HOT)) {
                sleepingArrangements.map(({ beds }) => {
                    totalBed += beds.length || 0
                    beds.map((curr) => {
                        price += curr.perNightPrice
                    })
                })
                totalBed = totalBed || 1
                // return `${Math.round(price / totalBed)} avg / night`;
                return {
                    str: `${Math.round(price / totalBed)} avg / night`,
                    price: Math.round(price / totalBed),
                    suffix: 'avg per night',
                }
            } else if (bookingType === COLD || (bookingType === FLEXIBLE && searchBookingType === COLD)) {
                sleepingArrangements.map(({ beds }) => {
                    totalBed += beds.length || 0
                    beds.map((curr) => {
                        price += curr.perMonthPrice
                    })
                })
                totalBed = totalBed || 1
                // return `${Math.round(price / totalBed)} avg / month`;

                return {
                    str: `${Math.round(price / totalBed)} avg / month`,
                    price: Math.round(price / totalBed),
                    suffix: 'avg per month',
                }
            }
        }
    }
    function priceCalculator(property) {
        if (property.rentalType === ENTIREPLACE) {
            if (property.bookingType === COLD || property.bookingType === FLEXIBLE) {
                return {
                    str: `${property.perMonthPrice}/ month`,
                    price: property.perMonthPrice,
                    suffix: 'Per month',
                }
            } else if (property.bookingType === HOT || property.bookingType === FLEXIBLE) {
                return {
                    str: `${property.perNightPrice}/ night`,
                    price: property.perNightPrice,
                    suffix: 'Per night',
                }
            }
        } else {
            const calc = calculateRoomAvgPrice(property?.sleepingArrangements || [], 'Cold bed', property.bookingType, property.rentalType, property)
            return calc
        }
    }

    return (
        <div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-[382px,382px,382px] items-center gap-10  mt-10 md:mt-20 justify-items-center lg:justify-items-start justify-center lg:mx-[3rem] ">
            {featuredPropertiesList.map((property) => {
                const navigationString = `/property-details/${property._id ? property._id : property.id}?bookingType=${
                    property.bookingType === 'Hot bed' ? 'Hot bed' : 'Cold bed'
                }&checkIn=${moment().format('YYYY-MM-DD')}&checkOut=${moment().add(1, 'day').format('YYYY-MM-DD')}&price=${
                    property.perMonthPrice ? property.perMonthPrice : property.perNightPrice
                }`
                return (
                    <div>
                        <div className=" hidden md:block" key={property.id}>
                            <div
                                className={`rounded-3xl h-32  md:h-auto max-w-full shadow-lg md:shadow-xl overflow-hidden grid grid-cols-6 grid-rows-2 md:grid-rows-1 text-gray-500`}
                            >
                                <div className="w-full h-full relative rounded-t-3xl  md:h-[14rem] col-span-2 md:row-start-1 md:col-span-full row-span-full md:row-span-1">
                                    <img alt="axon" className="w-full h-full object-cover" src={processMediaUrl(property.images[0])} />
                                    {property.isFavourite ? (
                                        <BsHeartFill
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                handleFavouriteProperty(property)
                                            }}
                                            size={24}
                                            color={'red'}
                                            className={` cursor-pointer  absolute top-5 right-5 z-50`}
                                        />
                                    ) : (
                                        <BsHeart
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                handleFavouriteProperty(property)
                                            }}
                                            size={24}
                                            color={'white'}
                                            className={` cursor-pointer  absolute top-5 right-5 z-50`}
                                        />
                                    )}
                                </div>
                                <div
                                    onClick={() => {
                                        history.push(navigationString)
                                    }}
                                    className="cursor-pointer md:w-[382px] lg:w-[298px] xl:w-[382px] px-4 py-2 md:p-4 flex justify-between items-center row-start-1 md:row-start-2 col-span-4 md:col-span-full"
                                >
                                    <div className="w-7/12">
                                        <h4 className="text-base font-medium truncate ">{property.listingName}</h4>
                                        <p className="text-xs font-light">${priceCalculator(property).str}</p>
                                        <p className="text-xs font-light text-bold">{property.host?.name}</p>
                                    </div>

                                    <div className="flex-shrink-0  flex items-end flex-col justify-center">
                                        <span className="flex-center text-yellow-500">
                                            <span className="text-yellow-500 mb-1">
                                                <StarRatings
                                                    rating={property.rating}
                                                    starDimension="18px"
                                                    starSpacing="0px"
                                                    starRatedColor="#eab308"
                                                />
                                            </span>
                                        </span>
                                        <p className="text-xs text-parrot-dark ">{property.totalReview} Reviews</p>
                                    </div>
                                </div>

                                <div className="px-4 py-2 md:p-4 flex lg:justify-end items-center row-start-2 md:row-start-3 col-span-4 md:col-span-full">
                                    <button className="flex-shrink-0 ml-2 text-parrot text-sm font-semibold  ">
                                        <span>
                                            <Link to={navigationString}>View Now</Link>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div
                            className="cursor-pointer md:hidden"
                            key={property.id}
                            onClick={() => {
                                history.push(navigationString)
                            }}
                        >
                            <div
                                className={`rounded-3xl h-32 max-w-lg md:h-auto shadow-lg md:shadow-xl overflow-hidden grid grid-cols-6 grid-rows-1 text-gray-500`}
                            >
                                <div className="w-full h-full relative   rounded-t-3xl col-span-2 md:row-start-1 md:col-span-full row-span-full md:row-span-1">
                                    <img alt="axon" className="w-full h-full object-cover" src={processMediaUrl(property.images[0])} />
                                </div>
                                <div className="px-4 py-2 md:p-4 flex justify-between items-center row-start-1 md:row-start-2 col-span-4 md:col-span-full">
                                    <div className="w-full  ">
                                        <h4 className="text-base font-medium truncate ">{property.listingName}</h4>
                                        <div className="flex-shrink-0  flex items-start sm:flex-row flex-col sm:justify-start sm:items-center sm:gap-5 justify-center">
                                            <span className="flex-center text-yellow-500">
                                                <span className="text-yellow-500 mb-1">
                                                    <StarRatings
                                                        rating={property.rating}
                                                        starDimension="18px"
                                                        starSpacing="0px"
                                                        starRatedColor="#eab308"
                                                    />
                                                </span>
                                            </span>
                                            <p className="text-xs text-parrot-dark ">{property.totalReview} Reviews</p>
                                        </div>
                                        <p className="text-xs font-light mt-1.5 sm:mt-0">${priceCalculator(property).str}</p>
                                        <div className="w-full py-1 md:p-4 flex justify-between lg:justify-end items-center row-start-2 md:row-start-3 col-span-4 md:col-span-full">
                                            <button className="flex-shrink-0 text-parrot text-sm font-semibold  ">
                                                <span>
                                                    <Link to={navigationString}>View Now</Link>
                                                </span>
                                            </button>
                                            {property.isFavourite ? (
                                                <BsHeartFill
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        handleFavouriteProperty(property)
                                                    }}
                                                    size={24}
                                                    color={'red'}
                                                    className={` cursor-pointer   border border-solid  `}
                                                />
                                            ) : (
                                                <BsHeart
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        handleFavouriteProperty(property)
                                                    }}
                                                    size={24}
                                                    color={'#B8B8B8'}
                                                    className={` cursor-pointer   `}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default HomeRoomCard
