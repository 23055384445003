import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Section from "@layout/Section";

import "swiper/swiper-bundle.css";

// import Swiper core and required modules
import SwiperCore, { Autoplay } from "swiper";

SwiperCore.use([Autoplay]);

function Partners() {
  return (
    <Section className="mt-20 mb-20 px-4">
      <h2 className="text-center  primary__heading">Our Partners</h2>

      <div className="mt-20 my-40 mx-auto flex items-center justify-center ">
        <Swiper
          freeMode
          spaceBetween={20}
          autoplay={{ delay: 10 }}
          speed={1500}
          slidesPerView={"auto"}
        >
          <SwiperSlide className="w-max px-2">
            <img
              alt="axon"
              className="self-center h-12 "
              src="/assets/resources/axon.png"
            />
          </SwiperSlide>
          <SwiperSlide className="w-max px-2">
            <img
              alt="qantas"
              className="self-center h-12 "
              src="/assets/resources/qantas.png"
            />
          </SwiperSlide>
          <SwiperSlide className="w-max px-2">
            <img
              alt="jetstar"
              className="self-center h-12 "
              src="/assets/resources/jetstar.png"
            />
          </SwiperSlide>
          <SwiperSlide className="w-max px-2">
            <img
              alt="litalla"
              className="self-center h-12 "
              src="/assets/resources/litalla.png"
            />
          </SwiperSlide>
          <SwiperSlide className="w-max px-2">
            <img
              alt="expedia"
              className="self-center h-12 "
              src="/assets/resources/expedia.png"
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </Section>
  );
}

export default Partners;
